import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import { TopicMessage } from '@index/interfaces/topic';
import { GthTopicMessage } from '../../../../../sentinels/src/lib/models/topic-message';
import { DBUtil } from '@index/utils/db-utils';
import { first, map, switchMap } from 'rxjs/operators';
import { GthUserModel } from '@sentinels/models';
import { GthCacheEventService } from '../cache/event.service';
import { Observable, combineLatest, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GthTopicMessagesFunctionService {
  constructor(
    private firestore: AngularFirestore,
    private events: GthCacheEventService,
  ) { }

  list$(
    topicId: string,
    teamId: string,
    teamUsers: GthUserModel[],
  ): Observable<GthTopicMessage[]> {
    return this.firestore
      .collection(`${DBUtil.TeamTopics}/${teamId}/topics/${topicId}/messages`)
      .snapshotChanges()
      .pipe(
        map((collection) => {
          if (!collection || collection.length === 0) {
            return [];
          }

          return collection.map((item) => {
            const doc = item.payload.doc;
            const { id } = doc;
            const data = doc.data();
            return {
              id,
              ...data as object,
              lastUpdatedTime: (data as any).lastUpdatedTime.toDate(),
              createdTime: (data as any).createdTime.toDate(),
            } as TopicMessage;
          });
        }),
        switchMap((dbMessages) => {
          if (!dbMessages) {
            return of([]);
          }
          const messages = dbMessages
            .map((m) => new GthTopicMessage(m.id, m))
            .map((m) => {
              // Populate sender
              if (m.sender) {
                const sender = teamUsers.find((u) => u.id === m.sender);
                m.setSender(sender);
              }
              return m;
            });
          const eventIds = messages
            .map((m) => m.eventId)
            .filter((m) => m);
          const uniqueEventids = [...new Set(eventIds)];
          if (eventIds.length === 0) {
            return of(messages);
          }
          const events$ = uniqueEventids.map((eventId) => {
            return this.events.getEventById$(eventId);
          });
          return combineLatest(events$).pipe(
            map((events) => {
              return messages.map((m) => {
                if (!m.eventId) {
                  return m;
                }
                const event = events.find((e) => m.eventId);
                if (!event) {
                  return m;
                }
                m.setEvent(event);
                return m;
              });
            }),
          );
        }),
      );
  }

  /**
   * Creates a new message
   * @param {string} topicId Id of the topic
   * @param {string} teamId Id of the team
   * @param {TopicMessage} message New Message Content
   * @return {string} New Message Id
   */
  async create(topicId: string, teamId: string, message: TopicMessage): Promise<string> {
    const collection = await this.firestore
      .collection(`${DBUtil.TeamTopics}/${teamId}/topics/${topicId}/messages`);

    try {
      const newMessage = {
        ...message,
        lastUpdatedTime: new Date(),
        createdTime: new Date(),
      };
      delete newMessage.isFavorite;
      const messageId = await collection.add(message);
      return messageId.id;
    } catch {
      return undefined;
    }
  }

  /**
   * Updates an existing message
   * @param {string} topicId Id of the topic
   * @param {string} teamId Id of the team
   * @param {TopicMessage} message New Message Content
   * @return {boolean} True if success
   */
  async update(topicId: string,
    teamId: string,
    message: TopicMessage): Promise<boolean> {
    const doc = await this.firestore
      .doc(`${DBUtil.TeamTopics}/${teamId}/topics/${topicId}/messages/${message.id}`)
      .get()
      .pipe(
        first(),
      )
      .toPromise();
    if (!doc.exists) {
      return false;
    }
    try {
      await doc.ref.update({
        content: message.content,
        lastUpdatedTime: new Date(),
        eventId: message.eventId,
      });
      return true;
    } catch {
      return false;
    }
  }

  /**
   * Creates a new message
   * @param {string} topicId Id of the topic
   * @param {string} teamId Id of the team
   * @param {string} messageId Id of the message
   * @param {TopicMessage} message New Message Content
   * @return {boolean} True if success
   */
  async delete(topicId: string, teamId: string, messageId: string): Promise<boolean> {
    const doc = await this.firestore
      .doc(`${DBUtil.TeamTopics}/${teamId}/topics/${topicId}/messages/${messageId}`)
      .get()
      .pipe(
        first(),
      )
      .toPromise();
    try {
      await doc.ref.delete();
      return true;
    } catch {
      return false;
    }
  }

  /**
   * Creates a new message
   * @param {string} topicId Id of the topic
   * @param {string} teamId Id of the team
   * @param {string} messageId Id of the message
   * @param {string} userId Id of the user
   * @param {boolean} value True if favorite
   * @return {boolean} True if success
   */
  favorite(topicId: string,
    teamId: string,
    messageId: string,
    userId: string,
    value: boolean): Promise<boolean> {
    return Promise.resolve(false);
  }
}
