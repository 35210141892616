<h1 mat-dialog-title>Attention</h1>
<div mat-dialog-content>
  <p>
    Please note: Updating the players status from this screen does not generate a refund or collect payments. You must finish this process through the appropriate payment channels.
  </p>
</div>
<div mat-dialog-actions class="fx-gap-10x fx-align-end row">
  <button mat-button class="dialog-button" (click)="onCancelButtonClick()">Cancel</button>
  <button
    mat-button
    class="dialog-button"
    (click)="onContinueButtonClick()"
    color="warn"
  >
    Ok
  </button>
</div>
