import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/compat/firestore';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { Observable, combineLatest, of } from 'rxjs';
import { distanceBetween } from 'geofire-common';
import firebase from 'firebase/compat/app';

import { DBUtil } from '@index/utils/db-utils';
import { TeamModel } from '@index/models/team';
import { GthCloudFunctionService } from './cloud/cloud-function.service';
import { GthErrorLoggerService } from './cloud/error-logger.service';
import { TeamSurvey, SurveyResult, Team, RosterV2, Invoice, User } from '@index/interfaces';
import { GthUserModel, GthInvoiceModel, GthTeamModel } from '@sentinels/models';

const CONTEXT = 'TeamsService';

@Injectable({ providedIn: 'root' })
export class TeamsService {
  constructor(
    private cloudFunctionService: GthCloudFunctionService,
    private logger: GthErrorLoggerService,
    private firestore: AngularFirestore,
  ) { }

  /**
   * Get team survey
   * @param {string} teamId - The team ID to get the surveys of
   * @param {string} surveyId - The team ID to get the surveys of
   * @return {TeamSurvey} - The team survey or null if not found
   */
  public async getTeamSurvey(
    teamId: string, surveyId: string,
  ): Promise<TeamSurvey & { id: string }> {
    const path = `${DBUtil.Team}/${teamId}/surveys`;
    const collectionRef = this.firestore.collection<TeamSurvey>(path);
    const docRef = collectionRef.doc(surveyId);
    const docSnap = await docRef.get().pipe(take(1)).toPromise();
    if (!docSnap.exists) return null;

    this.log(`Team survey for ${teamId} fetched by id`);

    return Object.assign({ id: surveyId }, docSnap.data()) as TeamSurvey & { id: string };
  }

  /**
   * Get team surveys
   * @param {string} teamId - The team ID to get the surveys of
   * @return {Observable<TeamSurvey[]>} teamSurveys$
   */
  public getTeamSurveys$(teamId: string) {
    const path = `${DBUtil.Team}/${teamId}/surveys`;
    const collectionRef = this.firestore.collection<TeamSurvey>(path);
    return collectionRef.valueChanges({ idField: 'id' }).pipe(
      take(1),
      map((surveys) => {
        this.log(`Team surveys for ${teamId} fetched`);
        return surveys;
      }),
    );
  }

  /**
   * Create team survey
   * @param {string} teamId - The team ID to create survey for
   * @param {TeamSurvey} survey - The survey to create
   * @return {Promise<DocumentReference>}
   */
  public createTeamSurvey(teamId: string, survey: TeamSurvey): Promise<DocumentReference> {
    const path = `${DBUtil.Team}/${teamId}/${DBUtil.TeamSurvey}`;
    const collectionRef = this.firestore.collection(path);
    /** Set Team Survey Created and Updated TimeStamps */
    survey.created = firebase.firestore.Timestamp.now();
    survey.updated = firebase.firestore.Timestamp.now();
    this.log(`Team survey created`);
    return survey?.id ?
      collectionRef.doc(survey.id)
        .set(survey, { merge: true })
        .then(() => survey as unknown as DocumentReference<TeamSurvey>) :
      collectionRef.add(survey) as Promise<DocumentReference<TeamSurvey>>;
  }

  /**
   * Delete team survey
   * @param {string} teamId - The team ID to delete survey from
   * @param {string} surveyId - The survey ID to delete
   * @return {Promise<void>}
   */
  public async deleteTeamSurvey(teamId: string, surveyId: string): Promise<boolean> {
    const batch = this.firestore.firestore.batch();

    /** Delete Team Survey Document */
    const path = `${DBUtil.Team}/${teamId}/surveys/${surveyId}`;
    const docRef = this.firestore.doc<TeamSurvey>(path).ref;
    this.log(`Team survey for ${teamId} deleted: ${surveyId}`);
    batch.delete(docRef);

    /** Delete Team Survey Results Collection */
    const resultsRef = docRef.collection(DBUtil.TeamSurveyResult);
    const snaps = await resultsRef.get();
    if (!snaps.empty) {
      for (const snap of snaps.docs) {
        batch.delete(snap.ref);
      }
    }

    /** Commit batch operations */
    return batch.commit()
      .then(() => true)
      .catch(() => false);
  }

  /**
   * Update team survey
   * @param {string} teamId - The team ID to update survey for
   * @param {TeamSurvey} survey - The survey to update with
   * @return {Promise<DocumentReference>}
   */
  public updateTeamSurvey(teamId: string, survey: TeamSurvey): Promise<void> {
    const path = `${DBUtil.Team}/${teamId}/${DBUtil.TeamSurvey}`;
    const collectionRef = this.firestore.collection(path);
    const docRef = collectionRef.doc(survey.id);
    /** Set Team Survey Updated Timestamp */
    survey.updated = firebase.firestore.Timestamp.now();
    this.log(`Team survey for ${teamId} updated: ${survey.id}`);
    return docRef.update(survey);
  }

  public createTeamSurveyResult(
    teamId: string,
    surveyId: string,
    playerId: string,
    result: SurveyResult,
  ) {
    // eslint-disable-next-line max-len
    if (!result.id) throw new Error(`Something went wrong creating team survey result! Missing player id in team survey result.`);

    const batch = this.firestore.firestore.batch();

    /** Update Team Document */
    const teamPath = `${DBUtil.Team}/${teamId}`;
    const teamDocRef = this.firestore.doc(teamPath).ref;
    batch.update(teamDocRef, 'updated', firebase.firestore.Timestamp.now());
    this.log(`Team survey for ${teamId} result collected`);

    /** Update Team Survey Document */
    const surveyDocRef = teamDocRef.collection(DBUtil.TeamSurvey).doc(surveyId);
    batch.update(surveyDocRef, 'updated', firebase.firestore.Timestamp.now());

    /** Create Team Survey Result Document */
    const surveyResultRef = surveyDocRef.collection(DBUtil.TeamSurveyResult).doc(result.id);
    /** Set Team Survey Result Created and Updated TimeStamps */
    result.created = firebase.firestore.Timestamp.now();
    result.updated = firebase.firestore.Timestamp.now();
    batch.set(surveyResultRef, result);

    /** Commit Batch */
    return batch.commit()
      .then(() => surveyResultRef)
      .catch(() => false);
  }

  public async getTeamSurveyResultSnap(
    teamId: string,
    surveyId: string,
    playerId: string,
  ): Promise<firebase.firestore.DocumentSnapshot<SurveyResult>> {
    // eslint-disable-next-line max-len
    const path = `${DBUtil.Team}/${teamId}/${DBUtil.TeamSurvey}/${surveyId}/${DBUtil.TeamSurveyResult}/${playerId}`;
    const teamSurveyDocRef = this.firestore.doc<SurveyResult>(path).ref;
    this.log(`Team survey for ${teamId} fetched and player ${playerId}`);
    return await teamSurveyDocRef.get();
  }

  public updateTeamSurveyResult(
    teamId: string,
    surveyId: string,
    playerId: string,
    result: SurveyResult,
  ) {
    // eslint-disable-next-line max-len
    if (!result.id) throw new Error(`Something went wrong creating team survey result! Missing player id in team survey result.`);

    const batch = this.firestore.firestore.batch();

    /** Update Team Document */
    const teamPath = `${DBUtil.Team}/${teamId}`;
    const teamDocRef = this.firestore.doc(teamPath).ref;
    batch.update(teamDocRef, 'updated', firebase.firestore.Timestamp.now());

    /** Update Team Survey Document */
    const surveyDocRef = teamDocRef.collection(DBUtil.TeamSurvey).doc(surveyId);
    batch.update(surveyDocRef, 'updated', firebase.firestore.Timestamp.now());

    /** Update Team Survey Result Document */
    const surveyResultRef = surveyDocRef.collection(DBUtil.TeamSurveyResult).doc(result.id);
    /** Set Team Survey Result Created and Updated TimeStamps */
    result.updated = firebase.firestore.Timestamp.now();
    batch.update(surveyResultRef, result);
    this.log(`Team survey for ${teamId} updated and player ${playerId}`);

    /** Commit Batch */
    return batch.commit()
      .then(() => surveyResultRef)
      .catch(() => false);
  }

  public getTeamSurveyResults$(teamId: string, surveyId: string) {
    // eslint-disable-next-line max-len
    const path = `${DBUtil.Team}/${teamId}/${DBUtil.TeamSurvey}/${surveyId}/${DBUtil.TeamSurveyResult}`;
    const collectionRef = this.firestore.collection<SurveyResult>(path);
    return collectionRef.valueChanges({ idField: 'id' }).pipe(
      take(1),
      /** Add user to result from id */
      /* todo: create GthSurveyResultModel */
      switchMap(async (results) => {
        for (const result of results) {
          result.user = await this.cloudFunctionService.user
            .getUserById$(result.id).pipe(take(1)).toPromise();
        }
        this.log(`Team survey for ${teamId} updated and survey ${surveyId} fetched`);
        return results as (SurveyResult & { id: string, user: GthUserModel })[];
      }),
    );
  }

  public async updateTeam(id: string, team: Partial<Team>) {
    /** todo: move to cloud functions */
    const docRef = this.firestore.collection(DBUtil.Team).doc(id);
    const updateTeamData = Object.assign({
      updated: firebase.firestore.Timestamp.now(),
    },
      team,
    );
    this.log(`Team updated: ${id}`);
    await docRef.update(updateTeamData);
  }

  public getAllTeams$() {
    const teams$ = this.firestore.collection(DBUtil.Team)
      .valueChanges({ idField: 'id' }) as unknown as Observable<Team[]>;
    return teams$.pipe(
      take(1),
      map((teams) => teams.filter((t) => t.discoverable)),
      /** Convert Team[] to TeamModel[] and populate roster */
      switchMap((teams) => {
        if (!teams || teams.length === 0) {
          return of([]);
        }
        const requests$ = teams.map((t) => this.getTeamModel$(t));
        this.log(`Teams listed`);
        return combineLatest(requests$);
      }),
    );
  }

  public getTeamsByLocation$(lat: number, lng: number) {
    /** Find cities within 200km of specified location */
    const radiusInM = 200 * 1000;
    const center: [number, number] = [lat, lng];

    const teams$ = this.firestore.collection(DBUtil.Team)
      .valueChanges({ idField: 'id' }) as Observable<Team[]>;
    return teams$.pipe(
      take(1),
      map((teams) => teams.filter((t) => t.discoverable)),
      /** Filter teams that are within 200km of the specified location */
      map((teams) => {
        this.log(`Teams listed by location`);
        return teams.filter((team) => {
          if (!team?.location?.lat || !team?.location?.lng) return false;

          const distanceInKm = distanceBetween(
            [team.location.lat, team.location.lng],
            center,
          );
          const distanceInM = distanceInKm * 1000;
          return distanceInM <= radiusInM;
        });
      }),
      /** Convert Team[] to TeamModel[] and populate roster */
      switchMap((teams) => {
        if (!teams || teams.length === 0) {
          return of([]);
        }
        const requests$ = teams.map((t) => this.getTeamModel$(t));
        return combineLatest(requests$);
      }),
    );
  }

  private getTeamModel$(team: Team): Observable<TeamModel<User>> {
    /** Team to TeamModel */
    const teamModel = new TeamModel<string | User>(
      undefined,
      team.name,
      team.description,
      team.photoURL,
      team.sport,
      team.discoverable,
      team.subscription,
      team.ageGroup,
      team.location,
      team.created,
      team.updated,
      team.website,
      team.displayPublicWebsite,
      team.online,
      team.skillLevel,
      team.roster as unknown as RosterV2<string>[],
    );
    teamModel.id = team.id;

    return of(teamModel as TeamModel<GthUserModel>);
  }

  private getUsersFromRoster$(
    docs: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>[],
  ) {
    const users$ = docs.map((roster) => {
      const id = roster.data().player;
      return this.cloudFunctionService.user.getUserById$(id).pipe(
        map((user) => {
          return {
            role: roster.data().role,
            player: user.model as User,
          };
        }),
      );
    });
    return combineLatest(users$);
  }

  async getInvoice(id: string) {
    const invoiceDocRef = this.firestore
      .collection(DBUtil.Invoices).doc<Invoice>(id);
    const invoiceSnap = await invoiceDocRef.get()
      .pipe(take(1)).toPromise();
    if (!invoiceSnap.exists) {
      this.log(`Invoice not found by id, ${id}`);
      return null;
    }
    this.log(`Invoice fetched by id: ${id}`);
    return invoiceSnap.data();
  }
  getInvoice$(id: string) {
    const invoiceDocRef = this.firestore
      .collection(DBUtil.Invoices).doc<Invoice>(id);
    return invoiceDocRef.valueChanges()
      .pipe(tap(() => this.log(`Invoice fetched by id: ${id}`)));
  }
  async listInvoices(
    teamId: string,
    userId: string,
    filter: 'sent' | 'received',
  ) {
    const invoicesColRef = this.firestore
      .collection<Invoice>(
        DBUtil.Invoices,
        (ref) => ref
          .where('team', '==', teamId)
          .where(filter === 'sent' ? 'from' : 'to', '==', userId),
      );
    const invoicesSnap = await invoicesColRef.get()
      .pipe(take(1)).toPromise();
    if (invoicesSnap.empty) {
      this.log(`No invoices found by team and user ids, respectively; ${teamId} , ${userId}`);
      return null;
    }
    this.log(`Invoices fetched by team and user ids, respectively; ${teamId} , ${userId}`);
    return invoicesSnap.docs
      .map((docSnap) => docSnap.data());
  }
  listInvoices$(
    teamId: string,
    userId: string,
    filter: 'sent' | 'received',
  ): Observable<Invoice[]> {
    const invoicesColRef = this.firestore.collection<Invoice>(DBUtil.Invoices, (ref) => {
      return ref
        .where('team', '==', teamId)
        .where(filter === 'sent' ? 'from' : 'to', '==', userId);
    });
    return invoicesColRef.valueChanges({ idField: 'id' }).pipe(
      tap(() => {
        this.log(`Invoices fetched by team and user ids, respectively; ${teamId} , ${userId}`);
      }),
      /**
       * todo: find why this is resulting in error
       * from cloudFunctionService being undefined
       */
      // switchMap((invoices) => {
      //   return forkJoin(invoices.map(this.convertInvoiceToGthInvoice$));
      // }),
    );
  }

  convertInvoiceToGthInvoice$(invoice: Invoice): Observable<GthInvoiceModel> {
    const team$ = this.cloudFunctionService.team.getTeamById$(invoice.team);
    const to$ = this.cloudFunctionService.user.getUserById$(invoice.to);
    const from$ = this.cloudFunctionService.user.getUserById$(invoice.from);

    return combineLatest([team$, to$, from$]).pipe(
      map(([team, to, from]) => {
        const gthInvoiceModel = new GthInvoiceModel(invoice.id, invoice);
        gthInvoiceModel.team = new GthTeamModel(team.id, team);
        gthInvoiceModel.to = new GthUserModel(to.uid, to);
        gthInvoiceModel.from = new GthUserModel(from.uid, from);

        return gthInvoiceModel;
      }),
    );
  }

  private log(text: string) {
    this.logger.debug(`${CONTEXT}: ${text}`);
  }
}
