<ng-container *ngIf="user">
  <img 
    *ngIf="!photoError && user.photoURL else noImageTmpl"
    class="avatar"
    [matTooltip]="user.displayNameFallback"
    [src]="user.photoURL"
    [style.height.px]="size"
    [style.width.px]="size"
    (error)="photoError = true" />
</ng-container>

<ng-template #noImageTmpl>
  <mat-icon
    class="avatar"
    [matTooltip]="user.displayNameFallback"
    [style.height.px]="size"
    [style.width.px]="size"
    [style.fontSize.px]="size"
  >person</mat-icon>
</ng-template>