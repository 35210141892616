<table class="table" mat-table [dataSource]="dataSource">
  <!-- Name Column -->
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>Name</th>
    <td mat-cell *matCellDef="let element">{{ element.name }}</td>
  </ng-container>

  <!-- Not Paid Column -->
  <ng-container matColumnDef="unpaid">
    <th mat-header-cell *matHeaderCellDef>Not Paid</th>
    <td mat-cell *matCellDef="let element" [class.balance--unpaid]="element.unpaid > 0">
      {{ element.unpaid | currency }}
    </td>
  </ng-container>

  <!-- Paid Column -->
  <ng-container matColumnDef="paid">
    <th mat-header-cell *matHeaderCellDef>Paid</th>
    <td mat-cell *matCellDef="let element" [class.balance--paid]="element.paid > 0">
      {{ element.paid | currency }}
    </td>
  </ng-container>

  <!-- Outstanding Column -->
  <ng-container matColumnDef="outstanding">
    <th mat-header-cell *matHeaderCellDef>Outstanding</th>
    <td mat-cell *matCellDef="let element">
      {{ element.outstanding }} {{ element.outstanding === 1 ? 'person' : 'people' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef class="actions-column"></th>
    <td mat-cell *matCellDef="let element">
      <button
        class="button"
        mat-flat-button
        color="primary"
        (click)="onEditViewButtonClick(element.payment)"
      >
        View/Edit
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns" sticky="true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
