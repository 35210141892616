import {
  EntityType,
  ExternalTeam,
  Location,
} from '@index/interfaces';
import { GthModel } from './model';

export class GthExternalTeamModel extends GthModel<ExternalTeam> {
  public readonly tag = 'external-team';

  constructor(id: string, model: ExternalTeam) {
    super(id, model);

    if (!model) {
      return;
    }
  }

  get id() {
    return this._id;
  }

  get name() {
    return this.model.name;
  }

  get description() {
    return this.model.description;
  }

  get activity() {
    return this.model.activity;
  }

  get sport() {
    return this.model.activity;
  }

  get allowAskToJoin() {
    return this.model.allowAskToJoin;
  }

  get photoURL() {
    return this.model.photoURL;
  }

  get externalURL() {
    return this.model.externalURL;
  }

  get creatorId() {
    return this.model.creator;
  }

  get location() {
    return this.model.location;
  }
  set location(val: Location) {
    this.model.location = val;
  }

  get online() {
    return this.model.online;
  }

  get created() {
    return this.model.created;
  }

  get updated() {
    return this.model.updated;
  }

  get createdDate() {
    return this.model.created.toDate();
  }

  get updatedDate() {
    return this.model.updated.toDate();
  }

  get skillLevel() {
    return this.model.skillLevel ?? [];
  }

  get entityType() {
    return this.model.entityType ?? EntityType.Team;
  }

  get contactEmail() {
    return this.model.contactEmail;
  }

  get contactPhone() {
    return this.model.contactPhone;
  }

  /**
   * Sets the Team Photo Url
   * @param {string} uri
   */
  setPhotoUrl(uri: string) {
    this.model.photoURL = uri;
  }
}
