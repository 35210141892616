import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

@Component({
  selector: 'gth-feedback-dialog',
  templateUrl: './feedback-dialog.component.html',
  styleUrls: ['./feedback-dialog.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
  ],
})
export class GthFeedbackDialogComponent {
  feedback = '';
  rating?: number;
  feedbackType: 'bug' | 'request' | 'general' = 'general';

  constructor(
    private dialogRef: MatDialogRef<GthFeedbackDialogComponent>,
  ) { }

  onSubmitButtonClick() {
    this.dialogRef.close({
      feedback: this.feedback,
      rating: this.rating,
      feedbackType: this.feedbackType,
    });
  }

  onCancelButtonClick() {
    this.dialogRef.close(undefined);
  }
}
