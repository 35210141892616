<div class="row text-center">
  <div class="col-md-4">
    <mat-button-toggle-group>
      <mat-button-toggle
        mwlCalendarPreviousView
        [view]="view"
        [(viewDate)]="viewDate"
        (viewDateChange)="closeOpenMonthViewDay()">Previous</mat-button-toggle>
      <mat-button-toggle
        mwlCalendarToday
        [(viewDate)]="viewDate">Today</mat-button-toggle>
      <mat-button-toggle
        class="btn btn-primary"
        mwlCalendarNextView
        [view]="view"
        [(viewDate)]="viewDate"
        (viewDateChange)="closeOpenMonthViewDay()">Next</mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <div class="col-md-4">
    <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
  </div>
  <div class="col-md-4">
    <mat-button-toggle-group>
      <mat-button-toggle
        (click)="setView(CalendarView.Month)"
        [class.active]="view === CalendarView.Month">Month</mat-button-toggle>
      <mat-button-toggle
        (click)="setView(CalendarView.Week)"
        [class.active]="view === CalendarView.Week">Week</mat-button-toggle>
      <mat-button-toggle
        (click)="setView(CalendarView.Day)"
        [class.active]="view === CalendarView.Day">Day</mat-button-toggle>
    </mat-button-toggle-group>
  </div>
</div>
<br />
<div [ngSwitch]="view">
  <mwl-calendar-month-view
    *ngSwitchCase="CalendarView.Month"
    [viewDate]="viewDate"
    [events]="events$ | async"
    [refresh]="refresh"
    [activeDayIsOpen]="activeDayIsOpen"
    (dayClicked)="dayClicked($event.day)"
    (eventClicked)="handleEvent('Clicked', $event.event)" />
  <mwl-calendar-week-view
    *ngSwitchCase="CalendarView.Week"
    [viewDate]="viewDate"
    [events]="events$ | async"
    [refresh]="refresh"
    (eventClicked)="handleEvent('Clicked', $event.event)" />
  <mwl-calendar-day-view
    *ngSwitchCase="CalendarView.Day"
    [viewDate]="viewDate"
    [events]="events$ | async"
    [refresh]="refresh"
    (eventClicked)="handleEvent('Clicked', $event.event)" />
</div>

<!-- Everything you see below is just for the demo, you don't need to include it in your app -->
