<div class="stored-video fx-fill" *ngIf="videoPath$ | async as path" fxLayout="column">
  <video class="stored-video__image" #videoPlayer (click)="onVideoClick($event)">
    <source [src]="path | getDownloadURL" type="video/mp4">
    Your browser does not support the video tag.
  </video>

  <button class="fab-button" mat-mini-fab color="primary" aria-label="Play" *ngIf="!playing"
    (click)="onPlayButtonClick($event)">
    <mat-icon>play_arrow</mat-icon>
  </button>
</div>
