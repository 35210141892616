import firebase from 'firebase/compat/app';

export interface PendingInvitation {
  email?: string;
  phoneNumber?: string;
  phoneNumberPermission?: PhoneNumberPermission;
  createdBy: string;
  type: PendingInvitationType;
  invitedTo: string;
  isActedOn: boolean;
  createdOn: firebase.firestore.Timestamp;
  actedOn?: firebase.firestore.Timestamp;
}
export enum PhoneNumberPermission {
  FullAccess = 'Full Access',
  SMSOnly = 'SMS Only',
}

export enum PendingInvitationType {
  Team = 'team',
}

export interface CreatePendingInvitationContract {
  type: PendingInvitationType,
  createdBy: string,
  invitedTo: string,
  email?: string,
  phoneNumber?: string,
  phoneNumberPermission?: PhoneNumberPermission,
}
export interface ListPendingInvitationContract {
  email?: string,
  phoneNumber?: string,
}
