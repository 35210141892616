import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { take, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { DBUtil } from '@index/utils/db-utils';
import { Transaction } from '@index/interfaces';
import { GthErrorLoggerService } from './cloud/error-logger.service';

const CONTEXT = 'TransactionsService';

@Injectable({ providedIn: 'root' })
export class TransactionsService {
  constructor(
    private logger: GthErrorLoggerService,
    private firestore: AngularFirestore,
  ) {}

  async getTransaction(id: string) {
    const transactionDocRef = this.firestore
      .collection(DBUtil.Transactions).doc<Transaction>(id);
    const transactionSnap = await transactionDocRef.get()
      .pipe(take(1)).toPromise();
    if (!transactionSnap.exists) {
      this.log(`Transaction not found by id, ${id}`);
      return null;
    }
    this.log(`Transaction fetched by id: ${id}`);
    return transactionSnap.data();
  }
  getTransaction$(id: string) {
    const transactionDocRef = this.firestore
      .collection(DBUtil.Transactions).doc<Transaction>(id);
    return transactionDocRef.valueChanges()
      .pipe(tap(() => this.log(`Transaction fetched by id: ${id}`)));
  }
  async listTransactions(userId: string, filter: 'incoming' | 'outgoing') {
    const transactionsColRef = this.firestore
      .collection<Transaction>(
        DBUtil.Transactions,
        (ref) => ref
          .where(filter === 'incoming' ? 'to' : 'from', '==', userId),
      );
    const transactionsSnap = await transactionsColRef.get()
      .pipe(take(1)).toPromise();
    if (transactionsSnap.empty) {
      this.log(`No ${filter} transactions found by user id: ${userId}`);
      return null;
    }
    const capFirstLetterFilter = filter.charAt(0).toUpperCase() + filter.slice(1);
    this.log(`${capFirstLetterFilter} transactions fetched by user id: ${userId}`);
    return transactionsSnap.docs
      .map((docSnap) => docSnap.data());
  }
  listTransactions$(
    userId: string,
    filter: 'incoming' | 'outgoing',
  ): Observable<Transaction[]> {
    const transactionsColRef = this.firestore.collection<Transaction>(
      DBUtil.Transactions, (ref) => {
      return ref
        .where(filter === 'incoming' ? 'to' : 'from', '==', userId);
    });
    return transactionsColRef.valueChanges({ idField: 'id' }).pipe(
      tap(() => {
        const capFirstLetterFilter = filter.charAt(0).toUpperCase() + filter.slice(1);
        this.log(`${capFirstLetterFilter} transactions fetched by user id: ${userId}`);
      }),
    );
  }

  private log(text: string) {
    this.logger.debug(`${CONTEXT}: ${text}`);
  }
}
