import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'gth-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class GthFileUploadComponent {
  @Input()
  get label() {
    return this._label;
  }
  set label(val: string) {
    this._label = val;
  }

  @Input()
  get accepts() {
    return this._accepts;
  }
  set accepts(val: string) {
    this._accepts = val;
  }

  @Input()
  get multiple() {
    return this._multiple;
  }
  set multiple(val: boolean) {
    this._multiple = val;
  }

  @Input()
  get disabled() {
    return this._disabled;
  }
  set disabled(val: boolean) {
    this._disabled = val;
  }

  @Input()
  maxUploadSize = 2;

  @Output()
  filesSelect = new EventEmitter<File[]>();

  files: File[] = [];
  private _label = 'Upload your file';
  private _accepts = 'png';
  private _multiple = false;
  private _disabled = false;

  constructor(private snackbar: MatSnackBar) { }

  onFileInput(evt: any) {
    const files = evt.target.files;
    let oversize = 0;
    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const fileSize = file.size / (1024 ** 2);
        if (fileSize <= this.maxUploadSize) {
          this.files.push(file);
        } else {
          oversize++;
        }
      }

      if (oversize > 0) {
        let message = 'File is too large';
        if (oversize > 1) {
          message = `${oversize} files are too large`;
        }

        this.snackbar.open(message);
      }

      if (files.length === 0) {
        return;
      }
      this.filesSelect.emit(this.files);
    }
  }

  getFileSize(file: File) {
    const decimals = 0;
    const bytes = file.size;
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  onDeleteImageButtonClick(index: number) {
    this.files.splice(index, 1);
  }
}
