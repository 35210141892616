import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { EventFormComponent } from './event-form.component';
import { GthEventFormModule } from '@gth-legacy/components/event-form/event-form.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    GthEventFormModule,
  ],
  declarations: [EventFormComponent],
  exports: [EventFormComponent],
})
export class EventFormModule { }
