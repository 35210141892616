import firebase from 'firebase/compat/app';

import { PendingInvitation, PendingInvitationType } from '@index/interfaces/pending-invitation';
import { GthModel } from './model';
import { GthTeamModel } from './team';

export class GthPendingInvitation extends GthModel<PendingInvitation> {
  constructor(id: string, model: PendingInvitation) {
    super(id, model);
  }

  get id() {
    return this._id;
  }

  get email() {
    return this.model.email;
  }

  get phoneNumber() {
    return this.model.phoneNumber;
  }

  get phoneNumberPermission() {
    return this.model.phoneNumberPermission;
  }

  get createdBy(): string {
    return this.model.createdBy;
  }

  get type(): PendingInvitationType {
    return this.model.type;
  }

  get invitedTo(): string {
    return this.model.invitedTo;
  }

  get isActedOn(): boolean {
    return this.model.isActedOn;
  }

  get createdOn(): Date {
    return this.model.createdOn.toDate();
  }

  get actedOn(): Date {
    return this.model.actedOn.toDate();
  }

  get team() {
    return this._team;
  }
  set team(team: GthTeamModel) {
    this._team = team;
  }

  private _team?: GthTeamModel;

  markComplete() {
    this.model.isActedOn = true;
    this.model.actedOn = firebase.firestore.Timestamp.now();
  }
}
