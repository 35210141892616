import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import { CloudCollectionService } from './cloud-collection.service';
import { DBUtil } from '@index/utils/db-utils';
import { GameType } from '@index/interfaces';
import { SrvCacheService } from '../cache.service';
import { GthGameTypeModel } from '../../models/game-type';

const COLLECTION_NAME = DBUtil.GameTypes;

@Injectable({
  providedIn: 'root',
})
export class SrvGameTypesService {
  private cloudCollection = new CloudCollectionService(this.firestore, COLLECTION_NAME);

  constructor(
    private firestore: AngularFirestore,
    private cache: SrvCacheService,
  ) {
  }

  /**
   * Gets a list of all game types
   * @return {Promise<GthGameTypeModel[]>} Returns a list of game types
   */
  async listAsync(): Promise<GthGameTypeModel[]> {
    const store = this.cache.read<GthGameTypeModel[]>(COLLECTION_NAME);
    if (store) return store;

    const items = await this.cloudCollection.list<GameType>();
    if (!items) return undefined;
    const models = items.map((i) => new GthGameTypeModel(i.id.toString(), i));
    this.cache.store(COLLECTION_NAME, models);
    return models;
  }

  async groupedAsync() {
    const gameTypes = await this.listAsync();
    const grouped: any = {};

    gameTypes.forEach((gameType) => {
      if (!grouped[gameType.type]) {
        grouped[gameType.type] = [];
      }
      grouped[gameType.type].push(gameType);
    });

    return grouped;
  }
}
