<div class="nav-bar card-bg">
  <div class="image-container">
    <picture [routerLink]="[MEH_APP_ROUTES.Home]">
      <img class="logo" [src]="getLogo(theme$ |async)" loading="lazy" aria-label="My Event Hero Logo" />
    </picture>
  </div>
  <div class="spacer"></div>

  <mat-slide-toggle (change)="onThemeToggleChange($event.checked)" color="primary"
    [checked]="(theme$ | async) === 'light'">
    <mat-icon class="toggle-icon" color="primary">{{ (theme$ | async) === 'dark' ? 'dark_mode' : 'light_mode' }}</mat-icon>
  </mat-slide-toggle>

  <button mat-button *ngIf="user$ | async as user; else loginTmpl" [matMenuTriggerFor]="menu">
    <div class="user-btn">
      <img class="user-img" *ngIf="!userPhotoError && user.photoURL; else imgPlaceholder" [src]="user.photoURL"
        (error)="userPhotoError = true" />
      <span class="user-name">{{ user.displayNameFallback }}</span>
      <mat-icon>arrow_drop_down</mat-icon>
    </div>

    <mat-menu #menu="matMenu">
      <button mat-menu-item [routerLink]="[MEH_APP_ROUTES.Events]">View Events</button>
      <button mat-menu-item [routerLink]="[MEH_APP_ROUTES.Settings]">Settings</button>
      <button mat-menu-item [routerLink]="[MEH_APP_ROUTES.Analytics]">Admin Tools</button>
      <mat-divider></mat-divider>
      <button mat-menu-item (click)="onFeedbackButtonClick()">Submit Feedback</button>
      <button mat-menu-item (click)="onLogoutButtonClick()">Logout</button>
      <!--additional info is only set via Gametime Hero, so we can assume users that have that property are full users-->
      <ng-container *ngIf="!user.additionalInfo">
        <a mat-menu-item href="https://gametimehero-coming-soon.web.app?ref=myeventhero">Get the Gametime Hero
          Experience!</a>
      </ng-container>
    </mat-menu>
  </button>
</div>

<ng-template #loginTmpl>
  <a mat-button class="btn" [routerLink]="[MEH_APP_ROUTES.Login]" color="primary">Login</a>
</ng-template>

<ng-template #imgPlaceholder>
  <div class="user-img--placeholder">
    <mat-icon fontIcon="person" />
  </div>
</ng-template>