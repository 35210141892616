import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

import { GthUserFunctionService } from '../cloud/user-function.service';
import { GthErrorLoggerService } from '../cloud/error-logger.service';
import { GthUserModel } from '../../../../../sentinels/src/lib/models/user';
import { GthCallableService } from '../cloud/callable.service';
import { SrvApiService } from '@sentinels/services/api.service';

@Injectable({
  providedIn: 'root',
})
export class GthCacheUserService extends GthUserFunctionService {
  private byEmailStoreSubject = new BehaviorSubject<
    Map<string, Observable<GthUserModel>>>(new Map<string, Observable<GthUserModel>>());
  private byPhoneStoreSubject = new BehaviorSubject<
    Map<string, Observable<GthUserModel>>>(new Map<string, Observable<GthUserModel>>());
  private byIdStoreSubject = new BehaviorSubject<
    Map<string, Observable<GthUserModel>>>(new Map<string, Observable<GthUserModel>>());

  constructor(
    private userCloudService: GthUserFunctionService,
    functions: AngularFireFunctions,
    callableService: GthCallableService,
    api: SrvApiService,
    logger: GthErrorLoggerService,
  ) {
    super(functions, callableService, api, logger);
  }

  refreshByEmail$(key: string) {
    const store = this.byEmailStoreSubject.getValue();

    const user$ = this.userCloudService.getUserByEmail$(key).pipe(
      first(),
      map((user) => {
        store.set(key, of(user));
        this.byEmailStoreSubject.next(store);
        return user;
      }),
    );
    store.set(key, user$);
    return user$;
  }

  getUserByEmail$(email: string): Observable<GthUserModel | undefined> {
    const store$ = this.byEmailStoreSubject.asObservable();
    return store$.pipe(
      switchMap((store) => {
        if (store.has(email)) {
          const userItem = store.get(email);
          return userItem;
        }

        const user$ = this.userCloudService.getUserByEmail$(email).pipe(
          first(),
          map((user) => {
            store.set(email, of(user));
            this.byEmailStoreSubject.next(store);
            return user;
          }),
        );
        store.set(email, user$);
        return user$;
      }),
    );
  }

  getUserByPhone$(phone: string): Observable<GthUserModel | undefined> {
    const store$ = this.byPhoneStoreSubject.asObservable();
    return store$.pipe(
      switchMap((store) => {
        if (store.has(phone)) {
          const userItem = store.get(phone);
          return userItem;
        }

        const user$ = this.userCloudService.getUserByPhone$(phone).pipe(
          first(),
          map((user) => {
            store.set(phone, of(user));
            this.byPhoneStoreSubject.next(store);
            return user;
          }),
        );
        store.set(phone, user$);
        return user$;
      }),
    );
  }

  getUserById$(id: string): Observable<GthUserModel | undefined> {
    const store$ = this.byIdStoreSubject.asObservable();
    return store$.pipe(
      switchMap((store) => {
        if (store.has(id)) {
          const userItem = store.get(id);
          return userItem;
        }

        const user$ = this.userCloudService.getUserById$(id).pipe(
          first(),
          map((user) => {
            store.set(id, of(user));
            this.byIdStoreSubject.next(store);
            return user;
          }),
        );
        store.set(id, user$);
        return user$;
      }),
    );
  }

  async getUsersById(userIds: string[]) {
    const requests$ = userIds.map((userId) => this.getUserById$(userId).pipe(
      first(),
    ).toPromise(),
    );
    const results = await Promise.allSettled(requests$);
    const users = [];
    results.forEach((r) => {
      if (r.status === 'fulfilled') {
        users.push(r.value);
      }
    });
    return users as GthUserModel[];
  }

  refreshById$(key: string) {
    const store = this.byIdStoreSubject.getValue();

    const user$ = this.userCloudService.getUserById$(key).pipe(
      first(),
      map((user) => {
        store.set(key, of(user));
        this.byIdStoreSubject.next(store);
        return user;
      }),
    );
    store.set(key, user$);
    return user$;
  }
}
