import { NgModule } from '@angular/core';
import { AngularFireRemoteConfig } from '@angular/fire/compat/remote-config';
import { MatDialogModule } from '@angular/material/dialog';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxGpAutocompleteModule } from '@angular-magic/ngx-gp-autocomplete';
import { Loader } from '@googlemaps/js-api-loader';

import { GTH_ENVIRONMENT, GthCallableService } from '@gth-legacy';
import { AppComponent } from './app.component';
import { FirebaseModules, FirebaseProviders } from './firebase-modules';
import { MehAnalyticsComponent } from './features/analytics/analytics.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { environment } from '@environments/environment';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    ...FirebaseModules,
    MatDialogModule,
    MatSnackBarModule,
    MehAnalyticsComponent,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxGpAutocompleteModule,
  ],
  providers: [
    ...FirebaseProviders,
    GthCallableService,
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 3000 } },
    {
      provide: GTH_ENVIRONMENT,
      useValue: {
        root: 'https://app.myeventhero.com',
        appName: 'My Event Hero',
        teamsEnabled: false,
      },
    },
    {
      provide: Loader,
      useValue: new Loader({
        apiKey: environment.googleMaps,
        libraries: ['places'],
      }),
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private remoteConfig: AngularFireRemoteConfig) {
    this.remoteConfig.fetchAndActivate();
  }
}
