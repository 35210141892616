import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { from, Observable } from 'rxjs';

import { Payment } from '@index/interfaces';
import { GthErrorLoggerService } from './error-logger.service';

const MOCK_PAYMENTS: Payment[] = [];
const CONTEXT = 'GthTeamPaymentsFunctionService';

@Injectable({
  providedIn: 'root',
})
export class GthTeamPaymentsFunctionService {
  constructor(
    private functions: AngularFireFunctions,
    private logger: GthErrorLoggerService,
  ) { }

  getOpenPayments$(): Observable<Payment[] | null> {
    return from(this.getOpenPayments());
  }

  getTeamOutgoingPayments$(teamId: string): Observable<Payment[] | null> {
    return from(this.getTeamOutgoingPayments(teamId));
  }

  markPaymentAsPaid$(payment: Payment): Observable<boolean> {
    return from(this.markPaymentAsPaid(payment));
  }

  createPayment$(payment: Payment): Observable<boolean> {
    return from(this.createPayment(payment));
  }

  updatePayment$(payment: Payment): Observable<boolean> {
    return from(this.updatePayment(payment));
  }


  private async getOpenPayments(): Promise<Payment[] | null> {
    if (!this.functions) return Promise.resolve(null);
    return Promise.resolve(MOCK_PAYMENTS);
  }

  private async getTeamOutgoingPayments(teamId: string): Promise<Payment[] | null> {
    if (!this.functions) return Promise.resolve(null);
    this.logger.error(`${CONTEXT}: Not Implemented: Get outgoing payments for ${teamId}`);
    return Promise.resolve(MOCK_PAYMENTS);
  }

  private async markPaymentAsPaid(payment: Payment): Promise<boolean> {
    if (!this.functions) return Promise.resolve(false);
    this.logger.error(`${CONTEXT}: Not Implemented: Mark Payment as paid: ${payment.id}`);
    return Promise.resolve(false);
  }

  private async createPayment(payment: Payment): Promise<boolean> {
    if (!this.functions) return Promise.resolve(false);
    this.logger.error(`${CONTEXT}: Not Implemented: Create Payment: ${payment.name}`);
    return Promise.resolve(false);
  }

  private async updatePayment(payment: Payment): Promise<boolean> {
    if (!this.functions) return Promise.resolve(false);
    this.logger.error(`${CONTEXT}: Not Implemented: Update Payment: ${payment.id}`);
    return Promise.resolve(false);
  }
}
