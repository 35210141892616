import firebase from 'firebase/compat/app';

import { GeoFire } from './geofire';
import { DBUtil } from '../../utils/db-utils';
import { EventItemMapper } from '../../mappers/event-item-mapper';
import { EventItemModel } from '../../models/event-item';
import { EventJoinerModel } from '../../models/event-joiner';
import { EventItemListFilter } from '../../interfaces/event-list-filter';

export class EventListFactory {
  constructor(
    readonly firestore: firebase.firestore.Firestore,
    private readonly geoFire: GeoFire = new GeoFire(),
  ) { }

  async getByLatLng(lat: number, lng: number) {
    const ref = await this.firestore.collection(DBUtil.EventItem);

    const radiusInM = 200 * 1000;
    const center: [number, number] = [lat, lng];
    const bounds = await this.geoFire.getBounds(center, radiusInM);
    const eventsInBounds = this.geoFire.getEventsInBounds(bounds, ref);


    const mergedEvents = await this.geoFire.mergeAllEvents(eventsInBounds, radiusInM, center);
    return mergedEvents;
  }

  async getByTeamId(teamId: string) {
    const ref = await this.firestore.collection(DBUtil.EventItem);
    const query = await ref.where('hostingTeam', '==', teamId).get();
    const teamArray = [];
    for (const doc of query.docs) {
      teamArray.push(new EventItemMapper().fromSnapshot(doc)!);
    }

    return teamArray;
  }

  async getById(id: string) {
    const doc = await this.firestore.collection(DBUtil.EventItem).doc(id).get();

    const events = [];
    events.push(new EventItemMapper().fromSnapshot(doc)!);

    return events;
  }

  // eslint-disable-next-line max-len
  async getParentCollectionPath(docRef: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>): Promise<string | null> {
    const parentRef = docRef.parent;
    if (parentRef !== null) {
      const parentCollectionRef = parentRef.parent;
      let path = parentRef.path;
      if (path.toLowerCase().endsWith('joiner')) {
        path = parentCollectionRef.path;
      }
      return path;
    }
    return null;
  }

  async getByUserId(userId: string) {
    try {
      // All games user has created
      const query1 = await this.firestore.collection(DBUtil.EventItem)
        .where(EventItemModel.CREATOR, '==', userId).get();

      // All games user is in.
      const joinerRef = this.firestore.collectionGroup(DBUtil.EventJoiner);
      const query2 = await joinerRef.where(EventJoinerModel.PLAYER, '==', userId).get();

      const teamArray = [];
      for await (const doc1 of query1.docs) {
        teamArray.push(new EventItemMapper().fromSnapshot(doc1)!);
      }

      for await (const doc2 of query2.docs) {
        const joiner = await doc2.ref.get();
        const eventPath = await this.getParentCollectionPath(joiner.ref);
        if (eventPath !== null) {
          const evtSnapshot = await this.firestore.doc(`${eventPath}`).get();
          if (evtSnapshot.exists) {
            const event = new EventItemMapper().fromSnapshot(evtSnapshot)!;
            // Only add events that are not already in the array
            if (!teamArray.some((e) => e.id === event.id)) {
              teamArray.push(event);
            }
          }
        }
      }

      return teamArray;
    } catch (error) {
      // Handle any errors here and return a default value (e.g., an empty array).
      console.error('Error occurred while fetching events:', error);
      return [];
    }
  }

  async get(filter: EventItemListFilter) {
    switch (true) {
      case filter.eventItemId !== undefined:
        return await this.getById(filter.eventItemId!);
      case filter.teamId !== undefined:
        return await this.getByTeamId(filter.teamId!);
      case filter.lat !== undefined && filter.lng !== undefined:
        return await this.getByLatLng(filter.lat!, filter.lng!);
      case filter.userId !== undefined:
        return await this.getByUserId(filter.userId!);
      default:
        throw new Error('invalid filter for event list');
    }
  }
}
