import { Injectable } from '@angular/core';
import { EventRepeatType, EventItemDuration } from '@index/interfaces';

const DAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

@Injectable({
  providedIn: 'root',
})
export class GthEventDateService {
  /**
   * Get days of week as array of strings.
   * @return {string[]}
   */
  get days() {
    return DAYS;
  }

  /**
   * Gets months of year as array of strings.
   * @return {string[]}
   */
  get months() {
    return MONTHS;
  }

  /**
   * Gets date in format day, month date year time
   * @param {Date} date
   * @return {string}
   */
  getDateString(date: Date) {
    const day = this.getDay(date);
    const month = this.getMonth(date);
    const dayIndex = date.getDate();
    const year = date.getFullYear();
    const time = this.getTimeFromDate(date);

    return `${day}, ${month} ${dayIndex} ${year} ${time}`;
  }

  /**
   * Gets string description for repeat type
   * @param {EventRepeatType} repeatType Event Recurrence Type
   * @param {Date} startDate Date event starts on
   * @return {string}
   */
  getRepeatTypeDescription(
    repeatType: EventRepeatType | undefined,
    startDate: Date | undefined,
  ) {
    switch (repeatType) {
      case EventRepeatType.Daily:
        return 'Repeats daily';
      case EventRepeatType.Weekly:
        if (startDate) {
          return `Repeats weekly on ${this.getDay(startDate)}`;
        }
        return 'Repeats weekly';
      case EventRepeatType.Monthly:
        if (startDate) {
          return `Repeats on the ${startDate.getDate()} every month`;
        }
        return 'Repeats monthly';
      case EventRepeatType.Annually:
        if (startDate) {
          return `Repeats yearly on ${this.getMonth(startDate)} ${startDate.getDate()}`;
        }
        return 'Repeats annually';
      case EventRepeatType.EveryWeekday:
        return 'Repeats every weekday';
      case EventRepeatType.None:
      default:
        return 'Does not repeat';
    }
  }

  /**
   * Gets description of event time
   * @param { EventItemDuration | undefined } duration Duration of the event
   * after the start date
   * @param { Date | undefined } startDate Start Date/Time of event
   * @return {string} describing event
   */
  getDurationDescription(
    duration: EventItemDuration | undefined,
    startDate: Date | undefined,
  ) {
    if (startDate && duration) {
      // eslint-disable-next-line max-len
      return `${this.getDateString(startDate)} for ${duration.hours} hours and ${
        duration.minutes
      } minutes`;
    }
    return undefined;
  }

  private getDay(date: Date) {
    return this.days[date.getDay()];
  }

  private getMonth(date: Date) {
    return this.months[date.getMonth()];
  }

  private getTimeFromDate(date: Date) {
    let clarifier = 'AM';
    let hours = date.getHours();
    if (hours >= 12) {
      clarifier = 'PM';
      hours -= 12;
    }
    const minutes = date.getMinutes();
    if (hours === 0) {
      hours = 12;
    }

    return `${hours}:${minutes.toString().padStart(2, '0')} ${clarifier}`;
  }
}
