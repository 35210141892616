import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { DBUtil } from '@index/utils/db-utils';
import { GthStripeSubscriptionModel } from '@sentinels/models/stripe-subscription';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const COLLECTION_NAME = DBUtil.StripeSubscriptions;
const MEH_COLLECTION_NAME = DBUtil.MehStripeSubscriptions;
const GTH_COLLECTION_NAME = DBUtil.GthStripeSubscriptions;

@Injectable({ providedIn: 'root' })
export class GthStripeSubscriptionsService {
  constructor(private firestore: AngularFirestore) {}

  /**
   * Gets list of all stripe subscriptions
   * @return {Observable<GthStripeSubscriptionModel[] | undefined>}
   * List of all stripe subscriptions
   */
  list$(): Observable<GthStripeSubscriptionModel[] | undefined> {
    const collection = this.firestore.collection<any>(COLLECTION_NAME);
    return collection.snapshotChanges().pipe(
      map((items) => {
        return items
          .map((item) => {
            const doc = item.payload.doc;
            const data = {
              uid: doc.id,
              ...doc.data(),
            };
            return new GthStripeSubscriptionModel(doc.id, data);
          });
      }),
    );
  }

  /**
   * Gets list of all meh stripe subscriptions
   * @return {Observable<GthStripeSubscriptionModel[] | undefined>}
   * List of all stripe subscriptions
   */
  listMeh$(): Observable<GthStripeSubscriptionModel[] | undefined> {
    const collection = this.firestore.collection<any>(MEH_COLLECTION_NAME);
    return collection.snapshotChanges().pipe(
      map((items) => {
        return items
          .map((item) => {
            const doc = item.payload.doc;
            const data = {
              uid: doc.id,
              ...doc.data(),
            };
            return new GthStripeSubscriptionModel(doc.id, data);
          });
      }),
    );
  }

  /**
   * Gets list of all gth stripe subscriptions
   * @return {Observable<GthStripeSubscriptionModel[] | undefined>}
   * List of all stripe subscriptions
   */
  listGth$(): Observable<GthStripeSubscriptionModel[] | undefined> {
    const collection = this.firestore.collection<any>(GTH_COLLECTION_NAME);
    return collection.snapshotChanges().pipe(
      map((items) => {
        return items
          .map((item) => {
            const doc = item.payload.doc;
            const data = {
              uid: doc.id,
              ...doc.data(),
            };
            return new GthStripeSubscriptionModel(doc.id, data);
          });
      }),
    );
  }
}
