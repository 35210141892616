import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { Observable, of, Subscription } from 'rxjs';
import { CommonModule, DatePipe } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { MatCardModule } from '@angular/material/card';
import { NgChartsModule } from 'ng2-charts';
import { FormsModule } from '@angular/forms';
import { ChartConfiguration } from 'chart.js';
import { MatNativeDateModule } from '@angular/material/core';
import { map, switchMap } from 'rxjs/operators';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Point } from '@angular/cdk/drag-drop';
import { MatSnackBar } from '@angular/material/snack-bar';

import {
  GthAuthService,
  DEFAULT_CURRENT_USER,
} from '@gth-legacy';
import {
  GthEventItemModel,
  GthUserModel,
} from '@sentinels/models';
import { MehNavBarComponent } from '../../shared/components/nav-bar/nav-bar.component';
import { CustomFilterDialogComponent } from './custom-filter-dialog/custom-filter.dialog.component';
import { EventsService } from '@gth-legacy/services/events.service';
import { MehAdminNavComponent } from '../admin-tools/components/admin-nav/admin-nav.component';
import { EventJoiner, EventJoinerStatus } from '@index/interfaces';

export enum EventTimeFilter {
  LastWeek = 'Last week',
  LastMonth = 'Last month',
  Last60Days = 'Last 60 days',
  Last180Days = 'Last 180 days',
  LastYear = 'Last year',
  Custom = 'Custom',
}

@Component({
  selector: 'meh-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
    MatChipsModule,
    MatCardModule,
    NgChartsModule,
    CustomFilterDialogComponent,
    FormsModule,
    MehNavBarComponent,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MehAdminNavComponent,
  ],
  providers: [DatePipe],
})
export class MehAnalyticsComponent implements OnInit, OnDestroy {
  @Input()
  hostedEvents: GthEventItemModel[] = [];

  selectedEvents: GthEventItemModel[] = [];
  totalAttended = 0;
  totalNoShowed = 0;
  totalEventsInSelectedTime = 0;
  loading = true;
  private subscriptions = new Subscription();
  events: GthEventItemModel[] = [];
  selectedTimeFilter: EventTimeFilter | null = null;
  eventsInTimeRange: GthEventItemModel[] = [];

  lineChartData?: ChartConfiguration<'line'>['data'];
  protected readonly timeFilters = EventTimeFilter;
  customFilter: { start: Date, end: Date } | null = null;
  protected readonly EventTimeFilter = EventTimeFilter;

  constructor(
    private eventsService: EventsService,
    private authService: GthAuthService,
    private snackbar: MatSnackBar,
    private datePipe: DatePipe,
    private dialog: MatDialog,
  ) { }

  async ngOnInit(): Promise<void> {
    this.loadEvents();
  }

  loadEvents(): void {
    const user$ = this.authService.userModel$;
    const events$ = user$.pipe(
      switchMap((user) => this.getEvents$(user)),
    );

    this.subscriptions.add(
      events$.subscribe(async (events) => {
        this.events = events;
        /** select all event by default */
        this.selectedEvents = [...events];
        this.onEventTimeFilterChange(this.selectedTimeFilter);
        this.loading = false;
      }),
    );

    this.calculateTotals();
  }

  async onSelectAll(): Promise<void> {
    this.selectedEvents = [...this.hostedEvents];
    // await this.updateEventJoiners();
    // TODO rkara
  }

  private calculateTotals(): void {
    const eventJoiners = this.eventsInTimeRange.reduce((i: EventJoiner[], acc) => {
      return i.concat(acc.participants);
    }, []);
    this.totalAttended = eventJoiners
      .filter((j) => j.status === EventJoinerStatus.Attended).length;
    this.totalNoShowed = eventJoiners
      .filter((j) => j.status === EventJoinerStatus.NoShowed).length;
    this.totalEventsInSelectedTime = this.eventsInTimeRange.length;
    this.calculateRsvpsForChart();
  }

  private calculateStartDate(daysAgo: number): Date {
    const today = new Date();
    return new Date(today.setDate(today.getDate() - daysAgo));
  }

  onCustomFilterClick(): void {
    const dialogRef = this.dialog.open(CustomFilterDialogComponent, {
      width: '300px',
    });

    dialogRef.afterClosed()
      .subscribe(async (result: { start: Date; end: Date } | undefined) => {
        if (result) {
          if (!result.start || !result.end) {
            this.snackbar.open(
              'You must select both `start` and `end` dates',
              '',
              { duration: 5000 },
            );
            return;
          }

          this.customFilter = result;
          this.onEventTimeFilterChange(EventTimeFilter.Custom);
        }
      });
  }

  private getEvents$(
    user: GthUserModel,
  ): Observable<GthEventItemModel[] | undefined> {
    this.loading = true;
    if (!user || user.uid === DEFAULT_CURRENT_USER.uid) {
      return of([]);
    }
    const events$ = this.eventsService.getEvents$(user.id);

    return events$
      .pipe(
        map((events: GthEventItemModel[]) =>
          events
            .filter((e) => (e.creatorId === user.uid && !e.cancelled)),
        ),
      );
  }

  onEventTimeFilterChange(filter: EventTimeFilter | null): void {
    try {
      this.loading = true;
      this.selectedTimeFilter = filter;

      /** determine start and end dates based on filter type */
      const dateRange = this.getDateRangeForFilter(this.selectedTimeFilter);

      if (dateRange) {
        /** apply filter to events */
        this.eventsInTimeRange = this.selectedEvents.filter((event) => {
          return event.dateStart >= dateRange.start && event.dateStart <= dateRange.end;
        });
      } else {
        /** show all events */
        this.eventsInTimeRange = [...this.selectedEvents];
      }

      this.calculateTotals();
    } catch (error: unknown) {
      console.error('Something went wrong updating events selected time filter', error);
    } finally {
      this.loading = false;
    }
  }

  private getDateRangeForFilter(filter: EventTimeFilter | null): { start: Date, end: Date } {
    if (filter === EventTimeFilter.Custom) {
      return { start: this.customFilter.start, end: this.customFilter.end };
    } else if (filter) {
      return this.calculateDateRangeForFilter(filter);
    } else {
      return undefined;
    }
  }

  calculateDateRangeForFilter(filter: EventTimeFilter | null): { start: Date, end: Date } {
    switch (filter) {
      case EventTimeFilter.LastWeek:
        return { start: this.calculateStartDate(7), end: new Date() };
      case EventTimeFilter.LastMonth:
        return { start: this.calculateStartDate(30), end: new Date() };
      case EventTimeFilter.Last60Days:
        return { start: this.calculateStartDate(60), end: new Date() };
      case EventTimeFilter.Last180Days:
        return { start: this.calculateStartDate(180), end: new Date() };
      case EventTimeFilter.LastYear:
        return { start: this.calculateStartDate(365), end: new Date() };
      case EventTimeFilter.Custom:
        return this.customFilter;
      case null:
      default:
        /** calculate for all events */
        return null;
    }
  }

  calculateRsvpsForChart(): void {
    const today = new Date();
    const firstDayOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const sixMonthsAgo = new Date();
    sixMonthsAgo.setMonth(today.getMonth() - 5); // Go back 6 months

    const eventJoiners = this.eventsInTimeRange.flatMap((event) => event.participants);

    let labelDates: Date[];
    let rsvpsPerLabel: { labels: string[], data: (number | Point)[] };
    switch (this.selectedTimeFilter) {
      case EventTimeFilter.LastWeek:
        labelDates = this.generateDatesForWeek(today);
        rsvpsPerLabel = this.calculateRsvpsPerLabel(
          labelDates,
          eventJoiners,
          /** check within 24 hours */
          86400000,
        );
        this.updateChart(
          rsvpsPerLabel.labels,
          rsvpsPerLabel.data,
        );
        break;
      case EventTimeFilter.LastMonth:
        labelDates = this.generateDatesForMonth(firstDayOfLastMonth);
        rsvpsPerLabel = this.calculateRsvpsPerLabel(
          labelDates,
          eventJoiners,
          /** check within 7 days */
          604800000,
        );

        this.updateChart(
          rsvpsPerLabel.labels,
          rsvpsPerLabel.data,
        );
        break;
      case EventTimeFilter.Last60Days:
        labelDates = this.generateDatesFor60Days(today);
        rsvpsPerLabel = this.calculateRsvpsPerLabel(
          labelDates,
          eventJoiners,
          /** check within 6 days */
          518400000,
        );
        this.updateChart(
          rsvpsPerLabel.labels,
          rsvpsPerLabel.data,
        );
        break;
      case EventTimeFilter.Last180Days:
        labelDates = this.generateDatesFor180Days(today);
        rsvpsPerLabel = this.calculateRsvpsPerLabel(
          labelDates,
          eventJoiners,
          /** check within 30 days */
          2592000000,
        );
        this.updateChart(
          rsvpsPerLabel.labels,
          rsvpsPerLabel.data,
        );
        break;
      case EventTimeFilter.Custom:
        labelDates = this.generateDatesForCustom(
          this.customFilter.start,
          this.customFilter.end,
        );
        rsvpsPerLabel = this.calculateRsvpsPerLabel(
          labelDates,
          eventJoiners,
          /** check within 24 hours */
          86400000,
        );
        this.updateChart(
          rsvpsPerLabel.labels,
          rsvpsPerLabel.data,
        );
        break;
      case EventTimeFilter.LastYear:
        labelDates = this.generateDatesForLastYear(today);
        rsvpsPerLabel = this.calculateRsvpsPerLabel(
          labelDates,
          eventJoiners,
          /** check month for duration */
          undefined,
          true,
        );
        this.updateChart(
          rsvpsPerLabel.labels,
          rsvpsPerLabel.data,
        );
        break;
      case null:
      default:
        /** calculate for all events */
        if (!this.eventsInTimeRange.length) {
          this.updateChart(this.lineChartData?.labels as string[], []);
          break;
        }
        const oldestEventDate = this.getOldestEventDate(this.eventsInTimeRange);
        labelDates = this.generateDatesForAllEvents(oldestEventDate, today);
        rsvpsPerLabel = this.calculateRsvpsPerLabel(
          labelDates,
          eventJoiners,
          /** check within 24 hours */
          86400000,
        );
        this.updateChart(
          rsvpsPerLabel.labels,
          rsvpsPerLabel.data,
        );
        break;
    }
  }

  private calculateRsvpsPerLabel(
    labelDates: Date[],
    eventJoiners: EventJoiner[],
    isWithinTimeRangeDuration?: number,
    dynamicDurationByMonth = false,
  ): { labels: string[], data: (number | Point)[] } {
    const labels = labelDates.map((d) => this.datePipe.transform(d));

    /** calculate RSVPs for each label: */
    const rsvpsPerLabel = {};
    for (const labelDate of labelDates) {
      /** zero out time for accurate comparison */
      this.setDateToMidnight(labelDate);

      for (const participant of eventJoiners) {
        /** skip if no RSVP date */
        if (!participant?.createdAt?.seconds) continue;

        const participantCreatedAt = participant.createdAt.toDate();
        /** set time to midnight for accurate comparison */
        participantCreatedAt.setHours(0, 0, 0, 0);

        if (dynamicDurationByMonth) {
          const nextMonthDate = new Date(labelDate);
          nextMonthDate.setMonth(nextMonthDate.getMonth() + 1);
          isWithinTimeRangeDuration = nextMonthDate.getTime() - labelDate.getTime();
          if (nextMonthDate.getMonth() === 0) {
            nextMonthDate.setFullYear(nextMonthDate.getFullYear() + 1);
          }
        }
        if (
          this.isWithinTimeRange(participantCreatedAt, labelDate, isWithinTimeRangeDuration)
        ) {
          /** increment RSVP count for the formatted label */
          const formattedLabelDate = this.datePipe.transform(labelDate);
          rsvpsPerLabel[formattedLabelDate] = (rsvpsPerLabel[formattedLabelDate] || 0) + 1;
        }
      }
    }

    /** prepare chart data */
    const data = labels.map((label) => rsvpsPerLabel[label] || 0);

    return { labels, data };
  }

  private generateDatesForWeek(startDate: Date): Date[] {
    return [...Array(7).keys()].map((index) => {
      const date = new Date(startDate);
      date.setDate(date.getDate() - 6 + index); // Go back 6 days and increment for each label

      return date;
    });
  }

  private generateDatesForMonth(startDate: Date): Date[] {
    return [...Array(4).keys()].map((index) => {
      const weekStart = new Date(startDate);
      /** increment by week */
      weekStart.setDate(weekStart.getDate() + (7 * index));
      return weekStart;
    });
  }

  private generateDatesFor60Days(startDate: Date): Date[] {
    return [...Array(10).keys()].map((index) => {
      const date = new Date(startDate);
      /** go back 59 days and increment for each label */
      date.setDate(date.getDate() - 59 + (6 * index));
      return date;
    });
  }

  private generateDatesFor180Days(startDate: Date): Date[] {
    const today = new Date(startDate);
    const sixMonthsAgo = new Date(today);
    sixMonthsAgo.setMonth(today.getMonth() - 6); // Go back 6 months
    return [...Array(6).keys()].map((index) => {
      const date = new Date(sixMonthsAgo);
      date.setMonth(sixMonthsAgo.getMonth() + index);
      return date;
    });
  }

  private generateDatesForLastYear(startDate: Date): Date[] {
    const today = new Date(startDate);
    const twelveMonthsAgo = new Date(today);
    twelveMonthsAgo.setFullYear(today.getFullYear() - 1); // Go back 1 year
    return [...Array(12).keys()].map((index) => {
      const date = new Date(twelveMonthsAgo);
      date.setMonth(twelveMonthsAgo.getMonth() + index);
      return date;
    });
  }

  private generateDatesForCustom(start: Date, end: Date): Date[] {
    const numDays = (end.getTime() - start.getTime()) / (1000 * 3600 * 24);
    const labelDates: Date[] = [];
    for (let i = 0; i <= numDays; i++) {
      const date = new Date(start.getTime() + (i * 24 * 3600 * 1000));
      labelDates.push(date);
    }
    return labelDates;
  }

  private getOldestEventDate(events: GthEventItemModel[]): Date | undefined {
    if (events.length === 0) {
      return undefined;
    }
    const oldestEvent = events.reduce((oldest, acc) => {
      return acc.dateStart < oldest.dateStart ? acc : oldest;
    });
    return oldestEvent.dateStart; // Return the start date of the oldest event
  }

  private generateDatesForAllEvents(oldestDate: Date, end: Date): Date[] {
    const today = new Date(end);
    const pastDate = new Date(today);
    pastDate.setTime(today.getTime() - oldestDate.getTime());
    const numDays = (today.getTime() - oldestDate.getTime()) / (1000 * 3600 * 24);
    const labelDates: Date[] = [];
    for (let i = 0; i <= numDays; i++) {
      const date = new Date(oldestDate.getTime() + (i * 24 * 3600 * 1000));
      labelDates.push(date);
    }
    return labelDates;
  }

  private setDateToMidnight(date: Date): void {
    date.setHours(0, 0, 0, 0);
  }

  private isWithinTimeRange(date: Date, startDate: Date, duration: number): boolean {
    return date.getTime() >= startDate.getTime() &&
      date.getTime() < startDate.getTime() + duration;
  }

  updateChart(labels: string[], data: (number | Point)[]): void {
    this.lineChartData = {
      labels,
      datasets: [
        {
          data,
          label: 'RSVPs',
          fill: true,
          tension: 0.5,
          borderColor: 'black',
          backgroundColor: 'rgba(255,0,0,0.3)',
        },
      ],
    };
  }

  onSelectAllBtnClick() {
    this.selectedEvents = [...this.events];
    this.onEventTimeFilterChange(this.selectedTimeFilter);
  }

  onDeselectAllBtnClick() {
    this.selectedEvents = [];
    this.onEventTimeFilterChange(this.selectedTimeFilter);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
