<h1 mat-dialog-title>Accept Payments</h1>

<mat-dialog-content>
    <p>Your account is not yet set up to accept payments.
        <br />
        <br />
        We use <a class="stripe-link" [href]="stripeLink.url" target="_blank">{{stripeLink.label}}</a> as our payment processor.
        Connect or set up a Stripe account to start accepting payments. It usually takes less than 5 minutes.
    </p>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-flat-button mat-dialog-close class="btn" color="primary"
            [routerLink]="APP_ROUTES.Settings"
            [queryParams]="{tab: 'payments'}">Connect Stripe</button>
</mat-dialog-actions>