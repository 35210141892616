import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SubscriptionsDialogComponent } from '../dialogs/subscriptions-dialog/subscriptions-dialog.component';
import { SubscriptionsDialogOpenContract } from '@sentinels/interfaces/stripe';

@Injectable({ providedIn: 'root' })
export class SubscriptionsService {
  constructor(private dialog: MatDialog) {}

  public openSubscriptionDialog(data: SubscriptionsDialogOpenContract) {
    this.dialog.open(SubscriptionsDialogComponent, {
      id: 'subscriptions-dialog',
      backdropClass: 'gth-overlay-backdrop',
      panelClass: 'gth-dialog',
      data: data,
    });
  }
}
