import { TopicMessage } from '@index/interfaces';
import { GthEventItemModel } from './event-item';
import { GthModel } from './model';
import { GthUserModel } from './user';

export class GthTopicMessage extends GthModel<TopicMessage> {
  private _sender: GthUserModel;
  private event: GthEventItemModel;

  get message(): TopicMessage {
    return this.model;
  }

  get id() {
    return this.model.id;
  }

  get favorite() {
    return this.model.isFavorite;
  }
  set favorite(val: boolean) {
    this.model.isFavorite = val;
  }

  get sender() {
    return this.model.sender;
  }

  get senderModel() {
    return this._sender;
  }

  get createdTime() {
    return this.model.createdTime;
  }

  get eventModel() {
    return this.event;
  }

  get eventId() {
    return this.model.eventId;
  }

  get isRead() {
    return this.model.isRead;
  }

  get content() {
    return this.model.content;
  }

  constructor(id: string, model: TopicMessage) {
    super(id, model);
  }

  setSender(user: GthUserModel) {
    this._sender = user;
    this.model.sender = user.id;
  }

  setEvent(event: GthEventItemModel) {
    this.event = event;
  }
}
