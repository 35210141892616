<mat-dialog-content>
  <form [formGroup]="externalTeamForm" (ngSubmit)="onSaveButtonClick()" class="fx-column">

    <gth-stored-image class="team-logo" *ngIf="photoURL && !teamLogoError" [id]="photoURL"
      (imageError)="teamLogoError = false"></gth-stored-image>

    <div class="fx-row fx-gap-10x">
      <!-- Team Name -->
      <mat-form-field appearance="outline" class="fx-50">
        <mat-label>Group Name</mat-label>
        <input matInput formControlName="teamName" placeholder="Enter team name" required>
      </mat-form-field>
    </div>

    <div class="fx-row upload-container fx-gap-10x">
      <gth-file-upload [maxUploadSize]="maxUploadSize" label="Team Logo" accepts="image/png,image/jpeg"
        [multiple]="false" [disabled]="uploading" (filesSelect)="onLogoSelected($event)"></gth-file-upload>
    </div>

    <div class="fx-row fx-gap-10x">
      <!-- City Input -->
      <mat-form-field appearance="outline" class="fx-50">
        <mat-label>Default Location</mat-label>
        <input matInput #cityInput ngx-gp-autocomplete formControlName="cityName" class="city-input"
          (change)="clearCityInput()" #placesRef="ngx-places" [options]="options"
          (onAddressChange)="onAutocompleteSelected($event)" required>
      </mat-form-field>

      <!-- Activities -->
      <mat-form-field appearance="outline" *ngIf="gameTypes" class="fx-auto">
        <mat-label>Select Activity...</mat-label>
        <mat-select formControlName="activity" (selectionChange)="onGameTypeChanged($event.value)">
          <mat-optgroup *ngFor="let group of gameTypes | keyvalue" [label]="group.key | titlecase">
            <mat-option *ngFor="let gameType of group.value" [value]="gameType.label">
              {{ gameType.label }}
            </mat-option>
          </mat-optgroup>
        </mat-select>
      </mat-form-field>
    </div>

    <mat-form-field appearance="outline" class="fx-auto">
      <mat-label>External URL</mat-label>
      <input matInput formControlName="externalURL">
    </mat-form-field>

    <!-- Level -->
    <div class="fx-auto expand">
      <mat-expansion-panel [expanded]="false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Skill Level - {{ skillLevelUIRender }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <gth-skill-level-form-control [skillLevelFormControl]="externalTeamForm.controls.level" />
      </mat-expansion-panel>
    </div>

    <div class="fx-row fx-gap-10x fx-auto radio">
      <mat-label>Entity Type</mat-label>
      <mat-radio-group formControlName="entityType">
        <mat-radio-button [value]="type.Team">Team</mat-radio-button>
        <mat-radio-button [value]="type.Group">Group</mat-radio-button>
        <mat-radio-button [value]="type.Club">Club</mat-radio-button>
        <mat-radio-button [value]="type.Organization">Organization</mat-radio-button>
      </mat-radio-group>
    </div>

    <!-- Description -->
    <mat-form-field appearance="outline" class="fx-auto">
      <mat-label>Description</mat-label>
      <textarea matInput formControlName="description" placeholder="Enter description"></textarea>
    </mat-form-field>

    <div class="fx-row fx-gap-10x">
      <!-- Contact Email -->
      <mat-form-field appearance="outline" class="fx-50">
        <mat-label>Creator, Admin or Owner Contact Email</mat-label>
        <input matInput formControlName="contactEmail" placeholder="Enter contact email" required>
        <mat-error *ngIf="externalTeamForm.get('contactEmail').hasError('email')">Invalid email</mat-error>
      </mat-form-field>

      <!-- Contact Phone -->
      <mat-form-field appearance="outline" class="fx-auto">
        <mat-label>Creator, Admin or Owner Contact Phone</mat-label>
        <input matInput formControlName="contactPhone" placeholder="Enter contact phone">
        <mat-error>Phone number must be 10 digits.</mat-error>
      </mat-form-field>
    </div>

    <div class="fx-row fx-centered">
      <mat-slide-toggle formControlName="allowAskToJoin">Allow users to ask to join/sub</mat-slide-toggle>
    </div>

    <button mat-flat-button class="uppercase save-btn" color="primary" type="submit" [disabled]="!valid && !uploading">
      {{ saveButtonLabel }}
    </button>
  </form>
</mat-dialog-content>