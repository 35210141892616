import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { httpsCallableFromURL, getFunctions } from 'firebase/functions';
import { environment } from '../../../../../../environments/environment';
import { first } from 'rxjs/operators';

import { DBUtil } from '@index/utils/db-utils';

export interface GthDebugging {
  debug: boolean;
}

export interface GthDebuggingUser {
  id: string;
  email: string;
  displayName: string;
  fullName: string;
}

@Injectable({
  providedIn: 'root',
})
export class GthDebuggingFunctionService {
  constructor(
    private firestore: AngularFirestore,
  ) { }

  async getDebugging(userId: string): Promise<GthDebugging> {
    const playerRoles = await this.firestore.collection(
      DBUtil.AdminDebugging,
      (ref) => ref
        .where('userId', '==', userId),
    ).get()
      .pipe(
        first(),
      )
      .toPromise();
    const docs = playerRoles.docs;
    if (!docs || docs.length === 0) {
      return undefined;
    }
    const doc = docs[0];
    return doc.data() as GthDebugging;
  }

  async getTestingUsers(): Promise<GthDebuggingUser[]> {
    try {
      const uri = this.getFunctionUri(`getTestUsers`);
      const callable = httpsCallableFromURL(getFunctions(), uri);

      const result = (await callable()).data as unknown as GthDebuggingUser[];
      console.log('Debugging users returned', result);
      return result;
    } catch (error) {
      console.error('Error:', error);
      return [];
    }
  }

  async deleteTestingUser(id: string) {
    try {
      const uri = this.getFunctionUri(`deleteTestingUser`);
      const callable = httpsCallableFromURL(getFunctions(), uri);

      const result = (await callable({ id })).data as any;
      console.log('Delete users response', result);
      return result;
    } catch (error) {
      console.error('Error:', error);
      return undefined;
    }
  }

  async sendEmail(header: string, recipientIds: string[], eventId: string) {
    try {
      const uri = this.getFunctionUri(`sendEmail`);
      const callable = httpsCallableFromURL(getFunctions(), uri);

      const result = (await callable({ header, recipientIds, eventId })).data as any;
      return result;
    } catch (error) {
      console.error('Error:', error);
      return undefined;
    }
  }

  async sendPwaTestNotificaiton() {
    try {
      const uri = this.getFunctionUri(`sendNotification`);
      const callable = httpsCallableFromURL(getFunctions(), uri);

      const result = (await callable()).data as any;
      return result;
    } catch (error) {
      console.error('Error:', error);
      return undefined;
    }
  }

  private getFunctionUri(functionName: string) {
    const env = this.getEnviroment();

    // eslint-disable-next-line max-len
    return `https://us-central1-gametimehero-${env}.cloudfunctions.net/debugging-triggers-${functionName}`;
  }

  private getEnviroment(): string {
    return environment.envName!;
  }
}
