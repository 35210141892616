import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

const BLACKLIST_AGENTS = ['FBAV', 'FBAN'];

/**
 * TODO rkara-ssr-defect
 * This file does not work appropriately while built in SSR.
 */

@Injectable({
  providedIn: 'root',
})
export class SrvSafeWindowService {
  get hasWindow() {
    return isPlatformBrowser(this.platformId);
  }

  get innerWidth() {
    if (this.hasWindow) {
      return window.innerWidth;
    }
    return undefined;
  }

  get navigator() {
    if (this.hasWindow) {
      return window.navigator;
    }
    return {
      onLine: true,
    };
  }

  get location() {
    if (this.hasWindow) {
      return window.location;
    }
    return {
      href: '',
      protocol: 'https://',
      host: 'app.gametimehero.com',
    };
  }

  get history() {
    if (this.hasWindow) {
      return window.history;
    }
    return undefined;
  }

  get historyUser() {
    if (this.hasWindow) {
      if (!window.history.state || !window.history.state.user) return null;
      return window.history.state.user.model;
    }

    return undefined;
  }

  get isLocalhost() {
    if (!this.hasWindow) return false;
    // Check if the protocol is 'file:' (local file system) or 'http:' or 'https:' (localhost)
    return (
      window.location.protocol === 'file:' ||
      window.location.hostname === 'localhost' ||
      window.location.hostname === '127.0.0.1'
    );
  }

  get blacklisted() {
    if (this.hasWindow) {
      const userAgent = window.navigator.userAgent;
      const isUnsafe = BLACKLIST_AGENTS.some((browser) => userAgent.includes(browser));
      if (isUnsafe) {
        return true;
      }
    }
    return false;
  }

  constructor(
    @Inject(PLATFORM_ID)
    private platformId: any,
  ) { }

  scrollTo(x: number, y: number) {
    if (!this.hasWindow) return;
    window.scrollTo(x, y);
  }

  navigate(path: string) {
    if (this.hasWindow) {
      window.location.href = path;
      return;
    }
  }

  open(path: string, target?: string) {
    if (this.hasWindow) {
      window.open(path, target);
      return;
    }
  }

  setProp(key: string, value: any) {
    if (this.hasWindow) {
      window[key] = value;
      return;
    }
  }

  getProp(key: string) {
    if (this.hasWindow) {
      return window[key];
    }
    return undefined;
  }

  createObjectURL(obj: Blob | MediaSource) {
    if (this.hasWindow) {
      return window.URL.createObjectURL(obj);
    }
    return undefined;
  }

  reload() {
    if (this.hasWindow) {
      window.location.reload();
      return;
    }
  }
}
