import { Injectable } from '@angular/core';

import { ConversationDAOService } from '@index/daos/conversation-dao';
import { EventItemDAOService } from '@index/daos/event-item-dao';
import { UserDAOService } from '@index/daos/user-dao';
import { TeamDAOService } from '@index/daos/team-dao';
import { EventJoinerDAOService } from '@index/daos/event-joiner-dao';
import { NotificationDAOService } from '@index/daos/event-notification-dao';
import { MailerDAOService } from '@index/daos/mailer-dao';
import { BadgeDAOService } from '@index/daos/badge-dao';
import { GuestDAOService } from '@index/daos/guest-dao';
import { CallableOptions, CallableRoutes, SuccessResult } from '@index/interfaces';

export { CallableOptions, CallableRoutes } from '@index/interfaces';

@Injectable({
  providedIn: 'root',
})
export class GthCallableService {
  constructor(
    readonly conversations: ConversationDAOService,
    readonly eventItems: EventItemDAOService,
    readonly users: UserDAOService,
    readonly teams: TeamDAOService,
    readonly joiners: EventJoinerDAOService,
    readonly notifications: NotificationDAOService,
    readonly mailer: MailerDAOService,
    readonly badges: BadgeDAOService,
    readonly guests: GuestDAOService,
  ) { }

  /**
   * Calls a Firebase Function
   * @param {CallableOptions} options Firebase Function Options
   * @return {Object} Function Response
   */
  async call(options: CallableOptions) {
    const { route, data } = options;

    try {
      SuccessResult;
      const callable = this.getRouteMap().get(route);

      let results = await callable(data);

      if (Array.isArray(results)) {
        results = results.map((item) => {
          return {
            id: item?.id || item?.uid, data: item,
          };
        },
        );
      } else {
        if (!results) {
          return undefined;
        }
        results = {
          id: results.id ? results.id : '', data: results,
        };
      }
      return SuccessResult(results);
    } catch (e) {
      throw (e);
    }
  }

  getRouteMap() {
    return new Map<CallableRoutes, any>([
      // Games
      [CallableRoutes.GAMES_CREATE, this.eventItems.create.bind(this.eventItems)],
      [CallableRoutes.GAMES_LIST, this.eventItems.list.bind(this.eventItems)],
      [CallableRoutes.GAMES_UPDATE, this.eventItems.update.bind(this.eventItems)],
      [CallableRoutes.GAMES_DELETE, this.eventItems.delete.bind(this.eventItems)],
      [
        CallableRoutes.GAMES_UPDATE_PAGE_VIEW,
        this.eventItems.updatePageView.bind(this.eventItems),
      ],
      [
        CallableRoutes.GAMES_GET_PAGE_VIEWS,
        this.eventItems.getPageViews.bind(this.eventItems),
      ],

      // Users
      [CallableRoutes.USERS_CREATE, this.users.create.bind(this.users)],
      [CallableRoutes.USERS_READ, this.users.read.bind(this.users)],
      [CallableRoutes.USERS_LIST, this.users.list.bind(this.users)],
      [CallableRoutes.USERS_UPDATE, this.users.update.bind(this.users)],

      // Guests
      [CallableRoutes.GUESTS_READ, this.guests.read.bind(this.guests)],
      [CallableRoutes.GUESTS_CREATE, this.guests.create.bind(this.guests)],

      // Teams
      [CallableRoutes.TEAMS_CREATE, this.teams.create.bind(this.teams)],
      [CallableRoutes.TEAMS_DELETE, this.teams.delete.bind(this.teams)],
      [CallableRoutes.TEAMS_LIST, this.teams.list.bind(this.teams)],
      [CallableRoutes.TEAMS_READ, this.teams.read.bind(this.teams)],
      [CallableRoutes.TEAMS_UPDATE, this.teams.update.bind(this.teams)],

      // Event Joiner
      [CallableRoutes.EVENT_JOINER_CREATE, this.joiners.create.bind(this.joiners)],
      [CallableRoutes.EVENT_JOINER_DELETE, this.joiners.delete.bind(this.joiners)],
      [CallableRoutes.EVENT_JOINER_LIST, this.joiners.list.bind(this.joiners)],
      [CallableRoutes.EVENT_JOINER_UPDATE, this.joiners.update.bind(this.joiners)],

      // Conversations
      [CallableRoutes.CONVERSATIONS_CREATE,
      this.conversations.create.bind(this.conversations)],
      [CallableRoutes.CONVERSATIONS_UPDATE,
      this.conversations.update.bind(this.conversations)],
      [CallableRoutes.CONVERSATIONS_GET,
      this.conversations.get.bind(this.conversations)],

      // Notifications
      [CallableRoutes.NOTIFICATIONS_CREATE,
      this.notifications.create.bind(this.notifications)],
      [CallableRoutes.NOTIFICATIONS_LIST,
      this.notifications.list.bind(this.notifications)],
      [CallableRoutes.NOTIFICATIONS_UPDATE,
      this.notifications.update.bind(this.notifications)],
      [CallableRoutes.NOTIFICATIONS_DELETE,
      this.notifications.delete.bind(this.notifications)],

      // Mailers
      [CallableRoutes.MAILER_CREATE, this.mailer.create.bind(this.mailer)],

      // Badges
      [CallableRoutes.BADGES_READ, this.badges.read.bind(this.badges)],
      [CallableRoutes.BADGES_LIST, this.badges.list.bind(this.badges)],
    ]);
  }
}
