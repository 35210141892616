import { GthModel } from './model';

import { GthUserModel } from './user';
import {
  EventItem,
  EventJoiner,
  EventJoinerStatus,
  EventRsvpStatus, EventTheme, EventTicketLevel,
  GenderInfo,
  UnregisteredTeamInfo,
  User,
} from '@index/interfaces';
import { EventItemTypes } from '@index/enums/event-item-type';
import { StripeItem } from '../interfaces/stripe';
import { StripeItemType } from '../enums/misc';

export enum JoinButtonType {
  JoinGame,
  JoinWaitlist,
  RequestJoinGame,
  AcceptInvite,
  Creator,
}

export class GthEventItemModel extends GthModel<EventItem> {
  constructor(id: string, model: EventItem) {
    if (model?.dateStart && model?.dateStart['seconds']) {
      model.dateStart = new Date(model.dateStart['seconds'] * 1000);
    }
    super(id, model);
  }

  get allowParticipantGuests() {
    return this.model.allowParticipantGuests;
  }

  get banner() {
    return this.model.banner;
  }

  get title() {
    return this.model?.title ?? '';
  }

  get creator() {
    return this._creator;
  }

  get creatorId() {
    if (!this.model.creator) {
      return '';
    }

    return this.model.creator as string;
  }

  get description() {
    return this.model.description ?? '';
  }

  get dateStart() {
    return this.model.dateStart;
  }

  get dateEnd() {
    const durationHours = this.duration.hours;
    const durationMinutes = this.duration.minutes;
    const endOfGame = new Date(this.dateStart);
    const newHours = endOfGame.getHours() + durationHours;
    const newMinutes = endOfGame.getMinutes() + durationMinutes;
    endOfGame.setHours(newHours, newMinutes);
    return endOfGame;
  }

  get duration() {
    return this.model.duration;
  }

  get recurringType() {
    return this.model.recurringType;
  }

  get location() {
    return this.model.location;
  }

  get online() {
    return this.model.online;
  }

  get eventJoinerStatus() {
    return this._eventJoinerStatus;
  }

  get skillLevel() {
    return this.model.skillLevel;
  }

  get eventType() {
    return this.model.type || EventItemTypes.Pickup;
  }

  get gameType() {
    const unregisteredTeam = this.model.hostingTeam as unknown as UnregisteredTeamInfo;
    if (unregisteredTeam && unregisteredTeam.gameType) {
      return unregisteredTeam.gameType;
    }
    return this.model.gameType ?? '';
  }

  /** Get Angular Material Icon for {@link GAME_TYPES} */
  // TODO: Some icons probably could be updated.
  get gameTypeIcon() {
    const defaultIcon = 'sports';
    switch (this.gameType) {
      /** Sports */
      case 'Football': return 'sports_football';
      case 'Soccer': return 'sports_soccer';
      case 'Volleyball': return 'sports_volleyball';
      case 'Basketball': return 'sports_basketball';
      case 'Baseball': return 'sports_baseball';
      case 'Lacrosse': return defaultIcon;
      case 'Softball': return defaultIcon;
      case 'Pickleball': return defaultIcon;
      /** Leisure */
      case 'Hiking': return 'hiking';
      case 'Camping': return 'camping';
      case 'Cooking': return 'cooking';
      case 'Reading': return 'auto_stories';
      case 'Coding': return 'code';
      case 'Watch Party': return 'movie';
      case 'Board games': return 'casino';
      case 'Card games': return defaultIcon;
      case 'Table top': return defaultIcon;
      /** Exercise */
      case 'Running': return 'directions_run';
      case 'Weightlifting': return 'fitness_center';
      case 'Cycling': return 'directions_bike';
      case 'Backpacking': return 'backpack';
      /** Extreme */
      case 'Skateboard': return 'skateboarding';
      case 'Other':
      default: return defaultIcon;
    }
  }

  get teamName() {
    const unregisteredTeam = this.model.hostingTeam as unknown as UnregisteredTeamInfo;
    if (unregisteredTeam && unregisteredTeam.name) {
      return unregisteredTeam.name;
    }
    return '';
  }

  get teamColor() {
    return this.model.teamColor;
  }

  get hostingTeam() {
    return this.model.hostingTeam;
  }
  set hostingTeam(val: string | UnregisteredTeamInfo) {
    this.model.hostingTeam = val;
  }

  get numberOfPlayers() {
    const { totalNeeded } = this.playerCount;
    return totalNeeded;
  }

  get totalParticipantsNeeded() {
    const { playersNeeded } = this.playerCount;
    return playersNeeded;
  }

  get participantsResponded() {
    const { participantsResponded } = this.playerCount;
    return participantsResponded;
  }

  get playerCount() {
    let participantsResponded = 0;
    let playersNeeded = 0;

    const participantsInfo = this.model.genderInfo;
    // eslint-disable-next-line guard-for-in
    for (const gender in participantsInfo) {
      const genderInfo = participantsInfo[gender];
      if (!!genderInfo && !!genderInfo.participants) {
        const uniqueParticipants = Array.from(new Set(
          genderInfo.participants.map(
            (item) => item.player,
          ),
        )) || [];
        genderInfo.participants =
          (uniqueParticipants.map((id) => genderInfo.participants
            .find((item) => item.player === id))).filter((p) =>
              p.status === 'Approved');
        participantsResponded += genderInfo.participants ? genderInfo.participants.length : 0;
        participantsResponded += genderInfo.participants.length;
      }

      if (!!genderInfo && !!genderInfo.numberOfPlayersNeeded) {
        playersNeeded += genderInfo.numberOfPlayersNeeded;
      }
    }

    // eslint-disable-next-line max-len
    return { participantsResponded, playersNeeded: playersNeeded - participantsResponded, totalNeeded: playersNeeded };
  }

  get isGenderSpecific() {
    const genders = [];
    const participantsInfo = this.model.genderInfo;
    // eslint-disable-next-line guard-for-in
    for (const gender in participantsInfo) {
      if (gender.toLowerCase() !== 'any') {
        genders.push(gender);
      }
    }

    return genders.length > 0;
  }

  get cost() {
    const cost = this.model.cost;
    if (
      !cost ||
      cost === null ||
      cost === 0 ||
      cost.toString().trim() === '0' ||
      cost.toString().trim() === '') {
      return undefined;
    }
    return this.model.cost;
  }

  get equipmentNeeded() {
    return this.model.equipmentNeeded;
  }

  get requiredAttendees() {
    return this.model.requiredAttendees;
  }

  get makePublicAfter() {
    return this.model.makePublicAfter;
  }

  get creatorApprovalNeeded() {
    return this.model.creatorApprovalNeeded ?? false;
  }

  set creatorApprovalNeeded(val: boolean) {
    this.model.creatorApprovalNeeded = val ?? false;
  }

  get platform() {
    return this.model.platform;
  }
  set platform(val: string) {
    this.model.platform = val;
  }

  get id() {
    return this._id;
  }

  get participantInfo() {
    return this.model.genderInfo ? this.model.genderInfo : {};
  }
  set participantInfo(val: { [key in string]?: GenderInfo }) {
    this.model.genderInfo = val;
  }

  get participants(): EventJoiner[] {
    let players: EventJoiner[] = [];
    const participantsInfo = this.participantInfo;
    // eslint-disable-next-line guard-for-in
    for (const gender in participantsInfo) {
      const genderInfo = participantsInfo[gender];
      if (genderInfo && genderInfo.participants) {
        players = players.concat(genderInfo.participants);
      }
    }

    return players;
  }

  get approvedPlayerIds(): string[] {
    const players: string[] = [];

    this.participants.forEach((p) => {
      if (p.status === EventJoinerStatus.Approved) {
        players.push(p.player);
      }
    });

    return players;
  }

  get waitList() {
    const players: string[] = [];

    this.participants.forEach((p) => {
      if (p.status === EventJoinerStatus.Waitlisted) {
        players.push(p.player);
      }
    });

    return players;
  }

  get cancelled() {
    return this.model.cancelled;
  }

  cancel() {
    this.model.cancelled = true;
  }

  get inPast() {
    const today = new Date();
    return this.dateStart.getTime() < today.getTime();
  }

  get genderInfo() {
    return this.model.genderInfo;
  }
  set genderInfo(val: { [key in string]?: GenderInfo }) {
    this.model.genderInfo = val;
  }

  get rated() {
    return this.model.rated;
  }
  set rated(val: boolean) {
    this.model.rated = val;
  }

  get discoverable() {
    return this.model.discoverable;
  }
  set discoverable(val: boolean) {
    this.model.discoverable = val;
  }

  get theme() {
    return this.model.theme;
  }
  set theme(val: EventTheme) {
    this.model.theme = val;
  }

  get backgroundColor() {
    return this.model.backgroundColor;
  }
  set backgroundColor(val: string) {
    this.model.backgroundColor = val;
  }

  get sendFeedbackEmailAfter() {
    return this.model.sendFeedbackEmailAfter;
  }
  set sendFeedbackEmailAfter(val: boolean) {
    this.model.sendFeedbackEmailAfter = val;
  }

  get ticketLevels() {
    return this.model.ticketLevels;
  }
  set ticketLevels(val: EventTicketLevel[]) {
    this.model.ticketLevels = val;
  }

  get priceId() {
    return this.model.priceId;
  }
  set priceId(val: string) {
    this.model.priceId = val;
  }

  private _creator?: GthUserModel;
  private _eventJoinerStatus?: EventJoinerStatus;
  private _selectedTicketLevel?: EventTicketLevel;

  setSelectedTicketLevel(val: EventTicketLevel) {
    this._selectedTicketLevel = val;
  }
  get selectedTicketLevel() {
    return this._selectedTicketLevel ?? null;
  }

  setCreator(val: GthUserModel) {
    this._creator = val;
    this.model.creator = val?.uid;
  }

  setEventJoinerStatus(status: EventJoinerStatus) {
    this._eventJoinerStatus = status;
  }

  setEventType(eventType: EventItemTypes) {
    this.model.type = eventType;
  }

  hasRsvp(user: GthUserModel | undefined) {
    if (!user) {
      return false;
    }
    const players = this.approvedPlayerIds;
    const userIndex = players.findIndex((pId) => pId === user.uid);
    return userIndex >= 0;
  }

  requiresGender(gender: string) {
    const participants = this.participantInfo;

    if (!participants) {
      return false;
    }

    const participantsOfGender = participants[gender];

    if (!participantsOfGender) {
      const participantsOfAny = participants['Any'];
      if (!participantsOfAny) {
        return false;
      }
    }

    return true;
  }

  getTeamPlayers(user: GthUserModel | undefined): GthUserModel[] {
    if (!user) {
      return [];
    }

    return [];
  }

  addPlayer(player: User) {
    if (!player.additionalInfo || !this.model.genderInfo) {
      return undefined;
    }

    const isTeamEvent = typeof this.model?.hostingTeam === 'string';

    const participantInfo: EventJoiner = {
      player: player.uid,
      createdAt: new Date(),
      status: EventJoinerStatus.PendingCreator,
      rsvpStatus: isTeamEvent ? EventRsvpStatus.NOT_PLAYING : EventRsvpStatus.PLAYING,
    };

    const gender = player.additionalInfo.gender;
    const participantsInfo = this.model.genderInfo;
    const participantsOfGender = participantsInfo[gender];
    const creatorApprovalNeeded = this.creatorApprovalNeeded ?? false;

    if (participantsOfGender) {
      const needed = participantsOfGender.numberOfPlayersNeeded;
      const has = participantsOfGender.participants ?
        participantsOfGender.participants.length :
        0;
      if (has < needed) {
        if (creatorApprovalNeeded) {
          participantInfo.status = EventJoinerStatus.PendingCreator;
        } else {
          participantInfo.status = EventJoinerStatus.Approved;
        }
      } else {
        participantInfo.status = EventJoinerStatus.Waitlisted;
      }

      if (!participantsOfGender.participants) {
        participantsOfGender.participants = [];
      }

      participantsOfGender.participants.push(participantInfo);
    } else {
      const participantsOfAny = participantsInfo['Any'];
      if (!participantsOfAny) {
        return undefined;
      }

      const needed = participantsOfAny.numberOfPlayersNeeded;
      const has = participantsOfAny.participants ? participantsOfAny.participants.length : 0;
      if (has < needed) {
        if (creatorApprovalNeeded) {
          participantInfo.status = EventJoinerStatus.PendingCreator;
        } else {
          participantInfo.status = EventJoinerStatus.Approved;
        }
      } else {
        participantInfo.status = EventJoinerStatus.Waitlisted;
      }
      if (!participantsOfAny.participants) {
        participantsOfAny.participants = [];
      }
      participantsOfAny.participants.push(participantInfo);
    }

    return participantInfo;
  }

  getParticipant(user: GthUserModel) {
    if (!user) {
      return false;
    }
    const participants = this.participants;

    const participant = participants.find((p) => {
      if (!user) {
        return false;
      }
      return p.player === user.uid;
    });
    return participant;
  }


  getAllParticipant(user: GthUserModel) {
    if (!user) {
      return false;
    }
    return this.participants;
  }

  getJoinParticipantStatus(user: GthUserModel) {
    switch (this.getParticipantStatus(user)) {
      case 'None':
      case 'Dropped':
      case 'Pending Joiner':
        return true;
      default:
        return false;
    }
  }

  getParticipantStatus(user: GthUserModel) {
    const participant = this.getParticipant(user);
    if (!participant || !participant.status) {
      return 'None';
    }

    return participant.status;
  }

  getParticipantRsvpStatus(user: GthUserModel) {
    const participant = this.getParticipant(user);
    if (!participant || !participant.rsvpStatus) {
      return 'None';
    }

    return participant.rsvpStatus;
  }

  getApprovalText(user: GthUserModel) {
    switch (this.getParticipantStatus(user)) {
      case EventJoinerStatus.Waitlisted:
        return 'You are currently on the waitlist.';
      case EventJoinerStatus.PendingCreator:
      case EventJoinerStatus.PendingApprovers:
        return 'Pending approval.';
    }
    return '';
  }

  hasAvailability(user: GthUserModel) {
    let hasAvailability = false;
    const anyParticipants = this.participantInfo['Any'];
    const playerGender = user.gender ?? 'Any';
    const genderParticipants = this.participantInfo[playerGender];

    if (genderParticipants) {
      const participantsNeeded = genderParticipants.numberOfPlayersNeeded;
      const participantsAccountedFor = genderParticipants.participants ?
        genderParticipants.participants.length :
        0;
      if (participantsNeeded > participantsAccountedFor) {
        hasAvailability = true;
      }
    } else if (anyParticipants) {
      const participantsNeeded = anyParticipants.numberOfPlayersNeeded;

      const participantsAccountedFor = anyParticipants.participants ?
        anyParticipants.participants.length :
        0;

      if (participantsNeeded > participantsAccountedFor) {
        hasAvailability = true;
      }
    }

    return hasAvailability;
  }

  getJoinButtonType(user: GthUserModel): JoinButtonType {
    if (!user) {
      return JoinButtonType.RequestJoinGame;
    }

    if (this.creatorId === user.uid) {
      return JoinButtonType.Creator;
    }

    const hasAvailability = this.hasAvailability(user);

    const participantStatus = this.getParticipantStatus(user);

    if (participantStatus === EventJoinerStatus.PendingJoiner) {
      return JoinButtonType.AcceptInvite;
    }

    if (!hasAvailability) {
      if (this.creatorApprovalNeeded ?? false) {
        return JoinButtonType.RequestJoinGame;
      }
      return JoinButtonType.JoinWaitlist;
    }

    if (this.creatorApprovalNeeded ?? false) {
      return JoinButtonType.RequestJoinGame;
    }

    return JoinButtonType.JoinGame;
  }

  getGthButtonText(user: GthUserModel) {
    const status = this.getParticipantRsvpStatus(user);

    switch (status) {
      case EventRsvpStatus.PLAYING:
        return 'Going';
      case EventRsvpStatus.MAYBE:
        return 'Might Go';
      case EventRsvpStatus.NOT_PLAYING:

        return 'Not Attending';
      case EventRsvpStatus.SPECTATING:
        return 'Just Watching';
      default:
        return 'Join Event';
    }
  }

  getJoinButtonText(user: GthUserModel) {
    switch (this.getJoinButtonType(user)) {
      case JoinButtonType.RequestJoinGame:
        return 'Request to Join Event';
      case JoinButtonType.AcceptInvite:
        return 'Accept Invite';
      case JoinButtonType.JoinWaitlist:
      default:
      // Todo(rkara): Our switch cases should ALWAYS throw an error
      // if we dont want it to get to the default
      // Right now, this is  not working as intented.
      // just returning JoinGame for now.
      case JoinButtonType.JoinGame:
        return 'Join Event';
    }
  }

  toItem(): StripeItem {
    return {
      id: this.id,
      name: this.title,
      quantity: 1,
      cost: this.cost,
      type: StripeItemType.JOIN_EVENT,
      platform: this.platform as 'gth' | 'meh',
    };
  }
}
