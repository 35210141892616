import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import { Availability } from '@index/interfaces';
import { CloudCollectionService } from './cloud-collection.service';
import { SrvAvailabilityModel } from '../../models/user-availability';

const COLLECTION_NAME = 'srv_user_availability';

@Injectable({
  providedIn: 'root',
})
export class SrvUserAvailabiiltyService {
  private cloudCollection = new CloudCollectionService(this.firestore, COLLECTION_NAME);

  constructor(
    private firestore: AngularFirestore,
  ) {
  }

  /**
   * Creates a new Availability based on the model
   * @param {string} id id of the user creating availability
   * @param {Availability} model availability being created
   * @return {Promise<SrvAvailabilityModel>} New Availability Model
   */
  async create(id: string, model: Availability): Promise<SrvAvailabilityModel> {
    const newId = await this.cloudCollection.createWithId<Availability>(id, model);
    if (!newId) return undefined;

    return new SrvAvailabilityModel(id, {
      ...model,
    });
  }

  /**
   * Reads availability model for user by user id
   * @param {string} id User Id
   * @return {Promise<SrvAvailabilityModel>} Returns availability model
   */
  async read(id: string): Promise<SrvAvailabilityModel> {
    const item = await this.cloudCollection.read<Availability>(id);
    if (!item) return undefined;
    return new SrvAvailabilityModel(id, {
      ...item,
    });
  }

  /**
   * Updates user availability
   * @param {string} id - The ID of the user for whom availability data is updated.
   * @param {GthAvailabilityModel} availabilityModel - The updated availability model.
   * @return {Promise<boolean>} True if successfully updated
   */
  update$(id: string, availabilityModel: SrvAvailabilityModel): Promise<boolean> {
    const item = availabilityModel.model;
    return this.cloudCollection.update<Availability>(id, item);
  }

  /**
   * Deletes user availability data
   * @param {string} id - The ID of the user for whom availability data is deleted.
   * @return {Promise<boolean>} True if successfully deleted
   */
  delete$(id: string): Promise<boolean> {
    return this.cloudCollection.delete(id);
  }
}
