<div class="banner-map-container fx-centered">
  <picture *ngIf="shouldShow.banner" class="banner-container">
    <img *ngIf="banner else placeholderBanner" [src]="banner"/>
    <ng-template #placeholderBanner>
      <img class="banner" src="assets/logo-standing-fullcolor.png" width="400" height="400" />
    </ng-template>
  </picture>

  <ng-container>
    <gth-google-map
    [latitude]="lat" [longitude]="lng" [zoom]="zoom" *ngIf="!online">
      <gth-google-map-marker [latitude]="lat" [longitude]="lng" />
    </gth-google-map>
  </ng-container>
</div>

<div class="details-container">
  <div class="title-container" *ngIf="shouldShow.title">
    <h2 [matTooltip]="title" class="title" [title]="title">{{ title }}</h2>

  </div>
    <span class="subtext">Hosted by 
      <a  [routerLink]="[APP_ROUTES.Profile, event.creator.uid]" routerLinkActive="router-link-active">{{event.creator.displayNameFallback}}</a>
      <button mat-flat-button class="action-button" [matMenuTriggerFor]="menu">
        <mat-icon class="icon">more_horiz</mat-icon>
      </button>
    </span>

    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="openGoogleLink()">Save to Google Calendar</button>
      <button mat-menu-item (click)="generateICalFile()">Save to iCal</button>
    </mat-menu>

    <mat-divider></mat-divider>

    <mat-tab-group>
      <mat-tab >
        <div class="info-tab">
            <ng-template mat-tab-label>
              <mat-icon class="example-tab-icon">info</mat-icon>
              More Info
            </ng-template>

            <ng-container *ngIf="platform !== 'meh'">
              <h4 *ngIf="shouldShow.type" class="event-type">{{eventType}}</h4>
            </ng-container>
            <p class="description secondary-text" *ngIf="shouldShow.description && !isGameInPast">{{description}}</p>
            <p *ngIf="shouldShow.description && gameDescription" class="description secondary-text">
              {{ gameDescription }}
            </p>
    
            <div *ngIf="shouldShow.date" class="date-container">
              <h3>Date</h3>
              <p class="secondary-text">
                {{ dateStart | date: 'mediumDate' }}<br />
                at {{ dateStart | date: 'h:mm a' }}
              </p>
            </div>
      
            <div *ngIf="shouldShow.location" class="location-container">
              <h3>Location</h3>
              <p class="secondary-text">{{address}}</p>
            </div>
      
            <div *ngIf="shouldShow.gameType && platform !== 'meh'" class="game-type-container">
              <h3>Type</h3>
              <p class="secondary-text">{{gameType}}</p>
            </div>
      
            <div *ngIf="shouldShow.cost && (cost || ticketLevels?.length)" class="cost-container">
              <ng-container *ngIf="ticketLevels?.length else showCost">
                <h3>Ticket levels</h3>
      
                <mat-chip-listbox [selectable]="true" [multiple]="false" [value]="null"
                  (change)="event.setSelectedTicketLevel($event.value)">
                  <mat-chip-option [selected]="event.selectedTicketLevel === null"
                    [selectable]="event.selectedTicketLevel !== null">
                    General - {{ cost ? (cost | currency:'USD') : 'Free' }}
                  </mat-chip-option>
                  <mat-chip-option *ngFor="let ticketLevel of ticketLevels" (click)="event.setSelectedTicketLevel(ticketLevel)"
                    [value]="ticketLevel" [selectable]="event.selectedTicketLevel?.name !== ticketLevel?.name">
                    {{ ticketLevel.name }} - {{ ticketLevel.cost | currency:'USD' }}
                  </mat-chip-option>
                </mat-chip-listbox>
              </ng-container>
              <ng-template #showCost>
                <h3>Cost</h3>
                <p class="secondary-text">{{ cost | currency:'USD' }}</p>
              </ng-template>
            </div>
            <div class="participants-container" (click)="openParticipantStatusDialog(participants)">
                <div>
                  <h4>{{totalCount}} {{ (totalCount) === 1 ? 'has responded' : 'have responded' }} <span
                    class="needed-text">({{totalParticipantsNeeded}} more needed)</span></h4>
                </div>
      
                <div class="circle-container">
                  <!-- Display up to three circles -->
                  <ng-container *ngFor="let participant of participants.slice(0, 3); let i = index">
                    <div class="circle c-bg"  [style.zIndex]="i + 1">
                      @if (participant?.photoURL) {
                        <img loading="lazy" src="{{getPhotoURL(participant.photoURL)}}" />
                      } @else {
                        <mat-icon class="message--user-icon" fontIcon="person" />
                      }
                    </div>
      
                  </ng-container>
                  <!-- Display a green fourth circle with a "+" sign if there are more than three participants -->
                  <div *ngIf="totalCount > 3" class="circle green" style="z-index: 4;">
                    <span class="plus">+</span>{{ totalCount - 3 }}
                  </div>
            </div>
          </div>
          <div class="margin-top-auto">
            <div *ngIf="shouldShow.actions" class="event-actions-container">
              <ng-container *ngIf="!isGameInPast; else pastGameTmpl">
                <div [matTooltipDisabled]="gameIncludesUsersGender" #tooltip="matTooltip"
                  *ngIf="!canEditEvent; else editButtonTmpl"
                  matTooltip="You cannot join this event because your gender does not match the one(s) required.">
                  <div class="error-container" *ngIf="errors.length > 0">
                    <ul>
                      <li *ngFor="let error of errors">
                        {{error}}
                      </li>
                    </ul>
                  </div>
      
                  <div class="fx-row fx-gap-10x">
                    <ng-container *ngIf="joinParticipantStatus else leaveGameTmpl">
                      <button mat-stroked-button color="warn" (click)="onDeclineInvite()"
                        *ngIf="participantStatus === 'Pending Joiner'">
                          Decline Invite
                      </button>
      
                      <gth-button-menu
                        [disabled]="!gameIncludesUsersGender || isCreator || canEditEvent || !needParticipants || isGuestProfile"
                        [options]="buttonMenuOptions" (itemClick)="onJoinEvent($event)">{{ gthButtonText }}</gth-button-menu>
                      <br />
                      <div *ngIf="isGuestProfile">
                        <a mat-flat-button color="primary" (click)="onLoginClick()">Log in to join this game!</a>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </ng-container>
      
              <ng-template #pastGameTmpl>
                <p>This event has completed.</p>
              </ng-template>
      
              <ng-template #editButtonTmpl>
                <button mat-flat-button color="primary" (click)="onEditButtonClick()" class="uppercase">
                  Edit Event
                </button>
      
                <button *ngIf="platform === 'gth'" mat-stroked-button color="primary" (click)="onManageButtonClick()"
                  class="uppercase">
                  Manage Event
                </button>
              </ng-template>
      
              <button *ngIf="canEditEvent && !isGameInPast && !cancelled" mat-button color="warn" class="uppercase"
                (click)="onCancelEvent()">Cancel Event</button>
            </div>

      
            <div *ngIf="shouldShow.share" class="share-container">
              <h3>Share</h3>
              <div class="buttons-container">
                <button mat-button color="primary" shareButton="facebook" [description]="shareDescription" [url]='url'
                  #fbBtn="shareButton">
                  <div>
                    <div class="icon-container"><fa-icon [icon]="faFacebookF"></fa-icon></div>
                    Facebook
                  </div>
                </button>
      
                <button mat-button color="primary" shareButton="twitter" [description]="shareDescription" [url]='url'
                  #twitterBtn="shareButton">
                  <div>
                    <div class="icon-container"><fa-icon [icon]="faTwitter"></fa-icon></div>
                    Twitter
                  </div>
                </button>
      
                <button mat-button color="primary" shareButton="copy" [description]="shareDescription" [url]="url" #copyLink>
                  <div>
                    <div class="icon-container"><mat-icon class="icon">link</mat-icon></div>
                    Copy Link
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
    
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="example-tab-icon">chat</mat-icon>
          Discussion
        </ng-template>
    
        <gth-wall-post [event]="this.event.id|| ''"></gth-wall-post>
      </mat-tab>
    </mat-tab-group>
</div>

<ng-template #leaveGameTmpl>
  <button class="dialog-button" mat-stroked-button color="warn" (click)="onLeaveEvent()">
    Leave Event
  </button>
</ng-template>