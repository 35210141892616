import { Injectable } from '@angular/core';
import { SrvEventRatingsService } from './cloud/event-ratings.service';
import { SrvGameTypesService } from './cloud/game-types.service';
import { SrvGendersService } from './cloud/genders.service';
import { SrvPersonalityTypesService } from './cloud/personality-types.service';
import { SrvTeamRatingsService } from './cloud/team-ratings.service';
import { SrvTeamRolesService } from './cloud/team-roles.service';
import { SrvUserAvailabiiltyService } from './cloud/user-availability.service';

@Injectable({
  providedIn: 'root',
})
export class SrvApiService {
  constructor(
    public eventRatings: SrvEventRatingsService,
    public gameTypes: SrvGameTypesService,
    public genders: SrvGendersService,
    public personalityTypes: SrvPersonalityTypesService,
    public teamRatings: SrvTeamRatingsService,
    public teamRoles: SrvTeamRolesService,
    public userAvailability: SrvUserAvailabiiltyService,
  ) { }
}
