import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import {
  GthAuthService,
  GthEventFormComponent,
  TimeSelectionComponent,
  TimeSelectionContract,
  AddressSelectionComponent,
} from '@gth-legacy';
import { EventItemTypes } from '@index/enums/event-item-type';
import {
  Location,
} from '@index/interfaces';
import {
  GthEventItemForm,
  GthEventItemModel,
  GthTeamModel,
  GthUserModel,
} from '@sentinels/models';

@Component({
  selector: 'app-event-form',
  templateUrl: './event-form.component.html',
})
export class EventFormComponent implements OnInit, AfterViewInit {
  @ViewChild(GthEventFormComponent)
  private eventForm?: GthEventFormComponent;

  @Input()
  get teamName() {
    return this.eventForm ? this.eventForm.teamName : this._teamName;
  }
  set teamName(val: string) {
    this._teamName = val;
    if (this.eventForm) {
      this.eventForm.teamName = val;
    }
  }

  @Input()
  disabled = false;

  @Input()
  eventPlatform = 'gth';

  @Input()
  event?: GthEventItemModel;

  @Input()
  get team() {
    return this._team;
  }
  set team(val: GthTeamModel | undefined) {
    this._team = val;
    if (this._team) {
      this.eventForm.eventItemForm.setTeam(this._team);
      this.eventForm.teamName = this._team.name;
    }
  }

  @Input()
  user?: GthUserModel;

  @Input()
  editing = false;

  @Input()
  routeOnSave = true;

  @Input()
  public = false;

  @Input()
  displayAppearanceSettings = false;

  @Input()
  displayGenderInformation = true;

  @Input()
  displaySendFeedbackEmailAfterEvent = false;

  @Input()
  displayTicketLevels = false;

  @Output()
  save = new EventEmitter<string>();

  @Output()
  savePublic = new EventEmitter<GthEventItemModel>();

  get valid() {
    if (!this.eventForm) {
      return false;
    }

    return this.eventForm.valid;
  }

  get eventItemType() {
    if (this.team) {
      return EventItemTypes.Team;
    }
    return EventItemTypes.Pickup;
  }

  get value() {
    if (!this.eventForm) {
      return undefined;
    }

    return this.eventForm.value;
  }

  currentUser$?: Observable<GthUserModel | undefined>;

  private _teamName = '';
  private _team?: GthTeamModel;

  constructor(
    private auth: GthAuthService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.currentUser$ = this.auth.userModel$;
  }

  ngAfterViewInit() {
    if (!this.eventForm || this.event) {
      return;
    }

    this.eventForm.teamName = this._teamName;

    if (this.team) {
      this.eventForm.eventItemForm.setTeam(this.team);
      this.eventForm.teamName = this.team.name;
    }

    if (this.user) {
      this.eventForm.eventItemForm.setUser(this.user);
    }
  }

  /**
   * Event handler for event time button click
   * @param {GthEventItemForm} form Form Item
   */
  onTimeButtonClick(form: GthEventItemForm) {
    const dialogRef = this.dialog.open(TimeSelectionComponent, {
      width: '720px',
      height: '450px',
      data: {
        startDate: form.dateStart,
        duration: form.duration,
        recurrence: form.recurringType,
      },
      backdropClass: 'gth-overlay-backdrop',
    });
    dialogRef.afterClosed().subscribe((result: TimeSelectionContract | string) => {
      if (result === 'cancel') {
        return;
      }
      const contract = result as TimeSelectionContract;
      if (contract) {
        form.dateStart = contract.startDate;
        form.duration = {
          hours: contract.durationHours,
          minutes: contract.durationMinutes,
        };
        form.recurringType = contract.recurrence;
      } else {
        form.dateStart = undefined;
        form.duration = undefined;
        form.recurringType = undefined;
      }
    });
  }

  /**
   * Event handler for location button click
   * @param {GthEventItemForm} form Form Item
   */
  onAddressButtonClick(form: GthEventItemForm) {
    const address = form.location;
    const dialogRef = this.dialog.open(AddressSelectionComponent, {
      id: 'address-selection-dialog',
      backdropClass: 'gth-overlay-backdrop',
      panelClass: 'gth-dialog--custom-size',
      maxWidth: '100%',
      width: '450px',
      data: { address },
    });
    dialogRef.afterClosed().subscribe((address: Location) => {
      form.location = address;
    });
  }

  onEventSave(id: string) {
    this.save.emit(id);
  }

  onPublicEventSave(event: GthEventItemModel) {
    this.savePublic.emit(event);
  }
}
