export class GthModel<T> {
  constructor(
    protected readonly _id: string,
    public readonly model: T,
    public metadata:any = {},
  ) {}
  get copy(): T {
    return Object.assign({}, this.model);
  }
}
