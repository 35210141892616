import { CommonModule, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { Router, RouterModule } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';

import { MEH_APP_ROUTES } from '../../../app-routing.module';
import { GthAuthService, GthFeedbackDialogComponent, GthFeedbackService } from '@gth-legacy';
import { Theme, ThemeSwitcherService } from '../../services/theme-switcher.service';
import { GthUserModel } from '@sentinels/models';

@Component({
  selector: 'meh-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    NgIf,
    RouterModule,
    MatButtonModule,
    MatDialogModule,
    MatDividerModule,
    MatIconModule,
    MatMenuModule,
    MatSlideToggleModule,
  ],
})
export class MehNavBarComponent implements OnInit {
  protected readonly MEH_APP_ROUTES = MEH_APP_ROUTES;
  user$?: Observable<GthUserModel>;
  theme$?: Observable<Theme>;
  userPhotoError = false;
  logoTypes = {
    dark: 'MyEventHero_Logo_Invert',
    light: 'MyEventHero_Logo',
  };

  constructor(
    private auth: GthAuthService,
    private feedback: GthFeedbackService,
    private themeSvc: ThemeSwitcherService,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.user$ = this.auth.userModel$;
    this.theme$ = this.themeSvc.theme$;
  }

  getLogo(theme:string) {
    return theme === 'light' ?
    'assets/imgs/MyEventHero_Logo.png' :
    'assets/imgs/MyEventHero_Logo_Invert.png';
  }

  async onLogoutButtonClick() {
    await this.auth.logout();
    this.router.navigate(['/']);
  }

  onThemeToggleChange(checked: boolean) {
    if (checked) {
      this.themeSvc.setTheme(Theme.light);
    } else {
      this.themeSvc.setTheme(Theme.dark);
    }
  }

  onFeedbackButtonClick() {
    const dialogRef = this.dialog.open(GthFeedbackDialogComponent, {
      backdropClass: 'gth-overlay-backdrop',
      panelClass: 'gth-dialog--custom-size',
      height: '500px',
      width: '550px',
      maxWidth: '100%',
    });

    dialogRef.afterClosed().subscribe(async (feedback) => {
      if (!feedback) return;
      await this.feedback.submitFeedback(feedback);
      this.snackbar.open('Thank you for your feedback!');
    });
  }
}
