<button #avatarButton class="avatar-button" type="button" (mouseenter)="showIcon()" (mouseleave)="hideIcon()"
        [ngStyle]="{ 'background-image': getUrl() }" (click)="openDialog()" color="primary"
        i18n="Button to sign up to Gametime Hero" mat-fab>
  <mat-icon #avatarIconButton class="avatar-icon-button" [style.visibility]="avatarFormControl.value ? 'hidden' : 'visible'">person</mat-icon>
</button>
<div class="text-container">
  <ng-container *ngIf="getUrl().length > 5">
    <p class="good-looking">That's one good looking photo.</p>
  </ng-container>
</div>
