import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';

import { GthTeamScheduleTableComponent } from './team-schedule-table.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    MatTableModule,
    MatCheckboxModule,
  ],
  exports: [GthTeamScheduleTableComponent],
  declarations: [GthTeamScheduleTableComponent],
})
export class GthTeamScheduleTableModule {}
