import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';

import { APP_ROUTES } from '@shared/helpers';
import { TemplateTooltipDirective } from './template-tooltip.directive';

export interface TemplateTooltipItem {
  displayName: string;
  uid: number | string;
}

@Component({
  selector: 'gth-template-tooltip',
  templateUrl: './template-tooltip.component.html',
  styleUrls: ['./template-tooltip.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    RouterModule,
    TemplateTooltipDirective,
  ],
})
export class TemplateTooltipComponent {
  @HostListener('mouseleave')
  onFocusOut() {
    this.mouseLeave.emit();
  }

  routes = APP_ROUTES;

  @Input()
  tooltipItem?: TemplateTooltipItem;

  @Input()
  platform!: string;

  @Output()
  mouseLeave = new EventEmitter();
}
