import { Topic } from '@index/interfaces';
import { GthModel } from './model';
import { GthTopicMessage } from './topic-message';
import { GthUserModel } from './user';

export class GthTopic extends GthModel<Topic> {
  private _creator: GthUserModel;
  private _users: GthUserModel[];
  private _admins: GthUserModel[];
  private _messages: GthTopicMessage[];

  get topic(): Topic {
    return this.model;
  }

  get id() {
    return this.model.id;
  }

  get favorite() {
    return this.model.isFavorite;
  }
  set favorite(val: boolean) {
    this.model.isFavorite = val;
  }

  get muted() {
    return this.model.isMuted;
  }
  set muted(val: boolean) {
    this.model.isMuted = val;
  }

  get name() {
    return this.model.name;
  }

  get description() {
    return this.model.description;
  }

  get creator() {
    return this.model.creator;
  }

  get users() {
    return this.model.users;
  }

  get admins() {
    return this.model.admins;
  }

  get isFavorite() {
    return this.model.isFavorite;
  }
  set isFavorite(val: boolean) {
    this.model.isFavorite = val;
  }

  get isMuted() {
    return this.model.isMuted;
  }
  set isMuted(val: boolean) {
    this.model.isMuted = val;
  }

  get creatorModel() {
    return this._creator;
  }

  get userModels() {
    return this._users;
  }

  get teamId() {
    return this.model.teamId;
  }

  get messages() {
    return this._messages;
  } set messages(val: GthTopicMessage[]) {
    this._messages = val;
  }

  get combinedUsers() {
    return [this.creatorModel, ...this.adminModels, ...this.userModels];
  }

  get adminModels() {
    return this._admins;
  }

  constructor(id: string, model: Topic) {
    super(id, model);
  }

  setUsers(creator: GthUserModel, users: GthUserModel[], admins: GthUserModel[]) {
    this._creator = creator;
    this._users = users;
    this._admins = admins;
  }

  setMessages(messages: GthTopicMessage[]) {
    this._messages = messages;
    this.model.messages = messages.map((m) => m.model);
  }
}

export interface GthTopicCollection {
  topicSections: GthTopicSection[],
  teamId: string,
}

export interface GthTopicSection {
  name: string,
  topics: GthTopic[],
}
