import { Component, Inject, Input, ViewChild } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { GthUserModel } from '../../../../../../sentinels/src/lib/models/user';
import { EventRsvpStatus } from '@index/interfaces';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { APP_ROUTES } from '@shared/helpers';
import { Router } from '@angular/router';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

type UserModelWithRsvpStatus = GthUserModel & {
    rsvpStatus: EventRsvpStatus;
}

@Component({
  selector: 'gth-participant-status-dialog',
  templateUrl: './participant-status-dialog.component.html',
  styleUrls: ['./participant-status-dialog.component.scss'],
  standalone: true,
  imports: [
  MatTabsModule,
  CommonModule,
  MatListModule,
  MatIconModule,
  MatButtonModule,
  ],
})
export class ParticipantStatusDialogComponent {
    @ViewChild('listTemplate') listTemplate: any;
    participants: UserModelWithRsvpStatus[] = [];
    isAdmin = false;

  get goingParticipants(): UserModelWithRsvpStatus[] {
    return this.participants.filter((participant) => {
      return participant.metadata.rsvpStatus === EventRsvpStatus.PLAYING;
    });
  }

  get spectatingParticipants(): UserModelWithRsvpStatus[] {
    return this.participants.filter((participant) => {
      return participant.metadata.rsvpStatus === EventRsvpStatus.SPECTATING;
    });
  }

  get notGoingParticipants(): UserModelWithRsvpStatus[] {
    return this.participants.filter((participant) => {
      return participant.metadata.rsvpStatus === EventRsvpStatus.NOT_PLAYING;
    });
  }

  get maybeParticipants(): UserModelWithRsvpStatus[] {
    return this.participants.filter((participant) => {
      return participant.metadata.rsvpStatus === EventRsvpStatus.MAYBE;
    });
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    ) {
    this.participants = data.participants;
    this.isAdmin = data.isAdmin;
  }

  onMessageButtonClick(userId: string) {
    this.router.navigate([APP_ROUTES.Messages], {
      queryParams: {
        userId,
      },
    });
  }
}
