import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GthFindPlayersDialogComponent } from '@gth-legacy/components/find-players-dialog/find-players-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';

const ANGULAR_MATERIAL_MODULES = [
  MatButtonModule, MatIconModule, MatDialogModule,
];

const CORE_MODULES = [CommonModule];

const COMPONENTS = [GthFindPlayersDialogComponent];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [...CORE_MODULES, ...ANGULAR_MATERIAL_MODULES, MatButtonModule],
  exports: [...COMPONENTS],
})
export class GthFindPlayersDialogModule { }
