import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

import { MEH_APP_ROUTES } from '../../../../app-routing.module';

@Component({
  selector: 'meh-admin-nav',
  standalone: true,
  templateUrl: './admin-nav.component.html',
  styleUrls: ['./admin-nav.component.scss'],
  imports: [
    CommonModule,
    MatButtonToggleModule,
    RouterModule,
  ],
})
export class MehAdminNavComponent {
  ROUTES = MEH_APP_ROUTES;

  constructor(private router: Router) { }

  onNavButtonClick(route: string) {
    this.router.navigate([route]);
  }
}
