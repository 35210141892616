import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import { from, Observable, of, Subject } from 'rxjs';
import { catchError, first, map, switchMap } from 'rxjs/operators';
import { ActionCodeSettings } from 'firebase-admin/lib/auth';

import { User } from '@index/interfaces';
import { GthUserModel } from '@sentinels/models/user';
import { GthCloudFunctionService } from './cloud/cloud-function.service';
import AuthError = firebase.auth.AuthError;
import { SrvSafeStorageService } from '@sentinels/services/safe-storage.service';

export interface AuthResults {
  newUser: boolean | null;
  user: GthUserModel | null;
  success: boolean;
  errorType?: LoginError,
}

export const enum StatusEnum {
  ONLINE = 'Online',
  OFFLINE = 'Offline',
}

export enum LoginError {
  Unknown,
  CancelledByUser,
  NetworkError,
  InvalidUser,
}

export interface UserStatus {
  state: StatusEnum;
  // eslint-disable-next-line
  last_changed: Object;
}

const USERNAME_STORE = 'USERNAME_STORE';

const isOfflineForDatabase: UserStatus = {
  state: StatusEnum.OFFLINE,
  last_changed: firebase.database.ServerValue.TIMESTAMP,
};

const isOnlineForDatabase = {
  state: StatusEnum.ONLINE,
  last_changed: firebase.database.ServerValue.TIMESTAMP,
};

// Use this to confirm if profile is a guest.
export const GUEST_PROFILE_ID = 'guest-profile-id';

const guestProfileModel = new GthUserModel(
  GUEST_PROFILE_ID,
  {
    uid: GUEST_PROFILE_ID,
    displayName: 'Guest',
    fullName: 'Guest',
    email: 'guest@gametimehero.fake',
    token: 'fake-token',
    photoURL: 'assets/avatars/guest-icon.png',
    emailVerified: true,
    defaultCity: null,
    createdAt: firebase.firestore.Timestamp.now(),
    updatedAt: firebase.firestore.Timestamp.now(),
  },
);

export const DEFAULT_CURRENT_USER = {
  uid: 'guest',
};

@Injectable({
  providedIn: 'root',
})
export class GthAuthService {
  guestProfile = guestProfileModel;

  get userModel$(): Observable<GthUserModel | undefined> {
    return this.getCurrentUser$().pipe(
      switchMap((user) => {
        if (!user || !user.email) {
          return of(undefined);
        }
        return this.cloudFunctions.user.getUserByEmail$(user.email);
      }),
    );
  }

  get userModelWithGuestProfile$(): Observable<GthUserModel> {
    return this.userModel$.pipe(map((user) => {
      if (user) return user;
      return this.guestProfile;
    }));
  }

  get isLoggedIn$(): Observable<boolean> {
    return this.userModel$.pipe(map((user) => {
      return !!user;
    }));
  }

  get emailVerified$() {
    return this.emailVerifiedSubject.asObservable();
  }

  private emailVerifiedSubject = new Subject<boolean>();

  constructor(
    public afAuth: AngularFireAuth,
    // Use this for realtime db
    private afdb: AngularFireDatabase,
    private cloudFunctions: GthCloudFunctionService,
    private safeStorage: SrvSafeStorageService,
  ) {
    this.afdb.database.ref('.info/connected').on('value', (snapshot) => {
      // If we're not currently connected, don't do anything.
      if (snapshot.val() == false) {
        return;
      }
      this.afAuth.onAuthStateChanged(async (user) => {
        if (user) {
          const userStatusDatabaseRef = this.getStatusRef(user);
          userStatusDatabaseRef
            .onDisconnect()
            .set(isOfflineForDatabase)
            .then(() => {
              userStatusDatabaseRef.set(isOnlineForDatabase);
            });
        }
      });
    });
  }

  getStatusRef(user: GthUserModel | firebase.User) {
    return this.afdb.database.ref('status/' + user.uid);
  }

  getUserModelAsync(): Promise<GthUserModel> {
    return this.userModel$.pipe(
      first(),
    ).toPromise();
  }

  getCurrentUser$(): Observable<User | undefined> {
    return new Observable<User | undefined>((observer) => {
      this.afAuth.onAuthStateChanged((user: any) => {
        if (user === null) {
          observer.next(null);
          return;
        }
        observer.next(user._delegate as unknown as User);
      });
    });
  }

  // Sign up with email/password
  signUpWithEmail$(email: string, password: string) {
    const signup$ = from(this.afAuth
      .createUserWithEmailAndPassword(email, password));
    return signup$.pipe(
      switchMap(() => this.sendVerificationMail$()),
      catchError((err) => {
        console.error('Error signing up user via email');
        console.error(err);
        return of(false);
      }),
    );
  }

  resendVerificationMail$(email: string, password: string) {
    const signIn$ = from(this.afAuth.signInWithEmailAndPassword(email, password));
    return signIn$.pipe(
      switchMap((providerUser) => {
        if (!providerUser || !providerUser.user || !providerUser.user.email) {
          return of(undefined);
        }
        return this.cloudFunctions.user.getUserByEmail$(providerUser.user.email);
      }),
      map((user) => {
        if (user && !user.emailVerified) {
          return false;
        }
        return true;
      }),
      switchMap((verified) => {
        if (verified) {
          return of(true);
        }
        return this.sendVerificationMail$();
      }),
      catchError((err) => of(false)),
    );
  }

  sendVerificationMail$() {
    const user = firebase.auth().currentUser!;
    this.emailVerifiedSubject.next(false);
    return from(user.sendEmailVerification()).pipe(
      map(() => {
        const onIdTokenChangedUnsubscribe = firebase.auth().onIdTokenChanged((user) => {
          const unsubscribeSetInterval = setTimeout(() => {
            firebase.auth().currentUser.reload();
            firebase.auth().currentUser.getIdToken(/* forceRefresh */ true);
          }, 5000);

          if (user && user.emailVerified) {
            clearInterval(unsubscribeSetInterval);
            this.emailVerifiedSubject.next(true);
            return onIdTokenChangedUnsubscribe();
          }
        });
        return true;
      }),
      catchError((err) => {
        console.error('Error sending email verification');
        console.error(err);
        return of(false);
      }),
    );
  }

  // Auth logic to run auth providers
  authLogin$(provider: firebase.auth.AuthProvider): Observable<AuthResults> {
    const signIn$ = from(this.afAuth.signInWithPopup(provider));

    return signIn$.pipe(
      switchMap((providerUser) => {
        if (!providerUser || !providerUser.user || !providerUser.user.email) {
          return of(undefined);
        }

        return this.cloudFunctions.user.getUserByEmail$(providerUser.user.email);
      }),
      map((user) => this.onLogin(user)),
      catchError((err) => this.onLoginError(err)),
    );
  }

  login$(email: string, password: string): Observable<AuthResults> {
    const signIn$ = from(this.afAuth.signInWithEmailAndPassword(email, password));
    return signIn$.pipe(
      switchMap((providerUser) => {
        if (!providerUser || !providerUser.user || !providerUser.user.email) {
          return of(undefined);
        }

        return this.cloudFunctions.user.getUserByEmail$(providerUser.user.email);
      }),
      map((user) => this.onLogin(user)),
      catchError((err) => this.onLoginError(err)),
    );
  }

  googleAuth$() {
    return this.authLogin$(new firebase.auth.GoogleAuthProvider());
  }

  facebookAuth$() {
    return this.authLogin$(new firebase.auth.FacebookAuthProvider());
  }

  isUserSaved$(email: string): Observable<boolean> {
    return this.cloudFunctions.user.getUserByEmail$(email).pipe(
      map((user) => !!user),
    );
  }

  isNewUser$(email: string) {
    return this.cloudFunctions.user.getUserByEmail$(email).pipe(
      map((user) => !!user && !user.hasAdditionalInfo),
    );
  }

  logout() {
    return this.afAuth.signOut();
  }

  /**
   * Stores user's name for the "Remember Me" checkbox
   * @return {string} User name from local storage
   */
  getStoredUserName() {
    //   const userName = this.safeStorage.getItem(USERNAME_STORE);
    //   if (userName) {
    //     return userName;
    //   }
    return undefined;
  }

  /**
   * Sets the user name in local storage
   * @param {string} userName =  the user's name
   */
  setStoredUserName(userName: string) {
    this.safeStorage.setItem(USERNAME_STORE, userName);
  }

  /**
   * Sends a password reset email to the given email address.
   *
   * @remarks
   * To complete the password reset, call {@link confirmPasswordReset}
   * with the code supplied in the email sent to the user, along with
   * the new password specified by the user.
   *
   * @param {string} email - The user's email address.
   * @param {ActionCodeSettings} actionCodeSettings
   *
   * @public
   */
  async sendPasswordResetEmail(
    email: string,
    actionCodeSettings?: ActionCodeSettings,
  ): Promise<void | AuthError> {
    return this.afAuth.sendPasswordResetEmail(email, actionCodeSettings);
  }

  deleteUser(uid: string) {
    const currentUser = firebase.auth().currentUser;

    if (currentUser.uid === uid) {
      return from([]);
      return from(firebase.auth().currentUser?.delete());
    }
    return from([]);
  }

  private getAuthResponseErrorType(error: string) {
    let errorType = LoginError.Unknown;
    const message = error.toLowerCase();
    if (message.indexOf('a network autherror') >= 0) {
      errorType = LoginError.NetworkError;
    } else if (message.indexOf('the popup has been closed') >= 0) {
      errorType = LoginError.CancelledByUser;
    } else if (message.indexOf('the password is invalid') >= 0) {
      errorType = LoginError.InvalidUser;
    }
    return errorType;
  }

  private onLoginError(err: any) {
    const errorType = this.getAuthResponseErrorType(err.toString());
    return of({
      success: false,
      newUser: false,
      errorType,
      user: null,
    });
  }

  private onLogin(user: GthUserModel) {
    if (!user) {
      return {
        success: true,
        newUser: true,
        user: null,
      };
    }

    const newUser = !user.hasAdditionalInfo;
    return {
      user,
      success: true,
      newUser,
    };
  }
}
