import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { StorageService } from '@gth-legacy/services/storage.service';
import { MatSnackBar } from '@angular/material/snack-bar';

const KB_TO_MB = 10000000;
const MAX_FILE_SIZE_MB = 1;
const MAX_FILE_SIZE = MAX_FILE_SIZE_MB * KB_TO_MB; // 1 MB

@Component({
  selector: 'gth-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.scss'],
})
export class GthUploadImageComponent {
  @Input()
  label = 'Upload an image';

  @Input()
  tooltip = '';

  @Input()
  accepts = ['image/*'];

  @Input()
  multiple = false;

  @Input()
  disabled = false;

  @Input()
  uploadPath = '';

  @Input()
  imageFormControl?: AbstractControl;

  loading = false;
  maxFileSizeMB = MAX_FILE_SIZE_MB;

  constructor(
    private storageService: StorageService,
    private snackbar: MatSnackBar,
  ) { }

  private async getFileDownloadURL(file: File) {
    const filePath = `${this.uploadPath}/${file.name}`;
    return await this.storageService.upload(filePath, file)
      .then(async (task) => await task.ref.getDownloadURL())
      .catch(() => {
        throw new Error(`Something went wrong uploading file`);
      });
  }

  public async onFileInputChange(event: Event) {
    this.loading = true;

    const inputElement = event.target as HTMLInputElement;
    const selectedFiles = inputElement.files;

    if (!selectedFiles || selectedFiles.length === 0) {
      this.snackbar.open('Select an image for upload.');
      return;
    }

    const file = selectedFiles[0];
    const fileSize = file.size / KB_TO_MB;
    if (fileSize > MAX_FILE_SIZE) {
      this.snackbar.open(`File must be smaller than ${MAX_FILE_SIZE_MB}MB.`);
      return;
    }

    try {
      /** Handle one file */
      if (selectedFiles.length === 1) {
        const downloadURL = await this.getFileDownloadURL(selectedFiles[0]);
        this.imageFormControl?.setValue(downloadURL);
      } else {
        /** Handle multiple files */
        const uploadedFiles = [];
        for (let i = 0; i < selectedFiles.length; i++) {
          const downloadURL = await this.getFileDownloadURL(selectedFiles[0]);
          uploadedFiles.push(downloadURL);
        }
        this.imageFormControl?.setValue(uploadedFiles);
      }
    } catch (error) {
      const message =
        `Something went wrong uploading ${selectedFiles.length === 1 ? 'file' : 'files'}`;
      this.snackbar.open(message, 'OK');
    } finally {
      this.loading = false;
    }
  }
}
