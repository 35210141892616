import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'gth-social-button',
  templateUrl: './social-button.component.html',
  styleUrls: ['./social-button.component.scss'],
})
export class GthSocialButtonComponent {
  @Output()
  socialClick = new EventEmitter<void>();

  @Input()
  text = 'Sign in';

  @Input()
  primaryBackgroundColor = '#000000';

  @Input()
  iconBackgroundColor = '#000000';

  @Input()
  iconSrc = '';

  handleClick() {
    this.socialClick.emit();
  }
}
