import { DBUtil, collectionRouteBuilder } from './db-utils';
import firebase from 'firebase/compat/app';
import { AngularFirestore } from '@angular/fire/compat/firestore';

export const firestorePopulate = async (
  fs:AngularFirestore,
  dbName: string | DBUtil[],
  id: string) => {
  if (Array.isArray(dbName)) {
    dbName = collectionRouteBuilder(dbName);
  }

  const doc = fs.collection(dbName).doc(id);
  return (await doc.get().toPromise());
};

export const firestorePopulateSnapshot = async (
  fs:firebase.firestore.DocumentReference<firebase.firestore.DocumentData>,
  id: string,
  dbName: string,
) => {
  const collection = await fs.collection(dbName);
  const ref = collection.doc(id);
  return (await ref.get());
};
