import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stripeRequirementsToTitleCase',
  standalone: true,
})
export class StripeRequirementsToTitleCasePipe implements PipeTransform {
  private toTitleCase(str: string): string {
    return str
      .split(' ')
      .map((word) => word.replace(word[0], word[0].toUpperCase()))
      .join(' ');
  }
  transform(requirements: string[]): string[] {
    if (!requirements) return [];

    return requirements.map((requirement) => {
        const req = requirement
          .replace(/_/g, ' ')
          .replace(/\./g, ' - ');
        return this.toTitleCase(req);
      });
  }
}
