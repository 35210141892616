import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Injectable } from '@angular/core';

import { MailerModel } from '../models/mailer';
import { DBUtil } from '../utils/db-utils';

@Injectable({ providedIn: 'root' })
export class MailerDAOService {
  constructor(readonly firestore: AngularFirestore) {}

  async create(mailerModel: MailerModel) {
    const collectionRef = this.firestore.collection(DBUtil.Mailer);
    const docRef = collectionRef.doc();

    await docRef.set(mailerModel);
    return (await docRef.get().toPromise()).id;
  }
}
