import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GthGoogleMapsService {
  get itemClicked$() {
    return this.clickSubject.asObservable();
  }

  private clickSubject = new Subject<any>();

  emit(evt: any) {
    this.clickSubject.next(evt);
  }
}
