import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { GthCallableService, CallableOptions as GthCallableOptions, CallableRoutes as GthCallableRoutes } from './callable.service';
import { GthErrorLoggerService } from './error-logger.service';
import { GuestReadFilter } from '@index/daos/guest-dao';
import { Results, Payload, User } from '@index/interfaces';
import { GthUserModel } from '@sentinels/models';

const CONTEXT = 'GthGuestFunctionService';


function isEmailValid(email: string): boolean {
  // Regular expression for a simple email validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Test the email against the regex
  return emailRegex.test(email);
}

@Injectable({ providedIn: 'root' })
export class GthGuestFunctionService {
  constructor(
    public callableService: GthCallableService,
    public functions: AngularFireFunctions,
    private logger: GthErrorLoggerService,
  ) {}

  getGuestByEmail$(email: string): Observable<GthUserModel | undefined> {
    return from(this.getGuestByEmail(email)).pipe(
      map((user) => {
        if (!user) {
          return undefined;
        }

        return this.getUser(user);
      }),
    );
  }

  create$(user: User) {
    return from(this.create(user)).pipe(
      map((id) => {
        if (!id) return undefined;

        return id;
      }),
    );
  }

  private async getGuestByEmail(email: string): Promise<GthUserModel | null> {
    if (!this.functions) return Promise.resolve(null);
    let data;
    if (isEmailValid(email)) {
      data = { email } as GuestReadFilter;
    } else {
      data = { id: email }as GuestReadFilter; // Try this - our old code used ids.
    }
    const options: GthCallableOptions = {
      route: GthCallableRoutes.GUESTS_READ,
      data,
    };
    const response = (await this.callableService.call(options)) as Results<User>;
    if (response && response.success && response.payload) {
      const result = response.payload as Payload<User>;
      this.log(`Guest user fetched by email`);
      return new GthUserModel(result.id, result.data);
    }
    return null;
  }

  private async create(user: User): Promise<string | null> {
    if (!this.functions) return Promise.resolve(null);

    const options: GthCallableOptions = {
      route: GthCallableRoutes.GUESTS_CREATE,
      data: user,
    };
    const response = (await this.callableService.call(options)) as Results<string>;
    if (response && response.success && response.payload) {
      const result = response.payload as Payload<string>;
      this.log(`Guest user created`);
      return result.data;
    }
    return null;
  }

  private getUser(user: GthUserModel) {
    if (!user.photoURL) {
      user.photoURL = '';
    }
    if (!user.fullName) {
      user.fullName = '';
    }
    if (!user.displayName) {
      user.displayName = '';
    }
    return user;
  }

  private log(text: string) {
    this.logger.debug(`${CONTEXT}: ${text}`);
  }
}
