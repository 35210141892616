<div class="header-container">
  <gth-stripe-icon class="stripe-icon" [size]="32" />

  <div class="text-container">
    <h4>Stripe Account</h4>
    <p [class]="stripeAccountComplete(user) ? 'success-color' : 'warning-color'">
      <mat-icon class="circle" fontIcon="circle" />
      {{stripeAccountComplete(user) ? 'Active' : 'Incomplete'}}
    </p>
  </div>
</div>

<div class="content-container">
  <mat-progress-spinner *ngIf="loadingRequirements || linkingStripeAccount" mode="indeterminate" [diameter]="40" color="primary" />
  <div *ngIf="stripeAccountRequirements?.currently_due?.length && stripeAccountIncomplete(user)" class="incomplete-container">
    <p>Stripe is reporting the following requirements for verification:</p>

    <ul>
      <li *ngFor="let requirement of stripeAccountRequirements?.currently_due | stripeRequirementsToTitleCase;trackBy:identifyRequirements">{{requirement}}</li>
    </ul>

    <p>If you'd like to use a different account, you can <button class="unlink-link" (click)="onUnlinkBtnClick()">unlink the current account</button>.</p>

    <button mat-flat-button type="button" class="btn" [disabled]="linkingStripeAccount || loadingCompleteVerification" (click)="onCompleteVerificationBtnClick()">
      <ng-container *ngIf="loadingCompleteVerification else showCompleteText">
        <mat-progress-spinner mode="indeterminate" color="accent" [diameter]="25" />
      </ng-container>
      <ng-template #showCompleteText>Complete Verification</ng-template>
    </button>
  </div>

  <div *ngIf="stripeAccountComplete(user)" class="complete-container">
    <p>Your Stripe account is active and accepting payments.</p>
    <div class="buttons-container">
      <a mat-flat-button type="button" class="open-stripe-btn" target="_blank" [href]="stripeAccountUrl(user.stripeId)">
        <mat-icon fontIcon="arrow_outward" />
        <span>Open Stripe</span>
      </a>
      <button mat-icon-button type="button"
              aria-label="Stripe actions icon-button with a menu"
              [matMenuTriggerFor]="stripeActionsMenu"><mat-icon fontIcon="more_horiz"/></button>
      <mat-menu #stripeActionsMenu="matMenu">
        <button mat-menu-item type="button" (click)="onCopyStripeAccountIdBtnClick()">
          <mat-icon fontIcon="content_copy" />
          <span>Copy Stripe Account ID</span>
        </button>
        <button mat-menu-item type="button" (click)="onUnlinkBtnClick()">
          <mat-icon fontIcon="link_off" />
          <span>Unlink Stripe Account</span>
        </button>
      </mat-menu>
    </div>
  </div>
</div>