<div class="team-games fx-fill">
  <ng-container *ngIf="games">
    <table mat-table *ngIf="games.length > 0; else noGamesTmpl" [dataSource]="games">
      <!-- Location Column -->
      <ng-container matColumnDef="location">
        <th mat-header-cell *matHeaderCellDef>Location</th>
        <td class="cell" mat-cell *matCellDef="let element">
          {{ element.location.formattedAddress }}
        </td>
      </ng-container>

      <!--Date Start-->
      <ng-container matColumnDef="dateStart">
        <th mat-header-cell *matHeaderCellDef>Date</th>
        <td class="cell" mat-cell *matCellDef="let element">
          {{ element.dateStart | date: 'MM/dd/yyyy' }}
        </td>
      </ng-container>

      <!--Time Start-->
      <ng-container matColumnDef="timeStart">
        <th mat-header-cell *matHeaderCellDef>Time</th>
        <td class="cell" mat-cell *matCellDef="let element">
          {{ element.dateStart | date: 'shortTime' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="actions-column"></th>
        <td class="cell" mat-cell *matCellDef="let element" align="right">
          <button
            class="button"
            mat-flat-button
            color="primary"
            (click)="onViewGameButtonClick(element)"
          >
            View
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns" sticky="true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </ng-container>

  <ng-template #noGamesTmpl>
    <div class="table--empty fx-fill">
      <p>No events scheduled at this time</p>
    </div>
  </ng-template>
</div>
