<div class="login-form">
  <h2 class="header" i18n="Header for form to sign up">
    Let's create your account
  </h2>
  <div class="existing-account">
    <p i18n="Already have an account?">Already have an account?</p>
    &nbsp;
    <a *ngIf="signInRoute; else signInButtonTmpl" mat-button color="primary" [routerLink]="signInRoute"
      i18n="Link to login page">Sign In</a>

    <ng-template #signInButtonTmpl>
      <a mat-button color="primary" (click)="onLoginButtonClick()" i18n="Link to login page">Sign In</a>
    </ng-template>

  </div>
  <form class="form-content" [formGroup]="formGroup">
    <!-- Name -->
    <mat-form-field appearance="outline">
      <mat-label>Your Name</mat-label>
      <input matInput required minlength="2" maxlength="100" i18n="Input for your name" type="text" class="fullName"
        formControlName="fullName" #name matTooltip="Please use a minimum of 2 characters." matTooltipPosition="above"
        [matTooltipDisabled]="formGroup.get('fullName')?.valid" />
      <mat-hint matSuffix align="end">
        {{name.value.length}}/100
      </mat-hint>
      <br>
    </mat-form-field>

    <!-- Email -->
    <mat-form-field appearance="outline">
      <mat-label>Email</mat-label>
      <input matInput required maxlength="100" type="email" class="email" i18n="Input for email" formControlName="email"
        matTooltip="Please use format: name@example.com" matTooltipPosition="above"
        [matTooltipDisabled]="formGroup.get('email')?.valid" />
      <mat-error>
        <ng-container *ngIf="formGroup.get('email').errors as emailErrors">
          <ng-container *ngIf="emailErrors.email">
            Please enter a valid email address
          </ng-container>
        </ng-container>
      </mat-error>
    </mat-form-field>

    <!-- Password -->
    <mat-form-field appearance="outline">
      <mat-label>Password</mat-label>
      <input matInput required minlength="8" maxlength="100" class="password" type="password" i18n="Input for password"
        formControlName="password" (keyup)="onPasswordChange()" [type]="hidePassword ? 'password' : 'text'"
        matTooltip="Password requires 8 characters with a mix of letters, numbers, and symbols."
        matTooltipPosition="above" [matTooltipDisabled]="formGroup.get('password')?.valid" />
      <button mat-icon-button matSuffix (click)="hidePassword = !hidePassword" [attr.aria-label]="'Hide password'"
        [attr.aria-pressed]="hidePassword">
        <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
      </button>
    </mat-form-field>

    <label for="passwordStrengthCtrl">Password Strength</label>
    <mat-progress-bar id="passwordStrengthCtrl" class="progress-bar" mode="determinate"
      value="{{ passwordStrength * 100 }}"></mat-progress-bar>

    <!-- Confirm Password -->
    <mat-form-field appearance="outline">
      <mat-label>
        Confirm password
      </mat-label>
      <input matInput required class="confirm-password" type="password" il8n="Input for confirm password"
        formControlName="confirmPassword" [type]="hideConfirmPassword ? 'password' : 'text'" />
      <button mat-icon-button matSuffix (click)="hideConfirmPassword = !hideConfirmPassword"
        [attr.aria-label]="'Hide confirm password'" [attr.aria-pressed]="hideConfirmPassword">
        <mat-icon>{{hideConfirmPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
      </button>
    </mat-form-field>

    <mat-error *ngIf="passwordMismatch$ | async">Passwords do not match</mat-error>

    <mat-checkbox class="remember-me-checkbox" formControlName="termsAndConditions">By creating an account, you agree to
      our
      <a role="button" (click)="openPoliciesDialog(privacyTabIndex)" class="policy-link">Privacy Policy</a>,
      <a role="button" (click)="openPoliciesDialog(termsConditionsTabIndex)" class="policy-link">Terms and
        Conditions</a>, and
      <a role="button" (click)="openPoliciesDialog(notificationsTabIndex)" class="policy-link">Cookie
        Policy</a>.</mat-checkbox>
    <div class="submit-button-container" matTooltip="Please complete all fields and policies."
      matTooltipPosition="below" [matTooltipDisabled]="formGroup.valid">
      <button class="submit-button" type="button" color="primary" (click)="onSignUpButtonClick()"
        [disabled]="attempted || formGroup.invalid || (passwordMismatch$ | async)" i18n="Button to sign up"
        mat-raised-button>
        Create Account
      </button>
    </div>

    <p class="or-container">
      <span class="or-line"></span><span class="or-text">or</span><span class="or-line"></span>
    </p>
  </form>

  <div class="social-buttons fx-column">
    <gth-google-button class="social-btn" (socialClick)="onGoogleSignUpButtonClick()"
      text="Sign up with Google"></gth-google-button>
  </div>
</div>

<div class="active-user mat-elevation-z2" *ngIf="user">
  <p class="sub-text">Sign in to {{ appName }}</p>
  <div class="user fx-row">
    <span class="user-image-container">
      <img class="user-image" *ngIf="!profileImgError && user.photoURL; else emptyPhotoTmpl" [src]="user.photoURL"
        (error)="profileImgError = true" />
    </span>
    <div class="fx-column">
      <p>{{ user.displayNameFallback }}</p>
      <p *ngIf="user.displayNameFallback !== user.email">
        {{ user.email }}
      </p>
    </div>
  </div>
  <a mat-raised-button class="btn-brand-green" type="button" color="primary" [routerLink]="['/profile']">
    Continue as {{ user.displayNameFallback }}
  </a>


  <ng-template #emptyPhotoTmpl>
    <div class="user-image user-image--empty">
      <mat-icon>person</mat-icon>
    </div>
  </ng-template>
</div>