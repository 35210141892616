<p class="title" matTooltip="test">{{ plannerName }}</p>

<table>
  <thead>
    <tr>
      <td></td>
      <td *ngFor="let dateItem of plainTextDates" class="day-label">
        {{ dateItem.dayAbbr }}
      </td>
    </tr>
    <tr>
      <td></td>
      <td *ngFor="let dateItem of plainTextDates" class="month-label">
        {{ dateItem.month }} {{ dateItem.date }}
      </td>
    </tr>
  </thead>
  <tbody *ngIf="displayedAvailability">
    <tr *ngFor="let displayedTime of displayedTimes; let i = index">
      <td class="time-label" [class.--hide]="!displayedTime.endsWith(':00 AM') && !displayedTime.endsWith(':00 PM')">
        {{ displayedTime }}
      </td>
      <ng-container *ngFor="let dateIndex of displayedDateIndexes">
        <td *ngIf="displayedAvailability[dateIndex][i] as props">
          <ng-container *ngFor="let plan of props" [ngTemplateOutlet]="tableCell"
            [ngTemplateOutletContext]="{props:plan}">
          </ng-container>
        </td>
      </ng-container>
    </tr>

  </tbody>
</table>

<ng-template #tableCell let-props='props'>
  <div class="availability" *ngIf="props" [class.--available]="props.available" [class.--end-available]="props.end"
    [class.--start-available]="props.start" [ngStyle]="{'background-color':props.backgroundColor}"
    [matTooltip]="props.tooltip">&nbsp;</div>
</ng-template>