import firebase from 'firebase/compat/app';

import { Mapper } from '../interfaces/mapper';
import { ConversationModel } from '../models/conversation';

export class ConversationMapper implements Mapper<ConversationModel> {
  fromSnapshot(snapshot: firebase.firestore.DocumentSnapshot): ConversationModel | undefined {
    if (snapshot === null || snapshot === undefined) return undefined;
    const data = snapshot.data();
    if (data === null || data === undefined) return undefined;

    return new ConversationModel(
      snapshot.ref as any,
      data[ConversationModel.MESSAGES],
      data[ConversationModel.PARTICIPANTS],
    );
  }

  toMap(item: ConversationModel): firebase.firestore.DocumentData {
    return {
      [ConversationModel.MESSAGES]: item.messages,
      [ConversationModel.PARTICIPANTS]: item.participants,
    };
  }
}
