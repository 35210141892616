<div class="header">
  <h1>Find Participants</h1>

  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<p class="hint-text">How would you like to find participants?</p>

<div class="buttons-container">
  <button mat-flat-button color="primary" (click)="onRemindTeamBtnClick()">Remind My Team</button>
  <button mat-flat-button color="primary" (click)="onFindAvailablePlayersBtnClick()">Find Available Participants</button>
  <button mat-flat-button color="primary" (click)="onCreateDicoverableGameBtnClick()">
    Create {{ data.event.discoverable ? 'Private' : 'Public' }} Event
  </button>
</div>
