import { PersonalityType } from '@index/interfaces';
import { GthModel } from './model';

export class GthPersonalityTypeModel extends GthModel<PersonalityType> {
  get personalityType(): PersonalityType {
    return this.model;
  }

  get uid() {
    return this.personalityType.uid;
  }

  get id() {
    return this.personalityType.id;
  }

  get label() {
    return this.personalityType.label;
  }

  get item() {
    return this.personalityType.item;
  }

  constructor(id: string, model: PersonalityType) {
    super(id, model);
  }
}
