import { Component, EventEmitter, Input, Output } from '@angular/core';

import { GthEventItemModel } from '../../../../../sentinels/src/lib/models/event-item';
import { GthTeamModel } from '../../../../../sentinels/src/lib/models/team';

@Component({
  selector: 'gth-team-games-table',
  templateUrl: './team-games-table.component.html',
  styleUrls: ['./team-games-table.component.scss'],
})
export class GthTeamGamesTableComponent {
  @Input()
  team?: GthTeamModel;

  @Input()
  games: GthEventItemModel[] | undefined | null = [];

  @Output()
  view = new EventEmitter<GthEventItemModel>();

  displayedColumns = ['location', 'dateStart', 'timeStart', 'actions'];

  onViewGameButtonClick(game: GthEventItemModel) {
    this.view.emit(game);
  }
}
