import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GthUserModel } from '@sentinels/models';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'gth-user-avatar',
  standalone: true,
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss'],
  imports: [
    CommonModule,
    MatIconModule,
    MatTooltipModule,
  ],
})
export class GthUserAvatarComponent {
  @Input()
  user?: GthUserModel;

  @Input()
  size = 40;

  photoError = false;
}
