import { Injectable } from '@angular/core';
import { Results } from '@index/interfaces';

@Injectable({
  providedIn: 'root',
})
export class GthErrorLoggerService {
  /**
   * Logs Error Response to console
   *
   * This is meant to be overridden.
   * @param {Result<T>} result Error Response
   */
  log<T>(result: Results<T>) {
    if (result && result.error) {
      console.error('Error calling function:', result.error);
    }
  }

  /**
  * Logs text to console log
  * @param {string} text text to be logged
  */
  debug(text: string) {
    console.log(text);
  }

  /**
  * Logs text to console error
  * @param {string} text text to be logged
  */
  error(text: string) {
    console.error(text);
  }
}
