import { Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable, from, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GthUserDeletionService {
  constructor(
    readonly firestore: AngularFirestore,
  ) { }

  delete$(uid: string): Observable<boolean> {
    const currentUser = firebase.auth().currentUser;

    if (currentUser.uid !== uid) {
      return of(false);
    }

    const userRef = this.firestore
      .collection('users').doc(uid).ref;

    return from(userRef.delete()).pipe(
      map(() => true),
      catchError(() => of(false)),
    );
  }
}
