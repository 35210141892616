import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';

import { GthStoredImageComponent } from './stored-image.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    AngularFireStorageModule,
  ],
  exports: [GthStoredImageComponent],
  declarations: [GthStoredImageComponent],
})
export class GthStoredImageModule { }
