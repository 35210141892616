<picture>
    <img alt="" src="assets/computers.webp" />
</picture>
<ng-container *ngIf="loading else showPaymentStatusTmpl">
    <div class="fx-auto fx-centered">
        <mat-spinner [diameter]="36"></mat-spinner>
    </div>
</ng-container>

<ng-template #showPaymentStatusTmpl>
    <h1>Payment Status</h1>
    <ng-container *ngIf="paymentIntentClientSecret else showNoInfoTmpl">
        <h3>{{(cost / 100) | currency}} - {{description}}</h3>
        <h3>{{status}}</h3>
    </ng-container>
    <ng-template #showNoInfoTmpl>
        <h3>No information provided!</h3>
    </ng-template>
</ng-template>

<button mat-flat-button color="primary" [routerLink]="[APP_ROUTES.Profile]">Go To Account</button>