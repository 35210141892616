<div class="login-form">
  <h2 class="header" i18n="Header for form to login">{{ welcomeText }}</h2>
  <div class="existing-account" *ngIf="subWelcomeText">
    {{ subWelcomeText }}
  </div>
  <div class="existing-account" *ngIf="displaySignup">
    <p i18n="Don't have an account?">Don't have an account?</p>
    &nbsp;
    <a *ngIf="signupRoute; else signupButton" mat-button class="sign-up-link" color="primary" [routerLink]="signupRoute"
      i18n="Link to sign up page">Sign
      Up</a>

    <ng-template #signupButton>
      <a mat-button class="sign-up-link" color="primary" (click)="onSignupButtonClick()" i18n="Link to sign up page">Sign
        Up</a>
    </ng-template>
  </div>
  <form class="form-content fx-column fx-gap-10x" [formGroup]="formGroup">
    <!-- Email -->
    <mat-form-field [appearance]="appearance">
      <mat-label>Email Address</mat-label>
      <input matInput required maxlength="100" type="email" class="email" i18n="Input for email" formControlName="email"
        email matTooltip="Please use format: name@example.com" matTooltipPosition="below"
        [matTooltipDisabled]="formGroup.get('email')?.valid" />
    </mat-form-field>

    <!-- Password -->
    <mat-form-field [appearance]="appearance">
      <mat-label>Password</mat-label>
      <input matInput required minlength="8" maxlength="100" class="password" type="password" i18n="Input for password"
        formControlName="password" type="password" (keyup.enter)="onLoginButtonClick()"
        matTooltip="Please enter minimum of 8 characters." matTooltipPosition="below"
        [matTooltipDisabled]="formGroup.get('password')?.valid" />
    </mat-form-field>

    <mat-checkbox *ngIf="displayRememberMe" class="remember-me-checkbox" formControlName="rememberMe">Remember Me
    </mat-checkbox>

    <div class="submit-button-container" matTooltip="Please complete all fields." matTooltipPosition="below"
      [matTooltipDisabled]="formGroup.valid">
      <button class="submit-button" type="submit" color="primary" (click)="onLoginButtonClick()"
        [disabled]="!formGroup.valid || loading" i18n="Button to login" mat-raised-button>
        Login
      </button>
    </div>

    <div class="forgot-password-container">
      <a mat-button type="button" [routerLink]="APP_ROUTES.ForgotPassword">Forgot password?</a>
    </div>

    <p class="or-container">
      <span class="or-line"></span><span class="or-text">or</span><span class="or-line"></span>
    </p>
  </form>

  <div class="social-buttons">
    <gth-google-button (socialClick)="onGoogleLoginButtonClick()"></gth-google-button>
  </div>
</div>