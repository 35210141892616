import { Injectable } from '@angular/core';
import { Time } from '@angular/common';

const MONTHS_ABBR = [
  {
    id: 0,
    label: 'Jan',
  },
  {
    id: 1,
    label: 'Feb',
  },
  {
    id: 2,
    label: 'March',
  },
  {
    id: 3,
    label: 'April',
  },
  {
    id: 4,
    label: 'May',
  },
  {
    id: 5,
    label: 'June',
  },
  {
    id: 6,
    label: 'July',
  },
  {
    id: 7,
    label: 'Aug',
  },
  {
    id: 8,
    label: 'Sept',
  },
  {
    id: 9,
    label: 'Oct',
  },
  {
    id: 10,
    label: 'Nov',
  },
  {
    id: 11,
    label: 'Dec',
  },
];

const DAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const DAYS_ABBR = [
  'Sun',
  'Mon',
  'Tues',
  'Wed',
  'Thur',
  'Fri',
  'Sat',
];

@Injectable({
  providedIn: 'root',
})
export class GthTimeService {
  get months() {
    return MONTHS_ABBR;
  }

  get days() {
    return DAYS;
  }

  getPlaintextDate(date: Date) {
    const day = DAYS[date.getDay()];
    const dayAbbr = DAYS_ABBR[date.getDay()];
    const month = MONTHS_ABBR[date.getMonth()].label;
    const dateNum = date.getDate();
    return {
      day,
      dayAbbr,
      date: dateNum,
      month,
    };
  }

  getTimeOptions() {
    return getTimeOptions();
  }

  getDateTime(time: string): Time {
    let hours = parseInt(time.split(':')[0]);
    if (time.toLowerCase().endsWith('pm')) {
      hours += 11;
    } else if (hours === 12) {
      hours = 0;
    }
    return {
      hours,
      minutes: parseInt(time.split(':')[1]),
    };
  }

  getDate(date: Date, time: string): Date {
    const timeA = this.getDateTime(time);
    date.setHours(timeA.hours);
    date.setMinutes(timeA.minutes);
    return date;
  }

  getTimeFromDate(date: Date) {
    let hours = date.getHours();
    let minutes: string | number = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    return hours + ':' + minutes + ' ' + ampm;
  }

  getTime(timeString: string) {
    const time = this.getDateTime(timeString);
    const date = new Date();
    date.setHours(time.hours);
    date.setMinutes(time.minutes);

    const options = {
      hour12: true,
    };
    return date.toLocaleString('en-US', options);
  }

  getTimezoneOffset() {
    const z = (n) => {
      return (n < 10 ? '0' : '') + n;
    };
    let offset = new Date().getTimezoneOffset();
    const sign = offset < 0 ? '+' : '-';
    offset = Math.abs(offset);
    return sign + z(offset / 60 | 0) + z(offset % 60);
  }

  isDateEqual(d1: Date, d2: Date) {
    const hasSameYear = d1.getFullYear() === d2.getFullYear();
    const hasSameMonth = d1.getMonth() === d2.getMonth();
    const hasSameDate = d1.getDate() === d2.getDate();
    return hasSameYear && hasSameMonth && hasSameDate;
  }
}

export const getTimeOptions = () => {
  let timeOptions: string[] = [];

  timeOptions = timeOptions.concat(getNoonOrMidnight('AM'));
  timeOptions = timeOptions.concat(getTimeOption('AM'));
  timeOptions = timeOptions.concat(getNoonOrMidnight('PM'));
  timeOptions = timeOptions.concat(getTimeOption('PM'));

  return timeOptions;
};

export const getTimeOption = (clarifier: string) => {
  const timeOptions = [];

  for (let i = 1; i < 12; i++) {
    for (let j = 0; j < 60; j += 15) {
      const time = `${i}:${j.toString().padStart(2, '0')} ${clarifier}`;
      timeOptions.push(time);
    }
  }

  return timeOptions;
};

export const getNoonOrMidnight = (clarifier: string) => {
  const timeOptions = [];

  const i = 12;
  for (let j = 0; j < 60; j += 15) {
    const time = `${i}:${j.toString().padStart(2, '0')} ${clarifier}`;
    timeOptions.push(time);
  }

  return timeOptions;
};
