<h3>PRIVACY POLICY</h3>
<br>
<h4>Welcome to Gametime Hero!</h4>
<p>Gametime Hero is owned and operated by Gametime Hero, LLC.
</p>
<p>Gametime Hero values your privacy and the protection of your personal data. This privacy policy describes what information we collect from you, how we collect it, how we use it, how we obtain your consent, how long we retain it in our databases and, if necessary, with whom we share it.
</p>
<p>By using the platform, you accept the practices described in this privacy policy. Your use of the platform is also subject to our terms and conditions. In this privacy policy, the words "platform" refers to the Gametime Hero App and website together, "we", "us", "our" and “Gametime Hero” refers to Gametime Hero and "you" and “user" refers to you, the Gametime Hero user.
</p>
<p>This privacy policy may change from time to time. Your continued use of the platform after we make changes is deemed acceptance of those changes, so please check the policy periodically for updates. This privacy policy has been prepared and is maintained in accordance with all applicable national and international laws and regulations and, in particular, the California Consumer Privacy Act (CCPA) and the GDPR (General Data Protection Regulation - European regulations).
</p>

<h4>1. GENERAL INFORMATION </h4>
<p>Users' personal data is collected through:</p>
<ul>
<li><strong>Hero App (Available on Google Play and App Store).</strong></li>
<li><a target="_blank" href="https://gametimehero.com">https://gametimehero.com</a></li>
</ul>
<p>Will be under responsibility and in charge of:</p>
<ul>
<li><strong>Gametime Hero, LLC.</strong></li>
<li><a target="_blank" href="mailto:Brittany@GametimeHero.com">Brittany&#64;GametimeHero.com</a></li>
</ul>
<p>(Hereinafter referred to as “Gametime Hero”).</p>

<h4>2. TYPES OF INFORMATION GATHERED</h4>
<p>The information we collect from our users helps us to provide our platform and to personalize and continually improve the user experience on the platform. These are the types of information we collect. These are the types of information we collect:
</p>
<p><strong>Information You Give Us.</strong> You provide information when you provide, search, read and view content on the platform, register as a user, create a group or event, join a group or event and/or communicate with us through our contact information or contact forms. As a result of those actions, you might supply us with the following information:
</p>
<ul>
<li>Name</li>
<li>Email Address</li>
<li>Location</li>
<li>Age</li>
<li>Gender</li>
<li>User content</li>
<li>Google, Facebook and Apple account information.</li>
<li>Any additional information relating to you that you provide to us directly or indirectly through our platform or online presence such as ‘cookies’.</li>
</ul>

<p>Gametime Hero will not collect any personally identifiable information about you, unless you provide it.
</p>
<p><strong>Information Collected Automatically:</strong> By accessing and using the platform you automatically provide us with the following information:
</p>

<ul>
<li>Mobile device ID</li>
<li>Model and manufacturer</li>
<li>Operating system</li>
<li>Version information</li>
<li>IP address</li>
</ul>

<p><strong>Registration and login with Facebook, Apple or Google:</strong> By registering on the platform through your Facebook, Apple or Google account, you authorize Gametime Hero to collect, process and store your Google, Apple or Facebook account information for the sole purpose of facilitating registration and login on our platform. The data provided through registration and login on Facebook, Apple or Google will be treated in accordance with this privacy policy and the privacy policy of Facebook, Apple and Google.
</p>
<p><strong> GOOGLE Analytics.</strong> We use Google Analytics provided by Google, Inc., USA (“Google”). These tools and technologies collect and analyze certain types of information, including IP addresses, device and software identifiers, referring and exit URLs, feature use metrics and statistics, usage and purchase history, media access control address (MAC Address), mobile unique device identifiers, and other similar information via the use of cookies. The information generated by Google Analytics (including your IP address) may be transmitted to and stored by Google on servers in the United States. We use the GOOGLE Analytics collection of data to enhance the platform and improve our service.
</p>
<p>Please consult Google's privacy policy here: </p>
<ul>
<li><a target="_blank" href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>
</ul>
<p><strong>Social networks:</strong> On our platform, you will find links and functions linked to different social networks or services where you can share your information. It is advisable to consult the privacy and data protection policy of each social network used on our platform.
</p>
<ul>
<li>Facebook: <a target="_blank" href="https://www.facebook.com/privacy/exsubscriptionation">https://www.facebook.com/privacy/exsubscriptionation</a>
<li>Instagram: <a target="_blank" href="http://instagram.com/about/legal/privacy/">http://instagram.com/about/legal/privacy/</a>
<li>Linkedin: <a target="_blank" href="https://www.linkedin.com/legal/privacy-policy">https://www.linkedin.com/legal/privacy-policy</a>
</ul>

<h4> 3. HOW LONG WE KEEP YOUR DATA</h4>
<p>Personal data provided by users through the platform will be retained for the time necessary to provide the platform and the functionalities available on the platform, such as the creation of groups or events or until the user decides to close the user account on the platform or until Gametime Hero closes and deletes the user account. Gametime Hero may retain personal data for a longer period where the user has given consent to such processing, provided that such consent is not withdrawn. In addition, Gametime Hero may be obliged to retain personal data for a longer period if this is necessary for compliance with a legal obligation or by order of an authority. Once the retention period expires, the personal data will be deleted. Therefore, the right of access, the right of erasure, the right of rectification, and the right to data portability cannot be asserted once the retention period has expired.
</p>

<h4>4. HOW WE USE YOUR INFORMATION.</h4> 
<p>In general, we use the information we collect primarily to provide, maintain, protect and improve our platform. We use personal information collected through our platform as described below:
Provide the platform (Available on Google Play and App Store).
</p>
<ul>
<li>User registration.</li>
<li>Publish user content.</li>
<li>Facilitate the creation of groups and events on the platform.</li>
<li>Publish user content via groups or events.</li>
<li>Process transactions within the platform.</li>
<li>Provide the functionalities available on the platform.</li>
<li>Understand and enhance your experience using our platform.</li>
<li>Respond to your comments or questions through our contact information.</li>
<li>Send you related information, including confirmations, invoices, technical notices, updates, security alerts and support and administrative messages.</li>
<li>Communicate with you about upcoming events, offers and news about Gametime Hero.</li>
<li>Marketing purposes of Gametime Hero.</li>
<li>Protect, investigate and deter against fraudulent, unauthorized, or illegal activity.</li>
</ul>

<h4>5. HOW DO YOU GET MY CONSENT?</h4>
<p>By registering as a user on the platform, using the functionalities available on the platform, creating a group or event, joining a group or event, communicating with us through our contact information and providing us with personal information to contact you, you consent to our use of cookies, you consent to our collection, storage and use of your information on the terms contained in this privacy policy. You may withdraw your consent by sending us your request via the contact information or contact page.
</p>

<h4>6. HOW WE SHARE INFORMATION</h4>
<p>
The personal information of our users is an important and fundamental part of our business. Under no circumstances will we sell or share information with third parties that have not been previously authorized by the user or owner of the personal data. We share user information solely and exclusively, as described below.
</p>    
<p>
<strong>Third-Party Service Providers.</strong> We use third-party services to perform certain functions through our platform. Examples include processing transactions, sending emails, analyzing data (Google Analytics), providing marketing assistance, and delivering search results.
</p> 
<p>
These third-party services and tools may have access to personal information needed to perform their functions but may not use that information for other purposes. Information shared with these third-party services will be treated and stored in accordance with their respective privacy policies and our privacy policy. 
</p> 
<p>
<strong>Business Transfers.</strong> In the event that Gametime Hero creates, merges with, or is acquired by another entity, your information will most likely be transferred. Gametime Hero will email you or place a prominent notice on our platform before your information becomes subject to another privacy policy.
</p>
<p>
<strong>Protection of Gametime Hero and others.</strong> We release personal information when we believe release is appropriate to comply with the law, enforce or apply our Terms and conditions and other agreements, or protect the rights, property, or safety of Gametime Hero, our users or others. This includes exchanging information with other companies and organizations for fraud protection and credit risk reduction.
</p> 
<p>
<strong>With Your Consent.</strong> Other than as set out above, you will receive notice when personally identifiable information about you might go to third parties, and you will have an opportunity to choose not to share the information.
</p>
<p>
<strong>Anonymous Information.</strong> Gametime Hero uses the anonymous browsing information collected automatically by our servers primarily to help us administer and improve the platform. We may also use aggregated anonymous information to provide information about the platform to potential business partners and other unaffiliated entities. This information is not personally identifiable.
</p> 
<p><strong>Email Address.</strong> The email address that you supply to us for purposes of receiving our email communications will never be rented or sold to a third party.
</p>

<h4>7. PROTECTING YOUR INFORMATION</h4>
<p>
We restrict authorized access to your personal information to those persons who have a legitimate need to know such information to provide certain functions and to those persons you have authorized to have access to such information. Gametime Hero follows generally accepted industry standards for data security to protect the personal information you provide and share through the platform, both during transmission and once Gametime Hero receives it. 
</p>
<p>
No method of transmission over the Internet, or method of electronic storage, is 100% secure. Therefore, while Gametime Hero strives to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security. We will not sell, distribute or lease your personal information to third parties unless we have your permission or are required by law to do so.
</p>

<h4> 8. RIGHTS </h4>
<p>
Users who provide information through our platform, as data subjects and data owners have the right to access, rectify, download or delete their information, as well as to restrict and object to certain processing of their information. While some of these rights apply generally, others apply only in certain limited circumstances. We describe these rights below:
</p>
<ul>
<li>
    <strong>Access and portability:</strong> to access and know what information is stored in our servers, you can send us your request through our contact information.
</li>
<li>
    <strong>Rectify, Restrict, Limit and/or Delete:</strong> You can also rectify, restrict, limit or delete much of your information.
</li>
<li>
    <strong>Right to be informed:</strong> Users of our platform will be informed, upon request, about what data we collect, how it is used, how long it is retained and whether it is shared with third parties.
</li>
<li> 
    <strong>Object:</strong> When we process your information based on our legitimate interests, as explained above, or in the public interest, you may object to this processing in certain circumstances. In such cases, we will stop processing your information unless we have compelling legitimate reasons to continue processing it or where it is necessary for legal reasons.
</li>
<li>
    <strong>Revoke consent:</strong> Where you have previously given your consent, such as to allow us to process and store your personal information, you have the right to revoke your consent to the processing and storage of your information at any time. For example, you may withdraw your consent by updating your settings. In certain cases, we may continue to process your information after you have withdrawn your consent if we have a legal basis for doing so or if your withdrawal of consent was limited to certain processing activities.
</li>
<li>
    <strong>Complaint:</strong> If you wish to file a complaint about our use of your information (and without prejudice to any other rights you may have), you have the right to do so with your local supervisory authority. Users can exercise all these rights by contacting us through the contact information or the contact page.
</li>
<li>
    <strong>Rights related to automated decision-making, including profiling:</strong> Platform users may request that we provide a copy of the automated processing activities we conduct if they believe that data is being unlawfully processed.
</li>
</ul>
<p>
Users or owners of the personal information they provide through the platform may exercise these rights over their personal information at any time and without any limitation by sending us their request through our contact information.
</p>

<h4>9. CHILDREN’S ONLINE PRIVACY PROTECTION </h4>
<p>We comply with the requirements of the California Consumer Privacy Act (CCPA) and the GDPR (General Data Protection Regulation - European regulations), regarding the protection of personal data of minors. Although the platform and content are available to all ages, we do not collect any information from children under the age of 13 without the respective permission of their parents or legal guardians. If you are aware that a child under the age of 13 has provided us with personal information without the permission of his or her parent or legal guardian, please contact us. If we become aware that a minor has provided us with personal information without the permission of a parent or legal guardian, we will take steps to delete that information, terminate that person's account, and restrict access to that person's account.
</p>

<h4> 10. THIRD PARTIES </h4>
<p>Except as otherwise expressly included in this privacy policy, this document addresses only the use and disclosure of information Gametime Hero collects from you. If you disclose your information to others, whether other Gametime Hero users or vendors, different rules may apply to their use or disclosure of the information you disclose to them. Gametime Hero does not control the privacy policies of third parties, and you are subject to the privacy policies of those third parties where applicable. Gametime Hero is not responsible for the privacy or security practices of other platforms, including those that are linked to from Gametime Hero.
</p>

<h4> 11. CONTACT US </h4>
<p>If you have any questions or concerns about this privacy policy and the processing and security of your data, please contact us via our contact page or by using the following contact information:
</p>

<p><strong>Gametime Hero, LLC. </strong> </p>
<p><strong> Brittany&#64;GametimeHero.com</strong> </p>
<br>
