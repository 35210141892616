import { TeamRating } from '@index/interfaces';
import { GthModel } from './model';

export class GthTeamRatingModel extends GthModel<TeamRating> {
  get rating(): TeamRating {
    return this.model;
  }

  get uid() {
    return this.rating.uid;
  }

  get id() {
    return this.rating.id;
  }

  get label() {
    return this.rating.label;
  }

  get item() {
    return this.rating.item;
  }

  constructor(id: string, model: TeamRating) {
    super(id, model);
  }
}
