<h1 mat-dialog-title>Select the date you want to assign specific hours</h1>

<mat-dialog-content>
  <mat-card appearance="outlined" class="calendar-card">
    <mat-calendar [selected]="selectedDate" [startAt]="selectedDate" (selectedChange)="onDateChange($event)" />
  </mat-card>

  <div class="header">
    <h3>What hours are you available?</h3>

    <button mat-icon-button type="button" (click)="addTimeRange()">
      <mat-icon fontIcon="add" />
    </button>
  </div>

  <ul class="time-ranges">
    <li *ngFor="let timeRange of timeRanges.controls; let i = index">
      <mat-form-field appearance="outline">
        <mat-label>Start Time</mat-label>
        <input matInput type="time" name="startTime" [formControl]="timeRange.controls.startTime" />
      </mat-form-field>

      <span class="dash">-</span>

      <mat-form-field appearance="outline">
        <mat-label>End Time</mat-label>
        <input matInput type="time" name="endTime" [formControl]="timeRange.controls.endTime" />
      </mat-form-field>

      <button mat-icon-button type="button" (click)="onRemoveTimeRange(i)">
        <mat-icon fontIcon="close" />
      </button>
    </li>
  </ul>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-stroked-button type="button" mat-dialog-close>Cancel</button>

  <button mat-flat-button type="button" color="primary"
          [disabled]="dateSpecificAvailabilityForm.invalid || !this.timeRanges.length"
          (click)="onAddBtnClick()">Add</button>
</mat-dialog-actions>
