import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TooltipStateService {
  private tooltipSubject = new Subject<void>();

  get tooltipOpen$() {
    return this.tooltipSubject.asObservable();
  }

  tooltipOpened() {
    this.tooltipSubject.next();
  }
}
