import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { first, map } from 'rxjs/operators';

import { DBUtil } from '@index/utils/db-utils';
import { GthAvailability, UserAvailability } from '@gth-legacy/interfaces/availability';

@Injectable({
  providedIn: 'root',
})
export class GthAvailabilityFunctionService {
  constructor(
    private firestore: AngularFirestore,
  ) { }

  async getById(id: string) {
    const docRef = this.firestore.collection(DBUtil.Availability)
      .doc(id);
    const doc = await docRef.get()
      .pipe(
        first(),
      )
      .toPromise();

    if (!doc.exists) {
      return false;
    }

    const data = doc.data();
    return this.mapToGthAvailability(doc.id, data);
  }

  async getByLinkName(name: string) {
    const docSnap = await this.firestore.collection(
      DBUtil.Availability,
      (ref) => ref
        .where('linkName', '==', name),
    ).get()
      .pipe(
        first(),
      )
      .toPromise();

    if (docSnap.docs.length === 0) {
      return undefined;
    }
    const doc = docSnap.docs[0];
    if (!doc.exists) {
      return undefined;
    }

    const data = doc.data();
    return this.mapToGthAvailability(doc.id, data);
  }

  async getUserAvailability(availabilityId: string) {
    const docSnap = await this.firestore.collection(
      DBUtil.UserAvailability,
      (ref) => ref
        .where('availabilityId', '==', availabilityId),
    ).get()
      .pipe(
        first(),
      )
      .toPromise();
    if (docSnap.docs.length === 0) {
      return [];
    }
    const docs = docSnap.docs;
    return docs.map((doc) => {
      const data = doc.data();
      const { id } = doc;
      return this.mapToGthUserAvailability(id, data);
    });
  }

  async delete(userId: string, id: string) {
    const docRef = this.firestore.collection(DBUtil.Availability)
      .doc(id);
    const doc = await docRef.get()
      .pipe(
        first(),
      )
      .toPromise();

    if (!doc.exists) {
      return false;
    }

    const data = doc.data();
    const { creatorId } = data as any;
    if (creatorId !== userId) {
      return false;
    }

    let deleted = false;
    try {
      docRef.delete();
      deleted = true;
    } catch {
      deleted = false;
    }

    if (!deleted) {
      return false;
    }

    const docSnap = await this.firestore.collection(
      DBUtil.UserAvailability,
      (ref) => ref
        .where('availabilityId', '==', id),
    ).get()
      .pipe(
        first(),
      )
      .toPromise();
    const requests = [];
    docSnap.docs.forEach((d) => {
      requests.push(d.ref.delete);
    });
    if (requests.length === 0) {
      return true;
    }
    const settled = await Promise.allSettled(requests);
    let success = false;
    for (const s of settled) {
      if (s.status !== 'fulfilled') {
        success = false;
      } else {
        success = true;
      }
    }
    return success;
  }

  async create(availability: GthAvailability) {
    const ref = await this.firestore.collection(DBUtil.Availability);
    try {
      const newRef = await ref.add(availability);
      return newRef.id;
    } catch {
      return undefined;
    }
  }

  async createUserAvailability(availability: UserAvailability) {
    const ref = await this.firestore.collection(DBUtil.UserAvailability);
    try {
      const newRef = await ref.add(availability);
      return newRef.id;
    } catch {
      return undefined;
    }
  }

  async updateUserAvailability(availability: UserAvailability) {
    if (!availability.id) {
      return Promise.resolve(false);
    }
    const { id } = availability;
    const doc = await this.firestore.collection(
      DBUtil.UserAvailability,
    ).doc(id)
      .get()
      .pipe(
        first(),
      )
      .toPromise();

    if (!doc || !doc.exists) {
      return Promise.resolve(false);
    }

    const { dates, days, allDay, startTime, endTime, gmtOffset } = availability;

    try {
      return await this.firestore.collection(DBUtil.UserAvailability).doc(id)
        .update({ dates, days, allDay, startTime, endTime, gmtOffset })
        .then(() => true)
        .catch(() => false);
    } catch {
      return false;
    }
  }

  private mapToGthAvailability(id: string, data: any) {
    const { dates } = data as any;

    const updatedDates = [];
    for (let i = 0; i < dates.length; i++) {
      const date = dates[i].toDate();
      updatedDates.push(date);
    }

    const availability: GthAvailability = {
      id,
      ...data as any,
      dates: updatedDates,
    };

    return availability;
  }

  private mapToGthUserAvailability(id: string, data: any) {
    const { dates } = data as any;

    const updatedDates = [];
    for (let i = 0; i < dates.length; i++) {
      const date = dates[i].toDate();
      updatedDates.push(date);
    }

    const availability: UserAvailability = {
      id,
      ...data as any,
      dates: updatedDates,
    };

    return availability;
  }
}
