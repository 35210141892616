import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { Post, Comment, WallPostService, GthEventWallPostModel } from './../services/wall-post.service';
import { Observable } from 'rxjs';
import { GthUserModel } from '@sentinels/models';
import { Timestamp } from 'firebase/firestore';
import moment from 'moment-timezone';
import { MatBadgeModule } from '@angular/material/badge';

@Component({
  selector: 'gth-wall-post',
  templateUrl: './wall-post.component.html',
  styleUrls: ['./wall-post.component.scss'],
  standalone: true,
  imports: [
  CommonModule,
    FormsModule,
    MatButtonModule,
    MatCardModule,
    MatDividerModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatBadgeModule,
  ],
})
export class WallPostComponent implements OnInit {
  @Input() event = '';
  newPost: '';
  posts: Observable<GthEventWallPostModel[]>;
  curUser: Observable<GthUserModel>;

  constructor(private wallPost: WallPostService) {

  }

  ngOnInit(): void {
    this.posts = this.wallPost.updateList(this.event);
    this.curUser = this.wallPost.AuthService.userModel$;
  }

  toggleContent(post: GthEventWallPostModel): void {
    post.showFullContent = !post.showFullContent;
  }

  likePost(post: GthEventWallPostModel, userId:string): void {
    if (post.likes.includes(userId)) {
      post.likes = [...post.likes.filter((id)=> id!== userId)];
    } else {
      post.likes.push(userId);
    }

    this.wallPost.update(this.event, post.id, structuredClone(post.model));
  }

  toggleCommentInput(post: GthEventWallPostModel): void {
    post.showCommentInput = !post.showCommentInput;
    if (post.showCommentInput) {
      post.newComment = ''; // Clear input when showing
    }
  }

  addComment(post: GthEventWallPostModel, userId:string ): void {
    const postCopy = post.copy;
    if (post.newComment.trim() !== '') {
      const newCopy = {
        ...postCopy,
        newComment: '',
        comments: [
          { userId: userId, createdAt: new Date(), content: postCopy.newComment, likes: [] },
          ...post.comments,
        ],
      };

      post.newComment = '';
      postCopy.newComment = '';
      this.wallPost.update(this.event, postCopy.id, newCopy);
    }
  }

  likeComment(comment: Comment, userId: string, post: GthEventWallPostModel): void {
    comment.likes.push(userId);
    const postCopy = post.copy;
    this.wallPost.update(this.event, postCopy.id, postCopy);
  }

  addPost(post: string) {
    this.curUser.subscribe((user)=>{
      this.wallPost.create(this.event, user.uid, post);
      this.newPost = '';
    });
  }

  toggleShowAllComments(post: GthEventWallPostModel): void {
    post.showAllComments = !post.showAllComments;
  }
}
