import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import { UserMapper } from '../mappers/user-mapper';
import { UserModel } from '../models/user';
import { DBUtil } from '../utils/db-utils';
import { replaceUndefinedWithNull } from './user-dao';

export interface GuestReadFilter {
  email?: string;
  id?: string;
  phone?: string;
}

@Injectable({ providedIn: 'root' })
export class GuestDAOService {
  mapper = new UserMapper();
  constructor(
    private readonly afs: AngularFirestore,
  ) {}

  async create(userModel: UserModel) {
    const collectionRef = this.afs.collection(DBUtil.Guests);
    const docRef = userModel.uid === null ?
      collectionRef.doc() :
      collectionRef.doc(userModel.uid);
    userModel.ref = docRef as any;
    const mappedUser = replaceUndefinedWithNull((this.mapper.toMap(userModel)));

    try {
      await docRef.set(mappedUser);
      return (await docRef.get().toPromise()).id;
    } catch (e) {
      throw (e);
    }
  }

  async getRefFromType(path: string, filter:GuestReadFilter, type: string) {
    const snapshot = this.afs.collection(path, (ref) => {
      switch (type) {
        case 'email':
          return ref
            .where('email', '==', filter.email)
            .limit(1);
        case 'phone':
          return ref
            .where('phoneNumber', '==', filter.phone)
            .limit(1);
        case 'id':
          return ref
            .where('uid', '==', filter.id)
            .limit(1);
        default: return ref.limit(1);
      }
    });

    return await snapshot.get().toPromise();
  }

  async read(filter: GuestReadFilter) {
    let type: 'email' | 'phone' | 'id';
    if (filter.email) type = 'email';
    else if (filter.id) type = 'id';
    else if (filter.phone) type = 'phone';

    let querySnapshot = await this.getRefFromType(DBUtil.Guests, filter, type);
    if (!querySnapshot.docs || querySnapshot.docs.length === 0) {
      querySnapshot = await this.getRefFromType(DBUtil.User, filter, type);
      if (!querySnapshot.docs || querySnapshot.docs.length === 0) return undefined;
    }
    const firstDocument = querySnapshot.docs[0];
    const userData = firstDocument.data() as any;
    return {
      ...userData,
      id: firstDocument.id,
    };
  }
}
