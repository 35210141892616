import { EventJoinerModel } from '../../models/event-joiner';
import { GenderInfo } from '../../interfaces/event-item';

export class EventJoinerSorter {
  sort(eventJoiners: EventJoinerModel[], eventGenderInfo: { [key in string]?: GenderInfo }) {
    eventJoiners.forEach((eventJoiner) => {
      if (this.hasAvailableSpot(eventJoiner, eventGenderInfo)) {
        const genderInfo = eventGenderInfo[eventJoiner.assignedGenderSpot!]!;
        genderInfo.participants = Array.isArray(genderInfo.participants) ?
          genderInfo.participants :
          [];
        genderInfo.participants.push(eventJoiner);
      }
    });

    return eventGenderInfo;
  }

  hasAvailableSpot(
    eventJoiner: EventJoinerModel,
    eventGenderInfo: { [key in string]?: GenderInfo },
  ) {
    const assignedGenderSpot = eventJoiner.assignedGenderSpot;
    if (!assignedGenderSpot || !eventGenderInfo[assignedGenderSpot]) return false;

    return true;
  }
}
