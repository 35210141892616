import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { GthUploadImageComponent } from './upload-image.component';

const ANGULAR_MATERIAL_MODULES = [
  MatButtonModule, MatIconModule,
  MatTooltipModule,
];

const CORE_MODULES = [CommonModule];

const COMPONENTS = [GthUploadImageComponent];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [...CORE_MODULES, ...ANGULAR_MATERIAL_MODULES, MatTooltipModule],
  exports: [...COMPONENTS],
})
export class GthUploadImageModule { }
