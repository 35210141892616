import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GthEventItemModel, GthTeamModel, GthUserModel } from '@sentinels/models';

export enum FIND_PLAYERS_CLOSE_ACTION {
  CANCEL,
  REMIND_TEAM,
  FIND_AVAILABLE_PLAYERS,
  CREATE_PUBLIC_GAME,
  CREATE_PRIVATE_GAME,
}

export interface FindPlayersDialogContract {
  event: GthEventItemModel,
  user: GthUserModel,
  team: GthTeamModel,
}

@Component({
  selector: 'gth-find-players-dialog',
  templateUrl: './find-players-dialog.component.html',
  styleUrls: ['./find-players-dialog.component.scss'],
})
export class GthFindPlayersDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: FindPlayersDialogContract,
    private dialogRef: MatDialogRef<GthFindPlayersDialogComponent>,
  ) {}
  public onRemindTeamBtnClick() {
    this.dialogRef.close(FIND_PLAYERS_CLOSE_ACTION.REMIND_TEAM);
  }
  public onFindAvailablePlayersBtnClick() {
    this.dialogRef.close(FIND_PLAYERS_CLOSE_ACTION.FIND_AVAILABLE_PLAYERS);
  }
  public onCreateDicoverableGameBtnClick() {
    this.data.event.discoverable ?
      this.dialogRef.close(FIND_PLAYERS_CLOSE_ACTION.CREATE_PRIVATE_GAME) :
      this.dialogRef.close(FIND_PLAYERS_CLOSE_ACTION.CREATE_PUBLIC_GAME);
  }
}
