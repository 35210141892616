import firebase from 'firebase/compat/app';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import { GthErrorLoggerService } from './error-logger.service';
import {
  CreatePendingInvitationContract,
  ListPendingInvitationContract,
  PendingInvitation,
  PendingInvitationType,
  PhoneNumberPermission,
} from '@index/interfaces';
import { DBUtil } from '../../../../../index/src/lib/utils/db-utils';
import { GthPendingInvitation } from '../../../../../sentinels/src/lib/models/pending-invitation';
import { GthTeamFunctionService } from './team-function.service';
import { GthTeamModel } from '@sentinels/models';

const COLLECTION_NAME = DBUtil.PendingInvitations;
const CONTEXT = 'GthPendingInvitesFunctionService';

@Injectable({
  providedIn: 'root',
})
export class GthPendingInvitesFunctionService {
  constructor(
    private firestore: AngularFirestore,
    private teamService: GthTeamFunctionService,
    private logger: GthErrorLoggerService,
  ) {}

  createTeamInvite$(createTeamContract: {
    createdBy: string,
    teamId: string,
    email?: string,
    phoneNumber?: string,
    phoneNumberPermission?: PhoneNumberPermission,
  }) {
    return this.create$({
      type: PendingInvitationType.Team,
      createdBy: createTeamContract.createdBy,
      invitedTo: createTeamContract.teamId,
      email: createTeamContract.email,
      phoneNumber: createTeamContract.phoneNumber,
      phoneNumberPermission: createTeamContract.phoneNumberPermission,
    });
  }

  listTeamInvites$(email: string, phoneNumber: string): Observable<GthPendingInvitation[]> {
    const pendingInvitations$ = combineLatest(
      this.list$({ email }),
      this.list$({ phoneNumber }),
    ).pipe(map(([emailInvites, phoneInvites]) => {
        return emailInvites.concat(phoneInvites);
    }));
    return pendingInvitations$.pipe(
      map((invites) => {
        console.debug('all pending invitations found', invites);
        return invites.filter((i) => {
          return i.type === PendingInvitationType.Team &&
            /** Only get phone invites where the team gave full access permission */
            i?.phoneNumberPermission !== PhoneNumberPermission.SMSOnly;
        });
      }),
      switchMap((invites) => {
        if (!invites || invites.length === 0) {
          return of([]);
        }
        const teamIds = invites.map((i) => i.invitedTo);
        const teams$ = teamIds.map((id) => this.teamService.getTeamById$(id));
        return combineLatest(teams$).pipe(
          map((teams) => {
            const teamModels = teams.map((t) => new GthTeamModel(t.id, t));
            invites.forEach((i) => {
              const team = teamModels.find((t) => t.id === i.invitedTo);
              if (team) {
                i.team = team;
              }
            });
            return invites;
          }),
        );
      }),
    );
  }

  markComplete$(item: GthPendingInvitation) {
    const storeItem = this.firestore
      .collection<PendingInvitation>(COLLECTION_NAME)
      .doc(item.id);

    this.log(`Marking pending invitation as complete`);
    item.markComplete();

    return new Observable((observer) => {
      storeItem
        .update(item.model)
        .then(() => {
          observer.next(true);
          observer.complete();
        })
        .catch(() => {
          observer.next(false);
          observer.complete();
        });
    }) as Observable<boolean>;
  }

  private list$(
    { email, phoneNumber }: ListPendingInvitationContract,
  ): Observable<GthPendingInvitation[]> {
    let type: 'email' | 'phone';
    if (email) type = 'email';
    else if (phoneNumber) type = 'phone';

    this.log(`Retrieving pending invitations for ${email ?? phoneNumber}`);
    if (!email) {
      return of([]);
    }

    const queryEmailCol$ = this.firestore.collection<PendingInvitation>(COLLECTION_NAME,
      (ref) => {
        switch (type) {
          case 'email':
            return ref.where('email', '==', email);
          case 'phone':
            return ref.where('phoneNumber', '==', phoneNumber);
        }
    }).snapshotChanges();
    return queryEmailCol$.pipe(
      take(1),
      map((items) => {
        this.log(`Retrieved ${items?.length} pending invitations for ${email ?? phoneNumber}`);
        return items.map((item) => {
          const doc = item.payload.doc;
          const data = {
            ...doc.data(),
          } as PendingInvitation;

          return new GthPendingInvitation(doc.id, data);
        });
      }),
      map((invites) => {
        return invites.filter((i) => !i.isActedOn);
      }),
    );
  }

  private create$({
                    type,
                    createdBy,
                    invitedTo,
                    email,
                    phoneNumber,
                    phoneNumberPermission,
  }: CreatePendingInvitationContract) {
    this.log(`Creating ${type} pending invitation`);
    const invite: PendingInvitation = {
      createdBy,
      type,
      invitedTo,
      isActedOn: false,
      createdOn: firebase.firestore.Timestamp.now(),
    };
    if (email) invite.email = email;
    if (phoneNumber) {
      invite.phoneNumber = phoneNumber;
      invite.phoneNumberPermission = phoneNumberPermission;
    }
    const collection = this.firestore.collection<PendingInvitation>(COLLECTION_NAME);
    return new Observable((observer) => {
      collection
        .add(invite)
        .then(() => {
          this.log(`Successfully created ${type} pending invitation`);
          observer.next(true);
          observer.complete();
        })
        .catch(() => {
          this.log(`Error creating ${type} pending invitation`);
          observer.next(false);
          observer.complete();
        });
    }) as Observable<boolean>;
  }

  private log(text: string) {
    this.logger.debug(`${CONTEXT}: ${text}`);
  }
}
