import firebase from 'firebase/compat/app';

import { Location } from './location';
import { SkillLevel } from '../enums/skill-level';

export enum EntityType {
  Team = 'Team',
  Group = 'Group',
  Club = 'Club',
  Organization = 'Organization',
}

export interface ExternalTeam {
  id?: string;
  creator: string;
  description: string;
  name: string;
  photoURL: string;
  externalURL: string;
  activity: string;
  location: Location;
  online: boolean;
  skillLevel: Array<SkillLevel>;
  entityType: EntityType;
  contactEmail: string;
  contactPhone: string;
  allowAskToJoin: boolean;
  created?: firebase.firestore.Timestamp;
  updated?: firebase.firestore.Timestamp;
}

export interface ExternalTeamRatingItem {
  rating: string;
  userId: string;
}
