import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { UploadTaskSnapshot } from '@angular/fire/compat/storage/interfaces';
import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

export interface GthUploadImageStatus {
  uploadPercent: number;
  filePath?: string;
  uid?: string;
}

@Injectable({
  providedIn: 'root',
})
export class GthFileUploadService {
  constructor(
    private storage: AngularFireStorage,
    private firestore: AngularFirestore,
  ) { }

  upload$(file: File): Observable<GthUploadImageStatus> {
    const id = this.firestore.createId();
    const task = this.storage.upload(`media/${id}`, file);
    const percent$ = task.percentageChanges();
    const downloadUri$ = task.snapshotChanges().pipe(
      switchMap((file) => {
        if (file) {
          return this.getDownloadURL(file);
        } else {
          return of(undefined);
        }
      }),
      switchMap((filePath) => {
        if (filePath && filePath.length > 0) {
          return this.storeImageMetadata(`media/${id}`, file.name).pipe(
            map((uid) => {
              return {
                filePath,
                uid,
              };
            }),
          );
        } else {
          return of({
            filePath,
            uid: '',
          });
        }
      }),
    );

    return combineLatest([percent$, downloadUri$]).pipe(
      map(([uploadPercent, metaData]) => {
        return {
          uploadPercent: uploadPercent ? uploadPercent : 0,
          filePath: metaData && metaData.filePath ? metaData.filePath : '',
          uid: metaData && metaData.uid ? metaData.uid : '',
        };
      }),
    );
  }

  getById$(id: string) {
    const item = this.firestore.collection<any>('media').doc(id);
    return item.snapshotChanges().pipe(
      take(1),
      map((item) => {
        return {
          uid: item.payload.id,
          ...item.payload.data(),
        };
      }),
    );
  }

  delete$(id: string, path: string) {
    const doc = this.firestore.collection<any>('media').doc(id);
    const store = this.storage.storage.ref(path);

    const deleteStore$ = new Observable((observer) => {
      store
        .delete()
        .then(() => {
          observer.next(true);
          observer.complete();
        })
        .catch(() => {
          observer.next(false);
          observer.complete();
        });
    });

    const deleteDoc$ = new Observable((observer) => {
      doc
        .delete()
        .then(() => {
          observer.next(true);
          observer.complete();
        })
        .catch(() => {
          observer.next(false);
          observer.complete();
        });
    });

    return forkJoin([deleteStore$, deleteDoc$]).pipe(
      map(([deleteStore, deleteDoc]) => {
        return deleteStore && deleteDoc;
      }),
    );
  }

  private getDownloadURL(task: UploadTaskSnapshot) {
    return new Observable<string>((observer) => {
      const ref = task.ref;
      if (ref) {
        ref
          .getDownloadURL()
          .then((filepath) => {
            observer.next(filepath);
            observer.complete();
          })
          .catch((e) => {
            console.log('Error retreiving download url', e);
            observer.next('');
            observer.complete();
          });
      } else {
        observer.next('');
      }
    });
  }

  private storeImageMetadata(path: string, fileName: string) {
    const collection = this.firestore.collection<any>('media');
    return new Observable<string>((observer) => {
      collection
        .add({ path, fileName })
        .then((x) => {
          observer.next(x.id);
          observer.complete();
        })
        .catch(() => {
          observer.next('');
          observer.complete();
        });
    });
  }
}
