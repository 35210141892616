import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { GthEventItemModel } from '@sentinels/models';

export interface RSVPEventDialogContract {
  event: GthEventItemModel,
}

export enum RSVPEventDialogCloseMethod {
  RSVP = 'Rsvp',
  CANCEL = 'Cancel',
}

export interface RSVPEventDialogCloseContract {
  closeMethod: RSVPEventDialogCloseMethod,
  name?: string,
  email?: string,
}

@Component({
  selector: 'gth-rsvp-event-dialog',
  templateUrl: './rsvp-event-dialog.component.html',
  styleUrls: ['./rsvp-event-dialog.component.scss'],
  standalone: true,
  imports: [
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatButtonModule,
  ],
})
export class RsvpEventDialogComponent implements OnInit {
  title = 'RSVP for Event';
  rsvpEventForm = new FormGroup({
    name: new FormControl<string>(
      null,
      { nonNullable: true, validators: Validators.required },
    ),
    email: new FormControl<string>(
      null,
      { nonNullable: true, validators: [Validators.required, Validators.email] },
    ),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: RSVPEventDialogContract,
    private dialogRef: MatDialogRef<RsvpEventDialogComponent>,
  ) {}
  ngOnInit() {
    if (this.data.event) {
      this.title = this.title+`(${this.data.event.title})`;
    }
  }

  onRSVPBtnClick() {
    const closeContract: RSVPEventDialogCloseContract = {
      closeMethod: RSVPEventDialogCloseMethod.RSVP,
      name: this.rsvpEventForm.controls.name.value,
      email: this.rsvpEventForm.controls.email.value,
    };
    this.dialogRef.close(closeContract);
  }

  onCancelBtnClick() {
    const closeContract: RSVPEventDialogCloseContract = {
      closeMethod: RSVPEventDialogCloseMethod.CANCEL,
    };
    this.dialogRef.close(closeContract);
  }
}
