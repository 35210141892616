export class GthPasswordUtil {
  /**
   * Gets password strength
   * @param {string} password
   * @return {number}
   */
  static getPasswordStrength(password: string) {
    let score = 0;

    if (password.length > 8) {
      score += 1;
    }

    if (password !== password.toLocaleLowerCase()) {
      score += 1;
    }

    const substring = [
      '@',
      '_',
      '!',
      '#',
      '$',
      '%',
      '^',
      '&',
      '*',
      '(',
      ')',
      '<',
      '>',
      '?',
      '/',
      '|',
      '}',
      '{',
      '~',
      ':',
    ];

    if (this.containsAny(password, substring)) {
      score += 1;
    }
    return score / 3;
  }

  private static containsAny(str: string, substrings: string[]): boolean {
    for (let i = 0; i != substrings.length; i++) {
      const substring = substrings[i];
      if (str.indexOf(substring) != -1) {
        return true;
      }
    }
    return false;
  }
}
