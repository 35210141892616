import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GthRsvpWithGuestDialogComponent } from '@gth-legacy/components/rsvp-with-guest-dialog/rsvp-with-guest-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

const ANGULAR_MATERIAL_MODULES = [
  MatDialogModule, MatButtonModule,
  MatFormFieldModule,
  MatIconModule, MatInputModule,
  MatSelectModule,
];

const CORE_MODULES = [CommonModule];

const COMPONENTS = [GthRsvpWithGuestDialogComponent];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [...CORE_MODULES, ...ANGULAR_MATERIAL_MODULES],
  exports: [...COMPONENTS],
})
export class GthRsvpWithGuestDialogModule { }
