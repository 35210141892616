<form [formGroup]="form" class="address-selection">
  <h1 mat-dialog-title class="title">Add Location</h1>
  <div mat-dialog-content class="content">
    <mat-form-field class="form-field">
      <mat-label>Street Address</mat-label>
      <input required class="city-input" matInput #cityInput
             ngx-gp-autocomplete
             [value]="placeResult.name"
             (change)="clearCityInput()"
             #placesRef="ngx-places"
             (onAddressChange)="onAutocompleteSelected($event)" />

    </mat-form-field>
    <ng-container *ngIf="placeResult?.name?.length">
      <gth-google-map [latitude]="lat" [longitude]="lng" [zoom]="zoom">
        <gth-google-map-marker [latitude]="lat"
                               [longitude]="lng"
                               [markerDraggable]="true"
                               (markerDrag)="markerDragEnd($event)" />
        </gth-google-map>
    </ng-container>
  </div>
  <div class="actions" mat-dialog-actions>
    <span class="spacer"></span>
    <button mat-button (click)="onCancelButtonClick()">Cancel</button>
    <button mat-flat-button color="primary" cdkFocusInitial
            [disabled]="!isFormValid"
            (click)="onSaveButtonClick()">Save</button>
  </div>
</form>
