<h1 mat-dialog-title>Unlink Stripe</h1>

<mat-dialog-content>
  Are you sure you want to unlink your current Stripe account?
</mat-dialog-content>


<mat-dialog-actions class="buttons-container">
  <button mat-flat-button type="button" color="warn" [disabled]="loading" (click)="unLinkBtnClick()">
    <ng-container *ngIf="loading else showText">
      <mat-progress-spinner mode="indeterminate" color="accent" [diameter]="25" />
    </ng-container>
    <ng-template #showText>Unlink</ng-template>
  </button>
  <button mat-flat-button type="button" mat-dialog-close [disabled]="loading">Cancel</button>
</mat-dialog-actions>