<div class="event" *ngIf="event">
  <div class="event--cancelled" *ngIf="event.cancelled" matTooltip="Event has been cancelled"></div>
  <div class="content-column fx-column fx-50">
    <picture class="banner-container">
      <img *ngIf="event.banner else placeholderBanner" [src]="event.banner" width="400" />
      <ng-template #placeholderBanner>
        <img src="assets/imgs/MyEventHero_FullLogo_Transparent-p-1080.webp" class="placeholder-banner" width="400" />
      </ng-template>
    </picture>

    <gth-google-map [latitude]="lat" [longitude]="lng" [zoom]="zoom" *ngIf="!online">
      <gth-google-map-marker [latitude]="lat" [longitude]="lng" />
    </gth-google-map>
  </div>

  <div class="content-column fx-column fx-50">
    <h3 class="secondary-header">{{ event.eventType }}</h3>
    <p *ngIf="event.description" class="description">{{ event.description }}</p>
    <div class="event-info date-container fx-row">
      <span class="fx-column date-text event-info-text">
        <span class="header">{{ event.dateStart | date:'longDate' }}</span>
        <span class="text">{{ event.dateStart | date:'shortTime' }} to {{ event.dateEnd | date:'shortTime'
          }}</span>
      </span>
    </div>

    <div class="event-info event-info-text game-type-container">
      <span class="header">Type</span>
      <span class="text">{{ event.eventType }}</span>
    </div>

    <div class="event-info event-info-text game-type-container">
      <span class="header">Participants ({{event.playerCount.participantsResponded}})</span>
      <span class="text"><gth-participant-collection [users]="participants$ | async" /></span>
    </div>
  </div>
</div>