import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

import { GthTeamFunctionService } from '../cloud/team-function.service';
import { GthCallableService } from '../cloud/callable.service';
import { GthErrorLoggerService } from '../cloud/error-logger.service';
import { Team } from '@index/interfaces/team';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root',
})
export class GthCacheTeamService extends GthTeamFunctionService {
  private byIdStoreSubject = new BehaviorSubject<
    Map<string, Observable<Team>>>(new Map<string, Observable<Team>>());

  constructor(
    private teamCloudService: GthTeamFunctionService,
    firestore: AngularFirestore,
    functions: AngularFireFunctions,
    callableService: GthCallableService,
    logger: GthErrorLoggerService,
  ) {
    super(firestore, functions, callableService, logger);
  }

  getTeamById$(id: string): Observable<Team | undefined> {
    const store$ = this.byIdStoreSubject.asObservable();
    return store$.pipe(
      switchMap((store) => {
        if (store.has(id)) {
          const teamItem = store.get(id);
          return teamItem;
        }

        const team$ = this.teamCloudService.getTeamById$(id).pipe(
          first(),
          map((team) => {
            store.set(id, of(team));
            this.byIdStoreSubject.next(store);
            return team;
          }),
        );
        store.set(id, team$);
        return team$;
      }),
    );
  }

  getLatestById$(id: string) {
    return this.teamCloudService.getTeamById$(id);
  }

  refreshById$(id: string) {
    const store = this.byIdStoreSubject.getValue();

    const team$ = this.teamCloudService.getTeamById$(id).pipe(
      first(),
      map((team) => {
        store.set(id, of(team));
        this.byIdStoreSubject.next(store);
        return team;
      }),
    );
    store.set(id, team$);
    return team$;
  }
}
