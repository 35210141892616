import { Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';

export const ANALYTICS_CONSTS = {
  sign_up: 'app_signup',

  // landing page analytics
  learnMore: 'button_learnmore',
  getStartedFree: 'button_getstarted_free',
  getStartedMenu: 'button_getstarted_menu',
  getStartedMidPage: 'button_getstarted_midpage',
  tryFree: 'button_tryfree',

  // footer analytics
  mailChimp: 'button_mailchimp',

  // screen names
  landingPage: 'landing_page',

  appOpen: 'app_open',
  onboardingDismissed: 'onboarding_dismissed',
  onboardingOpened: 'onboarding_opened',
  eventOpened: 'event_opened',
  badgesOpened: 'badge_list_opened',

  privateProfileOpened: 'private_profile_opened',
  publicProfileOpened: 'public_profile_opened',

  messagesOpenedDirect: 'messages_opened_direct',

  avatarDialogShown: 'avatar_dialog_opened',
  settingsSave: 'settings_save',
  settingsUpdated: 'settings_updated',
  settingsCreated: 'settings_created',
  deleteUserPrompt: 'delete_user_prompt',
  deleteUserPromptConfirm: 'delete_user_confirm',

  teamsSearchPerformed: 'teams_search_performed',
  teamsShare: 'teams_share',
  teamsFavorite: 'teams_favorite',
  teamsJoin: 'teams_join',
  teamsOpen: 'teams_open',
};

@Injectable({
  providedIn: 'root',
})
export class GthAnalyticsService {
  constructor(private analytics: AngularFireAnalytics) {
  }

  logEvent(eventName: string, params?: any) {
    const props = {
      ...params,
      dateCreated: new Date(),
      platform: 'web',
    };
    this.analytics.logEvent(eventName, props);
  }
}
