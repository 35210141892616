import { Component, Input } from '@angular/core';
import { CommonModule, KeyValue } from '@angular/common';
import { FormControl, Validators } from '@angular/forms';

import { SkillLevel, SkillLevelToIndexMap } from '@index/enums/skill-level';

@Component({
  selector: 'gth-skill-level-form-control',
  templateUrl: './skill-level-form-control.component.html',
  styleUrls: ['./skill-level-form-control.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class GthSkillLevelFormComponent {
  @Input()
  skillLevelFormControl = new FormControl<Array<SkillLevel>>(
    [],
    { nonNullable: true, validators: Validators.required },
  );
  protected readonly title = 'Skill Level';
  protected readonly SkillLevel = SkillLevel;

  originalOrder() {
    return 0;
  }

  identifySkillLevel(_: number, skillLevel: KeyValue<string, SkillLevel>) {
    return skillLevel.key;
  }

  onSkillLevelClick(val: SkillLevel) {
    const skillLevels = this.skillLevelFormControl.value ?? [];
    const skillLevelIndex = skillLevels.indexOf(val);

    if (val === SkillLevel.Any) {
      if (skillLevels.length === 4) {
        // Deslect all
        this.skillLevelFormControl.setValue([]);
      } else {
        this.skillLevelFormControl.setValue([
          SkillLevel.Novice,
          SkillLevel.Beginner,
          SkillLevel.Intermediate,
          SkillLevel.Advanced,
        ]);
      }
      return;
    }

    if (skillLevelIndex >= 0) {
      // Unselect skill level
      skillLevels.splice(skillLevelIndex, 1);
    } else {
      skillLevels.push(val);
    }

    this.skillLevelFormControl.setValue(skillLevels);
  }

  protected readonly SkillLevelToIndexMap = SkillLevelToIndexMap;
}
