<div>
  <gth-stored-image class="team-logo" *ngIf="photoURL && !teamLogoError" [id]="photoURL"
    (imageError)="teamLogoError = false"></gth-stored-image>
</div>
<h1>General Information</h1><i>(This team or group is NOT managed on Gametime Hero)</i>
<h2>
  {{ team.name }}
</h2>

<h3 class="text-content">
  {{ team.activity }}
</h3>

<h3 class="text-label mat-caption">
  Location
</h3>

<p class="text-content">
  {{ team.location.formattedAddress }}
</p>

<ng-container *ngIf="team.description">
  <h3 class="text-label mat-caption">
    Description
  </h3>

  <p class="text-content">
    {{ team.description }}
  </p>
</ng-container>

<ng-container *ngIf="team.skillLevel">
  <h3 class="text-label mat-caption">
    Skill Level
  </h3>

  <p class="text-content">
    {{ team.skillLevel.length === 4? 'All Levels' : team.skillLevel}}
  </p>
</ng-container>

<ng-container *ngIf="team.entityType">
  <h3 class="text-label mat-caption">
    Type of Entity
  </h3>

  <p class="text-content">
    {{ team.entityType }}
  </p>
</ng-container>

<ng-container *ngIf="team.contactEmail">
  <h3 class="text-label mat-caption">
    Email
  </h3>

  <p class="text-content">
    <a [href]="'mailto:' + team.contactEmail" target="_blank">{{ team.contactEmail }}</a>
  </p>
</ng-container>


<ng-container *ngIf="team.contactPhone">
  <h3 class="text-label mat-caption">
    Phone Number
  </h3>

  <p class="text-content">
    <a [href]="'tel:' + team.contactPhone" target="_blank">{{ team.contactPhone | phone }}</a>
  </p>
</ng-container>

<ng-container *ngIf="creator">
  <h3 class="text-label mat-caption">
    Creator, Admin or Owner Contact
  </h3>
  <gth-user-avatar [user]="creator"></gth-user-avatar>
</ng-container>

<gth-team-rating [view]="ratingViewType" [user]="user" [ratings]="ratings"
  (submitRatings)="onRatingChange($event)"></gth-team-rating>

<ng-container *ngIf="team.externalURL && !isSameUser && team.allowAskToJoin">
  <div class="join-btn">
    <button mat-raised-button color="primary" (click)="onRequestToJoin()" [disabled]="validating">
      {{ user ? 'Request to Join or Sub' : 'Sign Up Now!' }}
    </button>
  </div>
  <div id="recaptcha-container"></div>
</ng-container>