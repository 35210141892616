<h1 mat-dialog-title>Send Invoice Dialog</h1>

<mat-dialog-content>
  <form [formGroup]="sendInvoiceForm">
    <ng-container *ngIf="isTeamInvoice else emailFormTmpl">
      <mat-form-field appearance="outline">
        <mat-label>Team Members</mat-label>
        <mat-select formControlName="userId">
          <mat-option *ngFor="let member of this.data?.team?.members" [value]="member.id"
                      [disabled]="this.data?.user?.id === member.id">
            {{member.displayName}}{{this.data?.user?.id === member.id ? ' (You)' : ''}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
    <ng-template #emailFormTmpl>
      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input matInput type="email" name="email" formControlName="email" />
      </mat-form-field>
    </ng-template>

    <mat-form-field appearance="outline">
      <mat-label>Description</mat-label>
      <input matInput type="text" name="description" formControlName="description" />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Amount</mat-label>
      <mat-icon matPrefix fontIcon="attach_money" />
      <input matInput type="number" name="amount" formControlName="amount" />
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-flat-button color="primary" [disabled]="sendInvoiceForm.invalid"
          (click)="onSendInvoiceBtnClick()">Send Invoice</button>
</mat-dialog-actions>
