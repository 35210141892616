import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { APP_ROUTES } from '@shared/helpers';

@Component({
  selector: 'gth-payment-account-not-setup-dialog',
  templateUrl: './payment-account-not-setup-dialog.component.html',
  styleUrls: ['./payment-account-not-setup-dialog.component.scss'],
  standalone: true,
  imports: [
    RouterModule,
    MatDialogModule,
    MatButtonModule,
  ],
})
export class PaymentAccountNotSetupDialogComponent {
  stripeLink = {
    label: 'Stripe',
    url: 'https://stripe.com/',
  };
  protected readonly APP_ROUTES = APP_ROUTES;
}
