import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { from, Observable } from 'rxjs';

import { Payload, Results, Notification } from '@index/interfaces';
import { NotificationModel } from '@index/models/notifications';
import { GthNotificationModel, NotificationAdapter } from '@sentinels/models/notification';
import { CallableOptions, CallableRoutes, GthCallableService } from './callable.service';
import { GthErrorLoggerService } from './error-logger.service';

const CONTEXT = 'GthNotificationFunctionService';

@Injectable({
  providedIn: 'root',
})
export class GthNotificationFunctionService {
  constructor(
    public functions: AngularFireFunctions,
    public callableService: GthCallableService,
    private logger: GthErrorLoggerService,
    private adapter: NotificationAdapter,
  ) { }

  /**
   * Creates a notification
   * @param {NotificationModel} notification
   * @param {string} userId
   * @return {boolean} True if success
   */
  create$(notification: NotificationModel, userId:string): Observable<boolean> {
    return from(this.create(notification, userId));
  }

  /**
   * Gets list of notifications for logged in user
   * @param {string} creator
   * @return {GthNotificationModel[]}
   */
  list$(creator: string): Observable<GthNotificationModel[]> {
    return from(this.list(creator));
  }

  /**
   * Updates the notification
   * @param {GthNotificationModel} notification
   * @param {string} userId
   * @return {boolean} True if success
   */
  update$(notification: GthNotificationModel, userId:string): Observable<boolean> {
    return from(this.update(notification, userId));
  }

  /**
   * Deletes the notification
   * @param {GthNotificationModel} notification
   * @param {string} userId
   * @return {boolean} True if success
   */
  delete$(notification: GthNotificationModel, userId: string): Observable<boolean> {
    return from(this.delete(notification, userId));
  }

  /**
   * Creates a notification
   * @param {NotificationModel} notification
   * @param {string} userId
   * @return {boolean} True if success
   */
  private async create(notification: NotificationModel, userId:string): Promise<boolean> {
    if (!this.functions) return Promise.resolve(false);

    const options: CallableOptions = {
      route: CallableRoutes.NOTIFICATIONS_CREATE,
      data: {
        notification,
        creator: userId,
        contextId: notification.id,
      },
    };

    const response = (await this.callableService.call(options)) as Results<Notification>;
    this.log(`Notification created`);

    return response && response.success;
  }

  /**
   * Gets list of notifications for logged in user
   * @param {string} creator
   * @return {GthNotificationModel[]}
   */
  private async list(creator: string): Promise<GthNotificationModel[]> {
    const options: CallableOptions = {
      route: CallableRoutes.NOTIFICATIONS_LIST,
      data: creator,
    };
    const response = (await this.callableService.call(options)) as Results<Notification>;

    if (response && response.success && response.payload) {
      const results = response.payload as Payload<Notification>[];
      this.log(`Notifications listed`);
      return results.map((item) => this.adapter.adapt(item.data));
    }

    return [];
  }

  /**
   * Updates the notification
   * @param {GthNotificationModel} notification
   * @param {string} userId
   * @return {boolean} True if success
   */
  private async update(notification: GthNotificationModel, userId:string): Promise<boolean> {
    if (!this.functions) return Promise.resolve(false);

    const options: CallableOptions = {
      route: CallableRoutes.NOTIFICATIONS_UPDATE,
      data: {
        id: notification.id,
        userId,
        read: true,
      },
    };

    const response = (await this.callableService.call(options)) as Results<Notification>;
    this.log(`Notification updated by id: ${notification.id}`);

    return response && response.success;
  }

  /**
   * Deletes the notification
   * @param {GthNotificationModel} notification
   * @param {string} userId
   * @return {boolean} True if success
   */
  private async delete(notification: GthNotificationModel, userId: string): Promise<boolean> {
    if (!this.functions) return Promise.resolve(false);

    const options: CallableOptions = {
      route: CallableRoutes.NOTIFICATIONS_DELETE,
      data: {
        id: notification.id,
        userId,
      },
    };
    const response = (await this.callableService.call(options)) as Results<Notification>;
    this.log(`Notification deleted by id: ${notification.id}`);

    return response && response.success;
  }

  private log(text: string) {
    this.logger.debug(`${CONTEXT}: ${text}`);
  }
}
