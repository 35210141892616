<mat-card appearance="outlined" class="template-tooltip">
  <ng-container *ngIf="tooltipItem">
    <p class="display-name">{{ tooltipItem?.displayName }}</p>
    <ng-container *ngIf="platform !== 'meh'">
      <a class="user-button switch-to-public-button" mat-flat-button color="primary"
        [routerLink]="[routes.Profile, tooltipItem?.uid]">
        View More Info
      </a>
    </ng-container>
  </ng-container>
</mat-card>