import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
@Injectable({ providedIn: 'root' })
export class ScrollService {
    scrollSubject = new BehaviorSubject<number| null>(null);
    isScrolling = new BehaviorSubject<boolean>(false);
    lastScrollPosition = new BehaviorSubject<null|number>(null);

  constructor() {
    setTimeout(()=>{
        const main = this.getMainSection();
        main.addEventListener('scroll', () => this.updateScrollPosition(), true);
        main.addEventListener('scrollend', ()=>{
        this.isScrolling.next(false);
      });
    }, 2000);
   }

   private getMainSection() {
      const x = document.getElementsByTagName('mat-sidenav-content');
      for (const item of [...Array.from(x)]) {
          if (item.className.includes('main')) {
              return item;
          }
      }
      return null;
   }

   private updateScrollPosition() {
    this.isScrolling.next(true);
    const curTime = Date.now();
    this.scrollSubject.next(curTime);
    const main = this.getMainSection();
    const st = main.scrollTop;

    this.lastScrollPosition.next(st <= 0 ? 0 : st);
   }

   getLastScrollDirection(): 'up' | 'down' {
    const main = this.getMainSection();
    const currentScrollPosition = main.scrollTop;
    const scrollDirection =
      currentScrollPosition > this.lastScrollPosition.getValue() ?
        'down' : 'up';
    return scrollDirection;
  }

  addToScrollY(y=1) {
    const main = this.getMainSection();
    const lsp = this.lastScrollPosition.getValue();
    main.scrollBy(0, lsp +1);
  }
}
