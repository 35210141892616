import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

import { GthEventItemModel } from '@sentinels/models/event-item';
import { GthEventItemListFilter } from '@index/interfaces';
import { GthErrorLoggerService } from '../cloud/error-logger.service';
import { GthCallableService } from '../cloud/callable.service';
import { GthEventFunctionService } from '../cloud/event-function.service';

@Injectable({
  providedIn: 'root',
})
export class GthCacheEventService extends GthEventFunctionService {
  private byIdStoreSubject = new BehaviorSubject<Map<string, Observable<GthEventItemModel>>>(
    new Map<string, Observable<GthEventItemModel>>());

  constructor(
    private eventCloudService: GthEventFunctionService,
    functions: AngularFireFunctions,
    callableService: GthCallableService,
    logger: GthErrorLoggerService,
  ) {
    super(functions, callableService, logger);
  }

  list$(filter: GthEventItemListFilter): Observable<GthEventItemModel[]> {
    if (filter.eventItemId) {
      return this.getEventById$(filter.eventItemId).pipe(
        map((event) => {
          if (event) {
            return [event];
          }
          return [];
        }),
      );
    }
    return this.eventCloudService.list$(filter);
  }

  getEventById$(id: string): Observable<GthEventItemModel | undefined> {
    const store$ = this.byIdStoreSubject.asObservable();
    return store$.pipe(
      switchMap((store) => {
        if (store.has(id)) {
          const userItem = store.get(id);
          return userItem;
        }

        const event$ = this.eventCloudService.list$({ eventItemId: id }).pipe(
          first(),
          map((events) => {
            if (events && events.length > 0) {
              const event = events[0];
              store.set(id, of(event));
              this.byIdStoreSubject.next(store);
              return event;
            }
            return undefined;
          }),
        );
        store.set(id, event$);
        return event$;
      }),
    );
  }

  getLatestById$(id: string) {
    return this.eventCloudService.list$({ eventItemId: id }).pipe(
      map((events) => {
        if (!events || events.length === 0) {
          return undefined;
        }
        return events[0];
      }),
    );
  }

  refreshById$(id: string) {
    const store = this.byIdStoreSubject.getValue();

    const event$ = this.eventCloudService.list$({ eventItemId: id }).pipe(
      first(),
      map((events) => {
        if (!events || events.length === 0) {
          return undefined;
        }
        const event = events[0];
        store.set(id, of(event));
        this.byIdStoreSubject.next(store);
        return event;
      }),
    );
    store.set(id, event$);
    return event$;
  }
}
