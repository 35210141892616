<table class="table" mat-table [dataSource]="payments">
  <!-- Name Column -->
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>Name</th>
    <td mat-cell *matCellDef="let element">{{ element.name }}</td>
  </ng-container>

  <!-- Amount Column -->
  <ng-container matColumnDef="amount">
    <th mat-header-cell *matHeaderCellDef>Amount</th>
    <td mat-cell *matCellDef="let element">{{ element.amount | currency }}</td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef class="actions-column"></th>
    <td mat-cell *matCellDef="let element">
      <button
        class="pay-button"
        mat-flat-button
        color="primary"
        (click)="onPayButtonClick(element)"
      >
        Pay Now
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns" sticky="true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
