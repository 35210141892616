<h1 mat-dialog-title *ngIf="title">{{title}}</h1>

<mat-dialog-content>
  <p>{{description}}</p>
  <p>{{additionalInformation}}</p>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-flat-button [mat-dialog-close]="true" color="primary">{{buttonText}}</button>
</mat-dialog-actions>
