import firebase from 'firebase/compat';
import { FormControl } from '@angular/forms';

import { Location } from './location';
import { User } from './user';
import { EventItemTypes } from '../enums/event-item-type';
import { SubscriptionType } from '../enums/subscription-type';
import { AgeGroup } from '../enums/age-group';
import { TeamWebsite } from './team';
import { SkillLevel } from '../enums/skill-level';

/**
 * Represents Gaming Event
 */
export interface EventItem {
  id?: string;
  creator: string;
  title: string;
  dateStart: Date;
  gameType: string;
  duration: { hours: number; minutes: number };
  recurringType: EventRepeatType;
  location: Location;
  teamColor: string;
  hostingTeam: string | UnregisteredTeamInfo;
  cost?: number;
  equipmentNeeded: string[];
  requiredAttendees: string[];
  makePublicAfter: number | null;
  creatorApprovalNeeded: boolean;
  allowParticipantGuests: boolean;
  genderInfo: { [key in string]?: GenderInfo };
  days?: string[];
  type?: EventItemTypes;
  description?: string;
  banner?: string;
  online?: boolean;
  cancelled?: boolean;
  updated: firebase.firestore.Timestamp;
  rated: boolean;
  skillLevel?: SkillLevel;
  discoverable?: boolean;
  theme?: EventTheme;
  backgroundColor?: string;
  sendFeedbackEmailAfter?: boolean;
  ticketLevels?: EventTicketLevel[];
  platform?: string;
  priceId?: string,
}

export interface UnregisteredTeamInfo {
  name: string;
  photoURL: string;
  id: null;
  gameType: string;
  discoverable: boolean;
  subscription: SubscriptionType;
  ageGroup: AgeGroup;
  location: Location;
  created?: firebase.firestore.Timestamp;
  updated?: firebase.firestore.Timestamp;
  website: TeamWebsite;
  displayPublicWebsite: boolean;
  playerCount?: number;
  online?: boolean;
  skillLevel?: SkillLevel,
}

export interface GenderInfo {
  numberOfPlayersNeeded: number;
  participants?: EventJoiner[];
}

export interface EventJoiner {
  player: string;
  assignedGenderSpot?: string;
  createdAt: any;
  status?: EventJoinerStatus;
  approvedBy?: string[];
  guests?: EventItemGuest[];
  rsvpStatus: EventRsvpStatus;
  isGuestUser?: boolean;
}

export enum EventRsvpStatus {
  PLAYING = 'Playing',
  ATTEMPTING = 'Attempting',
  SPECTATING = 'Spectating',
  MAYBE = 'Maybe',
  NOT_PLAYING = 'Not Playing',
}

export enum EventJoinerStatus {
  Approved = 'Approved',
  Waitlisted = 'Waitlisted',
  PendingCreator = 'Pending Creator',
  PendingApprovers = 'Pending Approvers',
  Dropped = 'Dropped',
  Denied = 'Denied',
  PendingJoiner = 'Pending Joiner',
  /** After event statuses */
  Attended = 'Attended',
  NoShowed = 'No Showed',
  Removed = 'Removed',
  NotCommited = 'Not Commited',
}

export enum EventRepeatType {
  None = 0,
  Daily = 1,
  Weekly = 2,
  Monthly = 3,
  Annually = 4,
  EveryWeekday = 5,
}

export enum UpdateType {
  ADD_JOINER = 'addJoiner',
  DELETE_JOINER = 'deleteJoiner',
  UPDATE_EVENT = 'updateEvent',
}

export interface UpdateEventItemRequest {
  creator: string;
  id: string;
  title?: string;
  dateStart?: Date;
  duration?: { hours: number; minutes: number };
  recurringType?: EventRepeatType;
  location?: Location;
  hostingTeam: unknown;
  teamColor?: string;
  equipmentNeeded?: string[];
  requiredAttendees?: string[];
  makePublicAfter?: number;
  creatorApprovalNeeded?: boolean;
  allowParticipantGuests?: boolean;
  joiner: User;
  type: UpdateType;
  days?: string[];
  banner?: string;
  cancelled?: boolean;
}

export interface EventItemDeleteRequest {
  eventId: string;
  userId: string;
}

export interface EventItemDuration {
  hours: number;
  minutes: number;
}

export interface EventJoinerUpdateRequest {
  status: EventJoinerStatus;
  player: string;
  event: string;
  rsvpStatus: EventRsvpStatus;
  guests?: EventItemGuest[];
}

export interface GthEventItemListFilter {
  eventItemId?: string;
  lat?: number;
  lng?: number;
  userId?: string;
  teamId?: string;
}

export interface GthEventJoiner {
  player: string;
  status: EventJoinerStatus;
  assignedGenderSpot?: string;
  approvedBy: string[];
  createdAt: firebase.firestore.Timestamp;
  user?: unknown;
}

export interface EventItemGuest {
  type: string;
  number: number;
}

export enum EventTheme {
  Easter = 'Easter',
  Birthday = 'Birthday',
  Valentines = 'Valentines',
  Sports = 'Sports',
}

export interface EventTicketLevel {
  name: string,
  cost: number,
  priceId: string,
}

export interface EventTicketLevelFormGroup {
  name: FormControl<string>,
  cost: FormControl<number>,
  priceId: FormControl<string>,
}
