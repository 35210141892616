<h3>RSVP With Guest</h3>

<h4>How many guests are you bringing with you?</h4>

<div>
  <div class="gender-input-container" *ngFor="
                  let number of [].constructor(specificGendersArray.length);
                  let i = index
                ">
    <mat-form-field *ngIf="genders">
      <mat-select [value]="specificGendersArray[i].type" (selectionChange)="specificTypeChange(i, $event!)"
        class="gender-input">
        <mat-option *ngFor="let gender of genders" value="{{ gender.label }}">{{ gender.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="gender-input">
      <input value="{{specificGendersArray[i].number}}" (change)="specificNumberChange(i, $event.srcElement!)"
        name="gender-count-{{ i }}" matInput type="number" min="0" />
    </mat-form-field>

    <button (click)="removePlayerType(i)" mat-icon-button>
      <mat-icon>indeterminate_check_box</mat-icon>
    </button>
  </div>
  <button mat-button (click)="addSpecificGender()">
    <mat-icon color="primary">add</mat-icon>
    Add Gender
  </button>
</div>

<button mat-flat-button color="primary" (click)="onAskToJoinBtnClick()">{{buttonText}}</button>