import firebase from 'firebase/compat/app';
import {
  AbstractControl, FormArray, FormControl, FormGroup,
  UntypedFormControl,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';

import {
  EventItem,
  EventItemDuration,
  Location,
  EventRepeatType,
  GenderInfo,
  Team,
  UnregisteredTeamInfo,
  User, EventTheme, EventTicketLevel, EventTicketLevelFormGroup,
} from '@index/interfaces';
import { AgeGroup } from '@index/enums/age-group';
import { SubscriptionType } from '@index/enums/subscription-type';
import { EventItemTypes } from '@index/enums/event-item-type';
import { SkillLevel } from '@index/enums/skill-level';

import { GthEventItemModel } from './event-item';

const ERROR_TEXT =
  'Specific Gender count exceeds the number of players permitted within this event.';

/**
 * Validates the number of players input
 * based on the value of the gender fields when isGenderSpecific is true.
 * @return {ValidatorFn} Validator Function
 */
function playerCountValidator(): ValidatorFn {
  return (control: AbstractControl) => {
    const numberOfPlayersVal = control.value;
    if (numberOfPlayersVal) {
      const formGroup = control.parent;
      if (formGroup) {
        const genderSpecificCtrl = formGroup.get('isGenderSpecific');
        const gendersCtrl = formGroup.get('specificGenders');
        if (genderSpecificCtrl && gendersCtrl && genderSpecificCtrl.value) {
          const genders = gendersCtrl.value;
          if (genders && genders.length > 0) {
            let totalCount = 0;
            genders.forEach((g: { type: string; number: number }) => {
              totalCount += g.number;
            });
            if (totalCount > numberOfPlayersVal) {
              return {
                error: ERROR_TEXT,
              };
            }
          }
        }
      }
    }

    return null;
  };
}

export class GthEventItemForm {
  /**
   * Form Group
   */
  get formGroup() {
    return this._eventForm;
  }

  /**
   * Returns true if the form is disabled
   */
  get disabled() {
    return this._eventForm.disabled;
  }

  /**
   * Returns true if the form is valid
   */
  get valid() {
    return this.validLocation && this.validNumberOfPlayers;
  }

  get validNumberOfPlayers() {
    const numberOfPlayersStr = this.numberOfPlayers ? this.numberOfPlayers.toString() : '';
    const numberOfPlayers = parseInt(numberOfPlayersStr, 10);
    const isNumber = !isNaN(numberOfPlayers);
    const isValidNumberOfPlayers = numberOfPlayersStr.trim() === '' || isNumber;
    return this._eventForm.valid && isValidNumberOfPlayers;
  }

  get validLocation() {
    return this.location || this.online;
  }

  /**
   * Type of event being held
   */
  get gameType() {
    return this._gameType;
  }
  set gameType(val: string) {
    this._gameType = val;
    this.setFieldValue('gameType', val);
  }

  get isUnregisteredTeam() {
    if (!this.currentTeam) {
      return true;
    }
    if (!this.currentTeam.id) {
      return true;
    }

    return false;
  }

  /**
   * Allow Participants to bring guests
   */
  get allowParticipantGuests() {
    return this.getFieldValue('allowParticipantGuests');
  }
  set allowParticipantGuests(val: boolean) {
    this.setFieldValue('allowParticipantGuests', val);
  }

  /**
   * Banner for the Game
   */
  get banner() {
    return this.getFieldValue('banner');
  }
  set banner(val: string) {
    this.setFieldValue('banner', val);
  }

  /**
   * Title for the Game
   */
  get title() {
    return this.getFieldValue('title');
  }
  set title(val: string) {
    this.setFieldValue('title', val);
  }

  /**
   * Skill Level for the Game
   */
  get skillLevel() {
    return this.getFieldValue('skillLevel');
  }
  set skillLevel(val: SkillLevel) {
    this.setFieldValue('skillLevel', val);
  }

  /**
   * Send feedback email after the event
   */
  get sendFeedbackEmailAfter() {
    return this.getFieldValue('sendFeedbackEmailAfter');
  }
  set sendFeedbackEmailAfter(val: boolean) {
    this.setFieldValue('sendFeedbackEmailAfter', val);
  }

  /**
   * Ticket levels for event
   */
  get ticketLevels() {
    return this.formGroup.controls.ticketLevels.value as EventTicketLevel[];
  }
  set ticketLevels(val: EventTicketLevel[]) {
    const formArray = new FormArray<FormGroup<EventTicketLevelFormGroup>>([]);
    for (const ticketLevel of val) {
      formArray.push(new FormGroup<EventTicketLevelFormGroup>({
        name: new FormControl<string>(ticketLevel.name, Validators.required),
        cost: new FormControl<number>(
          ticketLevel.cost,
          [Validators.required, Validators.min(0), Validators.max(999999)],
        ),
        priceId: new FormControl<string>(ticketLevel.priceId),
      }));
    }
    this.formGroup.controls.ticketLevels = formArray;
  }

  get priceId() {
    return this.formGroup.controls.priceId.value;
  }
  set priceId(val: string) {
    this.setFieldValue('priceId', val);
  }

  /**
   * Date the Event is scheduled start
   */
  get dateStart() {
    return this.getFieldValue('dateStart');
  }
  set dateStart(val: Date | undefined) {
    const duration = this.getFieldValue('duration');

    if (!duration) {
      this.duration = {
        hours: 1,
        minutes: 0,
      };
    }

    this.setFieldValue('dateStart', val);
  }

  /**
   * Duration of the event
   */
  get duration() {
    return this.getFieldValue('duration');
  }
  set duration(val: EventItemDuration | undefined) {
    this.setFieldValue('duration', val);
  }

  /**
   * Type of recurrence for the event
   */
  get recurringType() {
    return this.getFieldValue('recurringType');
  }
  set recurringType(val: EventRepeatType | undefined) {
    this.setFieldValue('recurringType', val);
  }

  /**
   * Is the event online only
   */
  get online() {
    return this.getFieldValue('online');
  }
  set online(val: boolean) {
    this.setFieldValue('online', val);
  }

  /**
   * Location of the event
   */
  get location() {
    return this.getFieldValue('location');
  }
  set location(val: Location) {
    this.setFieldValue('location', val);
  }

  /**
   * Text description for the event
   */
  get locationDescription() {
    const location = this.location;
    if (location && location.formattedAddress) {
      return `${location.formattedAddress}`;
    }
    return 'Location not set';
  }

  /**
   * Name of the team
   */
  get teamName() {
    return this._teamName;
  }
  set teamName(val: string) {
    this._teamName = val;
    this.setFieldValue('teamName', val);
  }

  /**
   * Color of the team's jerseys
   */
  get teamColor() {
    return this.getFieldValue('teamColor');
  }
  set teamColor(val: string) {
    this.setFieldValue('teamColor', val);
  }

  get description() {
    return this.getFieldValue('description');
  }
  set description(val: string) {
    this.setFieldValue('description', val);
  }

  /**
   * Number of Players
   */
  get numberOfPlayers() {
    return this.getFieldValue('numberOfPlayers');
  }
  set numberOfPlayers(val: number) {
    this.setFieldValue('numberOfPlayers', val);
  }

  /**
   * Is the Gender of the players defined
   */
  get isGenderSpecific() {
    return this.getFieldValue('isGenderSpecific');
  }
  set isGenderSpecific(val: boolean) {
    this.setFieldValue('isGenderSpecific', val);
  }

  /**
   * Cost per Player to play
   */
  get cost() {
    return this.getFieldValue('cost');
  }
  set cost(val: number) {
    this.setFieldValue('cost', val);
  }

  /**
   * Equipment is needed to play
   */
  get equipmentNeeded() {
    return this.getFieldValue('equipmentNeeded');
  }
  set equipmentNeeded(val: string[]) {
    this.setFieldValue('equipmentNeeded', val);
  }

  /**
   * Equipment being typed in to add to equipment needed to play
   *
   * UI Only Property
   */
  get equipmentNeededItem() {
    return this.getFieldValue('equipmentNeededItem');
  }
  set equipmentNeededItem(val: string) {
    this.setFieldValue('equipmentNeededItem', val);
  }

  /**
   * Required Attendees for the event
   *
   * If populated the event is private
   */
  get requiredAttendees() {
    return this.getFieldValue('requiredAttendees');
  }
  set requiredAttendees(val: string[]) {
    this.setFieldValue('requiredAttendees', val);
  }

  /**
   * Require Attendee being typed in
   *
   * UI Only Property
   */
  get requiredAttendeeItem() {
    return this.getFieldValue('requiredAttendeeItem');
  }
  set requiredAttendeeItem(val: string) {
    this.setFieldValue('requiredAttendeeItem', val);
  }

  /**
   * Days to wait before making the event private
   */
  get makePublicAfter() {
    return this.getFieldValue('makePublicAfter');
  }
  set makePublicAfter(val: number | null) {
    this.setFieldValue('makePublicAfter', val);
  }

  /**
   * Creator Approval Needed checkbox
   */
  get creatorApprovalNeeded(): boolean {
    return this.getFieldValue('creatorApprovalNeeded') ?? false;
  }
  set creatorApprovalNeeded(val: boolean) {
    this.setFieldValue('creatorApprovalNeeded', val);
  }

  /**
   * Participant Info by Gender
   */
  get specificGenders() {
    return this.getFieldValue('specificGenders');
  }
  set specificGenders(val: { type: string; number: number }[]) {
    this.setFieldValue('specificGenders', val);
  }

  get value() {
    if (!this.currentUser) {
      return undefined;
    }

    const genderInfo: { [key in string]?: GenderInfo } = {};
    const value = this._eventForm.getRawValue();
    const numberOfPlayers = this.numberOfPlayers;

    if (value.specificGenders) {
      let totalParticipantsNeeded = 0;
      let hasAny = false;
      value.specificGenders.forEach((gender: { type: string; number: number }) => {
        if (gender.type === 'Any') {
          hasAny = true;
        }
        if (!genderInfo[gender.type]) {
          genderInfo[gender.type] = {
            numberOfPlayersNeeded: 0,
            participants: [],
          };
        }
        genderInfo[gender.type]!.numberOfPlayersNeeded += gender.number;

        totalParticipantsNeeded += gender.number;
      });

      if (!hasAny && numberOfPlayers && numberOfPlayers > totalParticipantsNeeded) {
        genderInfo['Any'] = {
          numberOfPlayersNeeded: numberOfPlayers - totalParticipantsNeeded,
        };
      }
    } else if (typeof numberOfPlayers === 'number') {
      genderInfo['Any'] = {
        numberOfPlayersNeeded: numberOfPlayers,
      };
    }

    const hostingTeam =
      this.currentTeam && this.currentTeam.id ?
        this.currentTeam.id :
        {
          id: null,
          photoURL: '',
          name: this.teamName,
          gameType: this.gameType,
          discoverable: false,
          subscription: SubscriptionType.ROOKIE,
          ageGroup: AgeGroup.ADULT,
          location: { lat: 0, lng: 0 },
          website: null,
          displayPublicWebsite: false,
        };

    const event: EventItem = {
      creator: this.currentUser.uid,
      title: value.title,
      dateStart: value.dateStart,
      duration: value.duration,
      recurringType: value.recurringType,
      online: value.online,
      location: value.location,
      teamColor: value.teamColor,
      hostingTeam,
      cost: value.cost,
      equipmentNeeded: value.equipmentNeeded,
      requiredAttendees: value.requiredAttendees,
      makePublicAfter: value.makePublicAfter,
      creatorApprovalNeeded: value.creatorApprovalNeeded ?? false,
      genderInfo,
      allowParticipantGuests: value.allowParticipantGuests,
      days: value.days,
      banner: value.banner,
      updated: firebase.firestore.Timestamp.now(),
      rated: false,
      description: value.description,
      gameType: value.gameType,
      skillLevel: value.skillLevel,
      backgroundColor: value.backgroundColor,
      theme: value.theme,
      sendFeedbackEmailAfter: value.sendFeedbackEmailAfter,
      ticketLevels: value.ticketLevels,
      priceId: value.priceId,
    };

    const eventItem: GthEventItemModel = new GthEventItemModel('', event);
    return eventItem;
  }

  get firstStepValid() {
    if (!this._eventForm.get('title').valid) {
      return false;
    }
    if (!this._eventForm.get('gameType').valid) {
      return false;
    }
    if (!this._eventForm.get('duration').valid) {
      return false;
    }
    if (!this._eventForm.get('dateStart').valid) {
      return false;
    }
    const hasLocation = this.location && this.location.lat && this.location.lng;
    if (!this.online && !hasLocation) {
      return false;
    }
    return true;
  }

  get dirty() {
    return this._eventForm.dirty;
  }

  get eventType() {
    return this._eventForm.get('eventType').value;
  }
  set eventType(val: EventItemTypes) {
    this._eventForm.get('eventType').setValue(val);
  }

  get error() {
    if (this.valid) {
      return '';
    }
    if (!this.validNumberOfPlayers) {
      return 'Invalid number of players';
    }
    return 'Missing Location for event';
  }

  private _eventForm = new FormGroup({
    id: new UntypedFormControl(''),
    title: new UntypedFormControl('', [Validators.required, Validators.maxLength(150)]),
    dateStart: new UntypedFormControl(null, Validators.required),
    duration: new UntypedFormControl(null, Validators.required),
    recurringType: new UntypedFormControl(EventRepeatType.None),
    location: new UntypedFormControl(null, Validators.required),
    gameType: new UntypedFormControl(null, Validators.required),
    teamName: new UntypedFormControl('', Validators.maxLength(100)),
    teamColor: new UntypedFormControl('#4dba79'),
    numberOfPlayers: new FormControl<number>(
      0,
      [Validators.min(0), Validators.max(100), playerCountValidator()],
    ),
    isGenderSpecific: new UntypedFormControl(false),
    specificGenders: new UntypedFormControl([]),
    cost: new FormControl<number>(null, [Validators.min(0), Validators.max(999999)]),
    equipmentNeeded: new UntypedFormControl([]),
    equipmentNeededItem: new UntypedFormControl(''),
    requiredAttendees: new UntypedFormControl([]),
    requiredAttendeeItem: new UntypedFormControl(''),
    makePublicAfter: new UntypedFormControl(0),
    creatorApprovalNeeded: new UntypedFormControl(false),
    allowParticipantGuests: new UntypedFormControl(false),
    banner: new UntypedFormControl(null),
    description: new UntypedFormControl(null),
    online: new UntypedFormControl(false),
    eventType: new UntypedFormControl(EventItemTypes.Pickup),
    skillLevel: new UntypedFormControl(SkillLevel.Any),
    days: new FormControl(null),
    theme: new FormControl<EventTheme>(null),
    backgroundColor: new FormControl<string>(null),
    sendFeedbackEmailAfter: new FormControl<boolean>(false),
    ticketLevels: new FormArray<FormGroup<EventTicketLevelFormGroup>>([]),
    priceId: new FormControl<string>(null),
  });

  private currentUser?: User;
  private currentTeam?: Team | UnregisteredTeamInfo;
  private _gameType = '';
  private _teamName = '';

  setUser(user: User | undefined) {
    this.currentUser = user;
  }

  setTeam(team: Team | UnregisteredTeamInfo | undefined) {
    this.currentTeam = team;

    if ((team as Team).sport) {
      this.gameType = (team as Team).sport;
      return;
    }

    if ((team as UnregisteredTeamInfo).gameType) {
      this.gameType = (team as UnregisteredTeamInfo).gameType;
      return;
    }
  }

  setEvent(val: GthEventItemModel | undefined) {
    if (!val) {
      this._eventForm.reset();
    } else {
      this.title = val.title;
      this.dateStart = val.dateStart;
      this.duration = val.duration;
      this.recurringType = val.recurringType;
      this.location = val.location;
      this.gameType = val.gameType;
      this.teamName = val.teamName;
      this.eventType = val.eventType;
      this.teamColor = val.teamColor;
      this.isGenderSpecific = val.isGenderSpecific;
      this.cost = val.cost ? val.cost : 0;
      this.equipmentNeeded = val.equipmentNeeded;
      this.online = val.online ?? false;
      this.requiredAttendees = val.requiredAttendees;
      this.makePublicAfter = val.makePublicAfter;
      this.allowParticipantGuests = val.allowParticipantGuests;
      this.description = val.description;
      this.banner = val.banner;
      this.skillLevel = val.skillLevel;
      this.sendFeedbackEmailAfter = val.sendFeedbackEmailAfter;
      this.ticketLevels = val.ticketLevels;
      this.priceId = val.priceId;

      let numberOfPlayers = 0;
      // eslint-disable-next-line guard-for-in
      for (const key in val.genderInfo) {
        const gender = val.genderInfo[key];
        numberOfPlayers += gender.numberOfPlayersNeeded;
      }
      this.numberOfPlayers = numberOfPlayers;
    }
  }

  clearLocation() {
    this.location = { lat: 0, lng: 0, formattedAddress: '' };
  }

  enable() {
    this._eventForm.enable();
  }

  disable() {
    this._eventForm.disable();
  }

  disableLocationCtrl() {
    const locationCtrl = this._eventForm.get('location');
    if (locationCtrl) {
      locationCtrl.disable();
    }
  }

  disableNumberOfPlayers() {
    const ctrl = this._eventForm.get('numberOfPlayers');
    if (!ctrl) {
      return;
    }
    ctrl.disable();
    ctrl.setValue(0);
  }

  enableNumberOfPlayers() {
    const ctrl = this._eventForm.get('numberOfPlayers');
    if (!ctrl) {
      return;
    }
    ctrl.enable();
    ctrl.setValue(0);
  }

  addSpecificGender() {
    if (!this.specificGenders) {
      this.specificGenders = [];
    }
    this.specificGenders.push({ type: 'Female', number: 0 });
  }

  /**
   * Adds equipment item to needed equipment list
   * @param {MatChipInputEvent} evt Chip Event
   */
  addEquipmentItem(evt: MatChipInputEvent) {
    const list = this.addItemToList(evt, this.equipmentNeeded, 'equipmentNeededItem');
    this.equipmentNeeded = list;
  }

  /**
   * Removes equipment item from needed equipment list
   * @param {string} equipment equipment item
   */
  removeEquipmentItem(equipment: string) {
    const list = this.removeItemFromList(this.equipmentNeeded, equipment);
    this.equipmentNeeded = list;
  }

  /**
   * Adds required attendee item to required attendee list
   * @param {MatChipInputEvent} evt Chip Event
   */
  addRequiredAttendeeItem(evt: MatChipInputEvent) {
    const list = this.addItemToList(evt, this.requiredAttendees, 'requiredAttendeeItem');
    this.requiredAttendees = list;
  }

  /**
   * Removes required attendee item from required attendee list
   * @param {string} attendee required attendee item
   */
  removeRequiredAttendeeItem(attendee: string) {
    const list = this.removeItemFromList(this.requiredAttendees, attendee);
    this.requiredAttendees = list;
  }

  isGenderSpecificChanged() {
    const ctrl = this._eventForm.get('numberOfPlayers');
    if (ctrl) {
      ctrl.updateValueAndValidity();
    }
  }

  private getFieldValue(fieldName: string) {
    const ctrl = this._eventForm.get(fieldName);
    return ctrl ? ctrl.value : undefined;
  }

  private setFieldValue(fieldName: string, val: unknown) {
    const ctrl = this._eventForm.get(fieldName);
    if (ctrl) {
      ctrl.setValue(val);
    }
  }

  private addItemToList(evt: MatChipInputEvent, listItems: string[], formCtrlName: string) {
    if (!listItems) {
      listItems = [];
    }
    const value = (evt.value || '').trim();
    listItems.push(value.trim());
    const inputCtrl = this.formGroup.get(formCtrlName);
    if (inputCtrl) {
      inputCtrl.setValue('');
    }
    return listItems;
  }

  private removeItemFromList(listItems: string[], value: string) {
    if (!listItems) {
      return [];
    }
    const index = listItems.indexOf(value);

    if (index >= 0) {
      listItems.splice(index, 1);
    }
    return listItems;
  }
}
