import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { from, Observable } from 'rxjs';

import { GetEventPageViewContract, UpdateEventPageViewContract } from '@index/daos/event-item-dao';
import { EventItem, CallableRoutes, Payload, Results, GthEventItemListFilter } from '@index/interfaces';
import { CallableOptions, GthCallableService } from './callable.service';
import { GthErrorLoggerService } from './error-logger.service';
import { GthEventItemModel } from '@sentinels/models';

const CONTEXT = 'GthEventFunctionService';

@Injectable({
  providedIn: 'root',
})
export class GthEventFunctionService {
  constructor(
    public functions: AngularFireFunctions,
    public callableService: GthCallableService,
    private logger: GthErrorLoggerService,
  ) { }

  /**
   * Creates a Game
   * @param {GthEventItemModel} model
   * @return {GthEventItemModel} New Game
   */
  create$(model: GthEventItemModel): Observable<GthEventItemModel | null> {
    return from(this.create(model));
  }

  /**
  * Updates the event
  * @param {string} id
  * @param {GthEventItemModel} event
  * @return {boolean} True if success
  */
  update$(id: string, event: GthEventItemModel): Observable<boolean> {
    return from(this.update(id, event));
  }

  /**
    * Deletes the event
    * @param {string} id
    * @param {GthEventItemModel} event
    * @return {boolean} True if success
    */
  delete$(id: string, event: GthEventItemModel): Observable<boolean> {
    return from(this.delete(id, event));
  }

  /**
    * Deletes the event
    * @param {string} id
    * @param {GthEventItemModel} event
    * @return {boolean} True if success
    */
  async delete(id: string, event: GthEventItemModel): Promise<boolean> {
    if (!this.functions) return Promise.resolve(false);

    const options: CallableOptions = {
      route: CallableRoutes.GAMES_DELETE,
      data: { ...event.copy, eventId: id },
    };
    const response = (await this.callableService.call(options)) as Results<EventItem>;
    this.log(`Deleted event ${id}`);

    return response.success;
  }

  /**
   * Gets list of Games based on the filter
   * @param {GthEventItemListFilter} filter
   * @return {GthEventItemModel[]}
   */
  list$(filter: GthEventItemListFilter): Observable<GthEventItemModel[]> {
    return from(this.list(filter));
  }

  updatePageView$(event: GthEventItemModel): Observable<boolean> {
    return from(this.updatePageView(event));
  }

  getPageViews$(event: GthEventItemModel, date?: Date): Observable<number> {
    return from(this.getPageViews(event, date));
  }

  /**
   * Creates a Game
   * @param {GthEventItemModel} model
   * @return {GthEventItemModel} New Game
   */
  private async create(model: GthEventItemModel): Promise<GthEventItemModel | null> {
    if (!this.functions) return Promise.resolve(null);

    const event = model.copy;

    const options: CallableOptions = {
      route: CallableRoutes.GAMES_CREATE,
      data: event,
    };

    const response = (await this.callableService.call(options)) as Results<EventItem>;
    if (response.success && response.payload) {
      const result = response.payload as Payload<EventItem>;
      this.log(`Created new Event`);
      return new GthEventItemModel(result.id || '', result.data || {} as unknown as EventItem);
    }

    return null;
  }

  /**
  * Updates the event
  * @param {string} id
  * @param {GthEventItemModel} event
  * @return {boolean} True if success
  */
  private async update(id: string, event: GthEventItemModel): Promise<boolean> {
    if (!this.functions) return Promise.resolve(false);

    const options: CallableOptions = {
      route: CallableRoutes.GAMES_UPDATE,
      data: { ...event.copy, id },
    };
    const response = (await this.callableService.call(options)) as Results<EventItem>;
    this.log(`Updated event ${id}`);
    return response.success;
  }

  /**
   * Gets list of Games based on the filter
   * @param {GthEventItemListFilter} filter
   * @return {GthEventItemModel[]}
   */
  private async list(filter: GthEventItemListFilter): Promise<GthEventItemModel[]> {
    const options: CallableOptions = {
      route: CallableRoutes.GAMES_LIST,
      data: filter,
    };

    const response = (await this.callableService.call(options)) as Results<EventItem>;
    if (response && response.success && response.payload) {
      const results = response.payload as Payload<EventItem>[];
      return results.map((d) => new GthEventItemModel(d.id, d.data));
    }

    return [];
  }

  private async updatePageView(event: GthEventItemModel): Promise<boolean> {
    if (!this.functions) return Promise.resolve(false);

    const contract: UpdateEventPageViewContract = {
      event: event.model,
    };
    const options: CallableOptions = {
      route: CallableRoutes.GAMES_UPDATE_PAGE_VIEW,
      data: contract,
    };
    const response = (await this.callableService.call(options)) as Results<unknown>;
    this.log(`Logged page view for event: ${event.id}`);
    return response.success;
  }

  private async getPageViews(event: GthEventItemModel, date?: Date) {
    if (!this.functions) return Promise.resolve(0);

    const contract: GetEventPageViewContract = {
      event: event.model,
      date,
    };
    const options: CallableOptions = {
      route: CallableRoutes.GAMES_GET_PAGE_VIEWS,
      data: contract,
    };
    const response = (await this.callableService.call(options)) as Results<number>;
    if (response && response.success && response.payload) {
      const payload = response.payload as Payload<number>;
      const pageViews = payload.data;
      this.log(`Retrieved ${pageViews} page views for event: ${event.id}`);
      return pageViews;
    }

    return 0;
  }

  private log(text: string) {
    this.logger.debug(`${CONTEXT}: ${text}`);
  }
}
