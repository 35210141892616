<div class="label">
  <h3>Themes</h3>

  <button mat-icon-button type="button" matTooltip="Select theme for event">
    <mat-icon fontIcon="info" />
  </button>
</div>

<div class="themes" *ngIf="themesFormControl">
  <div *ngFor="let theme of themes" class="theme" (click)="onThemeSelect(theme.label)"
       [ngClass]="{'active': themesFormControl.value === theme.label}">
    <span class="text">{{theme.label}}</span>
    <picture>
      <img class="theme-image" [alt]="theme.label" [src]="theme.image" (error)="onThemeImageError($event)" />
    </picture>
  </div>
</div>