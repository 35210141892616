<h3>COOKIE POLICY</h3>
<br>
<h4>Welcome to Gametime Hero!</h4>
<p>Gametime Hero is owned and operated by Gametime Hero, LLC.
</p>
<p>This cookie policy explains how and why cookies and other similar technologies may be stored on and accessed from your device when you use or visit:
</p>
    <ul>
        <li><a target="_blank" href="https://gametimehero.com">https://gametimehero.com</a></li>
    </ul>
<p>(Hereinafter referred to as “Gametime Hero”).
</p>

<p>The information collected through cookies will be under the responsibility and in charge of:
</p>
<ul>
    <li><strong>Gametime Hero, LLC.</strong></li>  
    <li><strong>Brittany&#64;GametimeHero.com</strong></li> 
</ul>
<p>This cookie policy should be read together with our privacy policy and our terms and conditions.
</p>
<p>By using the website, you accept the use of cookies by Gametime Hero, in the terms contained in this policy.
</p>
        
<h4>1. WHAT ARE COOKIES?</h4>
<p>Cookies are small text files that are stored on your computer or mobile device when you visit a website. They allow the website to recognize your device and remember if you have been to the website before. Cookies are a very common web technology; most websites use cookies and have done so for years. Cookies are widely used to make the website work more efficiently. Cookies are used to measure which parts of the website users visit and to personalize their experience. Cookies also provide information that helps us monitor and improve the performance of the website.
</p>

<h4>2. REFUSING OR WITHDRAWING CONSENT TO THE USE OF COOKIES </h4>
<p>If you do not want cookies to be dropped on your device, you can adjust the setting of your Internet browser to reject the setting of all or some cookies and to alert you when a cookie is placed on your device. For further information about how to do so, please refer to your browser ‘help’, ‘tool’, or ‘edit’ section. Please note that if you use your browser settings to block all cookies, including strictly necessary cookies, you may not be able to access or use all or parts of the functionalities of the website.
</p>
<p>If you want to remove previously-stored cookies, you can manually delete the cookies at any time. However, this will not prevent the website from placing further cookies on your device unless and until you adjust your Internet browser setting as described above.
</p>
<p>We provide the links for the management and blocking of cookies depending on the browser you use:
</p>
<ul>
    <li><strong>Microsoft Edge: </strong><a target="_blank" href="https://support.microsoft.com/en-us/office/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09?ui=en-us&rs=en-us&ad=us">https://support.microsoft.com/en-us/office/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09?ui=en-us&rs=en-us&ad=us</a></li>
    <li><strong>Firefox: </strong><a target="_blank" href="https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox">https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox</a></li>
    <li><strong>Chrome: </strong><a target="_blank" href="https://support.google.com/chrome/answer/95647?hl=en">https://support.google.com/chrome/answer/95647?hl=en</a></li>
    <li><strong>Safari: </strong><a target="_blank" href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac">https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac</a></li>
</ul>

<p>In cases where you access the website through an iOS or Android mobile device, please follow the instructions below to delete or block cookies on your device:
</p>
<ul>
    <li><strong>Android: </strong><a target="_blank" href="https://support.google.com/answer/32050">https://support.google.com/answer/32050</a></li>
    <li><strong>iOS: </strong><a target="_blank" href="https://support.apple.com/en-us/HT201265">https://support.apple.com/en-us/HT201265</a></li>
</ul>

<h4>3. FIRST-PARTY COOKIES </h4>
<p>We use cookies to enhance the performance of our website and personalize your online experience. Cookies help us to collect information on how people use our website and which pages they visit. They enable us to monitor the number of visitors and to analyze website usage patterns and trends. We collect this information anonymously, so it does not identify anyone as an individual and no personal information is stored in our cookies. We always use cookie data in a responsible way.
</p>

<h4>4. THIRD-PARTY COOKIES</h4>
<p>Third-party cookies may come from partners or third-party companies that provide functional web services or tools for our website and the optimal functioning and operation of our services. We use third party cookies responsibly and for the sole purpose of providing optimal functioning of the platform and services. You may opt out of these cookies by following the cookie removal information contained in this document or the technical information of the browser from which you access our website and services.
</p>

<h4>5. SESSION COOKIES</h4>
<p>Session cookies are used to log in to the website with your respective credentials and password. Session cookies are also used to keep users logged in. Session cookies are temporary and are deleted from the device or browser when the session is closed and the browser is closed. We use session cookies to keep the session open when using our services and to identify you on our system each time you log on to the website.
</p>

<h4>6. SOCIAL COOKIES</h4>
<p>These cookies allow you to interact with social networks through certain actions such as the "Like" button. They also allow you to interact with the contents of each social network and share website content on social networks. The way these cookies are used and the information collected is governed by the privacy policy of each social network.
</p>

<h4>7. COOKIES WE USE</h4>
<p>We use the following cookies on our website:
</p>

<table>
    <tr>
        <th>Cookie</th>
        <th>Domain</th>
        <th>Description</th>
        <th>Duration</th>
        <th>Type</th>
    </tr>
    <tr>
        <td>_ga_SMNQ6HCHZP</td>
        <td>.gametimehero-coming-soon.web.app</td>
        <td>This cookie is installed by Google Analytics.</td>
        <td>2 years</td>
        <td>Analytics</td>
    </tr>
    <tr>
        <td>_ga</td>
        <td>.gametimehero-coming-soon.web.app</td>
        <td>The _ga cookie, installed by Google Analytics, calculates visitor, session and campaign data and also keeps track of site usage for the site's analytics report. The cookie stores information anonymously and assigns a randomly generated number to recognize unique visitors.</td>
        <td>2 years</td>
        <td>Analytics</td>
    </tr>
</table>

<h4>8. PURPOSES OF OUR COOKIES</h4>
<p>Our cookies are used for the following purposes:
</p>
<p><strong>Strictly Necessary:</strong> These cookies are essential for the website to perform its basic functions.
</p>
<p><strong>Security:</strong> We use these cookies to help identify and prevent potential security risks.
</p>
<p><strong>Analytics and Performance:</strong> Performance cookies collect information on how users interact with our website, including what pages are visited most, as well as other analytical data. We use these details to improve how our website functions and to understand how users interact with them.
</p>
<p><strong>GOOGLE Analytics.</strong> We use Google Analytics provided by Google, Inc., USA (“Google”). These tool and technologies collect and analyze certain types of information, including IP addresses, device and software identifiers, referring and exit URLs, feature use metrics and statistics, usage and purchase history, media access control address (MAC Address), mobile unique device identifiers, and other similar information via the use of cookies. The information generated by Google Analytics (including your IP address) may be transmitted to and stored by Google on servers in the United States. We use the GOOGLE Analytics collection of data to enhance the website and platform and improve our service.
</p>

<h4>9. CONTACT US</h4>
<p>If you have questions or concerns about this cookie policy and the handling and security of your data, please contact us through our contact form or via the contact information below:
</p>
<p><strong>Gametime Hero, LLC. </strong> </p>
<p><strong> Brittany&#64;GametimeHero.com</strong> </p>
<br>
