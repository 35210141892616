import { Injectable } from '@angular/core';

import { Notification } from '@index/interfaces';
import { NotificationType } from '@index/enums';

import { GthModel } from './model';
import { Adapter } from './adapter';
import { GthUserModel } from './user';
import { GthEventItemModel } from './event-item';
import { GthConversationModel } from './conversation';
import { GthTeamModel } from './team';
import { GthBadgeModel } from './badge';

class TextBuilder {
  private text = '';
  private subtext = '';

  constructor(metaData: { [key: string]: any }, nType: NotificationType) {
    const eventTitle = metaData?.event?.title ?? '';
    const teamName = metaData?.team?.name ?? '';
    const joinerName = metaData?.joiner?.displayName ?? '';
    const isCreator = metaData?.recipient === 'creator';
    switch (nType) {
      /** Badge Notifications */
      case NotificationType.NEW_BADGE:
        this.text = `New badge earned!`;
        this.subtext = `You're crushing it!`;
        break;
      /** Team Notifications */
      case NotificationType.TEAM_REMINDER:
        // eslint-disable-next-line max-len
        this.text = `${teamName} reminder to join the event named ${eventTitle}`;
        this.subtext = `See event details...`;
        break;
      case NotificationType.TEAM_JOINER_PENDING_JOINER:
        this.text = `${teamName} wants you to join them`;
        this.subtext = 'See team details...';
        break;
      case NotificationType.TEAM_JOINER_PENDING_CREATOR:
        // eslint-disable-next-line max-len
        this.text = `${joinerName} wants to join your team name ${teamName}`;
        this.subtext = 'See team details...';
        break;
      case NotificationType.TEAM_JOINER_APPROVED:
        this.text = isCreator ?
          // eslint-disable-next-line max-len
          `${joinerName} has accepted your request to join your team named ${teamName}` :
          `${teamName} has accepted your request to join`;
        this.subtext = 'See team details...';
        break;
      case NotificationType.TEAM_JOINER_DENIED:
        this.text = isCreator ?
          // eslint-disable-next-line max-len
          `${joinerName} has denied your request to join your team named ${teamName}` :
          `${teamName} has denied your request to join`;
        this.subtext = 'See team details...';
        break;
      case NotificationType.TEAM_JOINER_DROPPED:
        this.text = isCreator ?
          // eslint-disable-next-line max-len
          `${joinerName} has dropped from your team named ${teamName}` :
          `You have been dropped from the team named ${teamName}`;
        this.subtext = 'See team details...';
        break;
      /** Message Notifications */
      case NotificationType.NEW_MESSAGE:
        // eslint-disable-next-line max-len
        this.text = `${metaData?.user?.displayName} posted a new message to ${teamName} message board: ` +
          metaData.conversation?.getLatestMessage().text;
        this.subtext = 'See message details...';
        break;
      /** Event Notifications */
      case NotificationType.EVENT_JOINER_APPROVED:
        this.text = isCreator ?
          // eslint-disable-next-line max-len
          `${joinerName} has joined your event named ${eventTitle}` :
          `Your request to join the event named ${eventTitle} has been approved`;
        this.subtext = 'See event details...';
        break;
      case NotificationType.EVENT_JOINER_DENIED:
        this.text = isCreator ?
          // eslint-disable-next-line max-len
          `${joinerName} was denied to join your event named ${eventTitle}` :
          `Your request to join the event named ${eventTitle} has been denied`;
        this.subtext = 'See event details...';
        break;
      case NotificationType.EVENT_JOINER_PENDING_JOINER:
        // TODO: This is not being set properly
        this.text = `Player wants you to join them`;
        this.subtext = 'See event details...';
        break;
      case NotificationType.EVENT_JOINER_DROPPED:
        this.text = isCreator ?
          // eslint-disable-next-line max-len
          `${joinerName} has dropped from your event named ${eventTitle}` :
          `You have been dropped from the event named ${eventTitle}`;
        this.subtext = 'See event details...';
        break;
      case NotificationType.EVENT_JOINER_PENDING_CREATOR:
      case NotificationType.EVENT_JOINER_WAITLIST:
        this.text = isCreator ?
          // eslint-disable-next-line max-len
          `${joinerName} wants to join your event named ${eventTitle}` :
          // eslint-disable-next-line max-len
          `Your request to join the event named ${eventTitle} is awaiting approval`;
        this.subtext = 'See event details...';
        break;
      case NotificationType.EVENT_CANCELLED:
        this.text = `The event name ${eventTitle} has been cancelled`;
        this.subtext = 'See event details...';
        break;
      case NotificationType.EVENT_UPCOMING:
        const timeDiffInMs = metaData?.event?.dateStart?.getTime() - new Date()?.getTime();
        const timeDiffInHours = Math.floor(timeDiffInMs / (1000 * 60 * 60));
        this.text = `${eventTitle} is coming up in ${timeDiffInHours} hours.`;
        this.subtext = 'See event details...';
        break;
      case NotificationType.EVENT_FULL:
        this.text = `The event name ${eventTitle} is full`;
        this.subtext = 'See event details...';
        break;
      /** Rating Notifications */
      case NotificationType.RATE_PLAYER:
        this.text = `Rate the event named ${eventTitle}`;
        this.subtext = 'Submit feedback';
        break;
      default: break;
    }
  }

  getText() {
    return this.text;
  }
  getSubtext() {
    return this.subtext;
  }
}

export class GthNotificationModel extends GthModel<Notification> {
  constructor(
    id: string,
    model: Notification,
    private textBuilder = new TextBuilder(model.metadata, model.type)) {
    super(id, model);
    this._eventModel = model.metadata.event as GthEventItemModel;
    this._joinerModel = model.metadata.joiner as GthUserModel;
    this._userModel = model.metadata.user as GthUserModel;
    this._conversationModel = model.metadata.conversation as GthConversationModel;
    this._teamModel = model.metadata.team as GthTeamModel;
    this._badgeModel = model.metadata.badge as GthBadgeModel;
  }

  get recipient(): 'joiner' | 'creator' {
    return this.model.metadata.recipient;
  }

  get type() {
    return this.model.type;
  }

  get text(): string {
    return this.textBuilder.getText();
  }

  get subtext(): string {
    return this.textBuilder.getSubtext();
  }

  get eventModel(): GthEventItemModel | undefined {
    return this._eventModel;
  }

  get joiner() {
    return this.joinerModel ? this.joinerModel.uid : null;
  }

  get user() {
    return this.userModel ? this.userModel.uid : null;
  }

  get conversation() {
    return this.conversationModel ? this.conversationModel.id : null;
  }

  get team() {
    return this.teamModel ? this.teamModel.id : null;
  }

  get badge() {
    return this.badgeModel ? this.badgeModel.id : null;
  }

  get eventItem() {
    return this.eventModel ? this.eventModel.id : null;
  }

  get joinerModel(): GthUserModel | undefined {
    return this._joinerModel;
  }

  get userModel(): GthUserModel | undefined {
    return this._userModel;
  }

  get conversationModel(): GthConversationModel | undefined {
    return this._conversationModel;
  }

  get teamModel(): GthTeamModel | undefined {
    return this._teamModel;
  }

  get badgeModel(): GthBadgeModel | undefined {
    return this._badgeModel;
  }

  get read(): boolean {
    return this.model.read;
  }

  get id(): string {
    return this._id;
  }

  private _eventModel?: GthEventItemModel;
  private _joinerModel?: GthUserModel;
  private _userModel?: GthUserModel;
  private _conversationModel?: GthConversationModel;
  private _teamModel?: GthTeamModel;
  private _badgeModel?: GthBadgeModel;

  setEvent(event: GthEventItemModel) {
    this._eventModel = event;
  }

  setJoiner(joiner: GthUserModel) {
    this._joinerModel = joiner;
  }

  markAsRead() {
    this.model.read = true;
  }
}

@Injectable({
  providedIn: 'root',
})
export class NotificationAdapter implements Adapter<Notification> {
  adapt(item: Notification): GthNotificationModel {
    return new GthNotificationModel(item.id, item);
  }
}
