import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import firebase from 'firebase/compat/app';
import { Observable } from 'rxjs';

import { ConversationMapper } from '../mappers/conversation-mapper';
import { DBUtil } from '../utils/db-utils';
import { User } from '../interfaces/user';
import {
  MessageCreateRequest,
  MessageUpdateRequest,
  Message,
  Conversation,
} from '../interfaces/conversation';

@Injectable({
  providedIn: 'root',
})
export class ConversationDAOService {
  database = this.afdb.database;
  mapper = new ConversationMapper();

  constructor(readonly afdb: AngularFireDatabase) { }

  generateConversation(ref: firebase.database.Reference, id: string | null) {
    const createKey = id ?? ref.push().key;
    return ref.child(createKey);
  }

  async create(request: MessageCreateRequest) {
    let conversationId;

    await Promise.all(
      request.participants.map(async (participant: User) => {
        const ref = this.database.ref(DBUtil.Conversation + `/${participant.uid}`);
        const conversation = this.generateConversation(
          ref,
          conversationId ?? request.conversationId,
        );
        conversationId = conversation.key;
        await this.createInternal(request, participant, conversation).catch((e) => {
          console.error('Error creating conversation', e);
        });
      }),
    );

    return true;
  }

  async createInternal(request: MessageCreateRequest, user: User, conversation) {
    if (!(await conversation.get()).exists()) {
      conversation.set({
        participants: request.participants,
        muted: false,
        blocked: false,
      });
    }

    const message = request.message;
    message.isRead = request.senderId === user.uid;
    if (message.messageSent instanceof Date) {
      message.messageSent = message.messageSent.toJSON() as any;
    }
    await conversation.child('messages').push().set(message);

    return true;
  }

  async update(request: MessageUpdateRequest) {
    const conversation = request.conversationId;
    const user = request.userId;
    const path = DBUtil.Conversation + `/${user}`;

    const userRef = this.database.ref(path);
    const childRef = userRef.child(conversation);

    const updateObj: { [key: string]: boolean } = {};

    if (request.muted !== undefined) {
      updateObj['muted'] = request.muted;
    }

    if (request.blocked !== undefined) {
      updateObj['blocked'] = request.blocked;
    }

    if (request.reported !== undefined) {
      updateObj['reported'] = request.reported;
    }

    if (request.read !== undefined) {
      // TODO: This will need to be fixed when pagination is added.
      const messageVal = (await childRef.child('messages').get()).val();
      Object.entries(messageVal).forEach((keyval) => {
        const message = keyval[1] as Message;
        message.isRead = request.read!;
      });
      childRef.child('messages').update(messageVal);
    }

    const value = (await childRef.get()).val();
    await childRef.set({
      ...value,
      ...updateObj,
    });

    return true;
  }

  async get(data: { conversationId: string, userId: string }) {
    return new Observable<Conversation>((observer) => {
      const conversationPath = `${DBUtil.Conversation}/${data.userId}/${data.conversationId}/`;
      this.afdb.database.ref(conversationPath).on(
        'value',
        (snap) => {
          const conversation = {
            id: data.conversationId,
            ...snap.val(),
          };
          observer.next(conversation);
        },
      );
    });
  }
}
