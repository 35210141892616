<ng-container *ngIf="games">
  <table
    class="table"
    mat-table
    *ngIf="games.length > 0; else noGamesTmpl"
    [dataSource]="games"
  >
    <!-- Location Column -->
    <ng-container matColumnDef="location">
      <th mat-header-cell *matHeaderCellDef>Location</th>
      <td mat-cell *matCellDef="let element">
        {{ element.location.formattedAddress }}
      </td>
    </ng-container>

    <!-- Players Column -->
    <ng-container matColumnDef="players">
      <th mat-header-cell *matHeaderCellDef>Players</th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="getPlayerCount(element) as count">
          {{ count.included }} of {{ count.total }}
          <ng-container *ngIf="count.included < count.total">
            <button mat-button color="primary" (click)="onFindSubButtonClick(element)">
              Find Sub
            </button>
          </ng-container>
        </ng-container>
      </td>
    </ng-container>

    <!-- Your RSVP Column -->
    <ng-container matColumnDef="rsvp">
      <th mat-header-cell *matHeaderCellDef>Your RSVP</th>
      <td mat-cell *matCellDef="let element">
        <mat-checkbox
          [checked]="getHasRsvp(element)"
          aria-label="Has Rsvp"
          (change)="onRsvpChange(element, $event.checked)"
        ></mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef class="actions-column"></th>
      <td mat-cell *matCellDef="let element" align="right">
        <button
          class="button"
          mat-button
          color="primary"
          *ngIf="gameHasWaitlist(element)"
          (click)="onManageWaitlistButtonClick(element)"
        >
          Manage Waitlist
        </button>
        <button
          class="button"
          mat-flat-button
          color="primary"
          (click)="onViewButtonClick(element)"
        >
          View
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns" sticky="true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</ng-container>

<ng-template #noGamesTmpl>
  <div class="table--empty fx-fill">
    <p>No events scheduled at this time</p>
  </div>
</ng-template>
