import { Injectable } from '@angular/core';
import { EventItem } from '@index/interfaces';
import { GthEventItemModel } from '@sentinels/models';
import { SrvSafeStorageService } from '@sentinels/services/safe-storage.service';

const EVENT_STORE = 'EVENT-STORE';

@Injectable({ providedIn: 'root' })
export class EventStoreService {
  constructor(
    private safeStorage: SrvSafeStorageService,
  ) { }

  store(event: GthEventItemModel) {
    this.safeStorage.setItem(EVENT_STORE, JSON.stringify(event.copy));
  }

  delete() {
    this.safeStorage.removeItem(EVENT_STORE);
  }

  read() {
    const store = this.safeStorage.getItem(EVENT_STORE);
    if (!store) {
      return undefined;
    }
    try {
      const eventItem = JSON.parse(store) as EventItem;
      const eventModel = new GthEventItemModel('', eventItem);
      return eventModel;
    } catch {
      return undefined;
    }
  }
}
