<script async
      src="https://js.stripe.com/v3/pricing-table.js">
</script>
@if (loading) {
  <div class="fx-auto fx-fill fx-centered">
    <mat-spinner [diameter]="36" />
  </div>
} @else {
  <stripe-pricing-table [attr.pricing-table-id]="id"
                        [attr.publishable-key]="publishableKey"
                        [attr.customer-email]="customerEmail"
                        [attr.client-reference-id]="platform">
  </stripe-pricing-table>
}
