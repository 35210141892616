import { Component } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'gth-confirm-update-dialog',
  templateUrl: './confirm-update-dialog.component.html',
  styleUrls: ['./confirm-update-dialog.component.scss'],
})
export class ConfirmUpdateDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmUpdateDialogComponent>,
  ) {}

  onCancelButtonClick() {
    this.dialogRef.close(false);
  }

  onContinueButtonClick() {
      this.dialogRef.close(true);
  }
}
