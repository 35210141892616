import { AngularFirestore } from '@angular/fire/compat/firestore';
import { first } from 'rxjs/operators';

import firebase from 'firebase/compat/app';

export class CloudCollectionService {
  constructor(
    private firestore: AngularFirestore,
    private collectionName: string,
  ) {
    if (!this.collectionName || this.collectionName.trim().length === 0) {
      throw new Error('CloudCollectionService: Missing collection name on configuration.');
    }
   }

  /**
   * Creates object T in the data store.
   * @param {T} item Item to create
   * @return {Promise<string | undefined>} Return new T id or undefined
   */
  async create<T>(item: T): Promise<string | undefined> {
    if ((item as any).id) return Promise.resolve(undefined);

    if (!this.collectionName) {
      throw new Error('CloudCollectionService: Missing collection name.');
    }

    (item as any).created = firebase.firestore.Timestamp.now();
    (item as any).updated = firebase.firestore.Timestamp.now();

    const ref = this.firestore.collection(this.collectionName);
    // JSON Parse and stringify in order to get rid of undefined properties
    const data = JSON.parse(JSON.stringify(item));
    try {
      const newRef = await ref.add(data);
      return newRef.id;
    } catch {
      return undefined;
    }
  }

  /**
   * Creates object T in the data store with custom id
   * @param {string} id Custom id of the new object
   * @param {T} item Item to create
   * @return {Promise<string | undefined>} Return new T id or undefined
   */
  async createWithId<T>(id: string, item: T): Promise<string | undefined> {
    if ((item as any).id) return Promise.resolve(undefined);

    if (!this.collectionName) {
      throw new Error('CloudCollectionService: Missing collection name.');
    }

    (item as any).created = firebase.firestore.Timestamp.now();
    (item as any).updated = firebase.firestore.Timestamp.now();


    const newDocRef = this.firestore.doc(`${this.collectionName}/${id}`);

    // JSON Parse and stringify in order to get rid of undefined properties
    const data = JSON.parse(JSON.stringify(item));
    newDocRef.set(data);

    try {
      await newDocRef.set(item);
      return id;
    } catch {
      return undefined;
    }
  }

  /**
   *
   * @param {string} id Id of the item to read
   * @return {Promise<T | undefined>} Reads an item by id
   */
  async read<T>(id: string): Promise<T | undefined> {
    if (!id) return Promise.resolve(undefined);

    if (!this.collectionName) {
      throw new Error('CloudCollectionService: Missing collection name.');
    }

    const ref = await this.firestore.collection(this.collectionName)
      .doc(id)
      .get()
      .pipe(
        first(),
      )
      .toPromise();

    if (!ref.exists) {
      return undefined;
    }

    return {
      id,
      ...ref.data() as T,
    };
  }

  /**
  * Updates T by Id
  * @param {string} id Id of the item to update
  * @param {T} item Item to update
  * @return {Promise<sboolean>} Return true if success
  */
  async update<T>(id: string, item: T): Promise<boolean> {
    if (!id) return Promise.resolve(false);

    if (!this.collectionName) {
      throw new Error('CloudCollectionService: Missing collection name.');
    }

    // Give the object an updated time
    (item as any).updated = firebase.firestore.Timestamp.now();

    const docRef = this.firestore.collection(this.collectionName)
      .doc(id);

    const ref = await docRef
      .get()
      .pipe(
        first(),
      )
      .toPromise();

    if (!ref.exists) {
      return false;
    }

    // JSON Parse and stringify in order to get rid of undefined properties
    const data = JSON.parse(JSON.stringify(item));

    try {
      return await docRef
        .update(data)
        .then(() => true)
        .catch(() => false);
    } catch {
      return false;
    }
  }

  /**
   * Deletes an object by id
   * @param {string} id Id of the object to delete
   * @return {Promise<boolean>} True if successful
   */
  async delete(id: string) {
    if (!this.collectionName) {
      throw new Error('CloudCollectionService: Missing collection name.');
    }
    try {
      await this.firestore.collection(this.collectionName).doc(id)
        .delete();
      return true;
    } catch {
      return false;
    }
  }

  /**
   * Returns a Promise containing a list of type T
   * @return {Promise<T[]>} Promise of objects of type T
   */
  async list<T>(): Promise<T[]> {
    if (!this.collectionName) {
      throw new Error('CloudCollectionService: Missing collection name.');
    }
    const collection = this.firestore.collection<T>(this.collectionName);
    const items = await collection.snapshotChanges().pipe(
      first(),
    )
      .toPromise();

    if (!items) return [];

    return items.map((i) => {
      const { payload } = i;
      const { doc } = payload;
      return {
        id: doc.id,
        ...doc.data(),
      };
    });
  }
}
