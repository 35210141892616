import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { GthAuthService, GthCacheUserService, GthCloudFunctionService } from '@gth-legacy/services';
import { GthModel, GthUserModel } from '@sentinels/models';
import { Timestamp } from 'firebase/firestore';
import moment, { Moment } from 'moment-timezone';
import { Observable, BehaviorSubject, from } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

export interface Comment {
    userId: string;
    createdAt: Date;
    content: string;
    likes: string[];
    userModel?: Observable<GthUserModel>;
  }

  export interface Post {
    id?: string;
    userId: string;
    createdAt: Date;
    content: string;
    showFullContent?: boolean;
    showCommentInput?: boolean;
    newComment?: string;
    newPost?: string;
    comments?: Comment[];
    userModel?: Observable<GthUserModel>;
    likes: string[];
  }

  export class GthEventWallPostModel extends GthModel<Post> {
    public showAllComments = false;
    constructor(
      public id: string,
      public model: Post,
      private userService: GthCacheUserService,
    ) {
      super(id, model);
    }

    get likes() {
        return this.model.likes;
    }

    set likes(likes: string[]) {
       this.model.likes = likes;
    }

    get creator() {
        return this.getUserModel(this.model.userId);
    }

    get userId(): string {
      return this.model.userId;
    }

    get createdAt(): Date {
      if (this.model.createdAt instanceof Timestamp) {
        return new Date(( this.model.createdAt as Timestamp).toDate());
      }
      return this.model.createdAt;
    }

    set createdAt(dateTime: Date) {
      this.model.createdAt = dateTime;
    }

    get content(): string {
      return this.model.content;
    }

    set content(content: string) {
      this.model.content = content;
    }

    get showFullContent(): boolean {
      return this.model.showFullContent;
    }

    set showFullContent(showFullContent: boolean) {
      this.model.showFullContent = showFullContent;
    }

    get showCommentInput(): boolean {
      return this.model.showCommentInput;
    }

    set showCommentInput(showCommentInput: boolean) {
      this.model.showCommentInput = showCommentInput;
    }

    get newComment(): string {
      return this.model.newComment;
    }

    set newComment(newComment: string) {
      this.model.newComment = newComment;
    }

    get newPost(): string {
      return this.model.newPost;
    }

    set newPost(newPost: string) {
      this.model.newPost = newPost;
    }

    get comments(): Comment[] {
      return this.model.comments.map((comment)=>{
        return { ...comment,
            userModel: this.getUserModel(comment.userId),
        };
      });
    }

    set comments(comments: Comment[]) {
      this.model.comments = comments;
    }

    getUserModel(userId: string): Observable<GthUserModel> {
      return this.userService.getUserById$(userId);
    }

    getTimeFormatForUI(time: Date | Timestamp) {
        if (time instanceof Timestamp) {
          return moment(time.toDate()).format('MM-DD-YYYY');
        }
        return moment(time).format('MM-DD-YYYY');
    }

    getAllUserModelsFromStringArray(userIds: string[]) {
         return from(this.userService.getUsersById(userIds));
    }

    get allUsersWhoLikedPost() {
        return this.getAllUserModelsFromStringArray(this.likes);
    }
  }

@Injectable({
  providedIn: 'root',
})
export class WallPostService {
  constructor(
    private firestore: AngularFirestore,
    private cloud:GthCloudFunctionService,
    public AuthService: GthAuthService,
    ) {}

  initList$(eventId: string) {
    return this.firestore.collection<Post>(
      `wallPosts/${eventId}/posts/`,
      (ref) => ref.orderBy('createdAt', 'asc'),
    ).snapshotChanges()
      .pipe(
        map((results) => {
            const posts: Post[] =
             results.map((data) =>({
                    id: data.payload.doc.id,
                    ...data.payload.doc.data(),
                }) as Post,
            );
            return posts;
        }),
        debounceTime(400), // Lets wait a bit, shall we?
      );
  }

  updateList(eventId: string) {
    const posts$ = new BehaviorSubject<Post[]>([]);


    this.initList$(eventId).subscribe((posts)=>{
        let hasChanged = false;
        const changedArray = posts$.getValue();
        posts.forEach((newPost) => {
            const existingPostIndex = changedArray.findIndex((post) => post.id === newPost.id);
            if (existingPostIndex === -1) {
                changedArray.unshift(newPost);
                hasChanged = true;
            } else if (!this.arePostsEqual(changedArray[existingPostIndex], newPost)) {
                changedArray[existingPostIndex] = newPost;
                hasChanged = true;
            }
          });
        if (!hasChanged) return;
        posts$.next(changedArray);
    });
    return posts$.pipe(map((posts)=>{
        return posts.map((post)=> this.mapToGthEventWallPostModel(post));
    }));
  }

  private arePostsEqual(postA: Post, postB: Post): boolean {
    return JSON.stringify(postA) === JSON.stringify(postB);
  }

  create(eventId: string, userId: string, content: string): void {
    const newPost: Post = {
      userId: userId,
      createdAt: new Date(),
      content: content,
      comments: [],
      likes: [],
    };

    this.firestore.collection(`wallPosts/${eventId}/posts/`).add(newPost);
  }

  update(eventId: string, postId: string, data: Partial<Post>): void {
    delete data.userModel;

    data.comments.map((comment)=>{
        delete comment.userModel;
    });

    this.firestore.collection<Post>(`wallPosts/${eventId}/posts/`).doc(postId).update(data);
  }

  delete(eventId: string, postId: string): void {
    this.firestore.collection<Post>(`wallPosts/${eventId}/posts/`).doc(postId).delete();
  }

  private mapToGthEventWallPostModel(post: Post): GthEventWallPostModel {
    return new GthEventWallPostModel(post.id, post, this.cloud.user);
  }
}
