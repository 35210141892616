import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

import { AccountSubscriptionType, StripeItemType } from '@sentinels/enums';
import { SubscriptionsDialogOpenContract } from '@sentinels/interfaces/stripe';

import { PaymentDialogComponent, PaymentDialogContract } from '../payment-dialog/payment-dialog.component';
import { StripeService } from '../../services/stripe.service';
import { ConfirmDialogComponent } from '../../components/confirm-dialog/confirm-dialog.component';
import { SubscriptionsComponent } from '../../components/subscriptions/subscriptions.component';
import { SubscriptionType } from '@index/enums';
import { GthStripeSubscriptionModel } from '@sentinels/models';
import { environment } from '@environments/environment';

@Component({
  selector: 'gth-subscriptions-dialog',
  templateUrl: './subscriptions-dialog.component.html',
  styleUrls: ['./subscriptions-dialog.component.scss'],
  standalone: true,
  imports: [MatDialogModule, SubscriptionsComponent],
})
export class SubscriptionsDialogComponent implements OnInit {
  public subscription: string;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: SubscriptionsDialogOpenContract,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<SubscriptionsDialogComponent>,
    private stripeService: StripeService,
    private snackbar: MatSnackBar,
  ) { }

  async ngOnInit() {
    switch (this.data.subType) {
      case AccountSubscriptionType.TEAMS:
        this.subscription = this.data.team.subscription;
        break;
      case AccountSubscriptionType.USER:
        this.subscription = this.data.platform === 'meh' ?
          this.data.user.userSubscription : this.data.user.subscription;
        break;
      case AccountSubscriptionType.TOURNAMENTS:
      case AccountSubscriptionType.DROPIN_PICKUP:
        this.subscription = this.data.user.subscription;
        break;
    }
  }

  public async onPlanChange(subscription: GthStripeSubscriptionModel) {
    switch (this.data.subType) {
      case AccountSubscriptionType.USER:
        if (subscription.priceId === null) {
          const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            id: 'confirm-cancel-subscription-dialog',
            backdropClass: 'gth-overlay-backdrop',
            panelClass: 'gth-dialog',
            data: {
              title: `Cancel subscription?`,
              // eslint-disable-next-line max-len
              description: `Are you sure you would like to cancel your subscription?`,
              // eslint-disable-next-line max-len
              additionalInformation: 'It will take a couple of minutes for the changes to propagate.',
            },
          });

          dialogRef
            .afterClosed()
            .pipe(take(1))
            .forEach(async (confirm) => {
              if (confirm) {
                const success = await this.stripeService.cancelSubscription(
                  AccountSubscriptionType.USER,
                  this.data.user.uid,
                  this.data.platform,
                );
                if (success) {
                  this.snackbar.open('Successfully cancelled subscription!', '', {
                    duration: 5000,
                  });
                  this.data.user.userSubscription = 'Free';
                  this.dialogRef.close();
                }
              }
            });
        } else {
          const contract: PaymentDialogContract = {
            type: StripeItemType.CHANGE_USER_SUBSCRIPTION,
            subscription,
            user: this.data.user,
            platform: this.data.platform,
          };
          this.dialog.open(PaymentDialogComponent, {
            minWidth: 360,
            data: contract,
          });
        }
        break;
      default:
        if (subscription.priceId === null) {
          const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            id: 'confirm-change-plan-to-free-dialog',
            backdropClass: 'gth-overlay-backdrop',
            panelClass: 'gth-dialog',
            data: {
              title: `Change Plan to ${subscription.label}?`,
              // eslint-disable-next-line max-len
              description: `Are you sure you would like to change your subscription to ${subscription.label}?`,
              // eslint-disable-next-line max-len
              additionalInformation: 'It will take a couple of minutes for the changes to propagate.',
            },
          });

          dialogRef
            .afterClosed()
            .pipe(take(1))
            .forEach(async (confirm) => {
              if (confirm) {
                const success = await this.stripeService.cancelSubscription(
                  AccountSubscriptionType.TEAMS,
                  this.data.team.id,
                  this.data.platform,
                );
                if (success) {
                  this.snackbar.open('Successfully cancelled subscription!', '', {
                    duration: 5000,
                  });
                  this.data.team.subscription = SubscriptionType.ROOKIE;
                  this.dialogRef.close();
                }
              }
            });
          return;
        } else if (this.data.team.subscription !== SubscriptionType.ROOKIE) {
          const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            id: 'confirm-change-plan-dialog',
            backdropClass: 'gth-overlay-backdrop',
            panelClass: 'gth-dialog',
            data: {
              title: `Change Plan to ${subscription.label}?`,
              // eslint-disable-next-line max-len
              description: `Are you sure you would like to change your subscription to ${subscription.label}?`,
              // eslint-disable-next-line max-len
              additionalInformation: 'It will take a couple of minutes for the changes to propagate.',
            },
          });

          dialogRef
            .afterClosed()
            .pipe(take(1))
            .forEach(async (confirm) => {
              if (!confirm) return;

              const success = await this.stripeService.createSubscription({
                items: [
                  {
                    id: this.data.team.id,
                    name: subscription.label,
                    quantity: 1,
                    cost: subscription.cost,
                    type: StripeItemType.CHANGE_TEAM_SUBSCRIPTION,
                    subscriptionPriceId: subscription.priceId,
                    platform: this.data.platform,
                  },
                ],
                environment: environment.envName,
              });
              if (success) {
                this.snackbar.open(
                  'Successfully changed subscription!',
                  '',
                  { duration: 5000 },
                );
                this.data.team.subscription = SubscriptionType[subscription.item];
                this.dialogRef.close();
              }
            });
          return;
        }

        console.log(`opening payment dialog for ${this.data.subType}`, this.data);
        const contract: PaymentDialogContract = {
          type: StripeItemType.CHANGE_TEAM_SUBSCRIPTION,
          team: this.data.team,
          subscription,
          user: this.data.user,
          platform: this.data.platform,
        };
        this.dialog.open(PaymentDialogComponent, {
          minWidth: 360,
          data: contract,
        });
        break;
    }
  }
}
