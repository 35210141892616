import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { first } from 'rxjs/operators';

import { Topic } from '@index/interfaces/topic';
import { DBUtil } from '../../../../../index/src/lib/utils/db-utils';
import { GthCacheUserService } from '../cache/user.service';

@Injectable({
  providedIn: 'root',
})
export class GthTopicPrivateFunctionService {
  constructor(
    private firestore: AngularFirestore,
    private users: GthCacheUserService,
  ) { }


  /**
   * Mutes, or unmutes, topic
   * @param {string} teamId Id of the team
   * @param {string} topicId Id of the topic
   * @param {string} userId Id of the user
   * @param {boolean} value True/False
   * @return {Promise<boolean>} true if success
   */
  async muteTopic(teamId: string, topicId: string, userId: string, value: boolean) {
    const muted = await this.firestore
      .collection(`${DBUtil.TeamTopics}/${teamId}/muted_topics`,
        (ref) => ref
          .where('user', '==', userId)
          .where('topicId', '==', topicId),
      )
      .get()
      .pipe(
        first(),
      )
      .toPromise();

    if (value) {
      const obj = {
        user: userId,
        topicId,
      };
      const topicsRef = await this.firestore
        .collection(`${DBUtil.TeamTopics}/${teamId}/muted_topics`);
      try {
        topicsRef.add(obj);
        return true;
      } catch {
        return false;
      }
    } else {
      // Remove it from the collection
      if (!muted || muted.empty || muted.docs.length === 0) {
        const doc = muted.docs[0];
        try {
          await doc.ref.delete();
          return true;
        } catch {
          return false;
        }
      }
    }
    return false;
  }

  /**
   * Favorites, or unfavorites topic
   * @param {string} teamId Id of the team
   * @param {string} topicId Id of the topic
   * @param {string} userId Id of the user
   * @param {boolean} value True/False
   * @return {Promise<boolean>} true if success
   */
  async favoriteTopic(teamId: string, topicId: string, userId: string, value: boolean) {
    const favorite = await this.firestore
      .collection(`${DBUtil.TeamTopics}/${teamId}/favorite_topics`,
        (ref) => ref
          .where('user', '==', userId)
          .where('topicId', '==', topicId),
      )
      .get()
      .pipe(
        first(),
      )
      .toPromise();
    if (value) {
      const obj = {
        user: userId,
        topicId,
      };
      const topicsRef = await this.firestore
        .collection(`${DBUtil.TeamTopics}/${teamId}/favorite_topics`);
      try {
        topicsRef.add(obj);
        return true;
      } catch {
        return false;
      }
    } else {
      // Remove it from the collection
      if (!favorite || favorite.empty || favorite.docs.length === 0) {
        const doc = favorite.docs[0];
        try {
          await doc.ref.delete();
          return true;
        } catch {
          return false;
        }
      }
    }
    return false;
  }

  async getFavoriteTopics(teamId: string, userId: string): Promise<string[]> {
    const favorites = await this.firestore
      .collection(`${DBUtil.TeamTopics}/${teamId}/favorite_topics`,
        (ref) => ref
          .where('user', '==', userId))
      .get()
      .pipe(
        first(),
      )
      .toPromise();

    if (!favorites || favorites.empty || favorites.docs.length === 0) {
      return [];
    }

    return favorites.docs.map((doc) => {
      const data = doc.data();
      return {
        ...data as object,
      } as unknown as { topicId: string };
    }).map((t) => t.topicId);
  }

  async getMutedTopics(teamId: string, userId: string): Promise<string[]> {
    const muted = await this.firestore
      .collection(`${DBUtil.TeamTopics}/${teamId}/muted_topics`,
        (ref) => ref
          .where('user', '==', userId))
      .get()
      .pipe(
        first(),
      )
      .toPromise();

    if (!muted || muted.empty || muted.docs.length === 0) {
      return [];
    }

    return muted.docs.map((doc) => {
      const data = doc.data();
      return {
        ...data as object,
      } as unknown as { topicId: string };
    }).map((t) => t.topicId);
  }
}
