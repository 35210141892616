

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { first } from 'rxjs/operators';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

import { APP_ROUTES } from '@shared/helpers';
import { GthExternalTeamModel, GthUserModel } from '../../../../../sentinels/src/lib/models';
import { GthCloudFunctionService } from '../../services/cloud/cloud-function.service';
import { GthUserAvatarComponent } from '../user-avatar/user-avatar.component';
import { GthAuthService } from '../../services/auth.service';
import { GthStoredImageModule } from '../stored-image/stored-image.module';
import { GthPhonePipeModule } from '../../pipes/phone.pipe';
import { GthTeamRatingComponent, GthTeamRatingType } from '../team-rating/team-rating.component';

import firebase from 'firebase/compat/app';
import { ExternalTeamRatingItem } from '@index/interfaces';
import { GthTeamRatingModel } from '@sentinels/models/team-rating';
import { SrvSafeWindowService } from '@sentinels/services/safe-window.service';

@Component({
  selector: 'gth-external-team-view',
  standalone: true,
  templateUrl: './external-team-view.component.html',
  styleUrls: ['./external-team-view.component.scss'],
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    GthUserAvatarComponent,
    GthStoredImageModule,
    GthPhonePipeModule,
    GthTeamRatingComponent,
  ],
})
export class GthExternalTeamViewComponent implements OnInit, OnChanges {
  @Input()
  team?: GthExternalTeamModel;

  get photoURL() {
    return this.team?.photoURL;
  }

  get isSameUser() {
    if (!this.user || !this.creator) return false;
    return this.user.id === this.creator.id;
  }

  get ratingViewType(): GthTeamRatingType {
    if (this.user && !this.isSameUser) {
      return GthTeamRatingType.write;
    }
    return GthTeamRatingType.readonly;
  }

  ratings: ExternalTeamRatingItem[] = [];
  creator?: GthUserModel;
  user?: GthUserModel;
  teamLogoError = false;
  validating = false;

  private recaptchaVerifier: firebase.auth.RecaptchaVerifier;

  constructor(
    private cloud: GthCloudFunctionService,
    private auth: GthAuthService,
    private safeWindow: SrvSafeWindowService,
    private router: Router,
    private snackbar: MatSnackBar,
  ) { }

  async ngOnInit() {
    this.user = await this.auth.getUserModelAsync();
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.team &&
      this.creator?.id !== this.team?.creatorId) {
      this.creator = await this.cloud.user
        .getUserById$(this.team.creatorId)
        .pipe(
          first(),
        )
        .toPromise();
    }

    if (changes.team) {
      this.ratings = await this.cloud.externalTeam.getRatings(this.team.id);
    }
  }

  async onRequestToJoin() {
    if (!this.team) return;
    if (!this.user) {
      this.router.navigate([APP_ROUTES.SignUp]);
      return;
    }

    if (this.team.externalURL) {
      await this.setupCaptcha();
      return;
    }

    const dismissed = this.snackbar.open('Contact the team captain', 'View Profile');
    dismissed.onAction().subscribe(() => {
      this.router.navigate([APP_ROUTES.Profile, this.team.creatorId]);
    });
    return;
  }

  async onRatingChange(ratings: GthTeamRatingModel[]) {
    if (!this.user || !this.team) return;
    await this.cloud.externalTeam.rate(this.user.id, this.team.id, ratings);
    this.snackbar.open('Thank you for rating the team');
    this.ratings = await this.cloud.externalTeam.getRatings(this.team.id);
  }

  private async setupCaptcha() {
    this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
      size: 'normal',
      callback: (response) => {
        this.validating = false;
        if (!response) return;
        this.cloud
          .externalTeam
          .sendJoinerRequest(this.creator, this.user).subscribe((results) => {
            results ? this.snackbar.open('Request Sent!') :
              this.snackbar.open('Something went wrong.');
          });
      },
    });
    this.safeWindow.setProp('recaptchaVerifier', this.recaptchaVerifier);
    const renderer = this.safeWindow.getProp('recaptchaVerifier');
    if (renderer) {
      renderer();
    }

    if (!this.recaptchaVerifier) return;
    this.validating = true;
    await this.recaptchaVerifier.verify();
  }
}
