import { Component } from '@angular/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ReactiveFormsModule, ValidationErrors,
  Validators,
} from '@angular/forms';
import { TimeRangeForm } from '../../components/date-range/date-range.component';
import { DateSpecificAvailability, TimeRange } from '@index/interfaces';
import { NgForOf } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';

export interface DateSpecificAvailabilityForm {
  date: FormControl<string>,
  timeRanges: FormArray<FormGroup<TimeRangeForm>>,
}

@Component({
  selector: 'gth-legacy-add-date-specific-availability-dialog',
  templateUrl: './add-date-specific-availability-dialog.component.html',
  styleUrls: ['./add-date-specific-availability-dialog.component.scss'],
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    NgForOf,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRippleModule,
    MatCardModule,
  ],
})
export class AddDateSpecificAvailabilityDialogComponent {
  dateSpecificAvailabilityForm = new FormGroup<DateSpecificAvailabilityForm>({
    date: new FormControl(null, { nonNullable: true, validators: Validators.required }),
    timeRanges: new FormArray<FormGroup<TimeRangeForm>>([
      this.newTimeRange({ startTime: '17:00', endTime: '20:00' }),
    ]),
  });
  selectedDate: Date | null = null;

  get date() {
    return this.dateSpecificAvailabilityForm.controls.date;
  }
  get timeRanges() {
    return this.dateSpecificAvailabilityForm.controls.timeRanges;
  }

  constructor(private dialogRef: MatDialogRef<AddDateSpecificAvailabilityDialogComponent>) {}

  newTimeRange(timeRange?: TimeRange) {
    return new FormGroup<TimeRangeForm>({
      startTime: new FormControl(
        timeRange?.startTime ?? null,
        { nonNullable: true, validators: Validators.required },
      ),
      endTime: new FormControl(
        timeRange?.endTime ?? null,
        {
          nonNullable: true,
          validators: [Validators.required, this.endTimeValidator],
        },
      ),
    });
  }

  endTimeValidator(control: AbstractControl): ValidationErrors | null {
    const endTime = control.value;
    const startTime = control.parent?.get('startTime')?.value;
    if (endTime && startTime && endTime <= startTime) {
      return { endTimeBeforeStartTime: true };
    }
    return null;
  }

  addTimeRange(timeRange?: TimeRange) {
    this.timeRanges.push(this.newTimeRange(timeRange));
  }

  onRemoveTimeRange(index: number) {
    this.timeRanges.removeAt(index);
  }

  onDateChange(date: Date) {
    this.date.setValue(date.toDateString());
  }

  onAddBtnClick() {
    if (this.dateSpecificAvailabilityForm.invalid || !this.timeRanges.length) return;

    // eslint-disable-next-line max-len
    const dateSpecificAvailability = this.dateSpecificAvailabilityForm.value as DateSpecificAvailability;
    this.dialogRef.close(dateSpecificAvailability);
  }
}
