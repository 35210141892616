import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GthUserModel } from '@sentinels/models';
import { GthTeamRatingModel } from '@sentinels/models/team-rating';
import { MatChipListboxChange, MatChipsModule } from '@angular/material/chips';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ExternalTeamRatingItem } from '@index/interfaces';
import { MatButtonModule } from '@angular/material/button';
import { SrvApiService } from '@sentinels/services/api.service';

export enum GthTeamRatingType {
  write = 'write',
  readonly = 'readonly',
}

const MAX_SELECTED = 3;

@Component({
  selector: 'gth-team-rating',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatChipsModule,
  ],
  templateUrl: './team-rating.component.html',
  styleUrls: ['./team-rating.component.scss'],
})
export class GthTeamRatingComponent implements OnChanges {
  @Input()
  ratings: ExternalTeamRatingItem[] = [];

  @Input()
  view = GthTeamRatingType.readonly;

  @Input()
  user?: GthUserModel;

  @Output()
  ratingChange = new EventEmitter<GthTeamRatingModel[]>();

  @Output()
  submitRatings = new EventEmitter<GthTeamRatingModel[]>();

  teamRatings: GthTeamRatingModel[] = [];
  displayedRatings: { label: string, count: number }[] = [];
  selectedTeamRatings: string[] = [];

  constructor(
    private api: SrvApiService,
    private snackbar: MatSnackBar,
  ) { }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.ratings && this.ratings) {
      if (!this.teamRatings.length) {
        this.teamRatings = await this.api.teamRatings.listAsync();
      }
      this.displayedRatings = this.getTeamRatings()
        .slice(0, 3);
    }
  }

  onTeamRatingChange(change: MatChipListboxChange) {
    const selectedRatingsNames = change.source.value as string[];
    const newLength = selectedRatingsNames.length;
    const itemAdded = newLength > this.selectedTeamRatings.length;

    if (itemAdded && newLength > MAX_SELECTED) {
      this.selectedTeamRatings = [...this.selectedTeamRatings];
      this.snackbar.open(`Please only endorse ${MAX_SELECTED} items`);
      return;
    }
    this.selectedTeamRatings = selectedRatingsNames;

    const ratings = this.selectedTeamRatings
      .map((r) => this.teamRatings.find((tr) => tr.item === r))
      .filter((r) => r);
    this.ratingChange.emit(ratings);
  }

  onRatingsSubmit() {
    const ratings = this.selectedTeamRatings
      .map((r) => this.teamRatings.find((tr) => tr.item === r))
      .filter((r) => r);
    this.submitRatings.emit(ratings);
  }

  private getTeamRatings() {
    const ratings = new Map<string, number>();
    const selectedTeamRatings = [];
    this.ratings.forEach((r) => {
      const teamRating = this.teamRatings.find((tr) => tr.id.toString() === r.rating);
      const ratingName = teamRating?.label ?? r.rating;
      if (r.userId === this.user?.id) {
        selectedTeamRatings.push(ratingName);
      }
      if (ratings.has(ratingName)) {
        const ratingCount = ratings.get(ratingName);
        ratings.set(ratingName, ratingCount + 1);
      } else {
        ratings.set(ratingName, 1);
      }
    });
    const sortedRatings = [];
    for (const key of ratings.keys()) {
      sortedRatings.push({ label: key, count: ratings.get(key) });
    }
    this.selectedTeamRatings = selectedTeamRatings;
    return sortedRatings.sort((a, b) => a.count - b.count);
  }
}
