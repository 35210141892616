<div class="date-range-container" [formGroup]="dateRangeForm">
  <div class="date-range-selection">
    <mat-checkbox *ngIf="showCheckbox" formControlName="isAvailable" (change)="onCheckboxChange($event)">
      {{ textBetweenCheckboxAndInput }}
      <span class="dash">-</span>
      {{ isAvailable.value ? 'Available' : 'Unavailable' }}
    </mat-checkbox>

    <button *ngIf="showAddTimeButton && (!showCheckbox || isAvailable.value)" mat-icon-button
            (click)="addTimeRange()">
      <mat-icon fontIcon="add" />
    </button>
  </div>

  <ng-container *ngIf="!showCheckbox || isAvailable.value">
    <div class="time-input-container" formArrayName="timeRanges">
      <div *ngFor="let _ of timeRanges.controls; let i = index" class="time-range" [formGroupName]="i">
        <mat-form-field appearance="fill" class="start-time">
          <mat-label>Start Time</mat-label>
          <input matInput type="time" formControlName="startTime" required />
        </mat-form-field>
        <span class="dash">-</span>

        <mat-form-field appearance="fill" class="end-time">
          <mat-label>End Time</mat-label>
          <input matInput type="time" formControlName="endTime" required />
        </mat-form-field>

        <button mat-icon-button *ngIf="showRemoveButton" (click)="removeTimeRange(i)">
          <mat-icon fontIcon="clear" />
        </button>
        <!-- Display error message for overlapping time ranges -->
        <div *ngIf="timeRanges.controls[i].errors?.overlappingTimes" class="error-message">
          Overlapping times with another range.
        </div>

        <!-- Additional check to compare current time range with other time ranges -->
        <div *ngFor="let _ of timeRanges.controls; let j = index">
          <div *ngIf="i !== j && checkOverlap(i, j)" class="error-message">
            Overlapping times with another range.
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
