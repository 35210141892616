import { Injectable, OnDestroy } from '@angular/core';
import { combineLatest, forkJoin, Observable, of, Subscription, throwError } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchError, filter, first, map, switchMap, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { distanceBetween } from 'geofire-common';
import firebase from 'firebase/compat/app';

import { StripeItemType, EventInfoDialogCloseMethod } from '@sentinels/enums';
import { GthEventItemModel, GthTeamModel, GthUserModel } from '@sentinels/models';
import { NotificationModel } from '@index/models/notifications';
import { EventJoinerModel } from '@index/models/event-joiner';
import { DBUtil } from '@index/utils/db-utils';
import { EventJoinerSorter } from '@index/daos/utils/event-joiner-sorter';
import { NotificationType } from '@index/enums';
import { EventItem, EventItemGuest, EventJoiner, EventJoinerStatus, EventRsvpStatus } from '@index/interfaces';


import { DEFAULT_CURRENT_USER, GUEST_PROFILE_ID } from './auth.service';
import { APP_ROUTES, TEAMS_ROUTES } from '@shared/helpers';
import {
  FIND_PLAYERS_CLOSE_ACTION,
  GthFindPlayersDialogComponent,
} from '../components/find-players-dialog/find-players-dialog.component';
import { ConfirmDialogComponent } from '../components/confirm-dialog/confirm-dialog.component';
import { GthCloudFunctionService } from './cloud/cloud-function.service';
import { ANALYTICS_CONSTS, GthAnalyticsService } from './logging/analytics.service';
import { GthRsvpWithGuestDialogComponent } from '../components/rsvp-with-guest-dialog/rsvp-with-guest-dialog.component';
import { RSVPEventDialogCloseContract, RSVPEventDialogCloseMethod, RsvpEventDialogComponent } from '../dialogs/rsvp-event-dialog/rsvp-event-dialog.component';
import { EventInfoDialogCloseContract, EventInfoDialogComponent, EventInfoDialogOpenContract } from '../dialogs/event-info-dialog/event-info-dialog.component';
import { PaymentDialogComponent, PaymentDialogContract } from '../dialogs/payment-dialog/payment-dialog.component';

// TODO: RED FLAG DO NOT CALL GTH FROM LEGACY
import { TeamsService } from '../../../../gth/src/app/features/teams/services/teams.service';

@Injectable({ providedIn: 'root' })
export class EventsService implements OnDestroy {
  private subscriptions = new Subscription();

  constructor(
    private cloudFunctionService: GthCloudFunctionService,
    private analytics: GthAnalyticsService,
    private firestore: AngularFirestore,
    private teamsService: TeamsService,
    private snackbar: MatSnackBar,
    private dialog: MatDialog,
    private router: Router,
  ) { }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  async onJoinGame(
    game: GthEventItemModel,
    user: GthUserModel,
    rsvpStatus: EventRsvpStatus,
    guests: EventItemGuest[] = [],
    withPrompt = true,
    joinerStatus?: EventJoinerStatus,
    isGuestUser = false,
  ): Promise<boolean> {
    const join = this.cloudFunctionService.eventJoiner
      .joinEvent$(user.uid, game.id, rsvpStatus, guests, joinerStatus, isGuestUser)
      .pipe(take(1))
      .toPromise();

    const success = await join;

    if (withPrompt) {
      if (success) this.onJoinGameSuccess(user.uid, game.id);
      else this.snackbar.open('Error joining game.', 'OK');
    }


    return success;
  }


  loadSingleGameData(
    eventId: string,
    user: GthUserModel,
    teamId?: string,
  ): Observable<{ game: GthEventItemModel; user: GthUserModel }> {
    const event$ = this.getEventById$(eventId);
    const user$ = of(user);
    let team$ = of(undefined);
    if (teamId) {
      team$ = this.cloudFunctionService.team.getTeamById$(teamId);
    }
    const requests$ = [event$, user$, team$];
    return combineLatest(requests$).pipe(
      map(([event, user, team]) => {
        const response = { game: event, user, team };
        if (team) {
          response.team = new GthTeamModel(team.id, team);
        }
        return response;
      }),
    );
  }

  populateGameJoiners$(game: GthEventItemModel): Observable<GthUserModel[]> {
   const p = game.participants;

    return this.getParticipants$(p).pipe(
      map((users) =>
        users.filter((x) => x !== undefined), // Filter out deleted users
      ),
    );
  }

  private getParticipants$(participants: EventJoiner[]) {
    if (participants.length === 0) {
      return of([] as GthUserModel[]);
    }
    const requests$: Observable<GthUserModel | undefined>[] = [];
    participants.forEach((p) => {
      let request: Observable<GthUserModel>;
      if (p.isGuestUser) request = this.cloudFunctionService.guests.getGuestByEmail$(p.player);
      else request = this.cloudFunctionService.user.getUserById$(p.player);
      requests$.push(request);
    });
    return combineLatest(requests$).pipe(
      map((participants) => {
        // Filter out deleted users
        return participants.filter((x) => {
          return x !== undefined;
        });
      }),
    );
  }

  async populateGameJoiners(game: GthEventItemModel) {
    const approvedPlayers = game.participants.filter(
      (p) => p.status === EventJoinerStatus.Approved,
    );

    return await this.getParticipants$(approvedPlayers).pipe(take(1)).toPromise();
  }

  async openDisplayInfoPage(
    context: string,
    game: GthEventItemModel,
    user: GthUserModel,
    team?: GthTeamModel,
  ) {
    const platform = context === 'meh' ? context : 'gth';
    if (!user && platform === 'meh') {
      return Promise.resolve(false);
    }
    this.analytics.logEvent(ANALYTICS_CONSTS.eventOpened, {
      context,
      event: game.id,
    });
    return await this.displayEventsPage(
      platform,
      game, user, team,
    ).toPromise();
  }

  getEventById$(eventItemId: string) {
    return this.cloudFunctionService.event.list$({ eventItemId }).pipe(
      switchMap((events) => {
        const event = events[0];
        let creatorId: string;

        if (event.creatorId) {
          creatorId = event.creatorId;
        }

        if (!event) {
          // Handle the case when events is empty.
          return of(null); // You can also return `of(null)` or any other default value.
        }

        const requests$ = this.cloudFunctionService.user.getUserById$(creatorId);

        return combineLatest([requests$]).pipe(
          map((users) => {
            if (users) {
              event.setCreator(users[0]);
            }

            return event;
          }),
        );
      }),
    );
  }

  getEvents$(userId: string): Observable<GthEventItemModel[]> {
    return this.cloudFunctionService.event.list$({ userId }).pipe(
      switchMap((events) => {
        const creatorIds: string[] = [];
        events.forEach((g) => {
          if (g.creatorId && !creatorIds.includes(g.creatorId)) {
            creatorIds.push(g.creatorId);
          }
        });

        if (!events.length) {
          // Handle the case when events is empty.
          return of([]); // You can also return `of(null)` or any other default value.
        }

        const requests$: Observable<GthUserModel | undefined>[] = [];
        creatorIds.forEach((c) => {
          const request = this.cloudFunctionService.user.getUserById$(c);
          requests$.push(request);
        });

        return combineLatest(requests$).pipe(
          map((users) => {
            // Filter out deleted users
            return users.filter((x) => x !== undefined);
          }),
          map((users) => {
            events.forEach((g) => {
              if (g.creatorId) {
                const user = users.find((u) => u && u.uid === g.creatorId);
                if (user) {
                  g.setCreator(user);
                }
              }
            });
            return events;
          }),
        );
      }),
    );
  }

  filterUpcomingEvents = (events: GthEventItemModel[]) => {
    return events?.length ?
      events.filter((e) => {
        const today = new Date();

        let gameDate = e?.dateEnd;
        if (!gameDate) return false;

        /** Convert to date if string */
        if (typeof gameDate === 'string') gameDate = new Date(gameDate);
        return today.getTime() < gameDate?.getTime();
      }) :
      events;
  };

  filterPastEvents = (events: GthEventItemModel[]) => {
    return events?.length ?
      events.filter((e) => {
        const today = new Date();

        let gameDate = e?.dateEnd;
        if (!gameDate) return false;

        /** Convert to date if string */
        if (typeof gameDate === 'string') gameDate = new Date(gameDate);
        return today.getTime() > gameDate?.getTime();
      }) :
      events;
  };

  openFindPlayersDialog(event: GthEventItemModel, user: GthUserModel, team?: GthTeamModel) {
    if (!user) return;

    const dialogRef = this.dialog.open(GthFindPlayersDialogComponent, {
      id: 'find-players-dialog',
      data: { event, user, team },
      backdropClass: 'gth-overlay-backdrop',
      panelClass: 'gth-dialog',
    });

    dialogRef.afterClosed().forEach(async (closeAction: FIND_PLAYERS_CLOSE_ACTION) => {
      if (!closeAction) return;

      switch (closeAction) {
        case FIND_PLAYERS_CLOSE_ACTION.REMIND_TEAM:
          const notification = new NotificationModel(
            undefined,
            NotificationType.TEAM_REMINDER,
            {
              event: event.id,
              team: team.id,
              user: user.uid,
            },
          );
          this.teamsService.sendNotification(notification, team);
          break;
        case FIND_PLAYERS_CLOSE_ACTION.FIND_AVAILABLE_PLAYERS:
          this.router.navigate([APP_ROUTES.DiscoverPlayers]);
          break;
        case FIND_PLAYERS_CLOSE_ACTION.CREATE_PUBLIC_GAME:
        case FIND_PLAYERS_CLOSE_ACTION.CREATE_PRIVATE_GAME:
          const discoverableText = event.discoverable ? 'private' : 'public';
          const confimDialogRef = this.dialog.open(
            ConfirmDialogComponent,
            {
              data: {
                title: `Make ${event.title} ${discoverableText}?`,
                // eslint-disable-next-line max-len
                description: `Are you sure you would like to make this event ${discoverableText}.`,
                buttonText: `Make ${discoverableText}`,
              },
            },
          );
          confimDialogRef.afterClosed().pipe(take(1)).forEach(async (confirm: boolean) => {
            if (confirm) {
              event.discoverable = !event.discoverable;
              await this.cloudFunctionService.event.update$(event.id, event)
                .pipe(take(1)).toPromise()
                .then((success) => {
                  if (success) {
                    this.snackbar.open(`Event is now ${discoverableText}`, '');
                    this.router.navigate([APP_ROUTES.DiscoverGames, event.id]);
                  } else {
                    this.snackbar.open(
                      `Failed to make event ${discoverableText}`,
                      'OK',
                      { duration: 0 },
                    );
                  }
                });
            }
          });
          break;
      }
    });
  }

  getAllEvents$() {
    interface EventItemWithId extends EventItem {
      id: string;
    }
    const events$ = this.firestore
      .collection(DBUtil.EventItem)
      .valueChanges({ idField: 'id' }) as unknown as Observable<EventItemWithId[]>;
    return events$.pipe(
      take(1),
      /** Convert EventItem[] to GthEventItemModel[] */
      map((events) => events.map((event) => new GthEventItemModel(event.id, event))),
      /** Load Event Joiners */
      switchMap(async (e) => this.getEventJoiners$(e)),
    );
  }

  getOnlineEvents$() {
    interface EventItemWithId extends EventItem {
      id: string;
    }
    const events$ = this.firestore
      .collection(DBUtil.EventItem, (ref) => ref.where('online', '==', true))
      .valueChanges({ idField: 'id' }) as unknown as Observable<EventItemWithId[]>;
    return events$.pipe(
      take(1),
      /** Convert EventItem[] to GthEventItemModel[] */
      map((events) => events.map((event) => new GthEventItemModel(event.id, event))),
      /** Load Event Joiners */
      switchMap(async (e) => this.getEventJoiners$(e)),
    );
  }

  getEventsByLocation$(lat: number, lng: number) {
    /** Find cities within 200km of specified location */
    const radiusInM = 200 * 1000;
    const center: [number, number] = [lat, lng];

    if (!lat || !lng) {
      return of([]);
    }

    interface EventItemWithId extends EventItem {
      id: string;
    }
    const events$ = this.firestore
      .collection(DBUtil.EventItem)
      .valueChanges({ idField: 'id' }) as unknown as Observable<EventItemWithId[]>;
    return events$.pipe(
      take(1),
      /** Filter events that within 200km of the specified location */
      map((events) => {
        return events.filter((event) => {
          if (!event?.location?.lat || !event?.location?.lng) return false;

          const distanceInKm = distanceBetween(
            [event.location.lat, event.location.lng],
            center,
          );
          const distanceInM = distanceInKm * 1000;
          return distanceInM <= radiusInM;
        });
      }),
      /** Convert EventItem[] to GthEventItemModel[] */
      map((events) => events.map((event) => new GthEventItemModel(event.id, event))),
    );
  }

  async getEventJoiners$(events: GthEventItemModel[]) {
    const sorter = new EventJoinerSorter();
    for await (const event of events) {
      const joiners = (await this.cloudFunctionService.eventJoiner
        .list$(event?.id)
        .pipe(
          take(1),
          catchError(() => of([])),
        )
        .toPromise()) as unknown as EventJoinerModel[];
      if (event?.genderInfo && joiners) {
        event.genderInfo = sorter.sort(joiners, event?.genderInfo);
      }
    }
    return events;
  }

  async onCancelGame(game: GthEventItemModel, user: GthUserModel) {
    if (game.creatorId !== user.uid) return;

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      id: 'confirm-cancel-event-dialog',
      backdropClass: 'gth-overlay-backdrop',
      panelClass: 'gth-dialog',
      data: {
        title: `Cancel ${game.title}?`,
        description: 'Are you sure you would like to cancel this event?',
      },
    });

    dialogRef.afterClosed().forEach((confirmed) => {
      if (!confirmed) return;

      this.cancelGame(game);
    });
  }

  private async cancelGame(game: GthEventItemModel) {
    const participants = game.participants;
    game.cancel();

    const cancelEvent = this.cloudFunctionService.event
      .update$(game.id, game)
      .pipe(
        catchError((error) => {
          return throwError(error);
        }),
        filter((success) => {
          if (!success) this.snackbar.open('Something went wrong cancelling event', 'OK');

          this.snackbar.open('Cancelled event', 'OK');
          return success && participants.length >= 1;
        }),
        switchMap(() => {
          const cancelNotification = (userId: string) =>
            new NotificationModel(undefined, NotificationType.EVENT_CANCELLED, {
              event: game.id,
              joiner: userId,
              recipient: 'joiner',
            });
          const participantIds = participants.map((p) => p.player);
          const notifications$ = participantIds.map((p) => {
            return this.cloudFunctionService.notification.create$(cancelNotification(p), p);
          });
          return forkJoin(notifications$);
        }),
        take(1),
      )
      .toPromise();

    return cancelEvent.then((success) => {
      if (typeof success === 'undefined') {
        // If there are no participants, the success value is undefined
        return;
      }
      if (success) this.snackbar.open('Event cancelled successfully', 'OK');
      else this.snackbar.open('Something went wrong cancelling your event', 'OK');
    });
  }

  private async onJoinGameSuccess(userId: string, eventItemId: string) {
    const eventJoiners$ = this.cloudFunctionService.eventJoiner.list$(eventItemId);
    this.subscriptions.add(
      eventJoiners$.subscribe((eventJoiners) => {
        if (!eventJoiners?.length) return;

        const player = eventJoiners.find((eventJoiner) => eventJoiner.player === userId);
        if (!player) return;


        const status = player.status;
        let message;
        const { rsvpStatus } = (player as any);
        let participationType = 'Participating';
        switch (status) {
          case EventJoinerStatus.Waitlisted:
            message = 'Successfully joined the waitlist';
            break;
          case EventJoinerStatus.PendingApprovers:
          case EventJoinerStatus.PendingCreator:
            message = 'Successfully requested to join the event';
            break;
          default:
            switch (rsvpStatus) {
              case EventRsvpStatus.ATTEMPTING:
              case EventRsvpStatus.MAYBE:
                participationType = 'Maybe';
                break;
              case EventRsvpStatus.NOT_PLAYING:
                participationType = 'Not Participating';
                break;
              case EventRsvpStatus.PLAYING:
                participationType = 'Participating';
                break;
              case EventRsvpStatus.SPECTATING:
                participationType = 'Spectating';
                break;
            }
            message = `You've successfully marked yourself as a '${participationType}'`;
            break;
        }
        if (message) this.snackbar.open(message);
      }),
    );
  }

  async onLeaveGame(game: GthEventItemModel, user: GthUserModel) {
    const leave = this.cloudFunctionService.eventJoiner
      .leaveEvent$(user.uid, game.id)
      .pipe(take(1))
      .toPromise();

    return await leave.then((success) => {
      if (!success) {
        this.snackbar.open('Error leaving game.', 'OK');
        return;
      }
      this.onLeaveGameSuccess();
    });
  }

  private onLeaveGameSuccess() {
    this.snackbar.open('Successfully left game');
  }

  private displayEventsPage(
    platform: 'gth' | 'meh',
    event: GthEventItemModel,
    user: GthUserModel,
    team?: GthTeamModel,
  ): Observable<boolean> {
    switch (platform) {
      case 'meh':
        {
          const eventInfoDialogContract: EventInfoDialogOpenContract = {
            event,
            user,
            team,
            platform,
          };
          const dialogRef = this.dialog.open(EventInfoDialogComponent, {
            id: 'game-info-dialog',
            data: eventInfoDialogContract,
            backdropClass: 'gth-overlay-backdrop',
            panelClass: 'gth-dialog',
          });
          return new Observable((observer) => {
            dialogRef.afterClosed()
              .subscribe(async (result: EventInfoDialogCloseContract) => {
                if (result) {
                  switch (result.closeMethod) {
                    case EventInfoDialogCloseMethod.SAVE:
                      const join = await this.onJoinEvent(platform, event, user, undefined);
                      observer.next(join);
                      observer.complete();
                      break;
                    case EventInfoDialogCloseMethod.LEAVE:
                      await this.onLeaveGame(event, user);
                      observer.next(true);
                      observer.complete();
                      break;
                    case EventInfoDialogCloseMethod.CANCEL:
                      await this.onCancelGame(event, user);
                      observer.next(true);
                      observer.complete();
                      break;
                    case EventInfoDialogCloseMethod.PAYMENTS:
                      this.router.navigate(
                        [APP_ROUTES.Settings],
                        { queryParams: { tab: 'payments' } },
                      );
                      observer.next(true);
                      observer.complete();
                      break;
                    case EventInfoDialogCloseMethod.OTHER:
                    default:
                      observer.next(false);
                      observer.complete();
                      break;
                  }
                }
                observer.next(false);
                observer.complete();
              });
          });
        }
      case 'gth':
        if (team) {
          this.router.navigate([APP_ROUTES.Teams, team.id, TEAMS_ROUTES.SCHEDULE, event.id]);
        } else {
          this.router.navigate([APP_ROUTES.DiscoverGames, event.id]);
        }
        return of(true);
    }
  }

  async onJoinEvent(
    platform: 'gth' | 'meh',
    event: GthEventItemModel,
    user: GthUserModel,
    isGuestUser = false,
    rsvpSelected = EventRsvpStatus.ATTEMPTING,
  ): Promise<boolean> {
    const noishRsvp = [
      EventRsvpStatus.NOT_PLAYING,
      EventRsvpStatus.MAYBE,
      EventRsvpStatus.SPECTATING,
    ];
    const status = noishRsvp.includes(rsvpSelected) ?
      EventJoinerStatus.NotCommited : undefined;
    /** check for default user uid and open dialog to get name and email */
    const isGuest = user.uid === DEFAULT_CURRENT_USER.uid || user.uid === GUEST_PROFILE_ID;
    if (isGuest) {
      const guestUser = await this.openRsvpEventDialog(event);
      if (!guestUser) return false;

      return this.onJoinEvent(platform, event, guestUser, true);
    }

    const eventCosts = (typeof event.cost === 'number' && event.cost !== 0) ||
      !!event?.selectedTicketLevel;
    const rsvpStatus = rsvpSelected ?? EventRsvpStatus.PLAYING;

    /**
     * Check if event allows participant guests
     * show dialog for user to enter guests if allowed
     */
    if (event.allowParticipantGuests) {
      const rsvpDialogRef = this.dialog.open(GthRsvpWithGuestDialogComponent, {
        id: 'rsvp-with-guest-dialog',
        backdropClass: 'gth-overlay-backdrop',
        panelClass: 'gth-dialog',
      });

      const guests = await rsvpDialogRef.afterClosed().pipe(
        first(),
      ).toPromise();

      if (!guests) {
        return true;
      }

      if (eventCosts) {
        return this.openJoinEventCostDialog(
          platform,
          event,
          user,
          guests,
          isGuestUser,
          rsvpSelected,
        );
      } else {
        // eslint-disable-next-line max-len
        return this.onJoinGame(event, user, rsvpSelected, guests, true, status, isGuestUser);
      }
    } else if (eventCosts) {
      return this.openJoinEventCostDialog(
        platform,
        event,
        user,
        [],
        isGuestUser,
        rsvpSelected,
      );
    } else {
      return this.onJoinGame(event, user, rsvpSelected, [], true, status, isGuestUser);
    }
  }

  deleteEvent(
    game: GthEventItemModel,
  ) {
    this.cloudFunctionService.event.delete(game.id, game);
  }

  openJoinEventCostDialog(
    platform: 'gth' | 'meh',
    event: GthEventItemModel,
    user: GthUserModel,
    guests: EventItemGuest[] = [],
    isGuestUser = false,
    status: EventRsvpStatus = EventRsvpStatus.ATTEMPTING,
  ): Promise<boolean> {
    return new Promise((resolve) => {
      const contract: PaymentDialogContract = {
        type: StripeItemType.JOIN_EVENT,
        event,
        user,
        email: isGuestUser ? user.email : undefined,
        // eslint-disable-next-line max-len
        hasPlatformFee: (platform === 'meh' ? event.creator?.userSubscription : event.creator?.subscription) === 'Free',
        platform,
      };
      const dialogRef = this.dialog.open(PaymentDialogComponent, {
        data: contract,
        minWidth: 360,
      });

      dialogRef
        .afterClosed()
        .pipe(take(1))
        .forEach(async (success) => {
          if (success) {
            return await this.onJoinGame(
              event, user, status, guests,
              true, undefined, isGuestUser,
            );
          } else return resolve(false);
        });
    });
  }

  openRsvpEventDialog(event: GthEventItemModel): Promise<GthUserModel | null> {
    return new Promise((resolve) => {
      const rsvpEventDialogContract = {
        event,
      };
      const dialogRef = this.dialog.open(
        RsvpEventDialogComponent,
        {
          id: 'rsvp-event-dialog',
          data: rsvpEventDialogContract,
        },
      );
      dialogRef.afterClosed()
        .pipe(first())
        .forEach(async (contract: RSVPEventDialogCloseContract) => {
          if (!contract) return resolve(null);

          switch (contract.closeMethod) {
            case RSVPEventDialogCloseMethod.RSVP:
              try {
                return resolve(await this.handleRsvpEvent(event, contract));
              } catch (error: unknown) {
                console.error('Error handling RSVP:', error);
                this.snackbar.open(
                  'Some went wrong while processing RSVP',
                  'OK',
                  { duration: 0 },
                );
                return resolve(null);
              }
            case RSVPEventDialogCloseMethod.CANCEL:
            default:
              return resolve(null);
          }
        });
    });
  }

  private async handleRsvpEvent(
    event: GthEventItemModel,
    { email, name }: RSVPEventDialogCloseContract,
  ): Promise<GthUserModel | null> {
    const isEventParticipant = (id: string) => {
      return event.participants.find((p) => p.player === id);
    };

    if (isEventParticipant((email))) {
      this.snackbar.open('Email account already an event participant', 'OK', { duration: 0 });
      return null;
    }

    const user = await this.cloudFunctionService.user
      .getUserByEmail$(email).pipe(first()).toPromise();

    if (user) {
      if (isEventParticipant(user.uid)) {
        this.snackbar.open(
          'Email account already an event participant',
          'OK',
          { duration: 0 },
        );
        return null;
      }

      this.snackbar.open('Email account already exists', '', { duration: 3500 });
      return user;
    }

    /** check if guest user already exists */
    let guestUser = await this.cloudFunctionService.guests
      .getGuestByEmail$(email).pipe(first()).toPromise();
    if (guestUser) {
      console.debug('EventsService#handRsvpEvent: guest user found', guestUser);
      return guestUser;
    }

    guestUser = new GthUserModel(
      email,
      {
        uid: email,
        email: email,
        displayName: name,
        fullName: name,
        createdAt: firebase.firestore.Timestamp.now(),
        updatedAt: firebase.firestore.Timestamp.now(),
      },
    );

    const success = await this.cloudFunctionService.guests.create$(guestUser.copy)
      .pipe(first()).toPromise();
    return success ? guestUser : null;
  }
}
