import { GthModel } from '@sentinels/models/model';
import { Invoice } from '@index/interfaces';
import { GthUserModel } from '@sentinels/models/user';
import { GthTeamModel } from '@sentinels/models/team';

export class GthInvoiceModel extends GthModel<Invoice> {
  constructor(id: string, model: Invoice) {
    super(id, model);
    if (!model) return;
  }

  get id() {
    return this._id;
  }

  get stripeInvoiceId() {
    return this.model.stripeInvoiceId;
  }

  get stripeConnectedAccountId() {
    return this.model.stripeConnectedAccountId;
  }

  private _from?: GthUserModel;
  get from() {
    return this._from;
  }
  set from(val: GthUserModel) {
    this._from = val;
    this.model.from = val.uid;
  }

  private _to?: GthUserModel;
  get to() {
    return this._to;
  }
  set to(val: GthUserModel) {
    this._to = val;
    this.model.to = val.uid;
  }

  private _team?: GthTeamModel;
  get team() {
    return this._team;
  }
  set team(val: GthTeamModel) {
    this._team = val;
    this.model.team = val.id;
  }

  get cost() {
    return this.model.cost;
  }

  get pdf() {
    return this.model.pdf;
  }

  get url() {
    return this.model.url;
  }

  get created() {
    return this.model.created;
  }

  get updated() {
    return this.model.updated;
  }

  get status() {
    return this.model.status;
  }

  get description() {
    return this.model.description;
  }
}
