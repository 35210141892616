import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import { distanceBetween } from 'geofire-common';
import { first, map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { ExternalTeam, ExternalTeamRatingItem, TeamRating } from '@index/interfaces';
import { DBUtil } from '@index/utils/db-utils';
import { GthExternalTeamModel, GthUserModel } from '@sentinels/models';
import { CreateMailerRequest } from '@index/interfaces/mailer';
import { GthMailerFunctionService } from './mailer-function.service';

const COLLECTION_NAME = DBUtil.ExternalTeams;

@Injectable({
  providedIn: 'root',
})
export class GthExternalTeamFunctionService {
  constructor(
    private firestore: AngularFirestore,
    private mailer: GthMailerFunctionService,
  ) { }

  async read(id: string): Promise<GthExternalTeamModel | undefined> {
    if (!id) return Promise.resolve(undefined);

    const ref = await this.firestore.collection(COLLECTION_NAME)
      .doc(id)
      .get()
      .pipe(
        first(),
      )
      .toPromise();

    if (!ref.exists) {
      return undefined;
    }

    const model: ExternalTeam = {
      id,
      ...ref.data() as ExternalTeam,
    };

    return new GthExternalTeamModel(id, model);
  }

  /**
   * Creates team within the external team collection.
   * @param {GthExternalTeamModel} team Team to create
   * @return {Promise<string | undefined>} Return new team id or undefined
   */
  async create(team: GthExternalTeamModel): Promise<string | undefined> {
    if (team.id) return Promise.resolve(undefined);

    const model = team.model;
    model.created = firebase.firestore.Timestamp.now();
    model.updated = firebase.firestore.Timestamp.now();

    const ref = this.firestore.collection(COLLECTION_NAME);
    try {
      const newRef = await ref.add(model);
      return newRef.id;
    } catch {
      return undefined;
    }
  }

  getAllSportAvailibility(user: GthUserModel) {
    let htmlString = '';

    for (const sport of user.sportAvailability) {
      if (sport.toggle) {
        htmlString += `<dd>${sport.sport}-${sport.skill}</dd>`;
      }
    }
    return htmlString;
  }

  sendJoinerRequest(creator: GthUserModel, user: GthUserModel) {
    const mailer: CreateMailerRequest = {
      to: `${creator.email}`,
      replyTo: user.email,
      message: {
        subject: `Join/Sub Request Received!`,
        // eslint-disable-next-line max-len
        html: `${user.displayName} Would love to join/sub on your group/team! Here is their contact information:<br />
          <dl>
            <dt>Display Name</dt>
            <dd>${user.displayName}</dd>
            <dt>Full Name</dt>
            <dd>${user.fullName}</dd>
            <dt>Sports</dt>
            ${this.getAllSportAvailibility(user)}
            <dt>Email</dt>
            <dd><a href="mailto:${user.email}">${user.email}</a></dd>
          </dl>
          <br/>
          <p>Please email them directly to contact them</p>
          `,
      },
    };

    return this.mailer.create$(mailer);
  }

  /**
  * Updates team within the external team collection.
  * @param {GthExternalTeamModel} team Team to create
  * @return {Promise<sboolean>} Return true if success
  */
  async update(team: GthExternalTeamModel): Promise<boolean> {
    const { id } = team;

    if (!id) return Promise.resolve(false);

    const model = team.model;
    model.updated = firebase.firestore.Timestamp.now();

    const {
      name,
      description,
      activity,
      photoURL,
      location,
      online,
      updated,
      contactEmail,
      contactPhone,
      skillLevel,
      allowAskToJoin,
    } = model;

    const ref = await this.firestore.collection(COLLECTION_NAME)
      .doc(id)
      .get()
      .pipe(
        first(),
      )
      .toPromise();

    if (!ref.exists) {
      return false;
    }

    const data = JSON.parse(JSON.stringify({
      name,
      description,
      activity,
      photoURL,
      location,
      online,
      updated,
      contactEmail,
      contactPhone,
      skillLevel,
      allowAskToJoin,
    }));

    try {
      return await this.firestore.collection(COLLECTION_NAME).doc(id)
        .update(data)
        .then(() => true)
        .catch(() => false);
    } catch {
      return false;
    }
  }

  async delete(id: string) {
    const ref = await this.firestore.collection(COLLECTION_NAME)
      .doc(id)
      .get()
      .pipe(
        first(),
      )
      .toPromise();

    if (!ref.exists) {
      return false;
    }

    try {
      this.firestore.collection(COLLECTION_NAME).doc(id)
        .delete();
      return true;
    } catch {
      return false;
    }
  }

  public getAllTeams$() {
    const teams$ = this.firestore.collection(COLLECTION_NAME)
      .valueChanges({ idField: 'id' }) as unknown as Observable<ExternalTeam[]>;
    return teams$.pipe(
      take(1),
      map((teams) => {
        if (!teams || teams.length === 0) {
          return [];
        }
        return teams.map((t) => new GthExternalTeamModel(t.id, t));
      }),
    );
  }

  public getAllCreatorTeams$(creatorId: string): Observable<ExternalTeam[]> {
    const teams$ = this.firestore.collection(COLLECTION_NAME, (ref) =>
      ref.where('creator', '==', creatorId),
    ).valueChanges({ idField: 'id' }) as unknown as Observable<ExternalTeam[]>;

    return teams$;
  }

  public readByLocation$(lat: number, lng: number) {
    /** Find cities within 200km of specified location */
    const radiusInM = 200 * 1000;
    const center: [number, number] = [lat, lng];

    const teams$ = this.firestore.collection(COLLECTION_NAME)
      .valueChanges({ idField: 'id' }) as Observable<ExternalTeam[]>;

    return teams$.pipe(
      take(1),
      /** Filter teams that are within 200km of the specified location */
      map((teams) => {
        return teams.filter((team) => {
          if (!team?.location?.lat || !team?.location?.lng) return false;

          const distanceInKm = distanceBetween(
            [team.location.lat, team.location.lng],
            center,
          );
          const distanceInM = distanceInKm * 1000;
          return distanceInM <= radiusInM;
        });
      }),
      map((teams) => {
        if (!teams || teams.length === 0) {
          return [];
        }
        return teams.map((t) => new GthExternalTeamModel(t.id, t));
      }),
    );
  }


  async getRatings(id: string): Promise<ExternalTeamRatingItem[]> {
    const ref = await this.firestore.collection(
      `${COLLECTION_NAME}/${id}/ratings`,
    ).get()
      .pipe(
        first(),
      )
      .toPromise();

    if (ref.empty) {
      return [];
    }

    return ref.docs.map((d) => d.data()) as ExternalTeamRatingItem[];
  }

  async rate(userId: string, id: string, ratings: TeamRating[]) {
    if (!id || !userId) return Promise.resolve(false);
    const ref = await this.firestore.collection(COLLECTION_NAME)
      .doc(id)
      .get()
      .pipe(
        first(),
      )
      .toPromise();

    if (!ref.exists) {
      return false;
    }

    await this.deleteRatingsByUserId(id, userId);

    const ratingsRef = this.firestore.collection(
      `${COLLECTION_NAME}/${id}/ratings`,
    );

    const requests = [];
    for (let i = 0; i < ratings.length; i++) {
      const model = ratings[i];
      requests.push(ratingsRef.add({
        userId,
        rating: model.id,
      }));
    }

    return Promise.allSettled(requests);
  }

  async deleteRatingsByUserId(id: string, userId: string) {
    const db = this.firestore.firestore;
    const batch = db.batch();
    const ref = db.collection(`${COLLECTION_NAME}/${id}/ratings`);
    const qs = await ref.where('userId', '==', userId).get();
    qs.docs.forEach((doc) => batch.delete(doc.ref));
    try {
      await batch.commit();
      return true;
    } catch {
      return false;
    }
  }
}
