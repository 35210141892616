import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input, OnChanges, OnDestroy,
  OnInit,
  Optional,
  Output,
  QueryList, SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatToolbarModule } from '@angular/material/toolbar';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDividerModule } from '@angular/material/divider';
import { ShareModule } from 'ngx-sharebuttons';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';
import { MatChipsModule } from '@angular/material/chips';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import { Router, RouterModule, ActivatedRoute } from '@angular/router';
import { first, map } from 'rxjs/operators';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';

import { APP_ROUTES } from '@shared/helpers';
import { EventFormModule } from '@shared/components/event-form/event-form.module';
import { EventFormComponent } from '@shared/components/event-form/event-form.component';
import { StripeItemType, EventInfoDialogCloseMethod } from '@sentinels/enums';
import {
  ParticipantCollectionComponent,
} from '../participant-collection/participant-collection.component';
import { GthGoogleMapModule } from '../google-map/google-map.module';
import { GUEST_PROFILE_ID, GthCloudFunctionService, StripeService, DEFAULT_CURRENT_USER } from '../../services';
import { GTH_ENVIRONMENT, GthEnvironment } from '../../tokens/environment-tokens';
import { GthEventItemModel, GthUserModel, GthTeamModel, JoinButtonType } from '@sentinels/models';
import { EventJoinerStatus, EventJoiner, EventRsvpStatus, GthEventJoiner } from '@index/interfaces/event-item';
import { PaymentDialogContract, PaymentDialogComponent } from '../../dialogs/payment-dialog/payment-dialog.component';
import { AuthDialogComponent } from '@shared/dialogs/auth-dialog/auth-dialog.component';
import { SrvSafeStorageService } from '@sentinels/services/safe-storage.service';
import { ButtonMenuModule } from '@gth-legacy/directives/button-menu/button-menu.module';
import { ButtonMenuOptions } from '@gth-legacy/directives/button-menu/button-menu.component';
import { ParticipantStatusDialogComponent } from './dialogs/participant-status-dialog.component';
import { MatListModule } from '@angular/material/list';
import { MatTabsModule } from '@angular/material/tabs';
import { WallPostComponent } from './wall-post/wall-post.component';
import { MatMenuModule } from '@angular/material/menu';

export interface JoinEventOptions {
  event: GthEventItemModel,
  status: EventRsvpStatus,
}

export interface EventInfoDisplayConfig {
  banner: boolean,
  map: boolean,
  title: boolean,
  type: boolean,
  description: boolean,
  date: boolean,
  location: boolean,
  gameType: boolean,
  cost: boolean,
  participants: boolean,
  actions: boolean,
  share: boolean,
}

@Component({
  selector: 'gth-event-info',
  templateUrl: './event-info.component.html',
  styleUrls: ['./event-info.component.scss'],
  standalone: true,
  imports: [
    ButtonMenuModule,
    CommonModule,
    FontAwesomeModule,
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatDialogModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTooltipModule,
    MatChipsModule,
    MatDividerModule,
    MatSnackBarModule,
    WallPostComponent,
    ParticipantCollectionComponent,
    GthGoogleMapModule,
    EventFormModule,
    RouterModule,
    ShareModule,
    MatListModule,
    MatDialogModule,
    MatTabsModule,
    MatMenuModule,
  ],
  providers: [
    {
      provide: MatDialogRef,
      useValue: {},
    },
  ],
})
export class EventInfoComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChildren('gameInfoImage', { read: ElementRef })
  gameInfoImageList?: QueryList<ElementRef>;

  @ViewChild(EventFormComponent)
  eventComponent?: EventFormComponent;

  @ViewChild(ParticipantCollectionComponent)
  participantCollectionComponent?: ParticipantCollectionComponent;

  @Input()
  event?: GthEventItemModel;

  @Input()
  user?: GthUserModel;

  @Input()
  platform: 'gth' | 'meh' = 'gth';

  @Input()
  team?: GthTeamModel;

  @Input()
  urlPath = '';

  @Input()
  shouldShow: EventInfoDisplayConfig = {
    banner: true,
    map: true,
    title: true,
    type: true,
    description: true,
    date: true,
    location: true,
    gameType: true,
    cost: true,
    participants: true,
    actions: true,
    share: true,
  };

  @Output()
  joinEvent = new EventEmitter<JoinEventOptions>();

  @Output()
  editEvent = new EventEmitter<GthEventItemModel>();

  @Output()
  manageEvent = new EventEmitter<GthEventItemModel>();

  @Output()
  declineInvite = new EventEmitter<GthEventItemModel>();

  @Output()
  cancelEvent = new EventEmitter<GthEventItemModel>();

  @Output()
  leaveEvent = new EventEmitter<GthEventItemModel>();

  @Output()
  refresh = new EventEmitter<string>();

  currentRoute: string;
  APP_ROUTES = APP_ROUTES;
  faFacebookF = faFacebookF;
  faEnvelope = faEnvelope;
  faInstagram = faInstagram;
  faTwitter = faTwitter;
  url = `${this.config.root}${this.urlPath}`;
  isTeamEvent = false;
  isUserTeamAdmin = false;
  participants: GthUserModel[] = [];
  subscriptions = new Subscription();
  joiners = new BehaviorSubject<GthEventJoiner[]>([]);


  buttonMenuOptions: ButtonMenuOptions[] = [
    { viewValue: 'Yes', value: EventRsvpStatus.PLAYING },
    { viewValue: 'No', value: EventRsvpStatus.NOT_PLAYING },
    { viewValue: 'Maybe', value: EventRsvpStatus.MAYBE },
  ];

  get totalCount() {
    return this.participants?.length;
  }

  get description() {
    if (this.isGameInPast) {
      return `Checkout ${this.config.appName} to learn more about local events you can play!`;
    }

    if (this.cancelled) {
      return 'This event has been cancelled!';
    }

    const status = this.participantStatus;

    switch (status) {
      case EventJoinerStatus.Approved:
        return 'You have joined this event!';
      case EventJoinerStatus.Waitlisted:
        return 'You are on the waitlist';
      case EventJoinerStatus.PendingCreator:
        return 'You have requested to join and are awaiting approval';
      case EventJoinerStatus.Denied:
        return 'Your request to join has been denied';
      case EventJoinerStatus.PendingJoiner:
        return 'You have been invited to join this event!';
      case EventJoinerStatus.PendingApprovers:
        return 'You have requested to join and are awaiting approval';
    }

    const joinType = this.joinButtonType;
    switch (joinType) {
      case JoinButtonType.JoinGame:
        return 'This event is looking for more people like you!';
      case JoinButtonType.JoinWaitlist:
        // eslint-disable-next-line max-len
        return 'This event is full and no longer accepting new people.';
      case JoinButtonType.RequestJoinGame:
        return 'This event requires approval to join.  Request approval now!';
      case JoinButtonType.AcceptInvite:
        return 'You have been invited to join this event!';
      case JoinButtonType.Creator:
        return 'You have created this event!';
    }

    return '';
  }

  get shareDescription() {
    return `Look at this amazing event happening on ${this.config.appName}!!`;
  }

  get gameDescription() {
    return this.event?.description ?? '';
  }

  get banner() {
    return this.event?.banner ?? '';
  }

  get title() {
    return this.event?.title ?? '';
  }

  get dateStart() {
    return this.event?.dateStart ?? undefined;
  }

  get cancelled() {
    return this.event?.cancelled ?? false;
  }

  get canEditEvent() {
    if (this.isTeamEvent) {
      if (!this.isUserTeamAdmin) {
        return false;
      }
    } else if (!this.isCreator) {
      return false;
    }
    return true;
  }

  get isGameInPast() {
    if (!this.dateStart) {
      return false;
    }
    const today = new Date();
    const endOfGame = new Date(this.dateStart);
    const newHours = endOfGame.getHours() + this.durationHours;
    const newMinutes = endOfGame.getMinutes() + this.durationMinutes;
    endOfGame.setHours(newHours, newMinutes);

    if (endOfGame.getTime() > today.getTime()) {
      return false;
    }
    return true;
  }

  get id() {
    return this.event?.id ?? '';
  }

  get durationHours() {
    return this.duration ? this.duration.hours : 0;
  }

  get durationMinutes() {
    return this.duration ? this.duration.minutes : 0;
  }

  get equipmentNeeded() {
    return this.event?.equipmentNeeded ?? [];
  }

  get creatorApprovalNeeded() {
    return this.event?.creatorApprovalNeeded ?? false;
  }

  get eventType() {
    return this.event?.gameType ?? '';
  }

  get gameType() {
    return this.event?.eventType ?? '';
  }

  get lat() {
    return this.event?.location.lat ?? 47.620422;
  }

  get lng() {
    return this.event?.location.lng ?? -122.349358;
  }

  get cost() {
    return this.event?.cost ?? 0;
  }

  get ticketLevels() {
    return this.event?.ticketLevels ?? [];
  }

  get errors() {
    const errors = [];

    if (typeof this.event?.cost === 'number' && this.event.cost !== 0) {
      errors.push('Payment is required');
    }
    if (!this.needParticipants) {
      errors.push('No longer accepting new players');
    }

    return errors;
  }

  get online() {
    return this.event && this.event.online;
  }

  get address() {
    if (!this.event) {
      return '';
    }
    if (this.event.online) {
      return 'This is an online event';
    }
    return this.event.location ? this.event.location.formattedAddress : '';
  }

  //   get joinButtonText() {
  //   if (!this.event) {
  //     return '';
  //   }
  //   return this.event.getJoinButtonText(this.player);
  // }


  get joinButtonText() {
    if (!this.event) {
      return '';
    }
    return this.event.getJoinButtonText(this.player);
  }

  get joinParticipantStatus() {
    if (!this.event) {
      return false;
    }
    return this.event.getJoinParticipantStatus(this.player);
  }

  get participantStatus() {
    if (!this.event) {
      return 'None';
    }
    return this.event.getParticipantStatus(this.player);
  }

  get approvalText() {
    if (!this.event) {
      return '';
    }
    return this.event.getApprovalText(this.player);
  }

  get participant() {
    if (!this.event) {
      return false;
    }

    return this.event.getParticipant(this.player);
  }

  get eventJoiners(): EventJoiner[] {
    if (!this.event) return null;

    return this.event.participants;
  }

  get gameIncludesUsersGender() {
    if (!this.event) {
      return true;
    }

    const totalNeeded = this.event.playerCount.totalNeeded;
    if (totalNeeded === 0) {
      return true;
    }

    return this.event.requiresGender(this.user.gender);
  }

  get needParticipants() {
    if (!this.event) return false;

    const totalNeeded = this.event.playerCount.totalNeeded;
    if (totalNeeded === 0) {
      return true;
    }
    return !!this.event.totalParticipantsNeeded;
  }

  get totalParticipantsNeeded() {
    if (!this.event) return 0;

    return this.event.totalParticipantsNeeded;
  }

  get isCreator(): boolean {
    if (!this.event || !this.user) return false;

    return this.event?.creator?.uid === this.user?.uid;
  }
  get gthButtonText() {
    if (!this.participants.length && !this.user) return 'Waiting...';
    const userAsJoiner = this.participants.find((p)=>{
      return p.uid === this.user?.uid;
    });

    if (!userAsJoiner) return 'Join Game';

    switch (userAsJoiner.metadata.rsvpStatus) {
      case EventRsvpStatus.PLAYING:
        return 'Going';
      case EventRsvpStatus.NOT_PLAYING:
          return 'Not Going';
      case EventRsvpStatus.MAYBE:
        return 'Maybe Attending';
      case EventRsvpStatus.SPECTATING:
          return 'Just Watching';
      case EventRsvpStatus.ATTEMPTING:
            return 'Waiting for Organizer';
    }

    return this.joinButtonText;
  }

  private get joinButtonType(): JoinButtonType {
    if (!this.event) {
      return JoinButtonType.RequestJoinGame;
    }
    return this.event.getJoinButtonType(this.player);
  }

  private get player() {
    return this.user ?? undefined;
  }

  private get duration() {
    return this.event?.duration ?? undefined;
  }

  get isGuestProfile(): boolean {
    if (!this.user) return false;

    return this.user.uid == GUEST_PROFILE_ID || this.user.uid === DEFAULT_CURRENT_USER.uid;
  }

  closeMethod = EventInfoDialogCloseMethod;
  zoom = 14;
  creatorModel: GthUserModel | null = null;

  constructor(
    @Inject(GTH_ENVIRONMENT)
    private config: GthEnvironment,
    @Optional()
    private dialogRef: MatDialogRef<EventInfoComponent>,
    private cloudFunctionService: GthCloudFunctionService,
    private safeStorage: SrvSafeStorageService,
    private stripeService: StripeService,
    private activatedRoute: ActivatedRoute,
    readonly libraray: FaIconLibrary,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private router: Router,
  ) {
    libraray.addIcons(faEnvelope);
  }

  async ngOnInit() {
    this.currentRoute = this.router.url;
    this.creatorModel = this.event.creator;

    console.log(this.creatorModel);
    this.watchQueryParams();
    this.url = `${this.config.root}${this.urlPath}`;
    await this.cloudFunctionService.event.updatePageView$(this.event)
      .pipe(first()).toPromise();

    // eslint-disable-next-line max-len
    if (!this.event || !this.user || this.user.uid === DEFAULT_CURRENT_USER.uid || this.user.uid === GUEST_PROFILE_ID) {
      return;
    }

    if (!this.user.stripeCustomerId) {
      this.user.stripeCustomerId = await this.stripeService.getOrCreateCustomer();
      console.debug('newly created stripe customer id', this.user.stripeCustomerId);
    } else {
      console.debug(`stripe customer id`, this.user.stripeCustomerId);
    }
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.event) {
      await this.refreshParticipants();
    }

    if (changes.team || this.user) {
      this.refreshTeamStatus();
    }
  }


  onLoginClick() {
    this.safeStorage.setItem('routeAfterLogin', this.currentRoute);
    if (this.dialogRef && this.dialogRef.close) {
      this.dialogRef.close();
    }

    const dialogRef = this.dialog.open(AuthDialogComponent, {
      width: '500px',
      height: '700px',
    });

    dialogRef.afterClosed().subscribe((results) => {
      if (results && this.event) {
        this.refresh.emit(this.event.id);
      }
    });
  }

  async refreshParticipants(evt?: GthEventItemModel) {
    this.participants = (await this.populateEventJoiners$(evt ?? this.event)
      .pipe(first()).toPromise());
  }

  watchQueryParams() {
    this.activatedRoute.queryParams.subscribe(async (params) => {
      /** Add event join if needed */
      const joiner = params['joiner'];
      if (joiner) {
        this.cloudFunctionService
          .eventJoiner
          .approvePlayer$(joiner, this.event.id).subscribe((results) => {
            if (results) {
              this.snackBar.open('Successfully added User');
            }
          });
      }
      const redirectStatus = params['redirect_status'];
      const paymentIntentClientSecret = params['payment_intent_client_secret'];
      const paymentIntent = params['payment_intent'];
      if (redirectStatus && paymentIntent && paymentIntentClientSecret) {
        /** Refresh participants and remove params from url */
        this.snackBar.open(
          'Congratulations, Payment was successful!',
          '',
          { duration: 5000 },
        );
        /** refresh event participants */
        this.participants = await this.populateEventJoiners$(this.event)
          .pipe(first()).toPromise();
        /** remove query params from url */
        this.router.navigate([], {
          queryParams: {
            redirect_status: null,
            payment_intent: null,
            payment_intent_client_secret: null,
          },
          queryParamsHandling: 'merge',
        });
      }
    });
  }

  isFormValid() {
    return !!(this.eventComponent && this.eventComponent.valid);
  }

  canJoinWithCurrentPaymentStatus() {
    if (
      !this.event ||
      this.event.cost === null ||
      this.event.cost === 0 ||
      this.event.cost as any === '0' ||
      !this.event.cost
    ) {
      return true;
    }
    return this.user.hasPaymentsSetup();
  }

  onEditButtonClick() {
    if (!this.canEditEvent) {
      return;
    }
    this.router.navigate([APP_ROUTES.CreateGame, this.event.id], {
      queryParams: {
        teamId: this.team?.id,
      },
    });

    this.editEvent.emit(this.event);
  }

  onManageButtonClick() {
    if (!this.canEditEvent) {
      return;
    }
    this.router.navigate([APP_ROUTES.DiscoverGames, this.event.id, 'manage'], {
      queryParams: {
        teamId: this.team?.id,
      },
    });

    this.manageEvent.emit(this.event);
  }

  getPhotoURL(src: string) {
    return src;
  }

  openPaymentDialog() {
    const paymentDialogContract: PaymentDialogContract = {
      type: StripeItemType.JOIN_EVENT,
      event: this.event,
      user: this.user,
      platform: this.platform,
    };
    return this.dialog.open(PaymentDialogComponent, {
      id: 'payment-dialog',
      backdropClass: 'gth-overlay-backdrop',
      panelClass: 'gth-dialog',
      data: paymentDialogContract,
    });
  }

  onJoinEvent(status: EventRsvpStatus) {
    this.joinEvent.emit({
      event: this.event,
      status,
    });
  }

  get disableJoinBtn() {
    return !this.gameIncludesUsersGender ||
      !this.user.hasPaymentsSetup() ||
      this.isCreator ||
      !this.needParticipants;
  }

  onDeclineInvite() {
    this.declineInvite.emit(this.event);
  }

  onCancelEvent() {
    this.cancelEvent.emit(this.event);
  }

  onLeaveEvent() {
    this.leaveEvent.emit(this.event);
  }

  populateEventJoiners$(event: GthEventItemModel): Observable<GthUserModel[]> {
    const participants = event.participants;
    return this.getParticipants$(participants).pipe(
      map((users) => users.filter(Boolean)),
    );
  }

  private getParticipants$(participants: EventJoiner[]) {
    if (participants.length === 0) {
      return of([] as GthUserModel[]);
    }

    const requests$: Observable<GthUserModel | undefined>[] = [];
    participants.forEach((p) => {
      let request: Observable<GthUserModel>;
      if (p.isGuestUser) request = this.cloudFunctionService.guests.getGuestByEmail$(p.player);
      else {
request = this.cloudFunctionService.user.getUserById$(p.player).pipe((map((user)=>{
        user.metadata = {
          rsvpStatus: p.rsvpStatus,
        };
        return user;
      })));
}
      requests$.push(request);
    });
    return combineLatest(requests$).pipe(
      map((users) => users.filter(Boolean)),
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private refreshTeamStatus() {
    if (!this.team) {
      this.isTeamEvent = false;
      this.isUserTeamAdmin = false;
      return;
    }

    this.isTeamEvent = true;
    if (this.team.isUserAdmin(this.user)) {
      this.isUserTeamAdmin = true;
      return;
    }
    this.isUserTeamAdmin = false;
  }

  openParticipantStatusDialog(participants: GthUserModel[]) {
    const dialogRef = this.dialog.open(ParticipantStatusDialogComponent, {
      width: '90vw !important',
      height: '500px',
      data: {
        participants: this.participants,
        isAdmin: this.event.creator.uid === this.user.uid,
       },
    });

    dialogRef.afterClosed().subscribe((result) => {
      // Do nothing.
    });
  }

  openGoogleLink() {
    if (this.event) {
      const eventTitle = encodeURIComponent(this.event.title);
      const eventLocation = encodeURIComponent(this.event.location.formattedAddress);
      const eventDetails = encodeURIComponent(this.event.description);
      const startDate = new Date(this.event.dateStart).toISOString().replace(/-|:|\.\d+/g, '');
      const endDate = new Date(this.event.dateEnd).toISOString().replace(/-|:|\.\d+/g, '');

      // eslint-disable-next-line max-len
      const googleCalendarLink = `https://calendar.google.com/calendar/u/0/r/eventedit?text=${eventTitle}&dates=${startDate}/${endDate}&location=${eventLocation}&details=${eventDetails}`;

      window.open(googleCalendarLink, '_blank');
    }
  }

  generateICalFile() {
    if (this.event) {
      const startDate = new Date(this.event.dateStart).toISOString()
        .replace(/[-:]/g, '')
        .replace(/\.\d{3}/, '')
        .replace(/(?<=.{15})/, '');
      const endDate = new Date(this.event.dateEnd).toISOString()
        .replace(/[-:]/g, '')
        .replace(/\.\d{3}/, '')
        .replace(/(?<=.{15})/, '');

      // eslint-disable-next-line max-len
      const icalString = `BEGIN:VCALENDAR\r\nVERSION:2.0\r\nCALSCALE:GREGORIAN\r\nMETHOD:PUBLISH\r\nBEGIN:VEVENT\r\nDTSTAMP:${new Date().toISOString().replace(/[-:]/g, '').replace(/\.\d{3}/, '').replace(/(?<=.{15})/, '')}\r\nDTSTART;TZID=America/New_York:${startDate}\r\nDTEND;TZID=America/New_York:${endDate}\r\nSTATUS:CONFIRMED\r\nSUMMARY:${this.event.title}\r\nDESCRIPTION:${this.event.description.replace(/(\r\n|\n|\r)/gm, '\\n')}\r\nCLASS:PUBLIC\r\nCREATED:${new Date().toISOString().replace(/[-:]/g, '').replace(/\.\d{3}/, '').replace(/(?<=.{15})/, '')}\r\nGEO:${this.event.location.lat};${this.event.location.lng}\r\nLOCATION:${this.event.location.formattedAddress.replace(/(\r\n|\n|\r)/gm, '\\n')}\r\nURL:${this.config.root}/discover/games/${this.event.id}\r\nLAST-MODIFIED:${new Date().toISOString().replace(/[-:]/g, '').replace(/\.\d{3}/, '').replace(/(?<=.{15})/, '')}\r\nUID:${this.event.id}@meetup.com\r\nEND:VEVENT\r\nEND:VCALENDAR`;

      // Create a Blob from the string
      const blob = new Blob([icalString], { type: 'text/calendar' });

      // Create a link element
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute('download', `${this.event.title}.ics`);

      // Append the link to the body
      document.body.appendChild(link);

      // Trigger the download
      link.click();

      // Clean up
      document.body.removeChild(link);
    }
  }
}
