export enum SkillLevel {
  Any = 'Any',
  Novice = 'Novice',
  Beginner = 'Beginner',
  Intermediate = 'Intermediate',
  Advanced = 'Advanced',
}
export const IndexToSkillLevelMap: Map<number, SkillLevel> = new Map([
  [0, SkillLevel.Any],
  [1, SkillLevel.Novice],
  [2, SkillLevel.Beginner],
  [3, SkillLevel.Intermediate],
  [4, SkillLevel.Advanced],
]);
export const SkillLevelToIndexMap: Map<SkillLevel, number> = new Map([
  [SkillLevel.Any, 0],
  [SkillLevel.Novice, 1],
  [SkillLevel.Beginner, 2],
  [SkillLevel.Intermediate, 3],
  [SkillLevel.Advanced, 4],
]);
