<mat-tab-group class="tab-group" fitInkBarToContent [selectedIndex]="tabIndex" *ngIf="availability"
  (selectedIndexChange)="onSelectedTabChange($event)">
  <mat-tab label="Days & Dates">
    <p class="label">Specific set of dates, or days.</p>
    <p class="sub-label">Let participants know what dates or days of week the event could take place.</p>
    <mat-form-field class="form-field" appearance="outline">
      <mat-label>Dates or Days?</mat-label>
      <mat-select [(ngModel)]="selectedTimeOption" (selectionChange)="onSelectedTimeOptionChange()"
        [disabled]="disabled">
        <mat-option value="dates">Dates</mat-option>
        <mat-option value="days">Days</mat-option>
      </mat-select>
    </mat-form-field>

    <ng-container [ngSwitch]="selectedTimeOption">
      <ng-container *ngSwitchCase="'dates'">
        <mat-calendar [minDate]="today" [selected]="selectedRangeValue"
          (selectedChange)="onDateRangeChange($event)"></mat-calendar>
      </ng-container>
      <ng-container *ngSwitchCase="'days'">
        <mat-selection-list [(ngModel)]="availability.days" [disabled]="disabled">
          <mat-list-option value="sunday">Sunday</mat-list-option>
          <mat-list-option value="monday">Monday</mat-list-option>
          <mat-list-option value="tuesday">Tuesday</mat-list-option>
          <mat-list-option value="wednesday">Wednesday</mat-list-option>
          <mat-list-option value="thursday">Thursday</mat-list-option>
          <mat-list-option value="friday">Friday</mat-list-option>
          <mat-list-option value="saturday">Saturday</mat-list-option>
        </mat-selection-list>
      </ng-container>
    </ng-container>
    <div class="fx-row fx-gap-10x">
      <button *ngIf="!disabled" mat-button class="publish-btn uppercase" color="accent" (click)="onClearDateRange()">
        Clear
      </button>
      <button mat-stroked-button class="publish-btn uppercase fx-auto" color="accent" (click)="tabIndex = 1"
        [disabled]="!dateRangeValid">
        Set Time
      </button>
    </div>

  </mat-tab>
  <mat-tab label="Times">
    <p class="label">What times might work?</p>
    <p class="sub-label">Let participants know when the event could take place.</p>
    <mat-checkbox [(ngModel)]="availability.allDay">Available the Entire Day</mat-checkbox>
    <mat-form-field class="form-field" appearance="outline">
      <mat-label>Availability Start</mat-label>
      <mat-select [(ngModel)]="availability.startTime" [disabled]="availability.allDay || disabled">
        <mat-option *ngFor="let option of timeOptions" [value]="option">
          {{ option }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="form-field" appearance="outline">
      <mat-label>Availability End</mat-label>
      <mat-select [(ngModel)]="availability.endTime" [disabled]="availability.allDay || disabled">
        <mat-option *ngFor="let option of timeOptions" [value]="option"
          [disabled]="!isTimeRangeValid(availability.startTime, option)">
          {{ option }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button *ngIf="!individualPlan; else individualPlanTmpl" mat-stroked-button class="publish-btn uppercase"
      color="accent" (click)="tabIndex = 2" [disabled]="!timeRangeValid">
      Preview & Publish
    </button>
    <ng-template #individualPlanTmpl>
      <button mat-stroked-button class="publish-btn uppercase" color="accent" (click)="onPublishButtonClick()"
        [disabled]="!valid || disabled">
        Publish
      </button>
    </ng-template>
  </mat-tab>
  <mat-tab label="Preview & Publish" *ngIf="!individualPlan">
    <p class="label">Sharing your Event</p>
    <p class="sub-label">Create a link to share with your friends and propsective participants.</p>
    <mat-form-field appearance="outline">
      <mat-label>Event Name</mat-label>
      <input matInput [(ngModel)]="availability.title" [disabled]="disabled" />
    </mat-form-field>
    <div class="link-row">
      <p>{{ uri }}</p>

      <mat-form-field appearance="outline">
        <mat-label>Event Link Name</mat-label>
        <input matInput [(ngModel)]="availability.linkName" [disabled]="disabled" />
      </mat-form-field>
    </div>

    <button mat-stroked-button class="publish-btn uppercase" color="accent" (click)="onPublishButtonClick()"
      [disabled]="!valid || disabled">
      Publish
    </button>
  </mat-tab>
</mat-tab-group>