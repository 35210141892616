import { GeoFire } from './geofire';
import firebase from 'firebase/compat/app';

import { DBUtil } from '../../utils/db-utils';
import { TeamListFilter } from '../../interfaces/team-list-filter';

export class TeamListFactory {
  constructor(
    readonly firestore: firebase.firestore.Firestore,
    private readonly geoFire: GeoFire = new GeoFire(),
  ) { }

  async getByLatLng(lat: number, lng: number) {
    const ref = await this.firestore.collection(DBUtil.Team);

    const radiusInM = 200 * 1000;
    const center: [number, number] = [lat, lng];
    const bounds = this.geoFire.getBounds(center, radiusInM);
    const teamsInBounds = this.geoFire.getTeamsInBounds(bounds, ref);
    return await this.geoFire.mergeAllTeams(teamsInBounds, radiusInM, center);
  }

  async getByPlayer(player: string) {
    try {
      const dataArray = [] as firebase.firestore.DocumentSnapshot[];
      const ref = await this.firestore.collectionGroup(DBUtil.Roster)
        .where('player', '==', player);
      const rosters = (await ref.get()).docs;

      for (const item of rosters) {
        /** Only add teams that the player is a member of and not pending */
        if (!['Owner', 'Member', 'Admin'].includes(item.get('role'))) {
          continue;
        }

        const parent = item.ref.parent.parent;
        dataArray.push(await parent.get());
      }
      return dataArray;
    } catch (e) {
      return [];
    }
  }

  async getAllTeams() {
    const dataArray = [] as firebase.firestore.DocumentSnapshot[];
    const ref = await this.firestore.collection(DBUtil.Team);
    for (const item of (await ref.get()).docs) {
      const parent = item.ref;
      dataArray.push(await parent.get());
    }
    return dataArray;
  }


  async get(filter: TeamListFilter) {
    switch (true) {
      /* List teams by player */
      case filter.player !== undefined:
        return await this.getByPlayer(filter.player);
      case filter.lat !== undefined && filter.lng !== undefined:
        return await this.getByLatLng(filter.lat, filter.lng);
      default:
        return await this.getAllTeams();
    }
  }
}
