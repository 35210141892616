import { Component, OnInit } from '@angular/core';
import { Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export const POLICY_TABS = {
  privacyTabIndex: 0,
  termsConditionsTabIndex: 1,
  notificationsTabIndex: 2,
};

@Component({
  templateUrl: './policy-dialog.component.html',
  styleUrls: ['./policy-dialog.component.scss'],
})
export class GthPolicyDialogComponent implements OnInit {
  tabIndex: number;

  constructor(
    private dialogRef: MatDialogRef<GthPolicyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    this.tabIndex = this.data;
  }

  close() {
    this.dialogRef.close();
  }
}
