<div  class='container' *ngIf="(curUser | async) as curUser">
  <mat-card class="post-create">
    <mat-card-header>
      <img 
        class="post-avatar-post-input"
        mat-card-avatar 
        src="{{curUser.photoURL}}"/>
      <input class="text-input" placeholder="Write a post..."  [(ngModel)]="newPost"/>
    </mat-card-header>
    <button mat-button color="primary" (click)="addPost(newPost)">Post!</button>
  </mat-card>

  <mat-card *ngFor="let post of (posts | async)" class="post-card">
    <!-- Post header -->
    <mat-card-header>
      <img class="post-avatar" mat-card-avatar src="{{(post.creator | async).photoURL}}" />
      <mat-card-title>{{ (post.creator | async).displayNameFallback }}</mat-card-title>
      <mat-card-subtitle>{{ post.getTimeFormatForUI(post.createdAt)}}</mat-card-subtitle>
    </mat-card-header>
  
    <!-- Post content -->
    <mat-card-content>
      <p>{{ post.content | slice:0:100 }}{{ post.content.length > 100 ? '...' : '' }}</p>
      <button *ngIf="post.content.length > 100" mat-button color="primary" (click)="toggleContent(post)">
        {{ post.showFullContent ? 'See less' : 'See more' }}
      </button>
    </mat-card-content>
    
    <div class="post-public-data" *ngIf="!!post.likes.length">
      <mat-divider class="divider"></mat-divider>
      {{ post.likes.length }} {{post.likes.length === 1?  'person' : 'people' }} liked this post!
    </div>
    <mat-divider class="divider"></mat-divider>
    <!-- Like and comment buttons -->
    <mat-card-actions >
      <button mat-icon-button [color]="post.likes.includes(curUser.uid)? 'accent' : 'black'" 
              (click)="likePost(post, curUser.uid)">
        <mat-icon color="white" >thumb_up_outlined</mat-icon>
      </button>
      <button mat-icon-button color="black" (click)="toggleCommentInput(post)">
        <mat-icon >comment</mat-icon>
      </button>
    </mat-card-actions>
  
    <mat-divider class="divider"></mat-divider>
  
    <!-- Comment input -->
    <mat-card-content class="comment-input" *ngIf="post.showCommentInput">
      <input class="text-input" matInput placeholder="Write a comment..." [(ngModel)]="post.newComment"/>
      <button mat-button color="primary" (click)="addComment(post, curUser.id)">Add Comment</button>
    </mat-card-content>
  
    <!-- Comments -->
    <mat-card-content>
      <ng-container *ngIf="!post.showAllComments; else allComments">
        <mat-card *ngFor="let comment of post.comments.slice(0, 3)" class="comment-card">
          <mat-card-header>
            <img class="comment-avatar" mat-card-avatar src="{{(post.creator | async).photoURL}}" />
            <mat-card-title>{{ (comment.userModel | async)?.displayNameFallback }}</mat-card-title>
            <mat-card-subtitle>{{ post.getTimeFormatForUI(comment.createdAt) }}</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <p>{{ comment.content }}</p>
          </mat-card-content>
        </mat-card>
        <button mat-button  *ngIf="post.comments.length > 3" color="primary" (click)="toggleShowAllComments(post)">See more...</button>
      </ng-container>
      <ng-template #allComments>
        <mat-card *ngFor="let comment of post.comments" class="comment-card">
          <mat-card-header>
            <img class="comment-avatar" mat-card-avatar src="{{(post.creator | async).photoURL}}" />
            <mat-card-title>{{ (comment.userModel | async)?.displayNameFallback }}</mat-card-title>
            <mat-card-subtitle>{{ post.getTimeFormatForUI(comment.createdAt) }}</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <p>{{ comment.content }}</p>
          </mat-card-content>
        </mat-card>
        <button mat-button color="primary" (click)="toggleShowAllComments(post)">See less...</button>
      </ng-template>
    </mat-card-content>
  </mat-card>
</div>
