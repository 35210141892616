import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { from, Observable } from 'rxjs';

import { EventItemGuest, EventJoinerStatus, EventJoinerUpdateRequest, EventRsvpStatus, GthEventJoiner, Payload, Results } from '@index/interfaces';
import { CallableOptions, CallableRoutes, GthCallableService } from './callable.service';
import { GthErrorLoggerService } from './error-logger.service';

const CONTEXT = 'GthEventJoinerFunctionService';

@Injectable({
  providedIn: 'root',
})
export class GthEventJoinerFunctionService {
  constructor(
    public functions: AngularFireFunctions,
    public callableService: GthCallableService,
    private logger: GthErrorLoggerService,
  ) { }

  /**
   * Joins an event
   * @param {string} userId Id of the user
   * @param {string} eventItemId Id of the event
   * @param {EventRsvpStatus} rsvpStatus RSVP Status
   * @param {EventItemGuest[]} guests Guests the user will be bringing
   * @param {EventJoinerStatus} joinerStatus Status generally used for invites
   * @param {boolean} isGuestUser Whether event joiner is unauthenticated
   * @return {boolean} True if successful
   */
  joinEvent$(
    userId: string,
    eventItemId: string,
    rsvpStatus: EventRsvpStatus,
    guests: EventItemGuest[],
    joinerStatus?: EventJoinerStatus,
    isGuestUser = false,
  ): Observable<boolean | null> {
    return from(
      this.joinEvent(userId, eventItemId, rsvpStatus, guests, joinerStatus, isGuestUser),
    );
  }

  /**
   * Leaves an event
   * @param {string} player Id of the user
   * @param {string} event Id of the event
   * @return {boolean} True if successful
   */
  leaveEvent$(player: string, event: string): Observable<boolean | null> {
    return from(this.leaveEvent(player, event));
  }

  /**
   * Approve a player for an event
   * @param {string} player Id of the user
   * @param {string} event Id of the event
   * @return {boolean} True if successful
   */
  approvePlayer$(player: string, event: string): Observable<boolean | null> {
    return from(this.approvePlayer(player, event));
  }

   /**
   * Update a player for an event
   * @param {EventJoinerUpdateRequest} jsur An object containing the player
    * and event information for update.
   * @return {boolean} True if successful
   */
   updatePlayer$(jsur:EventJoinerUpdateRequest): Observable<boolean | null> {
    return from(this.updatePlayer(jsur));
  }

  /**
   * Deny a player for an event
   * @param {string} player Id of the user
   * @param {string} event Id of the event
   * @return {boolean} True if successful
   */
  denyPlayer$(player: string, event: string): Observable<boolean | null> {
    return from(this.denyPlayer(player, event));
  }

  /**
   * Gets a list of players within a team
   * @param {string} eventItemId Id of the Event
   * @return {GthEventItemModel[]}
   */
  list$(eventItemId: string): Observable<GthEventJoiner[]> {
    return from(this.list(eventItemId));
  }

  /**
   * Removes a player from a game
   * @param {string} teamId Id of the team
   * @param {string} userId Id of the user
   * @return {Promise<boolean>} True if success
   */
  delete$(teamId: string, userId: string): Observable<boolean> {
    return from(this.delete(teamId, userId));
  }

  /**
   * Joins an event
   * @param {string} userId Id of the user
   * @param {string} eventItemId Id of the event
   * @param {EventRsvpStatus} rsvpStatus RSVP Status
   * @param {EventItemGuest[]} guests Guests the user will be bringing
   * @param {EventJoinerStatus} joinerStatus Status generally used for invites
   * @param {boolean} isGuestUser Whether event joiner is unauthenticated
   * @return {boolean} True if successful
   */
  private async joinEvent(
    userId: string,
    eventItemId: string,
    rsvpStatus: EventRsvpStatus,
    guests: EventItemGuest[],
    joinerStatus?: EventJoinerStatus,
    isGuestUser = false,
  ): Promise<boolean | null> {
    if (!this.functions) return Promise.resolve(null);

    const options: CallableOptions = {
      route: CallableRoutes.EVENT_JOINER_CREATE,
      data: {
        userId,
        eventItemId,
        guests,
        rsvpStatus,
        joinerStatus,
        isGuestUser,
      },
    };

    const response = (await this.callableService.call(options)) as Results<boolean>;
    this.log(`${userId} has joined event`);
    return response && response.success;
  }

  /**
   * Leaves an event
   * @param {string} player Id of the user
   * @param {string} event Id of the event
   * @return {boolean} True if successful
   */
  private async leaveEvent(player: string, event: string): Promise<boolean | null> {
    if (!this.functions) return Promise.resolve(null);

    const options: CallableOptions = {
      route: CallableRoutes.EVENT_JOINER_UPDATE,
      data: {
        player,
        event,
        status: EventJoinerStatus.Dropped,
      },
    };

    const response = (await this.callableService.call(options)) as Results<boolean>;
    this.log(`${player} has left event`);
    return response && response.success;
  }

  /**
   * Approve a player for an event
   * @param {string} player Id of the user
   * @param {string} event Id of the event
   * @return {boolean} True if successful
   */
  private async approvePlayer(player: string, event: string): Promise<boolean | null> {
    if (!this.functions) return Promise.resolve(null);

    const options: CallableOptions = {
      route: CallableRoutes.EVENT_JOINER_UPDATE,
      data: {
        player,
        event,
        status: EventJoinerStatus.Approved,
      },
    };

    const response = (await this.callableService.call(options)) as Results<boolean>;
    this.log(`${player} has been approved`);
    return response && response.success;
  }

  /**
   * Deny a player for an event
   * @param {string} player Id of the user
   * @param {string} event Id of the event
   * @return {boolean} True if successful
   */
  private async denyPlayer(player: string, event: string): Promise<boolean | null> {
    if (!this.functions) return Promise.resolve(null);

    const options: CallableOptions = {
      route: CallableRoutes.EVENT_JOINER_UPDATE,
      data: {
        player,
        event,
        status: EventJoinerStatus.Denied,
      },
    };

    const response = (await this.callableService.call(options)) as Results<boolean>;
    this.log(`${player} has been denied`);
    return response && response.success;
  }

  /**
   * Gets a list of players within a team
   * @param {string} eventItemId Id of the Event
   * @return {GthEventItemModel[]}
   */
  private async list(eventItemId: string): Promise<GthEventJoiner[]> {
    const options: CallableOptions = {
      route: CallableRoutes.EVENT_JOINER_LIST,
      data: eventItemId,
    };
    const response = (await this.callableService.call(options)) as Results<GthEventJoiner>;

    if (response && response.success && response.payload) {
      const results = response.payload as Payload<GthEventJoiner>[];
      this.log(`Event joiners fetched for ${eventItemId}`);
      return results.map((d) => {
        return d.data;
      });
    }

    return [];
  }

  /**
   * Removes a player from a game
   * @param {string} teamId Id of the team
   * @param {string} userId Id of the user
   * @return {Promise<boolean>} True if success
   */
  private async delete(teamId: string, userId: string): Promise<boolean> {
    if (!this.functions) return Promise.resolve(false);

    const options: CallableOptions = {
      route: CallableRoutes.EVENT_JOINER_DELETE,
      data: {
        teamId,
        userId,
      },
    };
    const response = (await this.callableService.call(options)) as Results<boolean>;
    this.log(`Removed ${userId} from ${teamId}`);
    return response && response.success;
  }


   /**
   * Updates a player's participation status for an event.
   * @param {EventJoinerUpdateRequest} jsur An object containing the player
    * and event IDs for the update.
   * @return {Promise<boolean | null>}
    * true: Promise resolves to true if the update is successful.
    * null: Promise resolves to null if the functions property is not initialized.
    * false: Promise rejects with an error if the update fails (implementation detail).
   */
   private async updatePlayer(jsur: EventJoinerUpdateRequest): Promise<boolean | null> {
    if (!this.functions) return Promise.resolve(null);

    const options: CallableOptions = {
      route: CallableRoutes.EVENT_JOINER_UPDATE,
      data: {
        ...jsur,
      },
    };

    const response = (await this.callableService.call(options)) as Results<boolean>;
    return response && response.success;
   }

  private log(text: string) {
    this.logger.debug(`${CONTEXT}: ${text}`);
  }
}
