import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import 'firebase/firestore';
import firebase from 'firebase/compat/app';

import { EventItemModel } from '../models/event-item';
import { DBUtil } from '../utils/db-utils';
import { EventItemMapper } from '../mappers/event-item-mapper';
import {
  EventItemDeleteRequest,
  UpdateEventItemRequest,
  EventJoinerStatus,
  EventItem,
} from '../interfaces/event-item';
import { EventListFactory } from './utils/event-list-factory';
import { EventJoinerSorter } from './utils/event-joiner-sorter';
import { EventJoinerDAOService } from './event-joiner-dao';
import { EventItemListFilter } from '../interfaces/event-list-filter';

export interface UpdateResponse {
  success: boolean;
  joinerType: EventJoinerStatus | null;
}

export interface UpdateEventPageViewContract {
  event: EventItem,
}

export interface GetEventPageViewContract {
  event: EventItem,
  date?: Date,
}

@Injectable({
  providedIn: 'root',
})
export class EventItemDAOService {
  mapper = new EventItemMapper();
  readonly firestore = this.afs.firestore;
  private eventListFactory = new EventListFactory(this.firestore);
  private readonly sorter = new EventJoinerSorter();
  private readonly eventPageViewShards = 5;

  constructor(
    private readonly afs:AngularFirestore,
    private readonly eventJoinerDao: EventJoinerDAOService,
  ) {
  }

  async create(eventItemModel: EventItemModel) {
    const collectionRef = this.firestore.collection(DBUtil.EventItem);
    const docRef = collectionRef.doc();
    eventItemModel.ref = docRef as any;
    await docRef.set(this.mapper.toMap(eventItemModel));
    return docRef;
  }

  async update(request: UpdateEventItemRequest) {
    const eventItemModel = this.mapper.toMap(request as unknown as EventItemModel);
    /** Remove EventJoinerModel from participants */
    for (const k in eventItemModel.genderInfo) {
      if (eventItemModel.genderInfo[k]?.participants?.length) {
        eventItemModel.genderInfo[k].participants = [];
      }
    }
    const collectionRef = this.firestore.collection(DBUtil.EventItem);
    const doc = await collectionRef.doc(request.id);

    const response: UpdateResponse = {
      success: true,
      joinerType: null,
    };

    /** Set flag for firebase function to sync with Stripe */
    eventItemModel.syncedToStripe = false;
    await doc.set(eventItemModel, { merge: true });

    return response;
  }

  async list(filter: EventItemListFilter) {
    let events: EventItemModel[] = [];
    events = await this.eventListFactory.get(filter);

    for await (const event of events) {
      const joiners = await this.eventJoinerDao.list(event?.id);
      if (event?.genderInfo) {
        event.genderInfo = this.sorter.sort(joiners, event?.genderInfo);
      }
    }
    return events;
  }

  async delete(request: EventItemDeleteRequest) {
    const collectionRef = this.firestore.collection(DBUtil.EventItem);
    const doc = await collectionRef.doc(request.eventId);
    await doc.delete();
    return true;
  }

  async updatePageView(contract: UpdateEventPageViewContract) {
    if (!contract?.event) return Promise.resolve(false);

    /** Increment event page view count */
    const shardId = Math.floor(Math.random() * this.eventPageViewShards).toString();
    const dateFormat = new Date().toISOString().slice(0, 10);
    const shardRef = this.firestore.collection(DBUtil.EventItem).doc(contract.event.id)
      .collection(DBUtil.EventInsights).doc(dateFormat)
      .collection('shards').doc(shardId);
    return await shardRef.set(
      { views: firebase.firestore.FieldValue.increment(1) },
      { merge: true },
    ).then(() => true);
  }

  async getPageViews(contract: GetEventPageViewContract) {
    if (!contract?.event) return Promise.resolve(0);

    /** Consolidate all shard page views */
    let views = 0;

    for (let i = 0; i < this.eventPageViewShards; i++) {
      const todayDate = new Date();
      const date = contract?.date ?? todayDate;
      const dateFormat = date.toISOString().slice(0, 10);
      const shardRef = this.firestore
        .collection(DBUtil.EventItem).doc(contract.event.id)
        .collection(DBUtil.EventInsights).doc(dateFormat)
        .collection('shards').doc(i.toString());
      const shardSnap = await shardRef.get();
      if (!shardSnap.exists) continue;
      views = views + (await shardSnap.get('views'));
    }

    return Promise.resolve(views);
  }
}
