<div class="participant-collection">
  <mat-list class="main-participant">
    <mat-list-item class="participant" *ngFor="let user of displayedParticipants | slice:0:maxDisplayed">
      <div class="list-item fx-row">
        <ng-container [ngTemplateOutlet]="participantImageTmpl" [ngTemplateOutletContext]="{ user: user}">
        </ng-container>
        <div class="list-item-content fx-column">
          <span class="user-name">{{user.displayNameFallback}}</span>
          <span class="rsvp-status">{{user.rsvpStatus}}</span>
        </div>
      </div>
    </mat-list-item>
  </mat-list>
  <br />

  <mat-accordion class="custom-panel" *ngIf="displayedParticipants.length > maxDisplayed">
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          + {{ displayedParticipants.length - 3 }} more participant(s)
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-list class="main-participant">
        <mat-list-item class="participant"
          *ngFor="let user of displayedParticipants| slice:maxDisplayed:displayedParticipants.length">
          <div class="list-item fx-row">
            <ng-container [ngTemplateOutlet]="participantImageTmpl" [ngTemplateOutletContext]="{ user: user}">
            </ng-container>
            <div class="list-item-content fx-column">
              <span class="user-name">{{user.displayNameFallback}}</span>
              <span class="rsvp-status">{{user.rsvpStatus}}</span>
            </div>
          </div>
        </mat-list-item>
      </mat-list>
    </mat-expansion-panel>
  </mat-accordion>
</div>

<ng-template #participantImageTmpl let-user='user' let-isFirst='isFirst'>
  <img [gthTemplateTooltip]="user" [matBadge]="'+'+getGuestCount(user)" [matBadgeHidden]="getGuestCount(user) < 1"
    *ngIf="user.photoURL && !invalidUserPhotos.includes(user.uid); else userTmpl" [src]="user.photoURL"
    [attr.alt]="user.displayNameFallback" [matTooltip]="user.displayNameFallback" (error)="onImageError(user)" />

  <ng-template #userTmpl>
    <div class="participant-initial-container participant-image" [gthTemplateTooltip]="user">
      <span class="participant-initial">{{ user.displayNameFallback?.substring(0, 1) }}</span>
    </div>
  </ng-template>
</ng-template>