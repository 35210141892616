import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { from, Observable } from 'rxjs';

import {
  CallableOptions,
  CallableRoutes,
  GthCallableService,
} from './callable.service';
import { GthErrorLoggerService } from './error-logger.service';
import { Payload, Results } from '@index/interfaces/results';
import { CreateMailerRequest } from '@index/interfaces/mailer';

const CONTEXT = 'GthMailerFunctionService';

@Injectable({ providedIn: 'root' })
export class GthMailerFunctionService {
  constructor(
    public functions: AngularFireFunctions,
    public callableService: GthCallableService,
    private logger: GthErrorLoggerService,
  ) { }

  /**
   * Creates a mailer document
   * @param {CreateMailerRequest} email
   * @return {string | null} ID of mailer document
   */
  create$(email: CreateMailerRequest): Observable<string | null> {
    return from(this.create(email));
  }

  /**
   * Creates a mailer document
   * @param {CreateMailerRequest} email
   * @return {string | null}
   */
  private async create(email: CreateMailerRequest): Promise<string | null> {
    if (!this.functions) return Promise.resolve(null);

    const options: CallableOptions = {
      route: CallableRoutes.MAILER_CREATE,
      data: email,
    };
    const results = await this.callableService.call(options) as Results<string>;
    if (results.success && results.payload) {
      const payload = results.payload as Payload<string>;
      this.log(`Mailer document created`);
      return payload.data;
    }

    return null;
  }

  private log(text: string) {
    this.logger.debug(`${CONTEXT}: ${text}`);
  }
}
