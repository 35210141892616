export const DB_API_KEY = 'AIzaSyASewx6UTNa7l2bhd7HXVEUX0Xz6ZdioQc';
export const STAGING_API_KEY = 'AIzaSyDkb4pt096uYK3uN0iEppXfVV9j9wEAcaA';
// eslint-disable-next-line max-len
export const STRIPE_API_KEY = 'pk_test_51NpMJmEGIuJxcjmDhTg0RBMOIeuycFTAx35w2swCLzbA0PsYmabLStPEzmvxaVf0D29SHPMykrVgU5ppwpkCudvG00RRZaXfHx';
// eslint-disable-next-line max-len
export const STAGING_STRIPE_API_KEY = 'pk_live_51NpMJmEGIuJxcjmDIoMxgP6LCOHCczBS7P7BIXNNDjLH0kehYn4hPFG01UD3UrwRlYDV4tD4V8nPZXIRKzPIbgep00BFx1EEdR';

export const GOOGLE_MAPS_KEY = 'AIzaSyASewx6UTNa7l2bhd7HXVEUX0Xz6ZdioQc';
export const STAGING_GOOGLE_MAPS_KEY = 'AIzaSyASewx6UTNa7l2bhd7HXVEUX0Xz6ZdioQc';

export const PROD_API_KEY = 'AIzaSyBYqcmfEsrJUmOaZBQYkjGjuNeNLx3gUhw';
export const PROD_GOOGLE_MAPS_KEY = DB_API_KEY;
