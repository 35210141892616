import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';

import { Payment } from '@index/interfaces';

interface OpenPaymentItem {
  name: string;
  paid: number;
  unpaid: number;
  outstanding: number;
  payment: Payment;
}

@Component({
  selector: 'gth-payments-opened-table',
  templateUrl: './payments-opened-table.component.html',
  styleUrls: ['./payments-opened-table.component.scss'],
})
export class GthPaymentsOpenedTableComponent implements OnChanges {
  @Input()
  payments: Payment[] = [];

  @Output()
  edit = new EventEmitter<Payment>();

  displayedColumns: string[] = ['name', 'unpaid', 'paid', 'outstanding', 'actions'];
  dataSource: OpenPaymentItem[] = [];

  ngOnChanges(changes: SimpleChanges) {
    if (changes.payments) {
      this.populateDataSource(this.payments);
    }
  }

  onEditViewButtonClick(payment: Payment) {
    this.edit.emit(payment);
  }

  private populateDataSource(payments: Payment[]) {
    this.dataSource = [];
    if (payments) {
      for (const payment of payments) {
        const name = payment.name;
        const amount = payment.amount;
        let paid = 0;
        let unpaid = 0;
        let outstanding = 0;
        for (const member of payment.users) {
          if (member.paid) {
            paid += amount;
          } else {
            unpaid += amount;
            outstanding++;
          }
        }

        this.dataSource.push({
          name,
          paid,
          outstanding,
          unpaid,
          payment,
        });
      }
    }
  }
}
