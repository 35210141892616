import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'gth-google-button',
  templateUrl: './google-button.component.html',
  styleUrls: ['./google-button.component.scss'],
})
export class GthGoogleButtonComponent {
  @Output()
  socialClick = new EventEmitter<void>();

  @Input()
  text = 'Sign in with Google';

  handleClick() {
    this.socialClick.emit();
  }
}
