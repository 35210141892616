import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { GthCloudFunctionService, GthTimeService } from '../../services';
import { GthPlanItem, GthPlanTime, GthPlannerItem } from './models/plan';
import { AvailabilityDay } from '../../interfaces/availability';
import { getRandomColor } from './chart-colors';
import { GthUserModel } from '../../../../../sentinels/src/lib/models/user';

@Component({
  selector: 'gth-planner',
  templateUrl: './planner.component.html',
  styleUrls: ['./planner.component.scss'],
})
export class GthPlannerComponent implements OnChanges {
  @Input()
  plannerName = '';

  @Input()
  availabilityItems: GthPlannerItem[] = [];

  displayedDateIndexes = [0, 1, 2, 3, 4, 5, 6];
  displayedDates: Date[] = [];
  displayedTimes: string[] = [];
  displayedAvailability?: any;
  plainTextDates = [];

  private plans: GthPlanItem[] = [];
  private planColors: Map<string, string> = new Map();
  private users: Map<string, GthUserModel> = new Map();

  constructor(
    private cloud: GthCloudFunctionService,
    private time: GthTimeService,
  ) {
    const today = new Date();
    this.displayedTimes = time.getTimeOptions()
      .filter((t) => !t.endsWith(':15 AM') && !t.endsWith(':15 PM'));
    this.setDateRange(today);
    this.planColors.set('group', '#4caf50');
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.availabilityItems) {
      await this.populateValues();
    }
  }

  private async populateValues() {
    this.clearPlans();
    this.availabilityItems.forEach(async (i) => this.populatePlans(i));
    const userIds = this.availabilityItems
      .map((i) => i.userId)
      .filter((id) => id !== 'group');
    const users = await this.cloud.user.getUsersById(userIds);
    if (users.length === 1) {
      users.forEach((user) => this.users.set(user.id, user));
    }

    this.displayedAvailability = undefined;
    const results = [];
    for (let dateIndex = 0; dateIndex < this.displayedDates.length; dateIndex++) {
      results.push([]);
      this.displayedTimes.forEach((time) => {
        const timeProperties = this.getTimeProperties(dateIndex, time);
        results[dateIndex].push(timeProperties);
      });
    }
    this.displayedAvailability = results;
  }

  private getTimeProperties(dateIndex: number, time: string) {
    const date = this.displayedDates[dateIndex];
    const plan = this.plans.find((p) => this.time.isDateEqual(date, p.date));
    const plansAtTime = plan.times.filter((p) => p.time === time);
    return plansAtTime
      .map((p) => {
        return this.getTimeAvailability(plan, p);
      })
      .filter((p) => p !== undefined)
      .flat();
  }

  private getTimeAvailability(plan: GthPlanItem, planTime: GthPlanTime) {
    const { id, time } = planTime;
    const userPlans = plan.times.filter((t) => t.id === id);
    const indexOfTime = userPlans.findIndex((t) => t.time === time);
    let backgroundColor = this.planColors.get(id);
    const user = this.users.get(id);
    let tooltip = 'Group Availability';
    if (user) {
      tooltip = `${user.displayNameFallback} Availability`;
    }
    const plansAtTime = plan.times
      .filter((t) => t.time === planTime.time).length;
    if (plansAtTime > 1) {
      tooltip += `: ${plansAtTime} available`;
    }
    const availability = this.availabilityItems.find((i) => i.id === id);
    if (!backgroundColor) {
      backgroundColor = getRandomColor();
      this.planColors.set(id, backgroundColor);
    }

    if (plan.times.length === 96) {
      return [{
        id,
        backgroundColor,
        start: indexOfTime === 0,
        end: indexOfTime === plan.times.length - 1,
        available: true,
        availability,
        tooltip,
      }];
    } else {
      if (indexOfTime >= 0) {
        return [{
          id,
          backgroundColor,
          start: indexOfTime === 0,
          end: indexOfTime === plan.times.length - 1,
          available: true,
          availability,
          tooltip,
        }];
      }
    }
    return undefined;
  }

  private setDateRange(startDate: Date) {
    this.displayedDates = [
      startDate,
    ];
    for (let i = 0; i < 6; i++) {
      const nextDate = new Date(startDate);
      nextDate.setDate(nextDate.getDate() + i + 1);
      this.displayedDates.push(nextDate);
    }

    this.plainTextDates = this.displayedDates.map((d) => this.time.getPlaintextDate(d));
  }

  private async populatePlans(availabiilty: GthPlannerItem) {
    if (!availabiilty.userId) return;

    // Add by Day
    if (availabiilty.days?.length > 0) {
      const availableDays = availabiilty.days.map((d) => this.getDayIndex(d));

      availableDays.forEach((dayIndex) => {
        this.displayedDates.forEach((displayedDate) => {
          // Check to see if this day is available within the plan
          if (displayedDate.getDay() === dayIndex) {
            const dayPlan = this.plans.find((p) => this.isDateEqual(displayedDate, p.date));

            if (dayPlan) {
              if (availabiilty.allDay) {
                dayPlan.addAllDay(availabiilty.id);
              } else {
                const startTime = availabiilty.startTime;
                const endTime = availabiilty.endTime;
                dayPlan.addTime(availabiilty.userId, startTime, endTime);
              }
            }
          }
        });
      });
      return;
    }

    // Add by Date
    if (availabiilty.dates?.length > 0) {
      availabiilty.dates.forEach((d) => {
        const dayPlan = this.plans.find((p) => this.isDateEqual(d, p.date));
        // Is date within date range
        if (dayPlan) {
          if (availabiilty.allDay) {
            dayPlan.addAllDay(availabiilty.id);
          } else {
            const startTime = availabiilty.startTime;
            const endTime = availabiilty.endTime;
            dayPlan.addTime(availabiilty.userId, startTime, endTime);
          }
        }
      });
    }
  }

  private clearPlans() {
    this.displayedDates.forEach((d) => {
      const datePlan = new GthPlanItem(d);
      this.plans.push(datePlan);
    });
  }

  private getDayIndex(day: AvailabilityDay) {
    switch (day) {
      case AvailabilityDay.Monday:
        return 1;
      case AvailabilityDay.Tuesday:
        return 2;
      case AvailabilityDay.Wednesday:
        return 3;
      case AvailabilityDay.Thursday:
        return 4;
      case AvailabilityDay.Friday:
        return 5;
      case AvailabilityDay.Saturday:
        return 6;
      case AvailabilityDay.Sunday:
      default:
        return 0;
    }
  }

  private isDateEqual(d1: Date, d2: Date) {
    return this.time.isDateEqual(d1, d2);
  }
}
