import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AngularFireAuthGuard, redirectLoggedInTo, redirectUnauthorizedTo } from '@angular/fire/compat/auth-guard';

export const MEH_APP_ROUTES = {
  Home: '/home',
  Events: '/events',
  Analytics: '/analytics',
  AvailabiltyPlanner: '/availability-planner',
  Login: '/login',
  SignUp: '/sign-up',
  AdminTools: '/admin-tools',
  AdminAttendance: '/admin-tools/attendance',
  AdminContacts: '/admin-tools/contacts',
  Settings: '/settings',
  CreateEvent: '/create',
  ManageEvent: (eventId: string) => `/events/${eventId}/manage`,
};

// eslint-disable-next-line max-len
export const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo([MEH_APP_ROUTES.Login]);
export const redirectLoggedInToHome = () => redirectLoggedInTo([MEH_APP_ROUTES.Events]);

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'events',
  },
  {
    path: 'events',
    loadChildren: () => import('./features/events/events.module')
      .then((m) => m.MehEventsModule),
  },
  {
    path: 'create/:id',
    redirectTo: '/events/:id/manage',
  },
  {
    path: 'analytics',
    loadComponent: () => import('./features/analytics/analytics.component')
      .then((m) => m.MehAnalyticsComponent),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'availability-planner',
    loadComponent: () =>
      import('./features/availability-planner/availability-planner.component')
        .then((m) => m.MehAvailabilityPlannerComponent),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'plan/:id',
    loadComponent: () =>
      import('./features/plan/plan.component')
        .then((m) => m.MehPlanComponent),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'create',
    loadComponent: () => import('./features/create-event/create-event.component')
      .then((m) => m.CreateEventComponent),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'login',
    loadComponent: () => import('./features/login/login.component')
      .then((m) => m.MehLoginComponent),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectLoggedInToHome },
  },
  {
    path: 'sign-up',
    loadComponent: () => import('./features/sign-up/sign-up.component')
      .then((m) => m.MehSignUpComponent),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectLoggedInToHome },
  },
  {
    path: 'forgot-password',
    loadComponent: () => import('./features/forgot-password/forgot-password.component')
      .then((m) => m.MehForgotPasswordComponent),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectLoggedInToHome },
  },
  {
    path: 'settings',
    loadComponent: () => import('./features/settings/settings.component')
      .then((m) => m.SettingsComponent),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'admin-tools',
    redirectTo: '/admin-tools/contacts',
    pathMatch: 'full',
  },
  {
    path: 'admin-tools/contacts',
    loadComponent: () =>
      import('./features/admin-tools/components/contacts/contacts.component')
        .then((m) => m.MehAdminContactsComponent),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'admin-tools/attendance',
    loadComponent: () =>
      import('./features/admin-tools/components/attendance/attendance.component')
        .then((m) => m.MehAdminAttendanceComponent),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: '**',
    redirectTo: 'events',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
