<button mat-icon-button type="button" class="close-btn" mat-dialog-close>
  <mat-icon fontIcon="close" />
</button>

<h1 mat-dialog-title>{{title}}</h1>

<mat-dialog-content>
  <h2 class="cost">{{total | currency}}{{type !== StripeItemType.JOIN_EVENT ? occurance : ''}}</h2>

  <div class="table">
    <div class="row">
      <div class="name">Cost</div>
      <div class="value">{{subtotal | currency}}</div>
    </div>

    <div class="row">
      <div class="name">Taxes</div>
      <div class="value">{{taxes | currency}}</div>
    </div>

    <div class="row">
      <div class="name">Service Fees</div>
      <div class="value">{{serviceFees | currency}}</div>
    </div>

    <div class="total row">
      <div class="name">Total:</div>
      <div class="value">
        {{total | currency}}{{type !== StripeItemType.JOIN_EVENT ? occurance : ''}}
      </div>
    </div>
  </div>

  <gth-stripe-payment-element *ngIf="!loading && items.length"
                              [stripeAccount]="type === StripeItemType.JOIN_EVENT ? event.creator.stripeId : undefined"
                              [items]="items" [type]="type" [email]="email"
                              [hasPlatformFee]="hasPlatformFee" [platform]="platform"
                              (ready)="onStripePaymentElementReady($event)" />
</mat-dialog-content>
