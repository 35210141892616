import { DBModel } from './model.db';
import firebase from 'firebase/compat/app';
import { NotificationType } from '../enums/notifications';

export class NotificationModel extends DBModel {
  static readonly TYPE = 'type';
  static readonly METADATA = 'metadata';
  static readonly READ = 'read';

  constructor(
    ref: firebase.firestore.DocumentReference<firebase.firestore.DocumentData> | undefined,
    public readonly type: NotificationType,
    public readonly metadata: {[key:string]: string|number|DBModel},
    public readonly read: boolean = false,
  ) {
    super(ref);
  }
}
