import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Payment } from '@index/interfaces';

@Component({
  selector: 'gth-payments-needed-table',
  templateUrl: './payments-needed-table.component.html',
  styleUrls: ['./payments-needed-table.component.scss'],
})
export class GthPaymentsNeededTableComponent {
  @Input()
  payments: Payment[] = [];

  @Output()
  pay = new EventEmitter<Payment>();

  displayedColumns: string[] = ['name', 'amount', 'actions'];

  onPayButtonClick(payment: Payment) {
    this.pay.emit(payment);
  }
}
