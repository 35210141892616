import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbstractControl, FormControl, FormGroup, FormsModule, ReactiveFormsModule, ValidationErrors, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { NgxGpAutocompleteModule } from '@angular-magic/ngx-gp-autocomplete';
import { NgxGpAutocompleteDirective } from '@angular-magic/ngx-gp-autocomplete';
import { geohashForLocation } from 'geofire-common';
import { GthFileUploadService } from '@gth-legacy/services';
import { Observable, Subscription, of } from 'rxjs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDialogModule } from '@angular/material/dialog';
import { first } from 'rxjs/operators';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatRadioModule } from '@angular/material/radio';

import { GthSkillLevelFormComponent } from '../skill-level-form-control/skill-level-form-control.component';
import { GthExternalTeamModel, GthUserModel } from '@sentinels/models';
import { EntityType, ExternalTeam, GameType } from '@index/interfaces';
import { SkillLevel } from '@index/enums/skill-level';
import { Location } from '@index/interfaces/location';
import { DefaultCity } from '../../../../../../../shared/interfaces/user';
import { GthFileUploadModule } from '../file-upload/file-upload.module';
import { GthStoredImageModule } from '../stored-image/stored-image.module';
import { SrvApiService } from '@sentinels/services/api.service';

@Component({
  selector: 'gth-external-team-form',
  standalone: true,
  templateUrl: './external-team-form.component.html',
  styleUrls: ['./external-team-form.component.scss'],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogModule,
    MatExpansionModule,
    MatInputModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    MatSnackBarModule,
    MatSelectModule,
    MatRadioModule,
    NgxGpAutocompleteModule,
    GthSkillLevelFormComponent,
    GthFileUploadModule,
    GthStoredImageModule,
  ],
})
export class GthExternalTeamFormComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('placesRef')
  placesRef: NgxGpAutocompleteDirective;

  @Input()
  user?: GthUserModel;

  @Input()
  team?: GthExternalTeamModel;

  @Input()
  saveButtonLabel = 'Save';

  @Input()
  prefixURL = 'https://app.gametimehero.com/et/';

  @Output()
  save = new EventEmitter<GthExternalTeamModel>();

  get city() {
    const ctrl = this.externalTeamForm.get('city');
    return ctrl ? ctrl.value : [];
  }

  get valid() {
    return this.externalTeamForm.valid;
  }

  get photoURL() {
    return this.externalTeamForm.get('photoURL').value;
  }

  get skillLevelUIRender() {
    if (!this.externalTeamForm.controls.level.value ||
      this.externalTeamForm.controls.level.value.length === 4) {
      return 'All Levels';
    }

    return this.externalTeamForm.controls.level.value.join(', ');
  }

  get cityName() {
    const ctrl = this.externalTeamForm.get('city');
    if (!ctrl || !ctrl.value) return;
    const val = ctrl.value as any;
    return val.city ?? '';
  }
  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  set city(val: DefaultCity | google.maps.places.PlaceResult | undefined) {
    const ctrl = this.externalTeamForm.get('city');
    if (!ctrl) return;
    ctrl.setValue(val);
  }

  /**
   * Form group for external team details
   */
  externalTeamForm = new FormGroup({
    teamName: new FormControl('', [Validators.required]),
    photoURL: new FormControl(''),
    activity: new FormControl(''),
    externalURL: new FormControl(''),
    entityType: new FormControl<EntityType>(EntityType.Team),
    online: new FormControl<boolean>(false, Validators.required),
    level: new FormControl<Array<SkillLevel>>(null, Validators.required),
    description: new FormControl(''),
    allowAskToJoin: new FormControl(false),
    contactEmail: new FormControl('', [Validators.email]),
    contactPhone: new FormControl(
      '',
      [Validators.minLength(10),
      Validators.maxLength(10)]),
    city: new FormControl<unknown>({ name: '', address_components: [] },
      this.locationValidation),
    cityName: new FormControl<string>('', Validators.required),
  });
  maxUploadSize = 2;
  options = {
    types: ['(cities)'],
    componentRestrictions: { country: 'US' },
  };
  gameTypes: { [key: string]: GameType[] } = {};
  uploading = false;
  teamLogoError = false;
  id = '';

  private subscriptions = new Subscription();
  type = EntityType;

  constructor(
    private api: SrvApiService,
    private uploadService: GthFileUploadService,
    private snackbar: MatSnackBar,
  ) { }

  async ngOnInit() {
    this.gameTypes = await this.api.gameTypes.groupedAsync();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.team && this.team) {
      this.populateTeamForm(this.team);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onAutocompleteSelected(place: any) {
    if (!place || !place.geometry || !place.geometry.location) {
      return;
    }
    const city = {
      name: place.formatted_address,
      lat: place.geometry!.location.lat(),
      lng: place.geometry!.location.lng(),
    };
    this.externalTeamForm.get('city')!.setValue({
      name: city.name,
      lat: city.lat,
      lng: city.lng,
    });
    this.externalTeamForm.get('cityName')!.setValue(place.name);
  }

  clearCityInput() {
    const emptyData = { name: '', address_components: [] };
    this.city = emptyData;
  }

  onGameTypeChanged(gameType: string) {
    this.externalTeamForm.get('activity').setValue(gameType);
  }

  onSaveButtonClick() {
    if (!this.externalTeamForm.valid) return;
    const value = this.externalTeamForm.getRawValue();
    const location = this.getLocation();
    const model: ExternalTeam = {
      id: this.id,
      creator: this.user ? this.user.id : '',
      description: value.description,
      name: value.teamName,
      photoURL: value.photoURL,
      externalURL: value.externalURL,
      activity: value.activity,
      location: location,
      online: value.online,
      skillLevel: value.level,
      entityType: value.entityType,
      contactEmail: value.contactEmail,
      contactPhone: value.contactPhone,
      allowAskToJoin: value.allowAskToJoin,
    };
    const externalTeam = new GthExternalTeamModel(this.id, model);
    this.save.emit(externalTeam);
  }

  getLocation() {
    const place = this.externalTeamForm.controls.city.value as google.maps.places.PlaceResult;
    const lat = place.geometry ? place.geometry.location.lat() : (place as DefaultCity).lat;
    const lng = place.geometry ? place.geometry.location.lng() : (place as DefaultCity).lng;
    const hash = geohashForLocation([lat, lng]);

    return {
      lat,
      lng,
      hash,
      formattedAddress: place.name,
    } as Location;
  }

  async onLogoSelected(files: File[]) {
    if (files.length === 0) {
      return;
    }
    this.uploading = true;
    const logoId = await this.uploadLogo$(files[0])
      .pipe(
        first(),
      )
      .toPromise()
      .catch(() => {
        this.snackbar.open('Error uploading team logo');
      });
    this.uploading = false;
    if (logoId) {
      this.externalTeamForm.get('photoURL').setValue(logoId);
      this.externalTeamForm.markAsDirty();
    }
  }

  private uploadLogo$(file: File): Observable<string | undefined> {
    if (!file) {
      return of(undefined);
    }
    return new Observable((observer) => {
      this.subscriptions.add(
        this.uploadService.upload$(file).subscribe((response) => {
          if (response.uploadPercent === 100 && response.uid) {
            observer.next(response.uid);
            observer.complete();
          }
        }),
      );
    });
  }

  private locationValidation(control: AbstractControl): ValidationErrors | null {
    const city = control.value;
    if ((city?.formattedAddress || city?.name) && city?.lat && city?.lng) return null;

    return { invalid: true };
  }

  private populateTeamForm(team: GthExternalTeamModel) {
    this.id = team.id;
    const cityName = team.location.formattedAddress ?? '';
    this.externalTeamForm.setValue({
      teamName: team.name,
      photoURL: team.photoURL,
      externalURL: team.externalURL,
      entityType: team.entityType,
      activity: team.activity,
      online: team.online,
      level: team.skillLevel,
      description: team.description,
      contactEmail: team.contactEmail,
      contactPhone: team.contactPhone,
      allowAskToJoin: team.allowAskToJoin ?? false,
      city: team.location,
      cityName,
    });
  }
}
