  import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import { take } from 'rxjs/operators';

import { DBUtil } from '../utils/db-utils';
import { EventItemGuest, EventJoinerStatus, EventJoinerUpdateRequest, EventRsvpStatus } from '../interfaces/event-item';
import { EventJoinerModel } from '../models/event-joiner';
import { EventJoinerMapper } from '../mappers/event-joiner-mapper';
import { firestorePopulate } from '../utils/populaters';
import { EventItemMapper } from '../mappers/event-item-mapper';
import { EventItemModel } from '../models/event-item';
import { UserMapper } from '../mappers/user-mapper';

@Injectable({ providedIn: 'root' })
export class EventJoinerDAOService {
  readonly mapper = new EventJoinerMapper();
  constructor(
    readonly firestore: AngularFirestore,
  ) { }

  async create(data: {
    userId: string,
    eventItemId: string,
    guests: EventItemGuest[],
    rsvpStatus: EventRsvpStatus,
    joinerStatus?: EventJoinerStatus,
    isGuestUser?: boolean,
  }) {
    const currentJoinerRef = await this.getJoiner(data.eventItemId, data.userId);
    const eventSnap = await firestorePopulate(
      this.firestore,
      DBUtil.EventItem,
      data.eventItemId,
    );

    const eventDocRef = this.firestore
      .collection(DBUtil.EventItem).doc(data.eventItemId).ref;
    const eventJoinerDocRef = eventDocRef
      .collection(DBUtil.EventJoiner).doc(data.userId);
    const eventItemModel = new EventItemMapper().fromSnapshot(eventSnap);
    const joinerModel = new EventJoinerModel(
      eventJoinerDocRef,
      data.userId,
      data.joinerStatus,
      undefined,
      undefined,
      data.guests,
      data.rsvpStatus,
      firebase.firestore.Timestamp.now() as any,
      data.isGuestUser ?? false,
    );
    const assignedGenderSpot = await this.getAssignedGender(eventItemModel, joinerModel);
    const status = data.joinerStatus ??
      await this.getStatus(eventItemModel, assignedGenderSpot);

    /** Update Event Joiner if already exists */
    if (currentJoinerRef && currentJoinerRef.exists) {
      return this.update({
        status,
        player: data.userId,
        event: data.eventItemId,
        guests: data.guests,
        rsvpStatus: data.rsvpStatus,
      });
    }

    /** Get Write Batch */
    const batch = this.firestore.firestore.batch();

    /** Update Event Document */
    batch.update(eventDocRef, 'updated', firebase.firestore.Timestamp.now());

    /** Create Event Joiner Document */
    const eventJoiner = {
      ...this.mapper.toMap(joinerModel),
      assignedGenderSpot,
      status,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    };
    batch.set(eventJoinerDocRef, eventJoiner);

    /** Commit the Batch */
    return batch.commit()
      .then(() => status);
  }

  async getAssignedGender(eventItem: EventItemModel, joiner: EventJoinerModel) {
    const userCollectionRef = await this.firestore.collection(DBUtil.User);
    const userDocRef = userCollectionRef.doc(joiner.player ?? '');
    const userSnap = await userDocRef.get().pipe(take(1)).toPromise();
    const userModel = new UserMapper().fromSnapshot(userSnap);
    if (!userModel) return 'Any';
    if (!userModel.gender || eventItem.isOpenToAnyGender) return 'Any';

    return userModel.gender;
  }

  async getStatus(eventItem: EventItemModel, assignedGenderSpot: string) {
    const genderInfo = eventItem.genderInfo;

    if (!genderInfo[assignedGenderSpot]) {
      return EventJoinerStatus.PendingCreator;
    }

    if (eventItem.creatorApprovalNeeded) {
      return EventJoinerStatus.PendingCreator;
    }

    if (
      !(await this.hasOpenSpotForAssignedGender(
        eventItem,
        assignedGenderSpot,
        genderInfo[assignedGenderSpot].numberOfPlayersNeeded,
      ))
    ) {
      return EventJoinerStatus.Waitlisted;
    }

    return EventJoinerStatus.Approved;
  }

  async hasOpenSpotForAssignedGender(
    eventItemModel: EventItemModel,
    gender: string,
    availableSpots: number,
  ) {
    const approvedPlayers = await this.getApprovedOpenSpots(eventItemModel, gender);

    return approvedPlayers < availableSpots;
  }

  async getApprovedOpenSpots(eventItemModel: EventItemModel, gender: string) {
    const eventCollectionRef = this.firestore.collection(DBUtil.EventItem);
    const eventDocRef = await eventCollectionRef.doc(eventItemModel.id);
    const query = eventDocRef
      .collection(DBUtil.EventJoiner,
        (ref) => ref.where(EventJoinerModel.ASSIGNED_GENDER_SPOT, '==', gender)
          .where(EventJoinerModel.STATUS, '==', EventJoinerStatus.Approved));
    return (await query.get().toPromise()).size;
  }

  private async listQuery(eventItemId: string) {
    const collectionRef = this.firestore.collection(DBUtil.EventItem);
    return collectionRef.doc(eventItemId);
  }

  async list(eventItemId: string) {
    const models = [];
    const joinerRef = await (await this.listQuery(eventItemId))
      .collection(DBUtil.EventJoiner)
      .get()
      .toPromise();
    for await (const doc of joinerRef.docs) {
      const model = new EventJoinerMapper().fromSnapshot(doc);
      models.push(model!);
    }
    return models;
  }

  async delete(eventItemId: string, userId: string) {
    const collectionRef = this.firestore.collection(DBUtil.EventItem);
    const doc = await collectionRef.doc(eventItemId);
    const joiners = await doc
      .collection(DBUtil.EventJoiner,
        (ref) => ref.where(EventJoinerModel.PLAYER, '==', userId))
      .get()
      .toPromise();
    for (const joiner of joiners.docs) {
      joiner.ref.delete();
    }

    return true;
  }

  async getJoiner(event: string, player: string) {
    const collectionRef = this.firestore.collection(DBUtil.EventItem);
    const doc = await collectionRef.doc(event);
    const snap = (await doc
      .collection(DBUtil.EventJoiner,
        (ref) => ref.where(EventJoinerModel.PLAYER, '==', player)
          .limit(1))
      .get()
      .toPromise()
    );
    return snap.docs[0];
  }

  async update(
    request: EventJoinerUpdateRequest,
    joiner?: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>,
  ) {
    const joinerRef = joiner ?? (await this.getJoiner(request.event, request.player)).ref;

    if (!joinerRef) throw new Error('Error in event-item - joiner ref');


    /** Get Write Batch */
    const batch = this.firestore.firestore.batch();

    /** Update Event Document */
    const eventDocRef = this.firestore.collection(DBUtil.EventItem).doc(request.event).ref;
    batch.update(eventDocRef, 'updated', firebase.firestore.Timestamp.now());

    /** Update Event Joiner Document */
    batch.update(joinerRef, 'status', request.status);
    batch.update(joinerRef, 'rsvpStatus', request.rsvpStatus ?? EventRsvpStatus.NOT_PLAYING);
    if (request?.guests) batch.update(joinerRef, 'guests', request.guests);

    /** Commit the Batch */
    return batch.commit()
      .then(() => true)
      .catch(() => false);
  }

  async getOrderedWaitlistedPlayers(event: string) {
    return (await this.listQuery(event)).collection(DBUtil.EventJoiner,
      (ref) =>
        ref.where(EventJoinerModel.STATUS, '==', EventJoinerStatus.Waitlisted)
          .orderBy(EventJoinerModel.CREATED_AT),
    );
  }

  async changeWaitlistedPlayerToApproved(event: string) {
    const ordered = await (await this.getOrderedWaitlistedPlayers(event))
      .get()
      .toPromise();
    const firstApproved = ordered.docs[0];
    const model = this.mapper.fromSnapshot(firstApproved)!;

    await this.update({
      event,
      player: model.player,
      status: EventJoinerStatus.Approved,
      rsvpStatus: EventRsvpStatus.PLAYING,
    });
  }
}
