<ng-container *ngIf="loading else availabilityTmpl">
  <div class="fx-auto fx-centered">
    <mat-spinner [diameter]="36" />
  </div>
</ng-container>
<ng-template #availabilityTmpl>
  <div class="weekly-schedule-container">
    <div class="schedule-header">
      <h2>Available hours</h2>
      <p>Set the times that people will be able to request you as a sub/participant in their events</p>
    </div>

    <div class="day-schedule" *ngFor="let day of daysOfWeek; let i = index">
      <gth-legacy-date-range [showCheckbox]="true" [showRemoveButton]="true" [showAddTimeButton]="true"
        [textBetweenCheckboxAndInput]="day"
        [(availabilityDay)]="userAvailability?.weeklyAvailability[day.toLowerCase()]"
        (dateRangeFormChange)="onDateRangeChange(day.toLowerCase(), $event)" />
    </div>

    <section class="date-specific">
      <h3>Date-specific hours</h3>
      <p>
        Override your availability specific dates from when your hours differ from your regular weekly hours.
      </p>
      <ul class="date-specific-times">
        <li *ngFor="let dateSpecificTime of userAvailability?.dateSpecificAvailability; let i = index">
          <span>{{dateSpecificTime.date | date}}</span>
          <span>
            <ng-container *ngFor="let timeRange of dateSpecificTime.timeRanges">
              {{timeRange.startTime}} - {{timeRange.endTime}}
            </ng-container>
          </span>
          <button mat-icon-button type="button" (click)="onRemoveDateSpecificTime(i)">
            <mat-icon fontIcon="close" />
          </button>
        </li>
      </ul>
      <button mat-stroked-button type="button" (click)="onAddDateSpecificBtnClick()">
        <mat-icon fontIcon="add" />
        <span>Add date-specific hours</span>
      </button>
    </section>

    <button mat-raised-button type="button" color="primary" [disabled]="!invalidForms.isEmpty()" class="save-btn"
      (click)="onSaveBtnClick()">Save</button>
  </div>
</ng-template>