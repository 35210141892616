import { GameType } from '@index/interfaces';
import { GthModel } from './model';

export class GthGameTypeModel extends GthModel<GameType> {
  get gameType(): GameType {
    return this.model;
  }

  get uid() {
    return this.gameType.uid;
  }

  get id() {
    return this.gameType.id;
  }

  get label() {
    return this.gameType.label;
  }

  get item() {
    return this.gameType.item;
  }

  get type() {
    return this.gameType.type;
  }

  constructor(id: string, model: GameType) {
    super(id, model);
  }
}
