import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { faFacebookF, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faCopy } from '@fortawesome/free-regular-svg-icons';

import { GthEventItemModel, GthUserModel } from '@sentinels/models';
import { StripePaymentElementComponent } from '@gth-legacy/components/stripe-payment-element/stripe-payment-element.component';
import { GthGoogleMapModule } from '@gth-legacy/components/google-map/google-map.module';
import { APP_ROUTES } from '@shared/helpers';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ShareModule } from 'ngx-sharebuttons';
import { FormsModule } from '@angular/forms';
import {
  ParticipantCollectionComponent,
} from '@gth-legacy/components/participant-collection/participant-collection.component';
import { GthCloudFunctionService } from '@gth-legacy/services';
import { forkJoin, Observable, of } from 'rxjs';
import { GTH_ENVIRONMENT, GthEnvironment } from '@gth-legacy/tokens/environment-tokens';

@Component({
  selector: 'gth-event-view',
  templateUrl: './event-view.component.html',
  styleUrls: ['./event-view.component.scss'],
  standalone: true,
  imports: [
    MatToolbarModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatTabsModule,
    MatTooltipModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    MatCardModule,
    MatTooltipModule,

    RouterModule,
    CommonModule,
    FontAwesomeModule,
    ShareModule,

    GthGoogleMapModule,
    FormsModule,
    StripePaymentElementComponent,
    ParticipantCollectionComponent,
  ],
})
export class EventViewComponent {
  @Input()
  event?: GthEventItemModel;

  @Input()
  user?: GthUserModel;

  @Output()
  join = new EventEmitter();

  @Output()
  addPayment = new EventEmitter();

  @Output()
  decline = new EventEmitter();

  @Output()
  edit = new EventEmitter();

  @Output()
  cancel = new EventEmitter();

  @Output()
  refresh = new EventEmitter();

  get APP_ROUTES() {
    return APP_ROUTES;
  }

  faFacebookF = faFacebookF;
  faEnvelope = faEnvelope;
  faInstagram = faInstagram;
  faTwitter = faTwitter;
  faCopy = faCopy;
  zoom = 14;

  constructor(
    private gthCloudFunctionService: GthCloudFunctionService,
    @Inject(GTH_ENVIRONMENT) private config: GthEnvironment,
  ) { }

  onAddPaymentMethodButtonClick() {
    this.addPayment.emit();
  }

  onJoinEventButtonClick() {
    this.join.emit();
  }

  onDeclineInviteButtonClick() {
    this.decline.emit();
  }

  onEditEventButtonClick() {
    this.edit.emit();
  }

  onCancelEventButtonClick() {
    this.cancel.emit();
  }

  getShareData() {
    return {
      uri: `${this.config.root}/public/events/${this.event.id}`,
      description: `Look at this amazing event happening on ${this.config.appName}!!`,
    };
  }

  getEventParticipantStatus() {
    return this.event.getParticipantStatus(this.user);
  }

  getEventErrors() {
    const errors = [];

    if (!this.canJoinWithCurrentPaymentStatus()) {
      errors.push('Payment is required');
    }
    if (!this.needParticipants()) {
      errors.push('No longer accepting new players');
    }
    if (!this.eventIncludesUsersGender()) {
      // eslint-disable-next-line max-len
      errors.push('You cannot join this event because your gender does not match the one(s) required.');
    }

    return errors;
  }

  canJoinWithCurrentPaymentStatus() {
    if (
      !this.event ||
      !this.event.cost
    ) {
      return true;
    }
    return this.user?.stripeChargesEnabled ?? false;
  }

  needParticipants() {
    const totalNeeded = this.event.playerCount.totalNeeded;
    if (totalNeeded === 0) {
      return true;
    }
    return !!this.event.totalParticipantsNeeded;
  }

  getJoinButtonText() {
    return this.event.getJoinButtonText(this.user);
  }

  getJoinButtonType() {
    return this.event.getJoinButtonType(this.user);
  }

  eventIncludesUsersGender() {
    if (!this.event) {
      return true;
    }
    const totalNeeded = this.event.playerCount.totalNeeded;
    if (totalNeeded === 0) {
      return true;
    }
    return this.event.requiresGender(this.user.gender);
  }

  getIsGameInPast() {
    const today = Date.now();
    const eventEnd = this.event.dateEnd.getTime();
    return today > eventEnd;
  }

  getEventIncludesUsersGender() {
    if (!this.event) {
      return true;
    }
    return this.event.requiresGender(this.user.gender);
  }

  getMapData() {
    if (!this.event || this.event.online || !this.event.location) {
      return undefined;
    }

    const { location } = this.event;
    const { lat, lng } = location;

    return {
      lat,
      lng,
      zoom: 14,
    };
  }

  getMapUri() {
    if (!this.event || !this.event.location) {
      return undefined;
    }

    // eslint-disable-next-line max-len
    return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(this.event.location.formattedAddress)}`;
  }

  get lat() {
    return this.event ? this.event.location.lat : 47.620422;
  }

  get lng() {
    return this.event ? this.event.location.lng : -122.349358;
  }

  get online() {
    return this.event && this.event.online;
  }

  get participants$(): Observable<GthUserModel[]> {
    return this.event ? forkJoin(this.event.participants
      .map((p) => this.gthCloudFunctionService.user.getUserById$(p.player))) :
      of([] as GthUserModel[]);
  }
}
