<ng-container *ngIf="subscriptions$ | async as subscriptions else loadingTmpl">
  <mat-form-field appearance="outline">
    <mat-label>Subscription Level</mat-label>
    <mat-select [value]="activeSubscription"
                (selectionChange)="onSubscriptionChange(getSubscription(subscriptions, $event.value))">
      <mat-option *ngFor="let subscription of subscriptions" [value]="subscription.label">
        {{subscription.label}} {{subscription.label !== activeSubscription ? (subscription.cost | currency) : ''}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div class="plans">
    <div class="plan mat-elevation-z4" *ngFor="let subscription of subscriptions"
         [ngClass]="{'active': subscription.label === activeSubscription}">
      <div class="name">{{subscription.label}}</div>

      <div class="price">
        <div class="value">
          <span>{{subscription.cost | currency}}</span>
          <strong>USD</strong>
        </div>
        <div class="clarifications">/{{subscriptionIncrement}}</div>
      </div>

      <button mat-stroked-button color="primary"
              (click)="onSubscriptionBtnClick(getSubscription(subscriptions, subscription.label))"
              [disabled]="subscription.label === activeSubscription">
        {{subscription.label === activeSubscription ? 'Current' : 'Get started'}}
      </button>

      <mat-divider />

      <ul class="features">
        <li *ngFor="let feature of subscription.features">
          <mat-icon color="primary" fontIcon="done" /> {{feature}}
        </li>
      </ul>
    </div>
  </div>
</ng-container>
<ng-template #loadingTmpl>
  <div class="fx-auto fx-centered">
    <mat-spinner [diameter]="36" />
  </div>
</ng-template>
