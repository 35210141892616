<gth-event-form
  [currentUser]="currentUser$ | async"
  [teamName]="teamName"
  [event]="event"
  [disabled]="disabled"
  [eventItemType]="eventItemType"
  [editing]="editing"
  [routeOnSave]="routeOnSave"
  [public]="public"
  [displayTicketLevels]="displayTicketLevels"
  [displayGenderInformation]="displayGenderInformation"
  [displayAppearanceSettings]="displayAppearanceSettings"
  [displaySendFeedbackEmailAfterEvent]="displaySendFeedbackEmailAfterEvent"
  (selectTime)="onTimeButtonClick($event)"
  (selectAddress)="onAddressButtonClick($event)"
  (save)="onEventSave($event)"
  (savePublic)="onPublicEventSave($event)"
  [eventPlatform]="eventPlatform" />