<ng-container *ngIf="loading">
  <div class="fx-auto fx-fill fx-centered">
    <mat-spinner [diameter]="36"></mat-spinner>
  </div>
</ng-container>
<form [ngClass]="{'hide': loading}" class="payment-form" (ngSubmit)="onPayBtnClick()">
  <strong *ngIf="paymentError">{{paymentError.message}}</strong>

  <div id="payment-element" #paymentElement>
    <!-- Stripe Elements will create form elements here -->
  </div>

  <button mat-flat-button type="submit" color="accent" (click)="onPayBtnClick()">Pay with Card</button>
</form>