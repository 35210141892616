import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import { CloudCollectionService } from './cloud-collection.service';
import { Gender } from '@index/interfaces';
import { DBUtil } from '@index/utils/db-utils';
import { SrvCacheService } from '../cache.service';
import { GthGenderModel } from '../../models/gender';

const COLLECTION_NAME = DBUtil.Genders;

@Injectable({
  providedIn: 'root',
})
export class SrvGendersService {
  private cloudCollection = new CloudCollectionService(this.firestore, COLLECTION_NAME);

  constructor(
    private firestore: AngularFirestore,
    private cache: SrvCacheService,
  ) {
  }

  /**
   * Gets a list of all genders
   * @return {Promise<GthGendersModel[]>} Returns a list of genders
   */
  async listAsync(): Promise<GthGenderModel[]> {
    const store = this.cache.read<GthGenderModel[]>(COLLECTION_NAME);
    if (store) return store;

    const items = await this.cloudCollection.list<Gender>();
    if (!items) return undefined;

    let male;
    let female;
    let other;
    let any;
    const maleIndex = items.findIndex((i) => i.label === 'Male');
    if (maleIndex >= 0) {
      male = { ...items[maleIndex] };
      items.splice(maleIndex, 1);
    }
    const femaleIndex = items.findIndex((i) => i.label === 'Female');
    if (femaleIndex >= 0) {
      female = { ...items[femaleIndex] };
      items.splice(femaleIndex, 1);
    }
    const otherIndex = items.findIndex((i) => i.label === 'Other');
    if (otherIndex >= 0) {
      other = { ...items[otherIndex] };
      items.splice(otherIndex, 1);
    }
    const anyIndex = items.findIndex((i) => i.label === 'Any');
    if (anyIndex >= 0) {
      any = { ...items[anyIndex] };
      items.splice(anyIndex, 1);
    }

    if (male) {
      items.unshift(male);
    }
    if (female) {
      items.unshift(female);
    }
    if (any) {
      items.unshift(any);
    }
    if (other) {
      items.push(other);
    }

    const models = items.map((i) => new GthGenderModel(i.id.toString(), i));

    this.cache.store(COLLECTION_NAME, models);
    return models;
  }
}
