<h1 mat-dialog-title>RSVP for Event</h1>

<form mat-dialog-content [formGroup]="rsvpEventForm" (ngSubmit)="onRSVPBtnClick()">
  <mat-form-field appearance="outline">
    <mat-label>Name</mat-label>
    <input matInput type="text" name="name" formControlName="name" />
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Email</mat-label>
    <input matInput type="email" name="email" formControlName="email" />
  </mat-form-field>
</form>


<mat-dialog-actions>
  <button mat-stroked-button type="button" color="primary" class="uppercase"
          [disabled]="rsvpEventForm.invalid"
          (click)="onRSVPBtnClick()">Rsvp!</button>

  <button mat-button type="button" color="warn" (click)="onCancelBtnClick()">Cancel</button>
</mat-dialog-actions>