<div class="ratings" *ngIf="teamRatings">
  <h3>Team Rating</h3>

  <p *ngIf="!ratings || ratings.length === 0; else ratingsTmpl">
    This team has not been rated yet.
  </p>
  <div class="rating-container">
    <div *ngFor="let rating of displayedRatings" class="rating-item fx-row fx-gap-10x">
      <span class="rating-label fx-auto">{{ rating.label }}</span>
      <span class="rating-count">{{ rating.count }}</span>
    </div>
  </div>

  <ng-template #ratingsTmpl>

  </ng-template>

  <!--View Review-->
  <ng-container *ngIf="view === 'write'">
    <h3>Rate this Team</h3>

    <p>Select the behaviors this team exemplifies.</p>

    <mat-chip-listbox aria-label="Team Review" [multiple]="true" *ngIf="teamRatings; else loadingTmpl"
      [value]="selectedTeamRatings" (change)="onTeamRatingChange($event)">
      <mat-chip-option *ngFor="let teamRatingType of teamRatings" [value]="teamRatingType.item">
        {{teamRatingType.label}}
      </mat-chip-option>
    </mat-chip-listbox>

    <div class="fx-row">
      <button mat-flat-button (click)="onRatingsSubmit()" color="primary"
        [disabled]="!selectedTeamRatings.length">Submit Ratings</button>
    </div>

    <ng-template #loadingTmpl>
      <div class="fx-auto fx-fill fx-centered">
        <mat-spinner [diameter]="36" />
      </div>
    </ng-template>
  </ng-container>
</div>