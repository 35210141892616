<ng-container *ngIf="ticketLevelFormControl">
  <div class="ticket-level" *ngFor="let ticketLevel of ticketLevelFormControl.controls; let i=index">
    <mat-form-field appearance="outline">
      <mat-label>Ticket Name</mat-label>
      <input matInput type="text" name="name" [formControl]="ticketLevel.controls.name" />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Ticket Cost</mat-label>
      <input matInput type="number" name="cost" [formControl]="ticketLevel.controls.cost" min="0" max="999999" />
      <mat-icon matPrefix fontIcon="attach_money" />
    </mat-form-field>

    <button mat-icon-button type="button"
            [matTooltip]="'Delete ' + ticketLevel.controls.name.value ?? 'ticket level'"
            [disabled]="disabled"
            (click)="ticketLevelFormControl.removeAt(i)">
      <mat-icon fontIcon="delete" />
    </button>
  </div>
</ng-container>

<div [matTooltipDisabled]="!disabled" matTooltip="Ticket levels disabled: Your account is not yet set up to accept payments.">
  <button mat-fab extended type="button" color="primary"
          [disabled]="disabled" (click)="onAddTicketLevel()">
    <mat-icon fontIcon="add" />
    <span>Add Ticket Level</span>
  </button>
</div>
