import { Injectable } from '@angular/core';
import {
  TournamentEventItem,
  TournamentEventWinCondition,
  TournamentEventScheduleDate,
  TournamentJoiner,
  TournamentEventItemLocation,
  TournamentFormat,
  TournamentEventType,
  TournamentTeam,
} from '@index/interfaces';
import { GthTournamentModel } from '@sentinels/models';

const MAX_NAME_LENGTH = 10;

export interface GthTournamentSchedule {
  events?: TournamentEventItem[];
  error: GthTournamentScheduleError;
}

export interface GthTournamentScheduleContract {
  gameDuration: number;
  winCondition: TournamentEventWinCondition;
  gamesPerMatch: number;
  gameDays: TournamentEventScheduleDate[];
}

export interface GthTournamentScheduleEvents {
  matches: GthTournamentScheduleEventItem[];

  offset?: TournamentJoiner;
}

export interface GthTournamentScheduleEventItem {
  teams: TournamentJoiner[];
  startDate?: Date;
  endDate?: Date;
  created?: Date;
  location?: TournamentEventItemLocation;

  played: boolean;
  scores?: number[];
}

export enum GthTournamentScheduleError {
  None = 0,
  MissingTournament = 1,
  MissingSchedule = 2,
  MissingFormat = 3,
  NotEnoughTime = 4,
  Unknown = 999,
}

@Injectable({
  providedIn: 'root',
})
export class GthTournamentSchedulerService {
  getGeneratedSchedule(
    tournament: GthTournamentModel,
    schedule: GthTournamentScheduleContract,
  ): GthTournamentSchedule {
    if (!tournament) {
      return {
        error: GthTournamentScheduleError.MissingTournament,
      };
    }
    if (
      !schedule.gameDays ||
      schedule.gameDays.length === 0) {
      return {
        error: GthTournamentScheduleError.MissingSchedule,
      };
    }

    const format = tournament.format;

    switch (format) {
      case TournamentFormat.SingleElimination:
        return this.getSingleEliminationTournament(tournament, schedule);
      case TournamentFormat.DoubleElimination:
        return this.getDoubleEliminationTournament(tournament, schedule);
      default:
        return {
          error: GthTournamentScheduleError.MissingFormat,
        };
    }
  }

  private getSingleEliminationTournament(
    tournament: GthTournamentModel,
    schedule: GthTournamentScheduleContract,
  ): GthTournamentSchedule {
    const teams = this.getTournamentParticipants(tournament);
    const round = this.getSingleEliminationRound(teams);
    const rounds = [];
    rounds.push(round);

    while (true) {
      const roundTeams = [];
      const lastRound = rounds[rounds.length - 1];
      const matches = [...lastRound.matches];
      const offset = lastRound.offset;

      if (matches.length <= 0) {
        break;
      }

      matches.forEach((match) => {
        if (match.played && match.scores && match.scores.length > 0) {
          const teams = match.teams;
          const scores = match.scores;
          const sorted = [...scores].sort((a, b) => b - a);
          const index = scores.indexOf(sorted[0]);
          const winningTeam = teams[index];
          roundTeams.push(winningTeam);
        } else {
          let combinedTeamName = match.teams.map(
            (team) => tournament.eventType === TournamentEventType.Singles ?
              team.name :
              team.teamName)
            .join(' & ');

          if (combinedTeamName.length > MAX_NAME_LENGTH) {
            combinedTeamName = combinedTeamName
              .slice(0, MAX_NAME_LENGTH) + '...';
          }
          const winningTeam = {
            ...match.teams[0],
          };
          if (tournament.eventType === TournamentEventType.Singles) {
            winningTeam.name = combinedTeamName;
          } else {
            winningTeam.teamName = combinedTeamName;
          }
          roundTeams.push(winningTeam);
        }
      });

      if (offset) {
        roundTeams.unshift(offset);
      }
      const nextRound = this.getSingleEliminationRound(roundTeams);
      rounds.push(nextRound);
    }

    const events = this.getTournamentGames(tournament, schedule, rounds);

    return {
      error: GthTournamentScheduleError.None,
      events,
    };
  }

  private getTournamentGames(
    tournament: GthTournamentModel,
    schedule: GthTournamentScheduleContract,
    rounds: GthTournamentScheduleEvents[]): TournamentEventItem[] {
    let events: TournamentEventItem[] = [];

    let startDate = undefined;
    for (let roundIndex = 0; roundIndex < rounds.length; roundIndex++) {
      const round = rounds[roundIndex];
      const roundEvents = this.getEventsForRound(
        tournament,
        schedule,
        round,
        roundIndex,
        startDate,
      );
      if (roundEvents.length > 0) {
        const lastEvent = roundEvents[roundEvents.length - 1];
        startDate = new Date(lastEvent.endDate);
        events = events.concat(roundEvents);
      }
    }

    return events;
  }

  private getDoubleEliminationTournament(
    tournament: GthTournamentModel,
    schedule: GthTournamentScheduleContract,
  ): GthTournamentSchedule {
    const teams = this.getTournamentParticipants(tournament);
    const numberOfTeams = teams.length;

    return {
      error: GthTournamentScheduleError.Unknown,
    };
  }

  // eslint-disable-next-line max-len
  private getTournamentParticipants(tournament: GthTournamentModel): TournamentTeam[] | TournamentJoiner[] {
    const eventType = tournament.eventType;
    switch (eventType) {
      case TournamentEventType.Teams:
        return [...tournament.teams.teams];
      case TournamentEventType.Singles:
      default:
        return [...tournament.participants];
    }
  }

  // eslint-disable-next-line max-len
  private getTimeAfterBreak(startDate: Date, endDate: Date, breaks: TournamentEventScheduleDate[]) {
    if (!breaks || breaks.length === 0) {
      return new Date(startDate);
    }

    let index = 0;
    for (index = 0; index < breaks.length; index++) {
      const gameBreak = breaks[index];
      const withinRange =
        this.isTimeWithinRange(startDate, endDate, gameBreak.startDate, gameBreak.endDate);
      if (withinRange) {
        return new Date(gameBreak.endDate);
      }
    }
    return new Date(startDate);
  }

  private getEventsForRound(
    tournament: GthTournamentModel,
    schedule: GthTournamentScheduleContract,
    round: GthTournamentScheduleEvents,
    roundNumber: number,
    startDate?: Date): TournamentEventItem[] {
    const locations = this.getNamedFields(tournament);
    const events: TournamentEventItem[] = [];

    let matchIndex = 0;
    const { gameDuration, gameDays, gamesPerMatch } = schedule;
    const totalGameDuration = gameDuration * gamesPerMatch;

    let startIndex = 0;
    let overrideStartDate: Date;
    if (startDate) {
      for (let i = 0; i < gameDays.length; i++) {
        const day = gameDays[i];
        const gDateStart = day.startDate.getTime();
        const gDateEnd = day.endDate.getTime();
        const compareDate = startDate.getTime();
        if (compareDate >= gDateStart && compareDate <= gDateEnd) {
          startIndex = i;
          break;
        } else if (compareDate <= gDateStart) {
          startIndex = i;
          break;
        }
      }
    }

    for (let i = startIndex; i < gameDays.length; i++) {
      const day = gameDays[i];
      const dayStart = overrideStartDate ?
        new Date(overrideStartDate) :
        new Date(day.startDate);
      const dayEnd = new Date(day.endDate);
      const { breaks } = day;

      overrideStartDate = undefined;

      // For every game duration
      let iTimeStart = new Date(dayStart);
      let iTimeEnd = new Date(iTimeStart);
      iTimeEnd.setMinutes(iTimeStart.getMinutes() + totalGameDuration);

      // Schedule one event at a location (per available time)
      // If all time is allocated within a location, go on to the next day
      for (const location of locations) {
        let isInDay = true;
        while (isInDay) {
          iTimeStart = this.getTimeAfterBreak(iTimeStart, iTimeEnd, breaks);
          iTimeEnd = new Date(iTimeStart);
          iTimeEnd.setMinutes(iTimeStart.getMinutes() + totalGameDuration);
          isInDay = this.isTimeWithinRange(iTimeStart, iTimeEnd, dayStart, dayEnd);

          if (isInDay) {
            // If it is not within the same day, go on to the next location
            const match = round.matches[matchIndex++];
            if (match) {
              const event: TournamentEventItem = {
                startDate: iTimeStart,
                endDate: iTimeEnd,
                created: new Date(),
                location,
                teams: match.teams,
                round: roundNumber,
                scores: match.played ? match.scores : match.teams.map((_) => 0),
              };
              events.push(event);
            }
            iTimeStart = new Date(iTimeEnd);
            if (matchIndex > round.matches.length - 1) {
              return events;
            }
          }
        }
      }
    }

    return events;
  }

  private isTimeWithinRange(
    startDate: Date,
    endDate: Date,
    compareDateStart: Date,
    compareDateEnd: Date,
  ) {
    const gStartTime = startDate.getTime();
    const gEndTime = endDate.getTime();
    const compareStartTime = compareDateStart.getTime();
    const compareEndTime = compareDateEnd.getTime();
    if (gStartTime >= compareStartTime && gStartTime <= compareEndTime) {
      // Start time falls within time
      return true;
    } else if (gEndTime >= compareStartTime && gEndTime <= compareEndTime) {
      // End time falls within time
      return true;
    }
    return false;
  }

  private getSingleEliminationRound(teamCollection: any[]): GthTournamentScheduleEvents {
    const round: GthTournamentScheduleEvents = {
      matches: [],
    };
    let offset: TournamentJoiner | undefined;

    if (teamCollection.length % 2 === 1) {
      offset = teamCollection.pop();
    }

    while (teamCollection.length > 1) {
      const firstTeam = teamCollection.shift();
      const secondTeam = teamCollection.shift();
      const teams: TournamentJoiner[] = [];
      teams.push(firstTeam);
      if (secondTeam) {
        teams.push(secondTeam);
      }
      const match = {
        teams,
        // TODO: Figure this out for existing schedules
        played: false,
      };
      round.matches.push(match);
    }

    return {
      ...round,
      offset: offset,
    };
  }

  private getNamedFields(tournament: GthTournamentModel) {
    const locations = [];
    tournament.venue.locations.forEach((location) => {
      const fields = location.fields;
      if (fields.length === 0) {
        fields.push('Main');
      }
      fields.forEach((field) => {
        locations.push({
          ...location,
          field,
        });
      });
    });
    return locations;
  }
}
