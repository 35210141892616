import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface ConfirmDialogContract {
  title: string;
  content: string;
  confirmButtonText: string;
}

@Component({
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class AppConfirmDialogComponent {
  title = '';
  content = '';
  confirmButtonText = '';

  constructor(
    public dialogRef: MatDialogRef<AppConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: ConfirmDialogContract,
  ) {
    if (this.data) {
      this.title = this.data.title;
      this.content = this.data.content;
      this.confirmButtonText = this.data.confirmButtonText;
    }
  }

  onCancelButtonClick() {
    this.dialogRef.close(false);
  }

  onConfirmButtonClick() {
    this.dialogRef.close(true);
  }
}
