<form [formGroup]="_timeForm" class="time-selection fx-column fx-fill">
  <h1 class="title" mat-dialog-title>Pick a Time</h1>
  <div mat-dialog-content class="content fx-column fx-auto fx-gap-10x">
    <mat-form-field appearance="fill">
      <mat-label>Date</mat-label>
      <input matInput [matDatepicker]="picker"
        class="date-input"
        formControlName="startDate" />
      <mat-datepicker-toggle matSuffix [for]="picker" />
      <mat-datepicker #picker />
      <mat-error>Invalid date</mat-error>
    </mat-form-field>
    <div class="fx-row fx-gap-10x">
      <mat-form-field class="fx-50" appearance="fill">
        <mat-label>Start Time</mat-label>
        <mat-select formControlName="startTime">
          <mat-option *ngFor="let timeOption of _timeOptions"
                      [value]="timeOption">{{timeOption }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="fx-auto" appearance="fill">
        <mat-label>Hours</mat-label>
        <input matInput type="number" formControlName="durationHours" />
        <mat-hint>Number of hours for event</mat-hint>
      </mat-form-field>
      <mat-form-field class="fx-auto" appearance="fill">
        <mat-label>Minutes</mat-label>
        <input matInput type="number" formControlName="durationMinutes" />
        <mat-hint>Number of minutes for event</mat-hint>
      </mat-form-field>
    </div>
  </div>
  <div class="actions fx-row" mat-dialog-actions>
    <span class="spacer"></span>
    <button mat-button (click)="onCancelButtonClick()">Cancel</button>
    <button
      mat-flat-button
      color="primary"
      cdkFocusInitial
      [disabled]="!isFormValid"
      (click)="_onSaveButtonClick()">Save</button>
  </div>
</form>
