import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { SrvSafeStorageService } from '@sentinels/services/safe-storage.service';
import { BehaviorSubject } from 'rxjs';

export enum Theme {
  dark = 'dark',
  light = 'light',
}

@Injectable({
  providedIn: 'root',
})
export class ThemeSwitcherService {
  private themeSubject = new BehaviorSubject<Theme>(Theme.light);

  get theme$() {
    return this.themeSubject.asObservable();
  }

  constructor(
    @Inject(DOCUMENT)
    private document: Document,
    private safeStorage: SrvSafeStorageService,
  ) {
    const stored = this.getStoredTheme();
    this.setTheme(stored);
  }

  setTheme(theme: Theme) {
    this.themeSubject.next(theme);
    this.setStoredTheme(theme);

    if (!this.document?.body) {
      return;
    }
    this.document.body.classList.add(theme);

    switch (theme) {
      case Theme.light:
        this.document.body.classList.remove(Theme.dark);
        break;
      case Theme.dark:
        this.document.body.classList.remove(Theme.light);
        break;
      default:
        break;
    }
  }

  private setStoredTheme(theme: Theme) {
    this.safeStorage.setItem('MEH_THEME', theme);
  }

  private getStoredTheme(): Theme {
    const stored = this.safeStorage.getItem('MEH_THEME');
    if (!stored) {
      return Theme.light;
    }
    return stored as Theme;
  }
}
