import { Component, Input, EventEmitter, Output, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { GthAvailability } from '../../interfaces/availability';
import { DateRange } from '@angular/material/datepicker';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GthTimeService } from '../../services/time.service';
import { GthCloudFunctionService } from '../../services/cloud/cloud-function.service';
import { GthUserModel } from '@sentinels/models';

export enum TimeSelection {
  dates = 'dates',
  days = 'days',
}

export const DEFAULT_AVAILABILITY: GthAvailability = {
  linkName: '',
  title: '',
  dates: [],
  days: [],
  allDay: false,
  startTime: '',
  endTime: '',
};

@Component({
  selector: 'gth-planner-form',
  templateUrl: './planner-form.component.html',
  styleUrls: ['./planner-form.component.scss'],
})
export class GthPlannerFormComponent implements OnChanges {
  protected readonly TimeSelection = TimeSelection;

  @Input()
  uri = 'https://app.myeventhero.com/plan/';

  @Input()
  availabilityId = '';

  @Input()
  disabled = false;

  @Input()
  user?: GthUserModel;

  @Input()
  individualPlan = false;

  @Input()
  availability? = { ...DEFAULT_AVAILABILITY };

  @Output()
  publishing = new EventEmitter<boolean>();

  @Output()
  publish = new EventEmitter<GthAvailability>;

  @Output()
  delete = new EventEmitter();

  tabIndex = 0;

  selectedRangeValue?: DateRange<Date>;
  selectedTimeOption = TimeSelection.dates;
  today = new Date();
  timeOptions: string[] = [];

  get eventUri() {
    if (!this.availability?.linkName) {
      return undefined;
    }

    return `${this.uri}${this.availability.linkName}`;
  }

  get timeRangeValid() {
    return this.isTimeRangeValid(this.availability?.startTime, this.availability?.endTime);
  }

  get dateRangeValid() {
    return this.availability?.dates.length > 0 || this.availability?.days.length > 0;
  }

  get linkNameValid() {
    return this.availability?.linkName.trim().length > 0;
  }

  get titleValid() {
    return this.availability?.title.trim().length > 0;
  }

  get publishTitleValid() {
    return this.linkNameValid && this.titleValid;
  }

  get valid() {
    return this.timeRangeValid &&
      this.dateRangeValid &&
      (this.individualPlan || this.publishTitleValid);
  }

  constructor(
    private time: GthTimeService,
    private cloud: GthCloudFunctionService,
    private snackbar: MatSnackBar,
  ) {
    this.timeOptions = time.getTimeOptions()
      .filter((t) => !t.endsWith(':15 AM') && !t.endsWith(':15 PM'));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.availability) {
      if (this.availability) {
        this.tabIndex = 0;
        if (this.availability.dates?.length > 0) {
          this.selectedTimeOption = TimeSelection.dates;
          if (this.availability.dates.length > 1) {
            this.selectedRangeValue = new DateRange(
              this.availability.dates[0],
              this.availability.dates[this.availability.dates.length - 1],
            );
          } else {
            this.selectedRangeValue = new DateRange(
              this.availability.dates[0],
              this.availability.dates[0],
            );
          }
        } else if (this.availability.days?.length > 0) {
          this.selectedTimeOption = TimeSelection.days;
        }
      } else {
        this.reset();
      }
    }
  }

  reset() {
    this.availability = { ...DEFAULT_AVAILABILITY };
    this.tabIndex = 0;
    this.selectedRangeValue = undefined;
    this.selectedTimeOption = TimeSelection.dates;
  }

  onSelectedTabChange(index: number) {
    this.tabIndex = index;
  }

  onDateRangeChange(date: Date) {
    if (this.disabled || !this.availability) return;
    let currentRange = this.selectedRangeValue;
    if (!currentRange) {
      this.selectedRangeValue = new DateRange(date, date);
      currentRange = new DateRange(date, date);
    }
    const startTime = currentRange.start.getTime();
    const endTime = date.getTime();
    if (startTime < endTime) {
      this.selectedRangeValue = new DateRange(currentRange.start, date);
    } else {
      this.selectedRangeValue = new DateRange(date, date);
    }

    const startDate = this.selectedRangeValue.start;
    const endDate = this.selectedRangeValue.end;
    const endIterationDate = new Date(endDate);
    endIterationDate.setDate(endIterationDate.getDate() + 1);
    this.availability.dates = [];

    if (startDate && endDate && !this.time.isDateEqual(startDate, endDate)) {
      let iterationDate = new Date(startDate);
      while (true) {
        this.availability.dates.push(iterationDate);
        const nextDate = new Date(iterationDate);
        nextDate.setDate(nextDate.getDate() + 1);
        iterationDate = nextDate;
        if (this.time.isDateEqual(iterationDate, endIterationDate)) {
          break;
        }
      }
    } else {
      this.availability.dates = [startDate, endDate];
    }
  }

  onClearDateRange() {
    this.availability.dates = [];
    this.selectedRangeValue = undefined;
  }

  onSelectedTimeOptionChange() {
    this.availability.days = [];
    this.availability.dates = [];
    this.selectedRangeValue = undefined;
  }

  isTimeRangeValid(startTime: string, endTime: string) {
    if (!this.availability) {
      return false;
    }
    if (this.availability.allDay) {
      return true;
    }
    const start = this.timeOptions.indexOf(startTime);
    const end = this.timeOptions.indexOf(endTime);
    return start < end;
  }

  async onPublishButtonClick() {
    if (!this.valid) {
      return;
    }
    this.publishing.emit(true);

    if (!this.individualPlan) {
      this.availability.linkName = encodeURIComponent(this.availability.linkName);
      const existingLink = await this.cloud.availability
        .getByLinkName(this.availability.linkName);
      if (existingLink) {
        this.publishing.emit(false);
        this.snackbar.open('Availability planner already exists with this link name', 'OK');
        return;
      }
    }

    if (!this.user) {
      this.publishing.emit(false);
      return;
    }

    const user = this.user;

    if (this.availability.allDay) {
      this.availability.startTime = '';
      this.availability.endTime = '';
      this.availability.gmtOffset = '';
    } else {
      const timezoneOffset = this.time.getTimezoneOffset();
      this.availability.gmtOffset = timezoneOffset;
    }

    const availability = this.availability;
    availability.creatorId = user.id;

    this.publish.emit(availability);
  }
}
