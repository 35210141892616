import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NgClass, NgForOf, NgIf } from '@angular/common';
import { FormControl } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';

import { EventTheme } from '@index/interfaces/event-item';

@Component({
  selector: 'gth-event-theme-form-control',
  templateUrl: './event-theme-form-control.component.html',
  styleUrls: ['./event-theme-form-control.component.scss'],
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule,
    NgForOf,
    NgClass,
    NgIf,
    MatTooltipModule,
  ],
})
export class EventThemeFormControlComponent {
  @Input()
  themesFormControl?: FormControl<EventTheme>;

  @Input()
  disabled = false;

  themes = [
    {
      label: EventTheme.Easter,
      image: `assets/themes/${EventTheme.Easter}.png`,
    },
    {
      label: EventTheme.Birthday,
      image: `assets/themes/${EventTheme.Birthday}.png`,
    },
    {
      label: EventTheme.Valentines,
      image: `assets/themes/${EventTheme.Valentines}.png`,
    },
    {
      label: EventTheme.Sports,
      image: `assets/themes/${EventTheme.Sports}.png`,
    },
  ];

  onThemeSelect(theme: EventTheme) {
    if (this.themesFormControl.disabled) {
      return;
    }
    if (this.themesFormControl.value === theme) {
      this.themesFormControl.reset();
      return;
    }
    this.themesFormControl.setValue(theme);
  }

  onThemeImageError(error: ErrorEvent) {
    console.error('Event theme image failed to load', error);
  }
}
