import { EventJoinerStatus } from './event-item';

export interface Tournament {
  id: string;
  public: boolean;
  creatorId: string;
  coHosts: TournamentHost[];
  name: string;
  sport: string;
  startDate: Date;
  description: string;
  primaryColor: string;
  secondaryColor: string;
  prizes: string[];
  logoImageId: string;
  bannerImageId: string;
  videoIds: string[];
  signUpFormId?: string;

  waiver?: string;

  gameDuration: number;
  winCondition: TournamentEventWinCondition;
  gamesPerMatch: number;
  gameDays: TournamentEventScheduleDate[];

  format: TournamentFormat;
  hasSemiFinal: boolean;
  eventType: TournamentEventType;
  venue: TournamentVenue;
  teams: TournamentTeamInfo;
  presentation: TournamentPresentation;
  participants: TournamentJoiner[];

  createdDate: Date;
  lastModifiedDate: Date;
  events?: TournamentEventItem[];
}

export interface TournamentVenue {
  rules: string[];
  venueType: TournamentVenueType;
  locations: TournmanetLocation[];
}

export interface TournamentEventDate {
  dateType: TournamentEventDateType;
  startDate: Date;
  endDate: Date;
}

export interface TournmanetLocation {
  lat?: number;
  lng?: number;
  address?: string;
  name?: string;
  fields: string[];
}

export interface TournamentTeamInfo {
  numberOfTeams: number;
  playersPerTeam: number;
  approvalType: WaitlistAndDropHandler;
  teams: TournamentTeam[];
  entryType: TournamentEntryType;
}

export interface TournamentTeam {
  participants: TournamentJoiner[];
  name: string;
  contact: string;
  numberOfPlayers: number;
  color: string;
  id: string,
}

export interface TournamentJoiner {
  player: string;
  name: string;
  skillLevel: TournamentSkillLevel;
  createdAt: Date;
  status: EventJoinerStatus;
  approvedBy?: string[];
  phoneNumber?: string;
  age: number;

  // keep or change to a single property of TournamentTeam without participants?
  teamId?: string;
  teamName?: string;
  teamColor?: string;
}

export interface TournamentPresentation {
  customLink: string;
  showLogo: boolean;
  useCustomColors: boolean;
}

export interface TournamentEventScheduleDate {
  startDate?: Date;
  endDate?: Date;
  breaks?: TournamentEventScheduleDate[];
}

export interface TournamentEventItem {
  startDate: Date;
  endDate: Date;
  teams: TournamentJoiner[];
  created: Date;
  location: TournamentEventItemLocation;
  round: number;
  scoreKeeperId?: string;

  scores?: number[];
}

export interface TournamentEventItemLocation {
  rules: string[];
  venueType: TournamentVenueType;
  locations: TournmanetLocation[];
  field: string;
}

export interface TournamentHost {
  userId: string;
  role: TournamentHostRole;
}

export enum TournamentHostRole {
  None = 0,
  Owner = 1,
  CoHost = 2,
  ScoreKeeper = 3,
}

export enum TournamentFormat {
  None = -1,
  SingleElimination = 1,
  DoubleElimination = 2,
}

export enum TournamentEventDateType {
  Event = 0,
  Date = 1,
}

export enum TournamentEventWinCondition {
  LessPoints = 0,
  MorePoints = 1,
}

export enum TournamentEventType {
  Singles = 0,
  Teams = 1,
}

export enum TournamentVenueType {
  SingleLocation = 0,
  MultipleLocations = 1,
  Online = 2,
}

export enum WaitlistAndDropHandler {
  Automatic = 0,
  PendingApproval = 1,
}

export enum TournamentEntryType {
  Manual = 0,
  Spreadsheet = 1,
  SignUpForm = 2,
}

export enum TournamentSkillLevel {
  None = 0,
  Beginner = 1,
  Intermediate = 2,
  Advanced = 3,

  ChuckNorris = 100,
}
