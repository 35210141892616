import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'meh-custom-filter-dialog',
  templateUrl: './custom-filter.dialog.component.html',
  styleUrls: ['./custom-filter.dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatDialogModule,
    MatInputModule,
    MatDatepickerModule,
    MatButtonModule,
    MatDatepickerModule,
  ],
})
export class CustomFilterDialogComponent {
  startDate: Date | null;
  endDate: Date | null;

  constructor(
    public dialogRef: MatDialogRef<CustomFilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { start: Date; end: Date },
  ) {
    this.startDate = data?.start || null;
    this.endDate = data?.end || null;
  }

  onApplyClick(): void {
    const result = {
      start: this.startDate,
      end: this.endDate,
    };

    this.dialogRef.close(result);
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }
}
