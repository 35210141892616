import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ListFilter, Results, Payload, User } from '@index/interfaces';
import { GthUserModel } from '../../../../../sentinels/src/lib/models/user';
import { GthCallableService, CallableOptions, CallableRoutes } from './callable.service';
import { GthErrorLoggerService } from './error-logger.service';
import { GthEventRatingModel } from '../../../../../sentinels/src/lib/models/event-rating';
import { SrvApiService } from '@sentinels/services/api.service';

const CONTEXT = 'GthUserFunctionService';

@Injectable({
  providedIn: 'root',
})
export class GthUserFunctionService {
  constructor(
    public functions: AngularFireFunctions,
    public callableService: GthCallableService,
    private api: SrvApiService,
    private logger: GthErrorLoggerService,
  ) { }

  getUserByEmail$(email: string): Observable<GthUserModel | undefined> {
    return from(this.getUserByEmail(email)).pipe(
      map((user) => {
        if (!user) {
          return undefined;
        }

        return this.getUser(user);
      }),
    );
  }

  getUserByPhone$(phone: string): Observable<GthUserModel | undefined> {
    return from(this.getUserByPhone(phone)).pipe(
      map((user) => {
        if (!user) {
          return undefined;
        }

        return this.getUser(user);
      }),
    );
  }

  getUserById$(id: string): Observable<GthUserModel | undefined> {
    return from(this.getUserById(id)).pipe(
      map((user) => {
        if (!user) {
          return undefined;
        }

        return this.getUser(user);
      }),
    );
  }

  update$(model: GthUserModel): Observable<boolean> {
    return from(this.update(model));
  }

  list$(filter: ListFilter): Observable<GthUserModel[]> {
    return from(this.list(filter));
  }

  sendInvite$(email: string): Observable<boolean> {
    return from(this.sendInvite(email));
  }

  private getUser(user: GthUserModel) {
    if (!user.photoURL) {
      user.photoURL = '';
    }
    if (!user.fullName) {
      user.fullName = '';
    }
    if (!user.displayName) {
      user.displayName = '';
    }
    return user;
  }

  private async getUserByEmail(email: string): Promise<GthUserModel | null> {
    if (!this.functions) return Promise.resolve(null);

    const options: CallableOptions = {
      route: CallableRoutes.USERS_READ,
      data: { email },
    };
    const response = (await this.callableService.call(options)) as Results<User>;
    if (response && response.success && response.payload) {
      const result = response.payload as Payload<User>;
      const eventRatings = await this.api.eventRatings.listAsync();
      const data = this.getUserWithRating(result.data, eventRatings);
      this.log(`User fetched by email`);
      return new GthUserModel(result.id, data);
    }
    return null;
  }

  private async getUserByPhone(phone: string): Promise<GthUserModel | null> {
    if (!this.functions) return Promise.resolve(null);

    const options: CallableOptions = {
      route: CallableRoutes.USERS_READ,
      data: { phone },
    };
    const response = (await this.callableService.call(options)) as Results<User>;
    if (response && response.success && response.payload) {
      const result = response.payload as Payload<User>;
      const eventRatings = await this.api.eventRatings.listAsync();
      const data = this.getUserWithRating(result.data, eventRatings);
      this.log(`User fetched by phone`);
      return new GthUserModel(result.id, data);
    }
    return null;
  }

  private async getUserById(id: string): Promise<GthUserModel | null> {
    if (!this.functions) return Promise.resolve(null);

    const options: CallableOptions = {
      route: CallableRoutes.USERS_READ,
      data: { id },
    };

    const response = (await this.callableService.call(options)) as Results<User>;
    if (response && response.success && response.payload) {
      const result = response.payload as Payload<User>;
      const eventRatings = await this.api.eventRatings.listAsync();
      const data = this.getUserWithRating(result.data, eventRatings);
      return new GthUserModel(result.id, data);
    }

    return null;
  }

  private async list(filter: ListFilter): Promise<GthUserModel[] | null> {
    if (!this.functions) return Promise.resolve(undefined);

    const options: CallableOptions = {
      route: CallableRoutes.USERS_LIST,
      data: filter,
    };
    const eventRatings = await this.api.eventRatings.listAsync();
    const response = (await this.callableService.call(options)) as Results<null>;
    if (response.success) {
      this.log(`Users listed`);

      return (response.payload as any).map((user) => {
        const data = this.getUserWithRating(user.data, eventRatings);
        return new GthUserModel(user.id, data);
      });
    }
    return null;
  }

  private getUserWithRating(model: User, eventRatings: GthEventRatingModel[]) {
    if (!model.ratings) {
      return model;
    }
    model.ratings = model.ratings.map((r) => {
      return {
        ...r,
        label: eventRatings.find((rating) => rating.id === r.rating)?.label,
      };
    });
    return model;
  }

  private async sendInvite(email: string): Promise<boolean> {
    if (!this.functions) return Promise.resolve(false);
    console.log(`TODO: Send invite to ${email}`);
    this.logger.error(`${CONTEXT}: User email not implemented`);
    return Promise.resolve(false);
  }

  private async update(model: GthUserModel): Promise<boolean> {
    if (!this.functions) return Promise.resolve(false);
    const user = model.copy;
    const options: CallableOptions = {
      route: CallableRoutes.USERS_UPDATE,
      data: user,
    };

    const response = (await this.callableService.call(options)) as Results<null>;
    this.log(`User updated: ${model.uid}`);
    return response && response.success;
  }

  private log(text: string) {
    this.logger.debug(`${CONTEXT}: ${text}`);
  }
}
