import {
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

import { TemplateTooltipItem } from './template-tooltip.component';
import { Subscription } from 'rxjs';
import { TooltipStateService } from './tooltip-state.service';
import { TemplateTooltipComponent } from './template-tooltip.component';

@Directive({
  selector: '[gthTemplateTooltip]',
  standalone: true,
})
export class TemplateTooltipDirective implements OnInit, OnDestroy {
  @Input('gthTemplateTooltip')
  tooltipItem?: TemplateTooltipItem;
  @Input() platform?: string;

  @Input()
  appDisabled = false;

  private overlayRef!: OverlayRef;
  private subscriptions = new Subscription();
  private tooltipOpen = false;

  constructor(
    private tooltipState: TooltipStateService,
    private overlay: Overlay,
    private overlayPositionBuilder: OverlayPositionBuilder,
    private elementRef: ElementRef,
  ) { }

  ngOnInit(): void {
    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withPositions([
        {
          originX: 'center',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'bottom',
          offsetY: -8,
        },
      ]);

    this.overlayRef = this.overlay.create({ positionStrategy });

    this.subscriptions.add(
      this.tooltipState.tooltipOpen$.subscribe(() => {
        this.closeTooltip();
      }),
    );
  }

  ngOnDestroy() {
    if (this.overlayRef) {
      this.overlayRef.detach();
    }
    this.subscriptions.unsubscribe();
  }

  @HostListener('mouseenter')
  show() {
    if (this.appDisabled || !this.tooltipItem) {
      return;
    }
    this.tooltipState.tooltipOpened();
    const tooltipRef: ComponentRef<TemplateTooltipComponent> = this.overlayRef.attach(
      new ComponentPortal(TemplateTooltipComponent),
    );


    tooltipRef.instance.platform = this.platform;

    this.tooltipOpen = true;
    const tooltipInstance = tooltipRef.instance;
    tooltipInstance.tooltipItem = this.tooltipItem;
    this.subscriptions.add(
      tooltipInstance.mouseLeave.subscribe(() => {
        this.closeTooltip();
      }),
    );
  }

  @HostListener('document:click')
  click() {
    this.closeTooltip();
  }

  private closeTooltip() {
    if (this.tooltipOpen) {
      this.overlayRef.detach();

      this.tooltipOpen = false;
    }
  }
}
