<div class="dialog-container">
    <mat-tab-group class="tab-group">
      <mat-tab label="Going">
        <ng-container matTabContent *ngTemplateOutlet="listTemplate; context: { viewParticipants: goingParticipants }"></ng-container>
      </mat-tab>
      <mat-tab label="Spectating" *ngIf="isAdmin">
        <ng-container 
        matTabContent
        *ngTemplateOutlet="listTemplate; context: { viewParticipants: spectatingParticipants }"></ng-container>
      </mat-tab>
      <mat-tab label="Not Going" *ngIf="isAdmin">
        <ng-container 
            matTabContent
            *ngTemplateOutlet="listTemplate; context: { viewParticipants: notGoingParticipants }"></ng-container>
      </mat-tab>
      <mat-tab label="Maybe" *ngIf="isAdmin">
        <ng-container 
            matTabContent
            *ngTemplateOutlet="listTemplate; context: { viewParticipants: maybeParticipants }"></ng-container>
      </mat-tab>
    </mat-tab-group>
  </div>
  
  <ng-template let-viewParticipants ="viewParticipants" #listTemplate>
    <mat-list class="list">
      <mat-list-item *ngFor="let participant of viewParticipants">
        <div class="fx-row">
          <div class="fx-auto fx-fill content">
            <h3>{{ participant.displayNameFallback }}</h3> 
          </div>
          <div class="fx-row action-container">
            <button mat-icon-button class="nav-btn" (click)="onMessageButtonClick(participant.id)" matTooltip="Message Participant">
              <mat-icon>message</mat-icon>
            </button>
          </div>
        </div>
      </mat-list-item>
    </mat-list>
  </ng-template>
