import firebase from 'firebase/compat/app';

export enum EndorsementType {
  PERSONALITY = 'Personality',
  PASSION = 'Passion',
}
export interface Endorsement {
  id: string,
  name: string,
  endorsedBy: string[],
  endorsedId: string,
  sport?: string,
  skill?: string,
  type: EndorsementType,
  updatedAt: firebase.firestore.FieldValue,
}
