<h2 mat-dialog-title>Custom Filter</h2>

<mat-dialog-content>
  <div>
    <mat-form-field>
      <mat-label>Start Date</mat-label>
      <input matInput [matDatepicker]="startDatePicker" placeholder="Start Date" [(ngModel)]="startDate">
      <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #startDatePicker></mat-datepicker>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field>
      <mat-label>End Date</mat-label>
      <input matInput [matDatepicker]="endDatePicker" placeholder="End Date" [(ngModel)]="endDate">
      <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #endDatePicker></mat-datepicker>
    </mat-form-field>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button (click)="onApplyClick()">Apply</button>
  <button mat-button (click)="onCancelClick()">Cancel</button>
</mat-dialog-actions>
