import 'firebase/firestore';
import firebase from 'firebase/compat/app';

import { DBModel } from './model.db';
import { EventItem, EventTheme, EventTicketLevel, GenderInfo, UnregisteredTeamInfo } from '../interfaces/event-item';
import { EventRepeatType } from '../interfaces/event-item';
import { Location } from '../interfaces/location';
import { EventItemTypes } from '../enums/event-item-type';
import { SkillLevel } from '../enums/skill-level';

export class EventItemModel extends DBModel {
  static readonly CREATOR = 'creator';
  static readonly TITLE = 'title';
  static readonly DATE_START = 'dateStart';
  static readonly DURATION = 'duration';
  static readonly RECURRING_TYPE = 'recurringType';
  static readonly TEAM_COLOR = 'teamColor';
  static readonly HOSTING_TEAM = 'hostingTeam';
  static readonly LOCATION = 'location';
  static readonly COST = 'cost';
  static readonly EQUIPMENT_NEEDED = 'equipmentNeeded';
  static readonly REQUIRED_ATTENDEES = 'requiredAttendees';
  static readonly MAKE_PUBLIC_AFTER = 'makePublicAfter';
  static readonly CREATOR_APPROVAL_NEEDED = 'creatorApprovalNeeded';
  static readonly ALLOW_PARTICIPANT_GUESTS = 'allowParticipantGuests';
  static readonly GENDER_INFO = 'genderInfo';
  static readonly DAYS = 'days';
  static readonly TYPE = 'type';
  static readonly BANNER = 'banner';
  static readonly CANCELLED = 'cancelled';
  static readonly UPDATED = 'updated';
  static readonly RATED = 'rated';
  static readonly ONLINE = 'online';
  static readonly DESCRIPTION = 'description';
  static readonly GAME_TYPE = 'gameType';
  static readonly SKILL_LEVEL = 'skillLevel';
  static readonly DISCOVERABLE = 'discoverable';
  static readonly THEME = 'theme';
  static readonly BACKGROUND_COLOR = 'backgroundColor';
  static readonly SEND_FEEDBACK_EMAIL_AFTER = 'sendFeedbackEmailAfter';
  static readonly TICKET_LEVELS = 'ticketLevels';
  static readonly PLATFORM = 'platform';
  static readonly PRICE_ID = 'priceId';

  constructor(
    ref: firebase.firestore.DocumentReference | undefined,
    public readonly creator: string,
    public readonly title: string,
    public readonly dateStart: Date,
    public readonly duration: { hours: number; minutes: number },
    public readonly recurringType: EventRepeatType,
    public readonly location: Location,
    public readonly teamColor: string,
    public readonly hostingTeam: string | UnregisteredTeamInfo,
    public readonly cost: number | undefined,
    public readonly equipmentNeeded: string[],
    public readonly requiredAttendees: string[],
    public readonly makePublicAfter: number | null,
    public genderInfo: { [key in string]?: GenderInfo },
    public creatorApprovalNeeded: boolean,
    public readonly allowParticipantGuests: boolean,
    public readonly days?: string[],
    public readonly type?: EventItemTypes,
    public readonly banner?: string,
    public readonly cancelled?: boolean,
    public readonly updated?: firebase.firestore.Timestamp,
    public readonly rated?: boolean,
    public readonly online?: boolean,
    public readonly description?: string,
    public readonly gameType?: string,
    public readonly skillLevel?: SkillLevel,
    public readonly discoverable?: boolean,
    public readonly theme?: EventTheme,
    public readonly backgroundColor?: string,
    public readonly sendFeedbackEmailAfter?: boolean,
    public readonly ticketLevels?: EventTicketLevel[],
    public readonly platform?: string,
    public readonly priceId?: string,
  ) {
    super(ref);
  }

  static fromJSON(
    event: EventItem,
    ref?: firebase.firestore.DocumentReference,
  ) {
    return new EventItemModel(
      ref,
      event.creator,
      event.title,
      event.dateStart,
      event.duration,
      event.recurringType,
      event.location,
      event.teamColor,
      event.hostingTeam,
      event.cost,
      event.equipmentNeeded,
      event.requiredAttendees,
      event.makePublicAfter,
      event.genderInfo,
      event.creatorApprovalNeeded,
      event.allowParticipantGuests,
      event.days,
      event.type,
      event.banner,
      event.cancelled,
      event.updated,
      event.rated,
      event.online,
      event.description,
      event.gameType,
      event.skillLevel,
      event.discoverable,
      event.theme,
      event.backgroundColor,
      event.sendFeedbackEmailAfter,
      event.ticketLevels,
      event.platform,
      event.priceId,
    );
  }

  getAvailableSpotsForGender(gender?: string): number {
    if (!gender) return 0;
    const currentGender = this.genderInfo[gender];
    const count = currentGender ? currentGender.numberOfPlayersNeeded : 0;

    return count;
  }

  get isOpenToAnyGender() {
    return Object.keys(this.genderInfo).includes('Any');
  }
}
