export interface GthAvailability {
  id?: string;
  creatorId?: string;
  title: string;
  linkName: string;
  dates: Date[];
  days: AvailabilityDay[];
  allDay: boolean;
  startTime: string;
  endTime: string;

  // When dealing with timezones we need to initialize the availability date/time properly
  // Example: new Date(`01/01/2024 09:00 GMT-${gmtOffset}`)
  gmtOffset?: string;
}

export interface UserAvailability {
  id?: string;
  userId: string;
  availabilityId: string;
  dates: Date[];
  days: AvailabilityDay[];
  allDay: boolean;
  startTime: string;
  endTime: string;

  // When dealing with timezones we need to initialize the availability date/time properly
  // Example: new Date(`01/01/2024 09:00 GMT-${gmtOffset}`)
  gmtOffset?: string;
}

export enum AvailabilityDay {
  Sunday = 'sunday',
  Monday = 'monday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday',
  Thursday = 'thursday',
  Friday = 'friday',
  Saturday = 'saturday',
}
