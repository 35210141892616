<ng-container *ngIf="loading else eventTmpl">
  <mat-spinner class="loading-spinner" mode="indeterminate" color="primary" diameter="36" />
</ng-container>
<ng-template #eventTmpl>
  <gth-event-info
        [platform]="platform"
        [event]="event"
        [user]="user"
        [team]="team"
        [urlPath]="urlPath"
        (joinEvent)="onJoinEvent($event)"
        (editEvent)="onEditEvent($event)"
        (declineInvite)="onDeclineInvite($event)"
        (cancelEvent)="onCancelEvent($event)"
        (leaveEvent)="onLeaveEvent($event)" />
</ng-template>

<button mat-icon-button class="close-btn" (click)="onClose()">
  <mat-icon fontIcon="close" />
</button>