
<ng-container *ngIf="editing && !event ||editing &&  !eventItemForm.formGroup.valid; else formTmpl">
  <div class="fx-auto fx-fill fx-centered">
      <mat-spinner [diameter]="36" />
  </div>
</ng-container>

<ng-template #formTmpl>
  <form [formGroup]="eventItemForm.formGroup" class="event" [class.event--disabled]="disabled">
    <mat-stepper class="stepper" [linear]="true" #stepper>
      <mat-step label="General">
        <div class="step-container">
          <h2>General</h2>
          <mat-form-field class="primary-input" appearance="outline">
            <mat-label>Event Title</mat-label>
            <input class='title-input' matInput formControlName="title" />
            <mat-error>
              {{
              eventItemForm.formGroup.get('title').errors?.required ?
              'Title is required' :
              'Title must be less than 150 characters'
              }}
            </mat-error>
          </mat-form-field>
          <ng-container *ngIf="eventPlatform !== 'meh'">
            <mat-form-field class="primary-input" appearance="outline" *ngIf="gameTypes">
              <mat-label>Activity</mat-label>
              <mat-select formControlName="gameType" [required]="true"
                (selectionChange)="onGameTypeChanged($event.value)">
                <mat-optgroup *ngFor="let group of gameTypes | keyvalue" [label]="group.key | titlecase">
                  <mat-option *ngFor="let gameType of group.value" [value]="gameType.label">
                    {{ gameType.label }}
                  </mat-option>
                </mat-optgroup>
              </mat-select>
              <mat-error>Activity is required</mat-error>
            </mat-form-field>
          </ng-container>  

          <mat-form-field appearance="outline">
            <mat-label>Start Date</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="dateStart" [min]="today"
              (dateChange)="onDateStartChange()">
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error>Start Date is required</mat-error>
          </mat-form-field>

          <ng-container *ngIf="dateStart">
            <div class="time-container">
              <div class="fx-row fx-gap-10x">
                <mat-form-field appearance="outline">
                  <mat-label>Start Time</mat-label>
                  <mat-select [value]="dateStartTime" (selectionChange)="onStartEventTimeChange($event)">
                    <mat-option *ngFor="let timeOption of timeOptions" [value]="timeOption">{{
                      timeOption
                      }}</mat-option>
                  </mat-select>
                </mat-form-field>

                <p class="to-text fx-auto">to</p>

                <mat-form-field appearance="outline">
                  <mat-label>End Time</mat-label>
                  <mat-select [value]="dateEndTime" (selectionChange)="onEndEventTimeChange($event)" class="time-select">
                    <mat-option *ngFor="let timeOption of timeOptions" [value]="timeOption">{{
                      timeOption
                      }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </ng-container>

          <p class="fx-column error-container">
            <ng-container *ngIf="eventItemForm.formGroup.get('dateStart') as dateStartCtrl">
              <mat-error class="mat-caption" *ngIf="dateTouched && dateStartCtrl.errors">
                Start date is required
              </mat-error>
            </ng-container>

            <ng-container *ngIf="eventItemForm.formGroup.get('duration') as durationCtrl">
              <mat-error class="mat-caption" *ngIf="dateTouched && durationCtrl.errors">
                Start and end time is required
              </mat-error>
            </ng-container>
          </p>

          <mat-slide-toggle labelPosition="before" formControlName="online"
            (change)="onOnlineChange($event.checked)">Online Only</mat-slide-toggle>

          <div class="location-container fx-row fx-gap-10x" *ngIf="!online">
            <mat-icon class="location-icon" color="accent">place</mat-icon>
            <div class="location-text-container">
              <span class="mat-body-strong">Add Location</span>
              <span class="location-text">{{ location }}</span>
            </div>
            <div>
              <button *ngIf="!disabled" mat-icon-button color="primary"
                (click)="onAddressButtonClick(); locationTouched = true" (focus)="locationTouched = true">
                <mat-icon class="location-edit" fontIcon="edit" />
              </button>
            </div>
          </div>

          <ng-container *ngIf="eventItemForm.formGroup.get('location') as locationCtrl">
            <mat-error class="mat-caption error-container"
              *ngIf="locationTouched && (locationCtrl.errors || !eventItemForm.location)">
              Location is required
            </mat-error>
          </ng-container>

          <mat-form-field class="primary-input" appearance="outline">
            <mat-label>Event Description</mat-label>
            <textarea class='title-input' matInput formControlName="description"></textarea>
          </mat-form-field>

          <ng-container *ngIf="displayAppearanceSettings">
            <div class="fx-column appearance">
              <div>
                <mat-button-toggle-group [value]="themeType" aria-label="Theme" (change)="onThemeTypeChange($event)">
                  <mat-button-toggle value="theme"><h2>Theme</h2></mat-button-toggle>
                  <mat-button-toggle value="color"><h2>Background Color</h2></mat-button-toggle>
                </mat-button-toggle-group>
              </div>
              <gth-event-theme-form-control [themesFormControl]="themeFormControl" *ngIf="themeType === 'theme'" />

              <div class="background-row fx-row fx-gap-10x" *ngIf="themeType === 'color'">
                <mat-form-field class="background-color-field" appearance="outline">
                  <mat-label>Background Color</mat-label>
                  <input class="background-color-fill" matInput type="color" name="backgroundColor" formControlName="backgroundColor" />
                </mat-form-field>
                <button mat-icon-button *ngIf="backgroundColor" type="button" matTooltip="Clear Background Color"
                  (click)="clearBackgroundColor()">
                  <mat-icon>clear</mat-icon>
                </button>
              </div>
            </div>
          </ng-container>

          <div class="upload-img-container">
            <gth-upload-image [imageFormControl]="eventItemForm.formGroup.controls.banner"
              [label]="uploadImgLabel" />
          </div>

          <div>
            <button class="get-started-button stepper-button" [disabled]="!eventItemForm.firstStepValid" matStepperNext
              mat-raised-button color="primary">Next</button>
          </div>
        </div>
      </mat-step>

      <mat-step label="Participants &amp; Groups">
        <div class="step-container">
          <h2>Participants &amp; Groups</h2>
          <mat-form-field appearance="outline" class="number-of-players-input"
            matTooltip="Please enter value between 0 and 100" matTooltipPosition="after">
            <mat-label>Max capacity</mat-label>
            <input matInput type="number" formControlName="numberOfPlayers" min="0" max="100" />
          </mat-form-field>
          <div class="gender-information" *ngIf="eventPlatform !== 'meh'">
            <div class="gender-required-checkbox-container">
              <mat-slide-toggle formControlName="isGenderSpecific" (change)="onGenderSpecificChange()">
                Is a specific gender required?
              </mat-slide-toggle>
            </div>
            <ng-container *ngIf="isGenderSpecific">
              <mat-card-content *ngIf="genders">
                <div class="gender-input-container" *ngFor="
                    let number of [].constructor(specificGendersArray.length);
                    let i = index
                  ">
                  <mat-form-field>
                    <mat-select [value]="specificGendersArray[i].type" (selectionChange)="specificTypeChange(i, $event!)"
                      class="gender-input">
                      <mat-option *ngFor="let gender of genders" value="{{ gender.label }}">{{ gender.label }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field class="gender-input">
                    <input value="{{specificGendersArray[i].number}}"
                      (change)="specificNumberChange(i, $event.srcElement!)" name="gender-count-{{ i }}" matInput
                      type="number" />
                  </mat-form-field>

                  <button (click)="removePlayerType(i)" mat-icon-button>
                    <mat-icon>indeterminate_check_box</mat-icon>
                  </button>
                </div>
                <button mat-button (click)="addSpecificGender()">
                  <mat-icon color="primary">add</mat-icon>
                  Add Gender
                </button>
              </mat-card-content>
            </ng-container>
          </div>
          <mat-form-field *ngIf="eventItemType === 'Pickup' && teams && teams.length > 0 && teamsEnabled"
            class="team-name-input" appearance="outline">
            <mat-label>Team Name</mat-label>
            <mat-select (selectionChange)="onTeamSelectionChange($event)">
              <mat-option *ngFor="let team of teams" [value]="team">{{team.name}}</mat-option>
            </mat-select>
          </mat-form-field>
          <div class="color-guests-container">
            <mat-form-field *ngIf="teamsEnabled && eventItemType === 'Pickup'" class="team-color-input color-picker"
              appearance="outline">
              <mat-hint>Team Color (Optional)</mat-hint>
              <input type="color" matInput formControlName="teamColor" />
            </mat-form-field>

            <mat-checkbox formControlName="allowParticipantGuests">Allow Participants to bring guests</mat-checkbox>
          </div>
          <div class="spacer"></div>
          <div class="side-by-side-buttons">
            <button class="get-started-button stepper-button" mat-stroked-button color="primary"
              matStepperPrevious>Back</button>
            <button class="get-started-button stepper-button" mat-raised-button color="primary"
              matStepperNext>Next</button>
          </div>
        </div>
      </mat-step>

      <mat-step label="Event Details">
        <div class="step-container">
          <h2>Event Details</h2>
          <mat-form-field class="team-name-input" appearance="outline" floatLabel="always" #costFormFieldEl>
            <mat-label>Cost</mat-label>
            <mat-icon matPrefix fontIcon="attach_money" />
            <input matInput formControlName="cost" type="number" min="0" max="999999" [placeholder]="eventIsFree ? 'Free' : ''"
              (click)="onCostInputClick()" #costInputEl />
          </mat-form-field>

          <mat-form-field class="team-name-input" appearance="outline" *ngIf="eventPlatform !== 'meh'">
            <mat-label>Equipment Required</mat-label>
            <input matInput formControlName="equipmentNeeded" />
          </mat-form-field>

          <gth-event-ticket-levels-form-control *ngIf="displayTicketLevels"
            [ticketLevelFormControl]="ticketLevelFormControl" [user]="currentUser" />

          <div class="event-options">
            <mat-checkbox class="isNeedingApproval" formControlName="creatorApprovalNeeded">
              Required approval from creator
            </mat-checkbox>

            <mat-checkbox *ngIf="displaySendFeedbackEmailAfterEvent" class="sendFeedbackEmailAfterEvent"
              formControlName="sendFeedbackEmailAfter">
              Send Feedback Email After Event
            </mat-checkbox>
          </div>

          <mat-error class="mat-caption" *ngIf="!eventItemForm.valid">
            {{ eventItemForm.error }}
          </mat-error>
          <div class="spacer"></div>
          <div class="side-by-side-buttons">
            <button class="get-started-button stepper-button" mat-stroked-button color="primary"
              matStepperPrevious>Back</button>
            <button class="get-started-button stepper-button" mat-raised-button color="primary"
              [disabled]="!eventItemForm.valid" (click)="onCreateButtonClick()">
              {{ editing ? 'Save' : 'Create' }}
            </button>
          </div>
        </div>
      </mat-step>
    </mat-stepper>
  </form>
</ng-template>