import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import { take } from 'rxjs/operators';

import 'firebase/firestore';

import { TeamModel } from '../models/team';
import { DBUtil } from '../utils/db-utils';
import { TeamMapper } from '../mappers/team-mapper';
import { User } from '../interfaces/user';
import { firestorePopulate } from '../utils/populaters';
import { RosterV2, Team } from '../interfaces/team';
import { CrudDAO } from './base';
import { TeamListFactory } from './utils/team-list-factory';
import { TeamListFilter } from '../interfaces/team-list-filter';

@Injectable({
  providedIn: 'root',
})
export class TeamDAOService implements CrudDAO {
  mapper = new TeamMapper();
  readonly firestore = this.afs.firestore;
  private teamListFactory = new TeamListFactory(this.firestore);

  constructor(readonly afs: AngularFirestore) { }

  async create(model: TeamModel<User>) {
    try {
      const roster = model.roster;

      model.roster = [];

      const collectionRef = this.afs.collection<Team>(DBUtil.Team);
      const doc = await collectionRef.doc();

      model.ref = (await doc.get().toPromise()).ref;

      const teamMap = this.mapper.toMap(model);
      delete teamMap.id;

      const teamDoc = Object.assign({
        created: firebase.firestore.Timestamp.now(),
      }, teamMap);

      /** Create a write batch */
      const batch = this.afs.firestore.batch();

      /** Create Team Document */
      await batch.set(doc.ref, teamDoc, { merge: true });

      if (roster && roster.length) {
        for (let i = 0; i < roster.length; i++) {
          const playerUID = roster[i].player.uid;
          const rosterDoc = await doc.collection(DBUtil.Roster).doc(playerUID);
          const rosterItem = {
            ...(roster[i] as any).model,
          };
          rosterItem.player = playerUID;
          /** Create Team Player Document */
          batch.set(rosterDoc.ref, rosterItem);
        }
      }

      /** Commit batch operations */
      return await batch.commit()
        /** Return Team Document ID */
        .then(() => {
          const teamId = doc.ref.id;
          console.log(`team id`, teamId);
          return teamId;
        });
    } catch (e) {
      throw e;
    }
  }

  async update(teamModel: TeamModel<string>) {
    const teamColRef = this.afs.collection(DBUtil.Team);
    const teamDocRef = await teamColRef.doc(teamModel.id);

    teamModel.ref = (await teamDocRef.get().toPromise()).ref;
    const roster = teamModel.roster;
    teamModel.roster = [];
    const teamMap = this.mapper.toMap(teamModel);

    const batch = this.firestore.batch();
    if (roster && roster.length) {
      for (const player of roster) {
        const playerUID = player.player;
        if (playerUID) {
          const rosterDoc = await teamDocRef.collection(DBUtil.Roster).doc(playerUID);
          const rosterSnap = await rosterDoc.get().pipe(take(1)).toPromise();
          if (rosterSnap.exists) {
            /** update player document */
            batch.update(rosterDoc.ref, player);
          } else {
            /** create player document */
            batch.set(rosterDoc.ref, player);
          }
        }
      }

      const rosterRef = teamDocRef.collection(DBUtil.Roster);
      const rosterSnap = await rosterRef.get().pipe(take(1)).toPromise();
      for (let i = 0; i < rosterSnap.docs.length; i++) {
        const rosterDocSnap = rosterSnap.docs[i];
        /** if player not in roster, remove player document */
        if (roster.some((p) => p.player === rosterDocSnap.id)) continue;

        batch.delete(rosterDocSnap.ref);
      }
    }

    /** Update Team Document */
    const teamDoc = Object.assign(
      {
        roster: [],
        updated: firebase.firestore.Timestamp.now(),
      },
      teamMap,
    );
    batch.set(teamDocRef.ref, teamDoc, { merge: true });

    /** Commit batch operations */
    return batch.commit().then(() => true);
  }

  async read(request: { id: string }) {
    const collectionRef = this.afs.collection(DBUtil.Team);
    const snap = await collectionRef.doc(request.id).get().toPromise();
    const teamModels = await this.generateTeamModelsFromSnapShots([snap]);
    return teamModels[0];
  }

  async delete(request: { id: string }) {
    const collectionRef = this.afs.collection(DBUtil.Team);
    const docRef = await collectionRef.doc(request.id!);
    const rosterRef = docRef.collection('roster');
    /** Create a write batch */
    const batch = this.afs.firestore.batch();

    /** Delete Team Document */
    batch.delete(docRef.ref);

    const rosterSnap = await rosterRef.get().pipe(take(1)).toPromise();
    for (const snapshot of rosterSnap.docs) {
      /** Delete Team Player Document */
      batch.delete(snapshot.ref);
    }

    /** Commit batch operations */
    return await batch.commit()
      .then(() => true)
      .catch(() => false);
  }

  async list(filter: TeamListFilter) {
    const teams = await this.teamListFactory.get(filter);

    return this.generateTeamModelsFromSnapShots(teams);
  }

  private async generateTeamModelsFromSnapShots(
    snapshot: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>[],
  ) {
    const teamModelArray: TeamModel<User>[] = [];
    for (const team of snapshot) {
      if (team) {
        const teamModel: TeamModel<User | string> = new TeamMapper().fromSnapshot(team);
        if (teamModel) {
          const rosterRef = await team.ref.collection(DBUtil.Roster).get();

          (teamModel as TeamModel<User>).roster = await this.populateUsersInRosterFromIds(
            rosterRef.docs,
          );
          teamModelArray.push(teamModel as TeamModel<User>);
        }
      }
    }
    return teamModelArray;
  }

  private async populateUsersInRosterFromIds(
    docs: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>[],
  ) {
    const rosterArray: RosterV2<User>[] = [];
    for (const roster of docs) {
      const popUser =
        (await firestorePopulate(
          this.afs,
          DBUtil.User,
          roster.data().player,
        )
        ).data();

      rosterArray.push({
        role: roster.data().role,
        player: popUser as User,
      });
    }

    return rosterArray;
  }
}
