import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'gth-list-input',
  templateUrl: './list-input.component.html',
  styleUrls: ['./list-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: GthListInputComponent,
    },
  ],
})
export class GthListInputComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input()
  get label() {
    return this._label;
  }
  set label(val: string) {
    this._label = val;
  }

  @Input()
  get disabled() {
    return this._disabled;
  }
  set disabled(val: boolean) {
    this._disabled = val;
  }

  @Input()
  get value() {
    return this._value;
  }
  set value(val: string[]) {
    this._value = val;
  }

  @Output()
  valueChange = new EventEmitter<string[]>();

  private _label = '';
  private _disabled = false;
  private _value: string[] = [];

  newItem = '';
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange = (_val) => { };
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = () => { };
  touched = false;

  private valueChanges = new EventEmitter<string[]>();
  private subscription = new Subscription();

  ngOnInit() {
    this.subscription =
      this.valueChanges.pipe(
        debounceTime(2000),
        distinctUntilChanged(),
      ).subscribe((value) => {
        this.onChange(this.value);
        this.value = value;
        this.markAsTouched();
        this.valueChange.emit(this.value);
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onAddItemButtonClick(index: number) {
    if (this.disabled) {
      return;
    }
    const item = this.newItem;
    if (index === -1) {
      if (!item) {
        return;
      }
      this.onChange(this.value);
      this.value.push(item);
      this.markAsTouched();
      this.valueChange.emit(this.value);
      return;
    }

    this.onChange(this.value);
    this.value.splice(index, 0, '');
    this.markAsTouched();
    this.valueChange.emit(this.value);
  }

  onRemoveItemButtonClick(index: number) {
    if (this.disabled) {
      return;
    }
    if (index >= 0) {
      this.markAsTouched();
      this.value.splice(index, 1);
      this.onChange(this.value);
      this.valueChange.emit(this.value);
    }
  }

  onInputChange(evt: any, index: number) {
    const newValue = evt.target.value;
    const arr = [...this.value];
    arr[index] = newValue;
    this.valueChanges.emit(arr);
  }

  writeValue(val: string[]) {
    this.value = val;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  trackByIndex(index: number, _obj: any): any {
    return index;
  }
}
