import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { GthAuthService } from '@gth-legacy';

@Component({
  selector: 'app-auth-dialog',
  templateUrl: './auth-dialog.component.html',
  styleUrls: ['./auth-dialog.component.scss'],
  standalone: true,
  imports: [
    RouterModule,
  ],
})
export class AuthDialogComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  constructor(
    private router: Router,
    private auth: GthAuthService,
    public dialogRef: MatDialogRef<AuthDialogComponent>,
  ) { }

  ngOnInit(): void {
    const currentRoute = this.router.url;
    if (!currentRoute.includes('auth')) {
      this.router.navigate(['auth/login'],
      );
    }

    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      takeUntil(this.unsubscribe$),
    ).subscribe((event: NavigationEnd) => {
      const url = event.url;
      if (!this.isValidRoute(url)) {
        this.auth.isLoggedIn$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((results)=>{
          this.dialogRef.close(results);
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private isValidRoute(url: string): boolean {
    // eslint-disable-next-line max-len
    const validRoutes = ['/auth/login', '/auth/sign-up', '/login', '/login/true', '/login/false', '/sign-up', '/sign-up/true/sign-up', '/sign-up/false/sign-up'];
    return validRoutes.includes(url);
  }
}
