import { DBModel } from './model.db';
import firebase from 'firebase/compat/app';
import { EventItemGuest, EventJoinerStatus, EventRsvpStatus } from '../interfaces/event-item';

export class EventJoinerModel extends DBModel {
  static readonly PLAYER = 'player';
  static readonly STATUS = 'status';
  static readonly ASSIGNED_GENDER_SPOT = 'assignedGenderSpot';
  static readonly APPROVED_BY = 'approvedBy';
  static readonly GUESTS = 'guests';
  static readonly RSVP_STATUS = 'rsvpStatus';
  static readonly CREATED_AT = 'createdAt';
  static readonly IS_GUEST_USER = 'isGuestUser';

  constructor(
    ref: firebase.firestore.DocumentReference<firebase.firestore.DocumentData> | undefined,
    public readonly player: string,
    public status?: EventJoinerStatus,
    public assignedGenderSpot?: string,
    public readonly approvedBy: string[] = [],
    public readonly guests: EventItemGuest[] = [],
    public readonly rsvpStatus: EventRsvpStatus = EventRsvpStatus.NOT_PLAYING,
    public readonly createdAt = firebase.firestore.Timestamp,
    public readonly isGuestUser = false,
  ) {
    super(ref);
  }
}
