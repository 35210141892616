import { ENTER, COMMA } from '@angular/cdk/keycodes';
import {
  Component, ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges, ViewChild,
} from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MatChipInputEvent } from '@angular/material/chips';
import { ThemePalette } from '@angular/material/core';
import { MatSelectChange } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';

import { EventItemTypes } from '@index/enums/event-item-type';
import { SkillLevel } from '@index/enums/skill-level';
import { GameType } from '@index/interfaces/stored-enums';
import { GthEventItemModel } from '@sentinels/models/event-item';
import { GthEventItemForm } from '@sentinels/models/event-item-form';
import { GthGenderModel, GthTeamModel, GthUserModel } from '@sentinels/models';
import { GthTimeService } from '../../services/time.service';
import { GthEventDateService } from '../../services/event-date.service';
import { GthCloudFunctionService } from '../../services/cloud/cloud-function.service';
import { PaymentAccountNotSetupDialogComponent } from '../../dialogs/payment-account-not-setup-dialog/payment-account-not-setup-dialog.component';
import { GTH_ENVIRONMENT, GthEnvironment } from '../../tokens/environment-tokens';
import { SrvApiService } from '@sentinels/services/api.service';

@Component({
  selector: 'gth-event-form',
  templateUrl: './event-form.component.html',
  styleUrls: ['./event-form.component.scss'],
})
export class GthEventFormComponent implements OnInit, OnChanges {
  @Input()
  get teamName() {
    return this.eventItemForm.teamName;
  }
  set teamName(val: string) {
    this.eventItemForm.teamName = val;
  }

  @Input()
  editing = false;

  @Input()
  get disabled() {
    return this.eventItemForm.disabled;
  }
  set disabled(val: boolean) {
    if (val) {
      this.eventItemForm.disable();
    } else {
      this.eventItemForm.enable();
    }
  }

  @Input()
  get event() {
    return this.value;
  }
  set event(val: GthEventItemModel | undefined) {
    this.eventItemForm.setEvent(val);
    if (!val) {
      return;
    }
    this.eventId = val.id;
  }

  @Input()
  currentUser?: GthUserModel | null;

  @Input()
  uploadImgLabel: string;

  @Input()
  eventPlatform = 'gth';

  @Input()
  get eventItemType() {
    return this.eventItemForm.eventType;
  }
  set eventItemType(val: EventItemTypes | undefined) {
    this.eventItemForm.eventType = val;
  }

  @Input()
  routeOnSave = true;

  @Input()
  public = false;

  @Input()
  displayAppearanceSettings = false;

  @Input()
  displaySendFeedbackEmailAfterEvent = false;

  @Input()
  displayTicketLevels = false;

  @Input()
  displayGenderInformation = true;

  @Output()
  selectAddress = new EventEmitter<GthEventItemForm>();

  @Output()
  selectTime = new EventEmitter<GthEventItemForm>();

  @Output()
  save = new EventEmitter<string>();

  @Output()
  savePublic = new EventEmitter<GthEventItemModel>();

  @ViewChild('costInputEl')
  costInputEl: ElementRef<HTMLInputElement>;

  /**
   * Returns true if the event form is valid
   */
  get valid() {
    return this.eventItemForm.valid;
  }

  get value() {
    return this.eventItemForm.value;
  }

  get teamColor(): ThemePalette {
    return this.eventItemForm.teamColor as unknown as ThemePalette;
  }

  get creatorApprovalNeeded() {
    return this.eventItemForm.creatorApprovalNeeded;
  }

  set creatorApprovalNeeded(val: boolean) {
    this.eventItemForm.creatorApprovalNeeded = val;
  }

  get isGenderSpecific() {
    return this.eventItemForm.isGenderSpecific;
  }

  get location() {
    return this.eventItemForm.locationDescription;
  }

  get dateStart() {
    return this.eventItemForm.dateStart;
  }

  get skillLevel() {
    return this.eventItemForm.skillLevel;
  }

  get dateStartTime() {
    const dateStart = this.eventItemForm.dateStart;
    if (!dateStart) {
      return undefined;
    }
    return this.getDateTimeStr(dateStart);
  }

  get dateEndTime() {
    const dateStart = new Date(this.eventItemForm.dateStart);
    if (!dateStart) {
      return undefined;
    }
    const duration = this.eventItemForm.duration ?? {
      hours: 2,
      minutes: 0,
    };
    dateStart.setMinutes(dateStart.getMinutes() + duration.minutes);
    dateStart.setHours(dateStart.getHours() + duration.hours);
    return this.timeService.getTimeFromDate(dateStart);
  }

  get time() {
    const duration = this.eventItemForm.duration;
    const dateStart = this.eventItemForm.dateStart;

    const description = this.eventDates.getDurationDescription(duration, dateStart);
    if (description) {
      return description;
    }

    return 'Add Event Date and Time';
  }

  get online() {
    return this.eventItemForm.online ?? false;
  }

  get teamsEnabled() {
    return this.config.teamsEnabled;
  }

  get recurrence() {
    const value = this.eventItemForm.recurringType;
    const startDate = this.eventItemForm.dateStart;
    return this.eventDates.getRepeatTypeDescription(value, startDate);
  }

  /**
   * Equipment Needed List
   */
  get equipmentNeeded() {
    return this.eventItemForm.equipmentNeeded;
  }

  /**
   * Required Attendees List
   */
  get requiredAttendees() {
    return this.eventItemForm.requiredAttendees;
  }
  set requiredAttendees(items: string[]) {
    this.eventItemForm.requiredAttendees = items;
  }

  get specificGendersArray(): { type: string; number: number }[] {
    if (!this.eventItemForm.specificGenders) {
      return [];
    }
    return this.eventItemForm.specificGenders;
  }

  get teams() {
    return this.userTeams;
  }
  private set teams(val: GthTeamModel[]) {
    this.userTeams = val;
  }

  get themeFormControl() {
    return this.eventItemForm.formGroup.controls.theme;
  }

  get backgroundColor() {
    return this.eventItemForm.formGroup.controls.backgroundColor.value;
  }

  get eventIsFree() {
    const cost = this.eventItemForm.cost;
    return !cost;
  }

  get ticketLevelFormControl() {
    return this.eventItemForm.formGroup.controls.ticketLevels;
  }

  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  specificGenderCount = 1;
  eventItemForm = new GthEventItemForm();
  timeOptions = this.timeService.getTimeOptions();
  today = new Date();
  genders: GthGenderModel[] = [];
  gameTypes: { [key: string]: GameType[] } = {};
  dateTouched = false;
  locationTouched = false;
  themeType = 'theme';

  private subscription = new Subscription();
  private userTeams: GthTeamModel[] = [];
  private timeStartSet = false;
  private eventId?: string;

  constructor(
    @Inject(GTH_ENVIRONMENT) private config: GthEnvironment,
    private router: Router,
    private cloudFunctionService: GthCloudFunctionService,
    private api: SrvApiService,
    private eventDates: GthEventDateService,
    private timeService: GthTimeService,
    private snackbar: MatSnackBar,
    private dialog: MatDialog,
  ) {}

  async ngOnInit() {
    this.uploadImgLabel = 'Upload a banner';
    this.genders = await this.api.genders.listAsync();
    this.gameTypes = await this.api.gameTypes.groupedAsync();
    this.disableLocationCtrl();
    this.setupDisabledMappings({
      theme: 'backgroundColor',
    }, this.eventItemForm.formGroup);
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.currentUser && this.currentUser) {
      this.eventItemForm.setUser(this.currentUser);
      /** Get User Teams */
      if (this.currentUser && this.currentUser.uid !== 'guest') {
        this.teams = await this.cloudFunctionService.teamRoster
          .getTeamsByUserId$(this.currentUser.uid)
          .pipe(take(1)).toPromise() as GthTeamModel[];
      }
    }

    if (changes.eventItemType) {
      this.eventItemForm.eventType = this.eventItemType;
    }

    if (changes.eventPlatform) {
      if (this.eventPlatform === 'meh') {
        this.eventItemForm.gameType = 'Other';
      }
    }
  }

  onThemeTypeChange(evt: MatButtonToggleChange) {
    const value = evt.value;
    this.themeType = value;
    if (this.themeType === 'color') {
      this.themeFormControl.reset();
    } else if (this.themeType === 'theme') {
      this.formGroup.get('backgroundColor').reset();
    }
  }

  get formGroup() {
    return this.eventItemForm.formGroup;
  }

  clearBackgroundColor() {
    this.eventItemForm.formGroup.get('backgroundColor').reset();
  }

  onDateStartChange() {
    if (this.eventItemForm.dateStart) {
      if (!this.timeStartSet) {
        this.eventItemForm.dateStart.setHours(10, 0, 0, 0);
      }

      if (!this.eventItemForm.duration) {
        this.eventItemForm.duration = {
          hours: 2,
          minutes: 0,
        };
      }
    }
  }

  onOnlineChange(online: boolean) {
    this.eventItemForm.online = online;
    if (online) {
      this.eventItemForm.clearLocation();
    }
  }

  onStartEventTimeChange(evt: MatSelectChange) {
    if (!this.dateStart) {
      return;
    }
    this.timeStartSet = true;
    const value = evt.value;
    const dateStart = this.dateStart;

    // eslint-disable-next-line max-len
    this.eventItemForm.dateStart = new Date(`${dateStart.getMonth() + 1}/${dateStart.getDate()}/${dateStart.getFullYear()} ${value}`);
    this.eventItemForm.dateStart.setSeconds(0);
    this.eventItemForm.dateStart.setMilliseconds(0);
  }

  onEndEventTimeChange(evt: MatSelectChange) {
    if (!this.dateStart) {
      return;
    }
    const value = evt.value;
    const dateStart = this.dateStart;

    // eslint-disable-next-line max-len
    const dateEnd = new Date(`${dateStart.getMonth() + 1}/${dateStart.getDate()}/${dateStart.getFullYear()} ${value}`);
    if (dateEnd.getTime() < dateStart.getTime()) {
      return;
    }
    const diffMs = dateEnd.getTime() - dateStart.getTime();
    const diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes

    this.eventItemForm.duration = {
      hours: diffHrs,
      minutes: diffMins,
    };
  }

  onGenderSpecificChange() {
    this.eventItemForm.isGenderSpecificChanged();
    if (this.isGenderSpecific) {
      this.eventItemForm.disableNumberOfPlayers();
    } else {
      this.eventItemForm.enableNumberOfPlayers();
    }
    this.eventItemForm.numberOfPlayers = this.specificNumberTotal();
  }

  onGameTypeChanged(gameType: string) {
    this.eventItemForm.gameType = gameType;
  }

  onVisibilityChanged(eventType: EventItemTypes) {
    this.eventItemForm.eventType = eventType;
  }

  onSkillLevelChanged(skillLevel: SkillLevel) {
    this.eventItemForm.skillLevel = skillLevel;
  }

  onTeamNameChanged(evt: KeyboardEvent) {
    if (!evt.target) {
      return;
    }

    const teamName = (evt.target as unknown as { value: string }).value;
    this.eventItemForm.teamName = teamName;
  }

  onTeamSelectionChange(change: MatSelectChange) {
    if (!change.value) return;

    const team = change.value as GthTeamModel;

    this.eventItemForm.setTeam(team);
  }

  addSpecificGender() {
    this.specificGenderCount += 1;
    this.eventItemForm.addSpecificGender();
  }

  specificNumberChange(index: number, src: EventTarget) {
    const gendersArray = this.specificGendersArray;
    gendersArray[index].number = parseInt((src as HTMLInputElement).value);
    this.onGenderSpecificChange();
  }

  toBoolean = (value: string | number | boolean): boolean => {
    return [true, 'true', 'True', 'TRUE', '1', 1].includes(value);
  };

  specificNumberTotal() {
    let total = 0;
    this.specificGendersArray.forEach(({ number }) => {
      total = total + number;
    });
    return total;
  }

  specificTypeChange(index: number, el: MatSelectChange) {
    const gendersArray = this.specificGendersArray;
    gendersArray[index].type = el.value;
    this.onGenderSpecificChange();
  }

  removePlayerType(index: number) {
    this.specificGendersArray.splice(index, 1);
    this.onGenderSpecificChange();
  }

  /**
   * Gets user readable value for thumb slider
   * @param {number} value Number value of slider
   * @return {string} Dollar representation of value
   */
  costLabel(value: number): string {
    return `$${value}`;
  }

  /**
   * Adds equipment item to needed equipment list
   * @param {MatChipInputEvent} evt Chip Event
   */
  addEquipmentItem(evt: MatChipInputEvent) {
    this.eventItemForm.addEquipmentItem(evt);
  }

  /**
   * Removes equipment item from needed equipment list
   * @param {string} equipment equipment item
   */
  removeEquipmentItem(equipment: string) {
    this.eventItemForm.removeEquipmentItem(equipment);
  }

  /**
   * Adds required attendee item to required attendee list
   * @param {MatChipInputEvent} evt Chip Event
   */
  addRequiredAttendeeItem(evt: MatChipInputEvent) {
    this.eventItemForm.addRequiredAttendeeItem(evt);
  }

  /**
   * Removes required attendee item from required attendee list
   * @param {string} attendee required attendee item
   */
  removeRequiredAttendeeItem(attendee: string) {
    this.eventItemForm.removeRequiredAttendeeItem(attendee);
  }

  /**
   * Event handler for event time button click
   */
  onTimePickerButtonClick() {
    this.selectTime.emit(this.eventItemForm);
  }

  /**
   * Event handler for location button click
   */
  onAddressButtonClick() {
    this.selectAddress.emit(this.eventItemForm);
  }

  onCreateButtonClick() {
    const value = this.eventItemForm.value;

    if (this.public) {
      this.savePublic.emit(value);
      return;
    }
    if (this.editing) {
      this.updateEvent(value);
      return;
    }
    this.createEvent(value);
  }

  onCostInputClick() {
    const isStripeAccountSetup = this.currentUser?.stripeId &&
      this.currentUser?.stripeChargesEnabled &&
      this.currentUser?.stripeDetailsSubmitted;
    if (!isStripeAccountSetup) {
      this.dialog.open(PaymentAccountNotSetupDialogComponent, { maxWidth: '340px' });
      /* Remove focus from cost input element */
      const inputEl = this.costInputEl.nativeElement;
      inputEl.blur();
    }
  }

  private updateEvent(event: GthEventItemModel) {
    if (!this.eventId) {
      return;
    }
    this.subscription.add(
      this.cloudFunctionService.event.update$(this.eventId, event).pipe(
        take(1),
      ).subscribe(() => {
        this.snackbar.open('Event updated successfully');
        this.save.emit(this.eventId);
      }),
    );
  }

  private createEvent(event: GthEventItemModel) {
    if (this.eventPlatform) {
      event.platform = this.eventPlatform;
    }
    this.subscription.add(
      this.cloudFunctionService.event.create$(event).pipe(take(1)).subscribe((event) => {
        this.snackbar.open('Event created successfully');
        this.save.emit(event.id);
        /* Component was opened as a route */
        if (this.routeOnSave) this.router.navigate(['/games']);
      }),
    );
  }

  private disableLocationCtrl() {
    this.eventItemForm.disableLocationCtrl();
  }

  private getDateTimeStr(date: Date) {
    return this.timeService.getTimeFromDate(date);
  }

  private setupDisabledMappings(mappings: { [s: string]: string }, form: FormGroup) {
    function toggleSiblingOnValue(val1: FormControl, val2: FormControl) {
      val1.valueChanges.subscribe((value) => {
        if (value) {
          val2.disable({ emitEvent: false });
        } else {
          val2.enable({ emitEvent: false });
        }
      });
    }

    // eslint-disable-next-line guard-for-in
    for (const mapping in mappings) {
      const val1 = form.get(mapping);
      const val2 = form.get(mappings[mapping]);
      toggleSiblingOnValue(val1 as any, val2 as any);
      toggleSiblingOnValue(val2 as any, val1 as any);
    }
  }
}
