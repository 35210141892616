<h1 mat-dialog-title>Give us your feedback</h1>
<div mat-dialog-content class="content">
  <p>Please rate the performance of our application and leave feedback below</p>

  <div class="rating-container">
    <button class="rating" mat-icon-button type="button" (click)="rating = 1" [class.--active]="rating > 0">
      <mat-icon>{{ rating > 0 ? 'star' : 'star_outline' }}</mat-icon>
    </button>
    <button class="rating" mat-icon-button type="button" (click)="rating = 2" [class.--active]="rating > 1">
      <mat-icon>{{ rating > 1 ? 'star' : 'star_outline' }}</mat-icon>
    </button>
    <button class="rating" mat-icon-button type="button" (click)="rating = 3" [class.--active]="rating > 2">
      <mat-icon>{{ rating > 2 ? 'star' : 'star_outline' }}</mat-icon>
    </button>
    <button class="rating" mat-icon-button type="button" (click)="rating = 4" [class.--active]="rating > 3">
      <mat-icon>{{ rating > 3 ? 'star' : 'star_outline' }}</mat-icon>
    </button>
    <button class="rating" mat-icon-button type="button" (click)="rating = 5" [class.--active]="rating > 4">
      <mat-icon>{{ rating > 4 ? 'star' : 'star_outline' }}</mat-icon>
    </button>
  </div>

  <mat-form-field appearance="outline">
    <mat-label>Your Feedback</mat-label>
    <textarea matInput [(ngModel)]="feedback"></textarea>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Feedback Type</mat-label>
    <mat-select [(ngModel)]="feedbackType">
      <mat-option value="general">General Feedback</mat-option>
      <mat-option value="bug">Bug</mat-option>
      <mat-option value="request">Feature Request</mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div mat-dialog-actions class="actions">
  <span class="fx-auto"></span>
  <button mat-button class="uppercase" (click)="onCancelButtonClick()">Cancel</button>
  <button mat-flat-button color="primary" class="uppercase" (click)="onSubmitButtonClick()">Submit</button>
</div>