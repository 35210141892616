import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'gth-stripe-icon',
  // eslint-disable-next-line max-len
  template: `<div class="stripe-icon" [style.height]="size+'px'" [style.width]="size+'px'" [innerHTML]="svg"></div>
  `,
  styles: [
    // eslint-disable-next-line max-len
    ':host {display: grid;place-content: center;background-color: rgb(99, 91, 255);padding: 0.5rem;}',
    '.stripe-icon {color: white;}',
  ],
  standalone: true,
  imports: [],
})
export class StripeIconComponent implements OnInit {
  @Input()
  public size = 32;
  // eslint-disable-next-line max-len
  STRIPE_SVG = (size: number) => `<svg width=\'${size}px\' height=\'${size}px\' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 360 150"><path fill="currentColor" fill-rule="evenodd" d="M360 77.4c0-25.6-12.4-45.8-36.1-45.8-23.8 0-38.2 20.2-38.2 45.6 0 30.1 17 45.3 41.4 45.3 11.9 0 20.9-2.7 27.7-6.5V96c-6.8 3.4-14.6 5.5-24.5 5.5-9.7 0-18.3-3.4-19.4-15.2h48.9c0-1.3.2-6.5.2-8.9Zm-49.4-9.5c0-11.3 6.9-16 13.2-16 6.1 0 12.6 4.7 12.6 16h-25.8Zm-63.5-36.3c-9.8 0-16.1 4.6-19.6 7.8l-1.3-6.2h-22v116.6l25-5.3.1-28.3c3.6 2.6 8.9 6.3 17.7 6.3 17.9 0 34.2-14.4 34.2-46.1-.1-29-16.6-44.8-34.1-44.8Zm-6 68.9c-5.9 0-9.4-2.1-11.8-4.7l-.1-37.1c2.6-2.9 6.2-4.9 11.9-4.9 9.1 0 15.4 10.2 15.4 23.3 0 13.4-6.2 23.4-15.4 23.4Zm-71.3-74.8 25.1-5.4V0l-25.1 5.3v20.4Zm0 7.6h25.1v87.5h-25.1V33.3Zm-26.9 7.4-1.6-7.4h-21.6v87.5h25V61.5c5.9-7.7 15.9-6.3 19-5.2v-23c-3.2-1.2-14.9-3.4-20.8 7.4Zm-50-29.1-24.4 5.2-.1 80.1c0 14.8 11.1 25.7 25.9 25.7 8.2 0 14.2-1.5 17.5-3.3V99c-3.2 1.3-19 5.9-19-8.9V54.6h19V33.3h-19l.1-21.7ZM25.3 58.7c0-3.9 3.2-5.4 8.5-5.4 7.6 0 17.2 2.3 24.8 6.4V36.2c-8.3-3.3-16.5-4.6-24.8-4.6C13.5 31.6 0 42.2 0 59.9 0 87.5 38 83.1 38 95c0 4.6-4 6.1-9.6 6.1-8.3 0-18.9-3.4-27.3-8v23.8c9.3 4 18.7 5.7 27.3 5.7 20.8 0 35.1-10.3 35.1-28.2-.1-29.8-38.2-24.5-38.2-35.7Z"></path></svg>`;
  svg: SafeHtml;
  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit() {
    if (this.size) {
      this.svg = this.sanitizer.bypassSecurityTrustHtml(this.STRIPE_SVG(this.size));
    }
  }
}
