import { Component, EventEmitter, Input, Output } from '@angular/core';

import { GthEventItemModel } from '../../../../../sentinels/src/lib/models/event-item';
import { GthTeamModel } from '../../../../../sentinels/src/lib/models/team';
import { GthUserModel } from '../../../../../sentinels/src/lib/models/user';

@Component({
  selector: 'gth-team-schedule-table',
  templateUrl: './team-schedule-table.component.html',
  styleUrls: ['./team-schedule-table.component.scss'],
})
export class GthTeamScheduleTableComponent {
  @Input()
  team?: GthTeamModel;

  @Input()
  games?: GthEventItemModel[];

  @Input()
  user?: GthUserModel | null;

  @Output()
  view = new EventEmitter<GthEventItemModel>();

  @Output()
  manageWaitlist = new EventEmitter<GthEventItemModel>();

  @Output()
  valueChange = new EventEmitter<GthEventItemModel>();

  @Output()
  findSub = new EventEmitter<GthEventItemModel>();

  displayedColumns = ['location', 'players', 'rsvp', 'actions'];

  gameHasWaitlist(game: GthEventItemModel) {
    return game.waitList.length > 0;
  }

  onRsvpChange(game: GthEventItemModel, rsvp: boolean) {
    if (this.user) {
      const players = game.getTeamPlayers(this.user);
      const player = players.find((p) => p.uid === this.user?.uid);
      if (player) {
        console.log('Unimplemented: Should set rsvp to ', rsvp);
        this.valueChange.emit(game);
      }
    }
  }

  onFindSubButtonClick(game: GthEventItemModel) {
    this.findSub.emit(game);
  }

  onViewButtonClick(game: GthEventItemModel) {
    this.view.emit(game);
  }

  onManageWaitlistButtonClick(game: GthEventItemModel) {
    this.manageWaitlist.emit(game);
  }

  getHasRsvp(game: GthEventItemModel) {
    if (!this.user) {
      return false;
    }
    return game.hasRsvp(this.user);
  }

  getPlayerCount(game: GthEventItemModel) {
    return {
      included: 0,
      total: game.numberOfPlayers,
    };
  }
}
