import { Injectable, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, combineLatest, forkJoin, Subscription } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { GthAuthService, GthCloudFunctionService, GthRemoteConfigService } from '@gth-legacy';
import { NotificationModel } from '@index/models/notifications';
import { GthTeamModel } from '@sentinels/models';

@Injectable({ providedIn: 'root' })
export class TeamsService implements OnDestroy {
  private teamsSubject = new BehaviorSubject<GthTeamModel[]>(null);
  private selectedActiveTeamSubject = new BehaviorSubject<GthTeamModel>(null);
  private subscriptions = new Subscription();

  constructor(
    private authService: GthAuthService,
    private remoteConfig: GthRemoteConfigService,
    private cloudFunctionService: GthCloudFunctionService,
    private snackbar: MatSnackBar,
  ) { }

  get teams$() {
    return this.teamsSubject.asObservable();
  }

  get selectedActiveTeam$() {
    return this.selectedActiveTeamSubject.asObservable();
  }

  get isUserAdminOrOwner$() {
    const user$ = this.authService.userModel$;
    const team$ = this.selectedActiveTeam$;
    return combineLatest([user$, team$]).pipe(
      map(([user, team]) => {
        if (!user || !team) {
          return false;
        }
        const userId = user.uid;
        const acceptedIds = team.admins.map((admin) => admin.player.uid);
        acceptedIds.push(team.creator.player.uid);
        return acceptedIds.includes(userId);
      }),
    );
  }

  get enableSubscriptions() {
    return this.remoteConfig.getSubscriptionsEnabled();
  }

  get enableTeamTopics() {
    return this.remoteConfig.getTeamTopicsEnabled();
  }

  async loadTeams(): Promise<void> {
    const activeTeam = this.selectedActiveTeamSubject.getValue();
    let id = '';
    if (activeTeam) {
      id = activeTeam.id;
    }
    const userID = await this.authService.userModel$.pipe(
      first(),
      map((user) => user.uid),
    ).toPromise();
    const teams = await this.cloudFunctionService.teamRoster.getTeamsByUserId(userID);
    this.teamsSubject.next(teams);
    const updatedTeam = teams.find((t) => t.id === id);
    this.selectedActiveTeamSubject.next(updatedTeam);
  }

  setSelectedActiveTeam(teamID?: string): void {
    this.subscriptions.add(
      this.teams$.subscribe((teams) => {
        const selectedActiveTeam = teams?.find((t) => t.id === teamID);
        this.selectedActiveTeamSubject.next(selectedActiveTeam ?? null);
      }),
    );
  }

  /* Send a notification to all team members */
  sendNotification(notification: NotificationModel, team?: GthTeamModel) {
    /** Use selected team if not provided */
    if (!team) team = this.selectedActiveTeamSubject.getValue();
    const sendNotifications$ = team.roster.map((player) => {
      return this.cloudFunctionService.notification.create$(notification, player.id);
    });

    this.subscriptions.add(
      forkJoin([sendNotifications$])
        .subscribe(
          () => this.snackbar.open('All notifications sent successfully', 'OK'),
          () => this.snackbar.open('Something went wrong sending notifications', 'OK'),
        ),
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
