import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SrvCacheService {
  private cacheMap = new Map();
  private cacheSubject = new BehaviorSubject(new Map());

  read$<T>(key: string) {
    const subject$ = this.cacheSubject.asObservable();
    return subject$.pipe(
      map((s) => s.get(key) as T),
    );
  }

  read<T>(key: string) {
    return this.cacheMap.get(key) as T;
  }

  store<T>(key: string, value: T) {
    this.cacheMap.set(key, value);
    this.cacheSubject.next(this.cacheMap);
  }

  clearByKey(key: string) {
    this.cacheMap.set(key, undefined);
    this.cacheSubject.next(this.cacheMap);
  }

  clear() {
    this.cacheMap.clear();
    this.cacheSubject.next(this.cacheMap);
  }
}
