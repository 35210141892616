import { DBModel } from './model.db';
import { MessageCreateRequest, Message } from '../interfaces/conversation';
import 'firebase/firestore';
import { User } from './../interfaces/user';
import firebase from 'firebase/compat/app';

export class ConversationModel extends DBModel {
  static readonly MESSAGES = 'messages';
  static readonly PARTICIPANTS = 'participants';

  constructor(
    ref: firebase.firestore.DocumentReference<firebase.firestore.DocumentData> | undefined,
    public readonly messages: Message,
    public readonly participants: User[],
  ) {
    super(ref);
  }

  static fromJSON(
    request: MessageCreateRequest,
    ref?: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>,
  ) {
    return new ConversationModel(ref, request.message, request.participants);
  }
}
