import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { first } from 'rxjs/operators';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule } from '@angular/forms';

import { GthEventItemModel, GthUserModel } from '@sentinels/models';
import { GthCloudFunctionService } from '@gth-legacy/services';
import { EventItemGuest, EventJoinerStatus, EventRsvpStatus, GthEventJoiner } from '@index/interfaces';
import { MatSelectModule } from '@angular/material/select';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmUpdateDialogComponent } from './dialogs/confirm-update-dialog/confirm-update-dialog.component';
import { ConfirmUpdateDialogModule } from './dialogs/confirm-update-dialog/confirm-update-dialog.module';
import { EventJoinerModel } from '@index/models/event-joiner';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatFormFieldModule } from '@angular/material/form-field';

type IntEventJoiner = EventJoinerModel & {
    user?: GthUserModel;
}

export interface EventJoinerUpdateRequest {
    status: EventJoinerStatus;
    player: string;
    event: string;
    rsvpStatus: EventRsvpStatus;
    guests?: EventItemGuest[];
  }

@Component({
    selector: 'gth-edit-participants',
    templateUrl: './edit-participants.component.html',
    styleUrls: ['./edit-participants.component.scss'],
    standalone: true,
    imports: [
    CommonModule,
        MatCardModule,
        MatIconModule,
        MatButtonModule,
        MatListModule,
        MatTooltipModule,
        MatSelectModule,
        FormsModule,
        ConfirmUpdateDialogModule,
        MatSnackBarModule,
        MatFormFieldModule,
    ],
})
export class EditParticipantsComponent implements OnChanges {
    @Input()
    event?: GthEventItemModel;
    statuses = [
        { viewValue: 'Yes', value: EventRsvpStatus.PLAYING },
        { viewValue: 'No', value: EventRsvpStatus.NOT_PLAYING },
        { viewValue: 'Maybe', value: EventRsvpStatus.MAYBE },
        { viewValue: 'Spectating', value: EventRsvpStatus.SPECTATING },
    ];

    @Output()
    messageUser = new EventEmitter<string>();

    joiners: IntEventJoiner[] = [];
    participants: any[]; // Assuming you have a data structure for participants
    editingIndex: null|number = null;
    selectedOption: EventRsvpStatus | null = null;
    newUserSelectedOption: EventRsvpStatus | null = null;
    newUserEmail = '';

    get eventId() {
        if (this.event) return this.event.id;
        return undefined;
    }

    constructor(
        private cloud: GthCloudFunctionService,
        private dialog: MatDialog,
        private snackBar: MatSnackBar,
    ) { }

    async ngOnChanges(changes: SimpleChanges) {
        if (changes.event) {
            await this.initJoiners();
        }
    }
    async onSaveJoiner(joiner: IntEventJoiner) {
        this.dialog.open(ConfirmUpdateDialogComponent)
            .afterClosed()
            .subscribe((data:boolean)=>{
            if (!data) return;
            console.log(data);
            const updatedJoiner = this.updateJoinerStatus(joiner, this.selectedOption);
            this.cloud.eventJoiner.updatePlayer$(updatedJoiner).subscribe(async (results)=>{
                if (results) {
                    this.snackBar.open('User updated.');
                }
                this.cloud.eventJoiner.list$(this.eventId).subscribe((joiners)=>{
                    this.participants = joiners;
                });
                this.editingIndex = null;
                await this.initJoiners();
            });
        });
    }

    addUser() {

    }

    updateJoinerStatus(joiner: EventJoinerModel, option: EventRsvpStatus) {
        const jsur: EventJoinerUpdateRequest = {
            rsvpStatus: option,
            status: joiner.status,
            guests: joiner.guests,
            event: this.event.id,
            player: joiner.id,
        };

        if (option === EventRsvpStatus.PLAYING) {
            jsur.status = EventJoinerStatus.Approved;
        }
        if (option === EventRsvpStatus.NOT_PLAYING) {
            jsur.status = EventJoinerStatus.Dropped;
        }
        return jsur;
    }

    onEdit(index:number) {
        console.log(index);
        this.editingIndex = index;
    }

    onRemoveJoiner(id: string) {
        this.cloud.eventJoiner.denyPlayer$(id, this.eventId);
    }

    onMessageButtonClick(joiner: IntEventJoiner) {
        this.messageUser.emit(joiner.player);
    }

    private async initJoiners() {
        this.joiners = await this.cloud.eventJoiner.list$(this.eventId).pipe(
            first(),
        )
            .toPromise() as unknown as IntEventJoiner[];
        const userIds = this.joiners.map((j) => j.player);
        const users = await this.cloud.user.getUsersById(userIds);
        this.joiners.forEach((j) => {
            const user = users.find((u) => u.id === j.player);
            j.user = user;
        });
    }
}
