import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { GthPaymentsNeededTableComponent } from './payments-needed-table.component';

@NgModule({
  imports: [CommonModule, RouterModule, ReactiveFormsModule, MatTableModule],
  exports: [GthPaymentsNeededTableComponent],
  declarations: [GthPaymentsNeededTableComponent],
})
export class GthPaymentsNeededTableModule {}
