import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Injectable } from '@angular/core';

import { DBUtil } from '../utils/db-utils';
import { EarnedBadge } from '../interfaces/earned-badge';

@Injectable({ providedIn: 'root' })
export class BadgeDAOService {
  constructor(private readonly firestore: AngularFirestore) {}

  public async read(request: {id: string}): Promise<EarnedBadge | null> {
    const collectionRef = this.firestore.collection(DBUtil.Badge);
    const snap = await collectionRef.doc(request.id).get().toPromise();
    return Object.assign({ id: snap.id }, snap.data()) as EarnedBadge;
  }

  public async list(): Promise<EarnedBadge[] | null> {
    const collectionRef = this.firestore.collection(DBUtil.Badge);
    const snap = await collectionRef.get().toPromise();
    return snap.docs.map((doc) => Object.assign({ id: doc.id }, doc.data()) as EarnedBadge);
  }
}
