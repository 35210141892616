import { getTimeOptions } from '../../../services/time.service';
import { AvailabilityDay } from '../../../interfaces/availability';

export interface GthPlannerItem {
  id: string;
  userId: string;
  dates: Date[];
  days: AvailabilityDay[];
  allDay: boolean;
  startTime?: string;
  endTime?: string;
}

export class GthPlanItem {
  date: Date;
  times: GthPlanTime[];

  private allTimes: string[] = [];

  constructor(date: Date) {
    this.date = date;
    this.times = [];

    this.allTimes = getTimeOptions();
  }

  addAllDay(id: string) {
    this.addTime(id, this.allTimes[0], this.allTimes[this.allTimes.length - 1]);
  }

  addTime(id: string, startTime: string, endTime: string) {
    const startIndex = this.allTimes.indexOf(startTime);
    const endIndex = this.allTimes.indexOf(endTime);
    if (startIndex === -1 || endIndex === -1) {
      console.error('Plan: Invalid start/end time');
      return;
    }
    for (let i = startIndex; i < (endIndex + 1); i++) {
      const time = this.allTimes[i];
      const existing = this.times.find((et) => et.id === id && et.time === time);
      if (!existing) {
        this.times.push(new GthPlanTime(id, time));
      }
    }
  }
}

export class GthPlanTime {
  id: string;
  time: string;

  constructor(id: string, time: string) {
    this.time = time;
    this.id = id;
  }
}
