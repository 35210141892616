<form class="omni-search-container mat-elevation-z1" [formGroup]="searchForm">
    {{searchForm.controls.value| json}}
    <!--
        Players:
        * Search Type
        * City
        * Sports
        * Rating
        * Gender

        Pickup Games
        * City
        * Sports
        * Event Type
        * Rating
        * Gender

        Teams
        * City
        * Sports
        * Show Favorites
    -->
    <ng-container *ngIf="isPickupGames">
        <div class="omni-search">
            <select class="event-type--mobile omni-select" *ngIf="eventTypes$ | async as eventTypes"
                placeholder="Event Type" formControlName="eventType">
                <option *ngFor="let eventType of eventTypes | keyvalue" [value]="eventType.key">
                    {{ eventType.value }}
                </option>
            </select>
        </div>
    </ng-container>
    <div class="omni-search fx-row">
        <ng-container *ngIf="isPickupGames">
            <select class="event-type omni-select" *ngIf="eventTypes$ | async as eventTypes" placeholder="Event Type"
                formControlName="eventType">
                <option *ngFor="let eventType of eventTypes | keyvalue" [value]="eventType.key">
                    {{ eventType.value }}
                </option>
            </select>
        </ng-container>

        <input class="omni-field" matInput #cityInput ngx-gp-autocomplete #placesRef="ngx-places"
            formControlName="cityName" placeholder="Where do you play?" (click)="displayOptions = true"
            (change)="clearCityInput()" (onAddressChange)="onAutocompleteSelected($event)" />
        <button class="clear-filter-button" mat-icon-button matTooltip="Clear Filter"
            (click)="onClearFilterButtonClick()" color="warn">
            <mat-icon>clear</mat-icon>
        </button>
        <button mat-icon-button matTooltip="Refresh Results" (click)="onRefreshButtonClick()">
            <mat-icon>refresh</mat-icon>
        </button>
        <button mat-icon-button matTooltip="Toggle Filter" (click)="displayOptions = !displayOptions">
            <mat-icon color="accent">filter_list</mat-icon>
        </button>
        <a mat-icon-button color="primary" [matTooltip]="createButtonText" (click)="openCreateGameDialog()"
            *ngIf="isPickupGames">
            <mat-icon>add</mat-icon>
        </a>
        <ng-content></ng-content>
    </div>
    
    <div class="omni-content" [class.--visible]="displayOptions">
        <div class="omni-search-type fx-row fx-gap-10x">
            <button *ngIf="!sportsDisplayed" type="button" 
                mat-stroked-button 
                [color]="getBtnColor(searchForm.controls.gameType.value.length)"
                (click)="displayedSearchType = 'Sport'">
                Sport
            </button>
            <button *ngIf="sportsDisplayed" type="button" mat-flat-button color="accent"
                (click)="displayedSearchType = 'Sport'">
                Sport
            </button>
            <ng-container *ngIf="isAvailablePlayers || isPickupGames">
                <button 
                    *ngIf="!genderDisplayed" 
                    type="button" 
                    mat-stroked-button 
                    [color]="getBtnColor(searchForm.controls.gender.value)"
                    (click)="displayedSearchType = 'Gender'">
                    Gender
                </button>
                <button *ngIf="genderDisplayed" type="button" mat-flat-button color="accent"
                    (click)="displayedSearchType = 'Gender'">
                    Gender
                </button>
            </ng-container>
            
            <ng-container *ngIf="isAvailablePlayers">
                <button 
                    *ngIf="!ratingsDisplayed" 
                    type="button" 
                    mat-stroked-button 
                    [color]="getBtnColor(!!(searchForm.controls.ratings.value.length))"
                    (click)="displayedSearchType = 'Ratings'">
                    Ratings
                </button>
                <button *ngIf="ratingsDisplayed" type="button" mat-flat-button color="accent"
                    (click)="displayedSearchType = 'Ratings'">
                    Ratings
                </button>
            </ng-container>
            <ng-container *ngIf="isAvailablePlayers">
                <button 
                    type="button" 
                    *ngIf="!daysDisplayed" 
                    mat-stroked-button 
                    [color]="getBtnColor(searchForm.controls.days.value.length)"
                    (click)="displayedSearchType = 'Days'">
                    Days Available
                </button>
                <button type="button"*ngIf="daysDisplayed" mat-flat-button color="accent"
                (click)="displayedSearchType = 'Days'">
                Days Available
            </button>
            <button type="button" 
                *ngIf="!exactDateDisplayed"
                 mat-stroked-button 
                 [color]="getBtnColor(!!searchForm.controls.exactDate.value)"
                (click)="displayedSearchType = 'ExactDate'">
                Exact Date
            </button>
            <button type="button" *ngIf="exactDateDisplayed" mat-flat-button color="accent"
            (click)="displayedSearchType = 'ExactDate'">
                Exact Date
            </button>
                <button *ngIf="genderDisplayed && !isAvailablePlayers" type="button" mat-flat-button color="accent"
                    (click)="displayedSearchType = 'Gender'">
                    Gender
                </button>
        </ng-container>
            <mat-slide-toggle labelPosition="before" formControlName="online" *ngIf="!isAvailablePlayers"
                (change)="onOnlineChange($event.checked)">Online Only</mat-slide-toggle>
            <ng-container *ngIf="isTeams">
                <mat-slide-toggle *ngIf="user$ | async" labelPosition="before" formControlName="showFavorites">Only
                    Show
                    Favorites</mat-slide-toggle>
            </ng-container>
        </div>

        <div>
            <div class="input-section" [class.input-section--visible]="displayedSearchType === 'ExactDate'">
                <mat-form-field class="example-full-width">
                    <mat-label>Choose a date</mat-label>
                    <input formControlName="exactDate" matInput [matDatepicker]="picker">
                    <mat-hint>MM/DD/YYYY</mat-hint>
                    <mat-datepicker
                        #picker></mat-datepicker>
                </mat-form-field>
                <button class="open-btn" mat-raised-button (click)="picker.open()">Open</button>
            </div>

            <div class="input-section" [class.input-section--visible]="displayedSearchType === 'Days'">
                <!-- Show abbreviations for days on small screens -->
                <mat-form-field appearance="outline" *ngIf="(isSmallScreen$ | async)">
                    <mat-label>Days</mat-label>
                    <mat-select formControlName="days" multiple>
                        <mat-option *ngFor="let day of days" [value]="day">{{ day }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <!-- Show chips on larger screens -->
                <mat-chip-listbox class="sport-listbox" aria-label="Select the days you want" formControlName="days"
                    *ngIf="(isSmallScreen$ | async)=== false" multiple>
                    <mat-chip-option *ngFor="let day of days" color="accent">{{ day }}</mat-chip-option>
                </mat-chip-listbox>
            </div>
            
            <div class="input-section" [class.input-section--visible]="displayedSearchType === 'Sport'">
                <mat-chip-listbox class="sport-listbox" aria-label="Select type of activity you would like to play"
                    formControlName="gameType" (change)="onGameTypeChanged($event.value)" *ngIf="gameTypes" multiple>
                    <mat-chip-option *ngFor="let gameType of gameTypes" color="accent">{{ gameType.label }}
                    </mat-chip-option>
                </mat-chip-listbox>

                <mat-form-field class="sport-select" appearance="outline" *ngIf="gameTypes">
                    <mat-label>Sport</mat-label>
                    <mat-select formControlName="gameType" class="gametypes-input" [multiple]="true">
                        <mat-option *ngFor="let gameType of gameTypes" [value]="gameType.label">{{ gameType.label }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="input-section" [class.input-section--visible]="displayedSearchType === 'Gender'">
                <mat-form-field appearance="outline" *ngIf="genders">
                    <mat-label>Gender</mat-label>
                    <mat-select formControlName="gender" class="gender-input">
                        <mat-option *ngFor="let gender of genders" [value]="gender.label">{{ gender.label }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="input-section" [class.input-section--visible]="displayedSearchType === 'Ratings'">
                <mat-form-field appearance="outline" *ngIf="ratings as ratings" placeholder="Rating">
                    <mat-label>Rating</mat-label>
                    <mat-select formControlName="ratings" class="ratings-input" [multiple]="true">
                        <mat-option *ngFor="let rating of ratings" [value]="rating.id">{{
                            rating.label }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>

    <div class="search-button-container fx-row fx-align-end" [class.--visible]="searchForm.valid && displayOptions">
        <button mat-flat-button color="primary" (click)="onSearchButtonClick()">{{ searchButtonText }}</button>
    </div>
</form>