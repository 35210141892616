import { Injectable } from '@angular/core';
import { GthConversationFunctionService } from './conversation-function.service';
import { GthCacheTeamService } from '../cache/team.service';
import { GthTeamPaymentsFunctionService } from './team-payments-function.service';
import { GthCacheUserService } from '../cache/user.service';
import { GthNotificationFunctionService } from './notification-function.service';
import { GthEventJoinerFunctionService } from './event-joiner-function.service';
import { GthMailerFunctionService } from './mailer-function.service';
import { GthBadgeFunctionService } from './badge-function.service';
import { GthUserDeletionService } from './user-deletion.service';
import { GthEventFunctionService } from './event-function.service';
import { GthPendingInvitesFunctionService } from './pending-invites-function.service';
import { GthTeamRosterFunctionService } from './team-roster-function.service';
import { GthDebuggingFunctionService } from './debugging-function.service';
import { GthTopicFunctionService } from './topic-function.service';
import { GthTopicMessagesFunctionService } from './topic-messages-function.service';
import { GthAvailabilityFunctionService } from './availability.service';
import { GthExternalTeamFunctionService } from './external-team-function.service';
import { GthGuestFunctionService } from './guest-function.service';

@Injectable({
  providedIn: 'root',
})
export class GthCloudFunctionService {
  constructor(
    public readonly user: GthCacheUserService,
    public readonly userDeletion: GthUserDeletionService,
    public readonly event: GthEventFunctionService,
    public readonly eventJoiner: GthEventJoinerFunctionService,
    public readonly team: GthCacheTeamService,
    public readonly externalTeam: GthExternalTeamFunctionService,
    public readonly teamRoster: GthTeamRosterFunctionService,
    public readonly payments: GthTeamPaymentsFunctionService,
    public readonly messages: GthConversationFunctionService,
    public readonly notification: GthNotificationFunctionService,
    public readonly mailer: GthMailerFunctionService,
    public readonly badge: GthBadgeFunctionService,
    public readonly invites: GthPendingInvitesFunctionService,
    public readonly debugging: GthDebuggingFunctionService,
    public readonly topics: GthTopicFunctionService,
    public readonly topicMessages: GthTopicMessagesFunctionService,
    public readonly availability: GthAvailabilityFunctionService,
    public readonly guests: GthGuestFunctionService,
  ) { }
}
