<div class="panel fx-row">
  <div class="text-content fx-auto" *ngIf="configuration">
    <h3>{{ configuration.title }}</h3>
    <p>
      {{ configuration.description }}
      <button class="btn" mat-button (click)="onActionButtonClick()" *ngIf="configuration.action as action">
        {{ action.text }}
      </button>
    </p>
  </div>
  <div class="dismiss-container">
    <button mat-icon-button (click)="onDismissButtonClick()" *ngIf="displayDismissButton">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
</div>