export interface CallableOptions {
  route: CallableRoutes;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
}

export enum CallableRoutes {
  // Games
  GAMES_READ = 'games-calls-read',
  GAMES_CREATE = 'games-calls-create',
  GAMES_LIST = 'games-calls-list',
  GAMES_UPDATE = 'games-calls-update',
  GAMES_DELETE = 'games-calls-delete',
  GAMES_UPDATE_PAGE_VIEW = 'games-calls-update-page-views',
  GAMES_GET_PAGE_VIEWS = 'games-calls-get-page-views',

  // Users
  USERS_CREATE = 'users-calls-create',
  USERS_READ = 'users-calls-read',
  USERS_LIST = 'users-calls-list',
  USERS_UPDATE = 'users-calls-update',

  // Guests
  GUESTS_CREATE = 'guests-calls-create',
  GUESTS_READ = 'guests-calls-read',

  // Teams
  TEAMS_CREATE = 'teams-db-create',
  TEAMS_DELETE = 'teams-db-delete',
  TEAMS_LIST = 'teams-db-list',
  TEAMS_READ = 'teams-db-read',
  TEAMS_UPDATE = 'teams-db-update',

  // Event Joiner
  EVENT_JOINER_CREATE = 'eventJoiner-calls-create',
  EVENT_JOINER_DELETE = 'eventJoiner-calls-delete',
  EVENT_JOINER_LIST = 'eventJoiner-calls-list',
  EVENT_JOINER_UPDATE = 'eventJoiner-calls-update',

  // Conversations
  CONVERSATIONS_CREATE = 'conversations-db-create',
  CONVERSATIONS_UPDATE = 'conversations-db-update',
  CONVERSATIONS_GET = 'conversations-db-get',

  // Notifications
  NOTIFICATIONS_CREATE = 'notifications-db-create',
  NOTIFICATIONS_LIST = 'notifications-db-list',
  NOTIFICATIONS_UPDATE = 'notifications-db-update',
  NOTIFICATIONS_DELETE = 'notifications-db-delete',

  // Mailers
  MAILER_CREATE = 'mailer-create',

  // Badges
  BADGES_READ = 'badges-db-read',
  BADGES_LIST = 'badges-db-list',
}
