import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GthLoadingService {
  private callStack: boolean[] = [];
  public loading$ = new BehaviorSubject<boolean>(false);

  /**
   * Sets the Display State of the listeners loading spinner
   * @param {boolean} value
   */
  set show(value: boolean) {
    if (value) {
      this.callStack.push(true);
    } else {
      this.callStack.pop();
    }
    this.loading$.next(!!this.callStack.length);
  }
}
