<mat-card appearance="outlined" class="dialog-container">
    <div class="header-container"> 
        <h1>Account Policies</h1>
        <div class="spacer"></div>
        <mat-icon (click)="close()">close</mat-icon>
    </div>
    <mat-tab-group [selectedIndex]="tabIndex" color="primary">
        <mat-tab> 
            <ng-template mat-tab-label>
                Privacy Policy
                <mat-icon class="tab-icon">lock</mat-icon>
            </ng-template>
            <mat-card appearance="outlined" class="scrollable-content">
                <mat-card-content>
                    <gth-privacy-policy></gth-privacy-policy>
                </mat-card-content>
            </mat-card>
        </mat-tab>
        <mat-tab> 
            <ng-template mat-tab-label>
                Terms and Conditions
                <mat-icon class="tab-icon">list_alt</mat-icon>
            </ng-template>
            <mat-card appearance="outlined" class="scrollable-content">
                <mat-card-content>
                    <gth-terms-conditions></gth-terms-conditions>
                </mat-card-content>
            </mat-card>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                Cookie Policy
                <mat-icon class="tab-icon">beenhere</mat-icon>
            </ng-template>
            <mat-card appearance="outlined" class="scrollable-content">
                <mat-card-content>
                    <gth-cookie-policy></gth-cookie-policy>
                </mat-card-content>
            </mat-card>  
        </mat-tab>
    </mat-tab-group>
</mat-card>

