<button 
  mat-flat-button 
  color="primary" 
  [disabled] = "disabled"
  [matMenuTriggerFor]="menu" 
  #t="matMenuTrigger">
  <ng-content></ng-content>
  <mat-icon iconPositionEnd>{{ isMenuOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</mat-icon>
</button>
<mat-menu #menu="matMenu" >
  <button 
  *ngFor="let option of options"
    (click)="onClick(option)"
     mat-menu-item>{{option.viewValue}}</button>
  
</mat-menu>