<div class="fx-fill fx-column">
  <h1 mat-dialog-title>{{ title }}</h1>
  <div mat-dialog-content class="fx-auto">
    <p>
      {{ content }}
    </p>
  </div>
  <div mat-dialog-actions class="fx-gap-10x fx-row">
    <button mat-stroked-button class="fx-auto dialog-button" (click)="onCancelButtonClick()"
      color="primary">Cancel</button>
    <button mat-flat-button class="fx-auto dialog-button" (click)="onConfirmButtonClick()" color="primary">
      {{ confirmButtonText }}
    </button>
  </div>
</div>
