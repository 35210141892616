import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';

import { GthTeamGamesTableComponent } from './team-games-table.component';

@NgModule({
  imports: [CommonModule, RouterModule, ReactiveFormsModule, MatTableModule],
  exports: [GthTeamGamesTableComponent],
  declarations: [GthTeamGamesTableComponent],
})
export class GthTeamGamesTableModule {}
