/* eslint-disable max-len */
export const CHART_COLORS = [
  '#3498db', '#2ecc71', '#e74c3c', '#f39c12', '#9b59b6', '#1abc9c', '#34495e', '#95a5a6', '#2c3e50', '#27ae60',
  '#d35400', '#c0392b', '#2980b9', '#16a085', '#8e44ad', '#3498db', '#f39c12', '#bdc3c7', '#7f8c8d', '#1abc9c',
  '#e74c3c', '#2ecc71', '#f39c12', '#3498db', '#9b59b6', '#1abc9c', '#34495e', '#95a5a6', '#2c3e50', '#27ae60',
  '#d35400', '#c0392b', '#2980b9', '#16a085', '#8e44ad', '#3498db', '#f39c12', '#bdc3c7', '#7f8c8d', '#1abc9c',
  '#e74c3c', '#2ecc71', '#f39c12', '#3498db', '#9b59b6', '#1abc9c', '#34495e', '#95a5a6', '#2c3e50', '#27ae60',
  // Add more colors as needed
];

export const getRandomColor = () => {
  const randomIndex = Math.floor(Math.random() * CHART_COLORS.length);
  return CHART_COLORS[randomIndex];
};
