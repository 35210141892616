import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgForOf, NgIf } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { EventTicketLevelFormGroup } from '@index/interfaces';
import { MatTooltipModule } from '@angular/material/tooltip';
import { GthUserModel } from '@sentinels/models';

@Component({
  selector: 'gth-event-ticket-levels-form-control',
  templateUrl: './event-ticket-levels-form-control.component.html',
  styleUrls: ['./event-ticket-levels-form-control.component.scss'],
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule,
    NgIf,
    NgForOf,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatTooltipModule,
  ],
})
export class EventTicketLevelsFormControlComponent implements OnChanges {
  @Input()
  ticketLevelFormControl?: FormArray<FormGroup<EventTicketLevelFormGroup>>;

  @Input()
  user?: GthUserModel;

  disabled = true;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.user) {
      /** Enable/Disable if stripe account is setup */
      if (this.user?.hasPaymentsSetup()) {
        this.disabled = false;
        this.ticketLevelFormControl?.enable();
      } else {
        this.disabled = true;
        this.ticketLevelFormControl?.disable();
      }
    }
  }

  onAddTicketLevel() {
    if (!this.ticketLevelFormControl || this.disabled) return;

    this.ticketLevelFormControl.push(new FormGroup<EventTicketLevelFormGroup>({
      name: new FormControl<string>(null, Validators.required),
      cost: new FormControl<number>(
        null,
        [Validators.required, Validators.min(0), Validators.max(999999)],
      ),
      priceId: new FormControl<string>(null),
    }));
  }
}
