import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';

import { CreateInvoiceContract } from '@sentinels/interfaces/stripe';
import { GthTeamModel, GthUserModel } from '@sentinels/models';

export interface SendInvoiceDialogOpenContract {
  team?: GthTeamModel,
  user: GthUserModel,
}

@Component({
  selector: 'gth-send-invoice-dialog',
  templateUrl: './send-invoice-dialog.component.html',
  styleUrls: ['./send-invoice-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
  ],
})
export class SendInvoiceDialogComponent implements OnInit {
  isTeamInvoice = false;
  sendInvoiceForm = new FormGroup({
    userId: new FormControl<string>(null, {
      nonNullable: true,
      validators: Validators.required,
    }),
    email: new FormControl<string>(null, {
      nonNullable: true,
      validators: [Validators.required, Validators.email],
    }),
    description: new FormControl<string>(null, {
      nonNullable: true,
      validators: Validators.required,
    }),
    amount: new FormControl<number>(null, {
      nonNullable: true,
      validators: Validators.required,
    }),
  });
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SendInvoiceDialogOpenContract,
    private dialogRef: MatDialogRef<SendInvoiceDialogComponent>,
  ) {}

  ngOnInit() {
    if (this.data.team) {
      this.isTeamInvoice = true;
      this.sendInvoiceForm.controls.email.clearValidators();
      this.sendInvoiceForm.updateValueAndValidity();
    } else {
      this.sendInvoiceForm.controls.userId.clearValidators();
      this.sendInvoiceForm.updateValueAndValidity();
    }
  }

  onSendInvoiceBtnClick() {
    if (this.sendInvoiceForm.invalid) return;

    let contract: CreateInvoiceContract;

    if (this.isTeamInvoice) {
      contract = {
        userId: this.sendInvoiceForm.controls.userId.value,
        teamId: this.data.team.id,
        description: this.sendInvoiceForm.controls.description.value,
        amount: this.sendInvoiceForm.controls.amount.value,
      };
    } else {
      contract = {
        email: this.sendInvoiceForm.controls.email.value,
        description: this.sendInvoiceForm.controls.description.value,
        amount: this.sendInvoiceForm.controls.amount.value,
      };
    }
    this.dialogRef.close(contract);
  }
}
