<meh-nav-bar />

<div class="content-container">
  <div class="title-row">
    <h1 class="title">Analytics</h1>
    <meh-admin-nav></meh-admin-nav>
  </div>

  <ng-container *ngIf="loading else showAnalytics">
    <div class="fx-auto fx-fill fx-centered">
      <mat-spinner [diameter]="36" />
    </div>
  </ng-container>
  <ng-template #showAnalytics>
    <div class="select-event-container">
      <h3>Select By Event</h3>

      <div class="row">
        <mat-form-field appearance="outline">
          <mat-label>Events you've hosted</mat-label>
          <mat-select [(ngModel)]="selectedEvents" [multiple]="true"
                      (selectionChange)="onEventTimeFilterChange(selectedTimeFilter)">
            <mat-select-trigger>
              {{selectedEvents?.[0]?.title || ''}}
              <ng-container *ngIf="(selectedEvents.length || 0) > 1">
          <span class="example-additional-selection">
          (+{{(selectedEvents?.length || 0) - 1}} {{selectedEvents?.length === 2 ? 'event' : 'events'}})
        </span>
              </ng-container>
            </mat-select-trigger>
            <mat-option *ngFor="let event of events" [value]="event">{{ event.title }}</mat-option>
          </mat-select>
        </mat-form-field>

        <button mat-button color="primary"
                [disabled]="events?.length === selectedEvents?.length"
                (click)="onSelectAllBtnClick()">Select all</button>
        <button mat-button color="primary"
                [disabled]="!selectedEvents?.length"
                (click)="onDeselectAllBtnClick()">Deselect all</button>
      </div>
    </div>

    <div class="select-time-container">
      <h3>Select By Time</h3>
      <em>Use custom for future events</em>

      <mat-chip-listbox aria-label="Filter events by time selection"
                        [(ngModel)]="selectedTimeFilter"
                        (change)="onEventTimeFilterChange($event.value)">
        <mat-chip-option [selectable]="false" (click)="onEventTimeFilterChange(null)">Clear</mat-chip-option>
        <mat-chip-option *ngFor="let filter of timeFilters | keyvalue"
                         [selectable]="filter.value !== EventTimeFilter.Custom"
                         [value]="filter.value" (click)="filter.value === EventTimeFilter.Custom ? onCustomFilterClick() : undefined">{{filter.value === EventTimeFilter.Custom ? 'Custom...' : filter.value}}</mat-chip-option>
      </mat-chip-listbox>
    </div>

    <div class="totals-container">
      <mat-card appearance="outlined" class="total-card">
        <h2>Total Attended</h2>
        <p>{{ totalAttended }}</p>
      </mat-card>
      <mat-card appearance="outlined" class="total-card">
        <h2>Total No Showed</h2>
        <p>{{ totalNoShowed }}</p>
      </mat-card>
      <mat-card appearance="outlined" class="total-card">
        <h2>Total Events in Selected Time</h2>
        <p>{{ totalEventsInSelectedTime }}</p>
      </mat-card>
    </div>

    <mat-card appearance="outlined" class="line-graph-card">
      <h2>RSVPs Over Time</h2>
      <canvas baseChart [type]="'line'" [data]="lineChartData"></canvas>
    </mat-card>
  </ng-template>
</div>