import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';

/**
 * Represents the options for a button menu.
 * @interface ButtonMenuOptions
 */
export interface ButtonMenuOptions {
  /**
   * The display value of the option.
   * @type {string}
   */
  viewValue: string;

  /**
   * The value associated with the option.
   * @type {any}
   */
  value: any;
}

@Component({
  selector: 'gth-button-menu',
  templateUrl: './button-menu.component.html',
  styleUrls: ['./button-menu.component.scss'],
})
export class ButtonMenuComponent {
  @ViewChild('t')
  menuTrigger!: MatMenuTrigger;

  @Input()
  options: ButtonMenuOptions[] = [];
  @Output()
  itemClick = new EventEmitter();
  @Input()
  disabled = false;

  menuOpen: boolean = false;

  get isMenuOpen() {
    return !!this.menuTrigger?.menuOpen;
  }

  onClick(item: ButtonMenuOptions) {
    this.itemClick.emit(item.value);
  }
}
