import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { first, map, switchMap } from 'rxjs/operators';

import { Topic, TopicCreationContract, TopicUpdateContract } from '@index/interfaces/topic';
import { GthTopic } from '../../../../../sentinels/src/lib/models/topic';
import { DBUtil } from '../../../../../index/src/lib/utils/db-utils';
import { GthUserModel } from '@sentinels/models';
import { DBTopicRole, GthTopicUserFunctionService, TopicRole } from './topic-user-function.service';
import { GthTopicPrivateFunctionService } from './topic-private-function.service';
import { Observable, combineLatest, from, of } from 'rxjs';

interface DBTeamTopic {
  id: string;
  name: string;
  description: string;
  creator: string;
  private: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class GthTopicFunctionService {
  constructor(
    private firestore: AngularFirestore,
    private topicSettings: GthTopicPrivateFunctionService,
    private topicUsers: GthTopicUserFunctionService,
  ) { }

  /**
 * Gets list of topics within team
 * @param {string} teamId Id of the team being requested
 * @param {GthUserModel[]} teamUsers list of users within team
 * @param {string} userId Id of the user
 * @return {Promise<Array<GthTopic>>} Array of topics
 */
  getTeamTopics$(
    teamId: string,
    teamUsers: GthUserModel[],
    userId: string): Observable<Array<GthTopic>> {
    return this.firestore
      .collection(`${DBUtil.TeamTopics}/${teamId}/topics`)
      .snapshotChanges()
      .pipe(
        map((collection) => {
          if (!collection || collection.length === 0) {
            return [];
          }
          return collection.map((item) => {
            const doc = item.payload.doc;
            const { id } = doc;
            const data = doc.data();
            return {
              id,
              ...data as object,
            } as DBTeamTopic;
          });
        }),
        switchMap((dbTopics: DBTeamTopic[]) => {
          const requests$ = dbTopics.map((t) => {
            return this.getDBTopicUsers$(t, teamUsers, teamId).pipe(
              map((users) => {
                const topic: Topic = {
                  ...t,
                  teamId,
                  users: [],
                  admins: [],
                  messages: [],
                  isFavorite: false,
                  isMuted: false,
                };
                const gTopic = new GthTopic(topic.id, topic);
                if (users) {
                  gTopic.setUsers(users.creator, users.users, users.admins);
                }
                return gTopic;
              }),
            );
          });
          if (requests$.length === 0) {
            return of([]);
          }
          return combineLatest([...requests$]);
        }),
        switchMap((topics: GthTopic[]) => {
          const favorites$ = from(this.topicSettings.getFavoriteTopics(teamId, userId));
          const muted$ = from(this.topicSettings.getMutedTopics(teamId, userId));
          return combineLatest([favorites$, muted$]).pipe(
            map(([favorites, muted]) => {
              favorites.forEach((f) => {
                const topic = topics.find((t) => t.id === f);
                if (topic) {
                  topic.favorite = true;
                }
              });
              muted.forEach((f) => {
                const topic = topics.find((t) => t.id === f);
                if (topic) {
                  topic.muted = true;
                }
              });
              return topics;
            }),
          );
        }),
      );
  }

  /**
   * Gets list of topics within team
   * @param {string} teamId Id of the team being requested
   * @param {GthUserModel[]} teamUsers list of users within team
   * @param {string} userId Id of the user
   * @return {Promise<Array<GthTopic>>} Array of topics
   */
  async getTeamTopics(
    teamId: string,
    teamUsers: GthUserModel[],
    userId: string): Promise<Array<GthTopic>> {
    const topicsRef = await this.firestore
      .collection(`${DBUtil.TeamTopics}/${teamId}/topics`)
      .get()
      .pipe(
        first(),
      )
      .toPromise();
    if (!topicsRef || topicsRef.docs.length === 0) {
      return [];
    }

    const topicsBrief = topicsRef.docs.map((doc) => {
      const { id } = doc;
      const data = doc.data();
      return {
        id,
        ...data as object,
      } as DBTeamTopic;
    });

    const usersRequests = topicsBrief.map(async (t) => {
      const users = await this.getDBTopicUsers(t, teamUsers, teamId);
      const topic: Topic = {
        ...t,
        teamId,
        users: [],
        admins: [],
        messages: [],
        isFavorite: false,
        isMuted: false,
      };
      const gTopic = new GthTopic(topic.id, topic);
      if (users) {
        gTopic.setUsers(users.creator, users.users, users.admins);
      }
      return gTopic;
    });

    const allSettled = await Promise.allSettled(usersRequests);
    const topics = [];
    allSettled.forEach((t) => {
      if (t.status === 'fulfilled') {
        topics.push(t.value);
      }
    });

    const favorites = await this.topicSettings.getFavoriteTopics(teamId, userId);
    const muted = await this.topicSettings.getMutedTopics(teamId, userId);
    favorites.forEach((f) => {
      const topic = topics.find((t) => t.id === f);
      if (topic) {
        topic.favorite = true;
      }
    });
    muted.forEach((f) => {
      const topic = topics.find((t) => t.id === f);
      if (topic) {
        topic.muted = true;
      }
    });

    return topics;
  }

  /**
   * Creates a team topic
   * @param {TopicCreationContract} topic Topic default params
   * @param {string} teamId Id of the team
   * @return {Promise<boolean>} true if success
   */
  async createTeamTopic(topic: TopicCreationContract, teamId: string): Promise<string> {
    const topicsRef = await this.firestore.collection(`${DBUtil.TeamTopics}/${teamId}/topics`);
    try {
      const newTopicRef = await topicsRef.add({
        ...topic,
      });
      const newTopic = await newTopicRef
        .get();

      const { id } = newTopic;

      // Create topic roles
      await this.addUser(teamId, id, topic.creator, 'creator');
      return id;
    } catch {
      return undefined;
    }
  }

  /**
   * Deletes a team topic
   * @param {string} teamId Id of the team
   * @param {string} topicId Id of the topic
   * @return {Promise<boolean>} true if success
   */
  async deleteTeamTopic(teamId: string, topicId: string) {
    const topicsRef = await this.firestore
      .doc(`${DBUtil.TeamTopics}/${teamId}/topics/${topicId}`)
      .get()
      .pipe(
        first(),
      )
      .toPromise();
    if (!topicsRef.exists) {
      return false;
    }
    try {
      await topicsRef.ref.delete();
      return true;
    } catch {
      return false;
    }
  }

  /**
   * Updates the name and description of a team topic
   * @param {string} teamId Id of the team
   * @param {string} topicId Id of the topic
   * @param {TopicUpdateContract} contract Properties to be updated within team topic
   * @return {Promise<boolean>} true if success
   */
  async updateTeamTopic(teamId: string, topicId: string, contract: TopicUpdateContract) {
    const topicsRef = await this.firestore
      .doc(`${DBUtil.TeamTopics}/${teamId}/topics/${topicId}`)
      .get()
      .pipe(
        first(),
      )
      .toPromise();

    if (!topicsRef.exists) {
      return false;
    }

    const params: any = {};
    const { name, description } = contract;
    if (name) {
      params.name = name;
    }
    if (description) {
      params.description = description;
    }
    try {
      await topicsRef.ref.update(params);
      return true;
    } catch {
      return false;
    }
  }


  /**
   * Mutes, or unmutes, topic
   * @param {string} teamId Id of the team
   * @param {string} topicId Id of the topic
   * @param {string} userId Id of the user
   * @param {boolean} value True/False
   * @return {Promise<boolean>} true if success
   */
  async muteTopic(teamId: string, topicId: string, userId: string, value: boolean) {
    return this.topicSettings.muteTopic(teamId, topicId, userId, value);
  }

  /**
   * Favorites, or unfavorites topic
   * @param {string} teamId Id of the team
   * @param {string} topicId Id of the topic
   * @param {string} userId Id of the user
   * @param {boolean} value True/False
   * @return {Promise<boolean>} true if success
   */
  async favoriteTopic(teamId: string, topicId: string, userId: string, value: boolean) {
    return this.topicSettings.favoriteTopic(teamId, topicId, userId, value);
  }

  /**
   * Adds a user to the team
   * @param {string} teamId Id of the team
   * @param {string} topicId Id of the topic
   * @param {string} userId Id of the user being added
   * @param {TopicRole} role Role of the new user
   * @return {Promise<boolean>} true if success
   */
  async addUser(teamId: string, topicId: string, userId: string, role: TopicRole) {
    return this.topicUsers.addUser(teamId, topicId, userId, role);
  }

  /**
   * Adds a user to the team
   * @param {string} teamId Id of the team
   * @param {string} topicId Id of the topic
   * @param {string[]} userIds Id of the user being added
   * @param {TopicRole} role Role of the new user
   * @return {Promise<boolean[]>} true if success
   */
  async addUsers(teamId: string, topicId: string, userIds: string[], role: TopicRole) {
    return this.topicUsers.addUsers(teamId, topicId, userIds, role);
  }

  /**
   * Removes a user by id
   * @param {string} teamId Id of the team
   * @param {string} topicId Id of the topic
   * @param {string} userId Id of the user being removed
   * @return {Promise<boolean>} true if success
   */
  async removeUser(teamId: string, topicId: string, userId: string) {
    return this.topicUsers.removeUser(teamId, topicId, userId);
  }

  async getTopicUsers(topic: Topic) {
    return this.topicUsers.getTopicUsers(topic);
  }

  private getDBTopicUsers(
    topic: DBTeamTopic,
    teamUsers: GthUserModel[],
    teamId: string) {
    return this.getDBTopicUsers$(topic, teamUsers, teamId)
      .pipe(
        first(),
      )
      .toPromise();
  }

  private getDBTopicUsers$(
    topic: DBTeamTopic,
    teamUsers: GthUserModel[],
    teamId: string) {
    return this.firestore
      .collection(`${DBUtil.TeamTopics}/${teamId}/topics/${topic.id}/roles`)
      .snapshotChanges()
      .pipe(
        map((collection) => {
          if (!collection || collection.length === 0) {
            return [];
          }
          return collection.map((item) => {
            const doc = item.payload.doc;
            const data = doc.data();
            return {
              ...data as object,
            } as DBTopicRole;
          });
        }),
        map((roles) => {
          const users = [];
          const admins = [];
          let creator: GthUserModel;
          roles.forEach((role) => {
            const user = teamUsers.find((u) => u.id === role.user);
            if (user) {
              switch (role.role) {
                case 'admin':
                  admins.push(user);
                  break;
                case 'member':
                  users.push(user);
                  break;
                case 'creator':
                  if (creator) {
                    throw new Error('Creator is already defined');
                  }
                  creator = user;
                  break;
              }
            }
          });
          return {
            users,
            admins,
            creator,
          };
        }),
      );
  }
}
