export interface Results<T> {
  payload: Payload<T> | Payload<T>[] | null;
  success: boolean;
  error?: Error | null;
}

export interface Payload<T> {
  id: string;
  data: T;
}

export function SuccessResult<T>(payload: Payload<T> | Payload<T>[] | null): Results<T> {
  return {
    payload,
    success: true,
    error: null,
  } as Results<T>;
}

export function ErrorResult<T>(error?: any): Results<T> {
  return {
    payload: null,
    success: false,
    error: error,
  } as Results<T>;
}
