import { RosterV2, User } from '@index/interfaces';
import { GthModel } from './model';
import { GthUserModel } from './user';

export class GthTeamPlayerModel extends GthModel<RosterV2<User>> {
  private userModel?: GthUserModel;
  private _rosterId?: string;

  constructor(id: string, rosterId: string, model: RosterV2<User>) {
    super(id, model);
    this._rosterId = rosterId;
    this.userModel = new GthUserModel(model.player.uid, model.player);
  }

  get id() {
    return this._id;
  }

  get role() {
    return this.model.role;
  }
  set role(val: string) {
    this.model.role = val;
  }

  get rosterId() {
    return this._rosterId;
  }

  get player() {
    return this.userModel;
  }

  get displayName() {
    if (!this.userModel) {
      return '';
    }
    if (this.userModel.displayName?.length > 0) {
      return this.userModel.displayName;
    }
    if (this.userModel.fullName?.length > 0) {
      return this.userModel.fullName;
    }
    if (this.userModel.email?.length > 0) {
      return this.userModel.email;
    }
    return 'N/A';
  }

  get email() {
    if (!this.userModel) {
      return '';
    }
    return this.userModel.email;
  }
}
