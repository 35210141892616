import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { GthOnboardingStepComponent } from './onboarding-step.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  imports: [CommonModule, RouterModule, MatButtonModule, MatIconModule],
  exports: [GthOnboardingStepComponent],
  declarations: [GthOnboardingStepComponent],
})
export class GthOnboardingStepModule { }
