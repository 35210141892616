import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import { CloudCollectionService } from './cloud-collection.service';
import { GthTeamRatingModel } from '../../models/team-rating';
import { TeamRating } from '@index/interfaces';
import { DBUtil } from '@index/utils/db-utils';
import { SrvCacheService } from '../cache.service';

const COLLECTION_NAME = DBUtil.TeamRatings;

@Injectable({
  providedIn: 'root',
})
export class SrvTeamRatingsService {
  private cloudCollection = new CloudCollectionService(this.firestore, COLLECTION_NAME);

  constructor(
    private firestore: AngularFirestore,
    private cache: SrvCacheService,
  ) {
  }

  /**
   * Gets a list of all team ratings
   * @return {Promise<GthTeamRatingModel[]>} Returns a list of team ratings
   */
  async listAsync(): Promise<GthTeamRatingModel[]> {
    const store = this.cache.read<GthTeamRatingModel[]>(COLLECTION_NAME);
    if (store) return store;

    const items = await this.cloudCollection.list<TeamRating>();
    if (!items) return undefined;
    const models = items.map((i) => new GthTeamRatingModel(i.id.toString(), i));
    this.cache.store(COLLECTION_NAME, models);
    return models;
  }
}
