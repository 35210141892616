import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { loadStripe } from '@stripe/stripe-js';
import { APP_ROUTES } from '@shared/helpers';
import { environment } from '@environments/environment';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CurrencyPipe, NgIf } from '@angular/common';

@Component({
  selector: 'gth-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.scss'],
  standalone: true,
  imports: [
    NgIf, CurrencyPipe, RouterLink,
    MatButtonModule, MatProgressSpinnerModule,
  ],
})
export class PaymentSuccessComponent implements OnInit {
  cost?: number;
  description?: string;
  status?: string;
  paymentIntentClientSecret?: string;
  loading = true;

  constructor(private route: ActivatedRoute) { }
  async ngOnInit() {
    this.paymentIntentClientSecret = this.route.snapshot
      .queryParams['payment_intent_client_secret'];
    if (!this.paymentIntentClientSecret) {
      /** question: should redirect here? */
      return;
    }

    const stripe = await loadStripe(environment.stripe);
    const paymentIntentResult = await stripe
      .retrievePaymentIntent(this.paymentIntentClientSecret);
    if (paymentIntentResult.paymentIntent) {
      const paymentIntent = paymentIntentResult.paymentIntent;
      switch (paymentIntent.status) {
        case 'succeeded':
          this.status = 'Success! Payment received.';
          break;

        case 'processing':
          this.status = 'Payment processing. We\'ll update you when payment is received.';
          break;

        case 'requires_payment_method':
          this.status = 'Payment failed. Please try another payment method.';
          // Redirect your user back to your payment page to attempt collecting
          // payment again
          break;

        default:
          this.status = 'Something went wrong.';
          break;
      }
      this.description = paymentIntent.description;
      this.cost = paymentIntent.amount;
    }
    this.loading = false;
  }

  get APP_ROUTES() {
    return APP_ROUTES;
  }
}
