import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import { catchError, take, tap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';

import { DBUtil } from '@index/utils/db-utils';
import { Endorsement, EndorsementType } from '@index/interfaces';
import { GthUserModel } from '@sentinels/models';
import { GthErrorLoggerService } from './cloud/error-logger.service';

const CONTEXT = 'EndorsementsService';

@Injectable({ providedIn: 'root' })
export class EndorsementsService {
  constructor(
    private logger: GthErrorLoggerService,
    private firestore: AngularFirestore,
  ) {}
  /**
   * Endorses an existing endorsement by adding the endorser's ID to the
   * endorsement's `endorsedBy` array.
   * @param {string} endorserId - The ID of the user who is endorsing the endorsement.
   * @param {Endorsement} endorsement - The endorsement object to be endorsed.
   * @return {Promise<boolean>} - A Promise resolving to `true` if the endorsement
   * was successfully endorsed, or `false` if an error occurred.
   */
  async endorse(endorserId: string, endorsement: Endorsement) {
    const batch = this.firestore.firestore.batch();

    /** update endorsement document */
    const endorsementRef = this.firestore
      .collection(DBUtil.User).doc(endorsement.endorsedId)
      .collection<Endorsement>(DBUtil.Endorsements).doc(endorsement.id).ref;
    this.log(`Endorse ${endorsement.name} from ${endorsement.endorsedId} by ${endorserId}`);
    /** Partial<Endorsement> */
    const updateData = {
      endorsedBy: firebase.firestore.FieldValue.arrayUnion(endorserId),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    };
    batch.update(endorsementRef, updateData);

    return await batch.commit()
      .then(() => true)
      .catch(() => false);
  }
  async unendorse(endorserId: string, endorsement: Endorsement) {
    const batch = this.firestore.firestore.batch();

    /** update endorsement document */
    const endorsementRef = this.firestore
      .collection(DBUtil.User).doc(endorsement.endorsedId)
      .collection<Endorsement>(DBUtil.Endorsements).doc(endorsement.id).ref;
    this.log(`Unendorse ${endorsement.name} from ${endorsement.endorsedId} by ${endorserId}`);
    /** Partial<Endorsement> */
    const updateData = {
      endorsedBy: firebase.firestore.FieldValue.arrayRemove(endorserId),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    };
    batch.update(endorsementRef, updateData);

    return await batch.commit()
      .then(() => true)
      .catch(() => false);
  }
  /**
   * Retrieves an observable stream of endorsement data for a specified user.
   *
   * @param {string} userId - The ID of the user whose endorsements are being observed.
   * @return {Observable<ReadEndorsement[]>} - An Observable stream that emits an array
   * of endorsement objects whenever the endorsements change, or completes if an error occurs.
   */
  getEndorsements$(userId: string) {
    return this.firestore.collection(DBUtil.User).doc(userId)
      .collection<Endorsement>(DBUtil.Endorsements).valueChanges({ idField: 'id' })
      .pipe(
        tap(() => this.log(`Endorsements fetched for ${userId}`)),
        catchError((error: unknown) => {
          console.error(`Something went wrong getting endorsements for ${userId}`, error);
          return EMPTY;
        }),
      );
  }
  /**
   * Retrieves a list of endorsement data for a specified user.
   * @param {string} userId - The ID of the user whose endorsements are being retrieved.
   * @return {Promise<ReadEndorsement[]> | null} - A Promise resolving to an array of
   * endorsement objects, or `null` if an error occurs during retrieval.
   */
  async getEndorsements(userId: string) {
    try {
      const querySnap = await this.firestore
        .collection(DBUtil.User).doc(userId)
        .collection<Endorsement>(DBUtil.Endorsements).get()
        .pipe(take(1)).toPromise();
      if (querySnap.empty) {
        this.log(`Endorsements not found for user: ${userId}`);
        return null;
      }
      this.log(`Endorsements fetched for ${userId}`);
      return querySnap.docs.map((doc) => doc.data());
    } catch (error: unknown) {
      console.error(`Something went wrong getting endorsements for ${userId}`, error);
      return null;
    }
  }
  /**
   * Synchronizes the user's passion endorsements with the Firestore database.
   * This function compares the user's current passion endorsements with the
   * endorsements stored in the database and updates or creates endorsements as needed.
   * @param {GthUserModel} user - The user whose passion endorsements are being synchronized.
   * @return {Promise<boolean>} - A Promise resolving to `true` if the synchronization
   * was successful, or `false` if an error occurred.
   */
  async syncPassionEndorsements(user: GthUserModel) {
    const userEndorsements: Endorsement[] = user.sportAvailability?.map((sa) => {
      return {
        type: EndorsementType.PASSION,
        id: sa.sport.toLowerCase(),
        name: sa.sport,
        skill: sa.skill,
        sport: sa.sport,
        endorsedId: user.uid,
        endorsedBy: [],
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      };
    });
    const dbEndorsements = await this.getEndorsements(user.uid);
    /** Compare user endorsements to DB endorsements */
    const endorsementsToCreate: Endorsement[] = [];
    const endorsementsToUpdate: Endorsement[] = [];
    const endorsementsToRemove: Endorsement[] = [];
    for (const userEndorsement of userEndorsements) {
      const existingEndorsement = dbEndorsements?.find(
        (dbEndorsement) => {
          return (dbEndorsement.id === userEndorsement.id &&
            dbEndorsement.name === userEndorsement.name &&
            dbEndorsement.sport === userEndorsement.sport);
        },
      );
      if (!existingEndorsement) {
        /** User endorsement does not exist in DB, so create it */
        endorsementsToCreate.push(userEndorsement);
      } else if (existingEndorsement.skill !== userEndorsement.skill) {
        /** Update existing endorsement with new skill */
        const updatedEndorsement = existingEndorsement;
        updatedEndorsement.skill = userEndorsement.skill;
        /** Clear endorsedBy array */
        updatedEndorsement.endorsedBy = [];
        updatedEndorsement.updatedAt = firebase.firestore.FieldValue.serverTimestamp();
        endorsementsToUpdate.push(updatedEndorsement);
      }
    }

    /** Identify endorsements that exist in DB but not in user endorsements */
    dbEndorsements?.forEach((dbEndorsement) => {
      const existingUserEndorsement = userEndorsements.find((ue) => {
        return ue.id === dbEndorsement.id;
      });

      if (!existingUserEndorsement) {
        /** Endorsement exists in DB but not in user endorsements, so remove it */
        endorsementsToRemove.push(dbEndorsement);
      }
    });

    if (endorsementsToCreate.length || endorsementsToUpdate.length || endorsementsToRemove) {
      const batch = this.firestore.firestore.batch();

      /** Create new endorsements */
      endorsementsToCreate.forEach((e) => {
        const docRef = this.firestore
          .collection(DBUtil.User).doc(user.uid)
          .collection(DBUtil.Endorsements).doc(e.id).ref;
        batch.set(docRef, e);
      });

      /** Update existing endorsements */
      endorsementsToUpdate.forEach((e) => {
        const docRef = this.firestore
          .collection(DBUtil.User).doc(user.uid)
          .collection(DBUtil.Endorsements).doc(e.id).ref;
        batch.update(docRef, e);
      });

      /** Remove endorsements that are no longer relevant */
      endorsementsToRemove.forEach((e) => {
        const docRef = this.firestore
          .collection(DBUtil.User).doc(user.uid)
          .collection(DBUtil.Endorsements).doc(e.id).ref;
        batch.delete(docRef);
      });

      this.log(`Syncing endorsements for ${user.uid}`);

      return await batch.commit()
        .then(() => true)
        .catch(() => false);
    } else return true;
  }
  async updateEndorserEndorsements(
    userId: string,
    endorsementsToUnendorse: Endorsement[],
    endorsementsToEndorse: Endorsement[],
  ) {
    if (!endorsementsToUnendorse.length && !endorsementsToEndorse.length) return true;
    const batch = this.firestore.firestore.batch();

    /** Add endorsements */
    if (endorsementsToEndorse.length) {
      endorsementsToEndorse.forEach((e) => {
        const docRef = this.firestore
          .collection(DBUtil.User).doc(e.endorsedId)
          .collection(DBUtil.Endorsements).doc(e.id).ref;
        const endorsementData = {
          endorsedBy: firebase.firestore.FieldValue.arrayUnion(userId),
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        };
        batch.update(docRef, endorsementData);
      });
    }

    /** Remove endorsements */
    if (endorsementsToUnendorse.length) {
      endorsementsToUnendorse.forEach((e) => {
        const docRef = this.firestore
          .collection(DBUtil.User).doc(e.endorsedId)
          .collection(DBUtil.Endorsements).doc(e.id).ref;
        const endorsementData = {
          endorsedBy: firebase.firestore.FieldValue.arrayRemove(userId),
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        };
        batch.update(docRef, endorsementData);
      });
    }

    this.log(`Updating endorser endorsements for ${userId}`);

    return await batch.commit()
      .then(() => true)
      .catch(() => false);
  }
  async removePersonalityEndorsement(userId: string, personality: string) {
    const docRef = this.firestore
      .collection(DBUtil.User).doc(userId)
      .collection(DBUtil.Endorsements).doc(personality).ref;
    this.log(`Remove personality endorsement, ${personality}, for ${userId}`);
    return await docRef.delete()
      .then(() => true)
      .catch((error: unknown) => {
        console.error('Something went wrong removing personality endorsement', error);
        return false;
      });
  }
  async addPersonalityEndorsement(userId: string, personality: string) {
    const docRef = this.firestore
      .collection(DBUtil.User).doc(userId)
      .collection(DBUtil.Endorsements).doc(personality).ref;
    this.log(`Add personality endorsement, ${personality}, for ${userId}`);
    const endorsementData: Endorsement = {
      type: EndorsementType.PERSONALITY,
      id: personality,
      name: personality,
      endorsedId: userId,
      endorsedBy: [],
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    };
    return await docRef.set(endorsementData)
      .then(() => true)
      .catch((error: unknown) => {
        console.error('Something went wrong adding personality endorsement', error);
        return false;
      });
  }
  private log(text: string) {
    this.logger.debug(`${CONTEXT}: ${text}`);
  }
}
