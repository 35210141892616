<div class="fx-row fx-gap-10x" *ngIf="!value || value.length === 0">
  <mat-form-field appearance="outline" class="fx-auto">
    <input matInput [(ngModel)]="newItem">
    <mat-icon matSuffix>clear</mat-icon>
  </mat-form-field>
  <div class="button-container item" *ngIf="!disabled">
    <button class="fab-button" mat-mini-fab color="accent" aria-label="Add Item" (click)="onAddItemButtonClick(-1)">
      <mat-icon>add</mat-icon>
    </button>
  </div>
</div>

<div *ngFor="let item of value; let i = index;trackBy:trackByIndex;" class="fx-row fx-gap-10x">
  <mat-form-field appearance="outline" class="fx-auto">
    <input matInput [(ngModel)]="value[i]" (keyup)="onInputChange($event, i)">
    <mat-icon matSuffix role="button" aria-label="Remove Item" (click)="onRemoveItemButtonClick(i)">clear</mat-icon>
  </mat-form-field>
  <div class="button-container item" *ngIf="!disabled">
    <button class="fab-button" mat-mini-fab color="accent" aria-label="Add Item" (click)="onAddItemButtonClick(i)">
      <mat-icon>add</mat-icon>
    </button>
  </div>
</div>
