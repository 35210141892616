import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Injectable } from '@angular/core';

import 'firebase/firestore';

import { DBUtil } from '../utils/db-utils';
import { EventNotificationMapper } from '../mappers/event-notifications-mapper';
import { NotificationModel } from '../models/notifications';

@Injectable({
  providedIn: 'root',
})
export class NotificationDAOService {
  realTimeDB = this.afs.database;
  mapper = new EventNotificationMapper();
  readonly notificationMapper = new EventNotificationMapper();

  constructor(
  private readonly afs:AngularFireDatabase) {

  }

  async create(props: {
    notification: NotificationModel,
    creator: string,
    contextId?: string
  }) {
    const collectionRef = this.realTimeDB.ref(DBUtil.Notification + `/${props.creator}`);
    const notificationKey = props.contextId || collectionRef.push().key;
    const newChild = collectionRef.child(notificationKey);

    await newChild.set(this.mapper.toMap(props.notification));
    return newChild.key;
  }

  async update(props: {id: string, read: boolean, userId:string }) {
    const path = DBUtil.Notification + `/${props.userId}` +`/${props.id}`;
    const collectionRef = this.realTimeDB.ref(path);

    const snapshot = (await collectionRef.get());

    await collectionRef.set({ ...snapshot.val(), read: props.read });

    return true;
  }

  async delete(props: {id: string, read: boolean, userId:string }) {
    const path = DBUtil.Notification + `/${props.userId}` +`/${props.id}`;
    const notificationRef = this.realTimeDB.ref(path);

    await notificationRef.remove();
    return true;
  }

  async list(creator: string) {
    const collectionRef = this.realTimeDB.ref(DBUtil.Notification + `/${creator}`);
    const snaps = await collectionRef.get();
    const models: NotificationModel[] = [];
    snaps.forEach((snap) => {
      const model = this.notificationMapper.fromDataSnapshot(snap)!;
      models.push(model);
    });

    return models;
  }
}
