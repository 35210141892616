import { AfterViewInit, Component, Input, OnChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';
import { BehaviorSubject } from 'rxjs';

import { TemplateTooltipDirective } from '@gth-legacy/directives/template-tooltip/template-tooltip.directive';
import { EventJoiner, EventRsvpStatus, GthEventJoiner } from '@index/interfaces';
import { GthUserModel } from '@sentinels/models';

@Component({
  selector: 'gth-participant-collection',
  templateUrl: './participant-collection.component.html',
  styleUrls: ['./participant-collection.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatBadgeModule,
    MatButtonModule,
    MatCardModule,
    MatCardModule,
    MatListModule,
    MatExpansionModule,
    MatIconModule,
    MatTooltipModule,
    TemplateTooltipDirective,
  ],
})
export class ParticipantCollectionComponent implements AfterViewInit {
  @Input()
  users: GthUserModel[] = [];

  @Input()
  user?: GthUserModel;

  @Input()
  eventJoiners: EventJoiner[] = [];

  @Input()
  pending: GthEventJoiner[] = [];

  @Input()
  isHost = false;

  @Input()
  platform = 'gth';

  @Input()
  maxDisplayed = 3;

  get displayedParticipants() {
    const uniqueParticipants = [];
    const uniqueIds = new Set();

    // Filter duplicates in this.users
    this.users.forEach((user) => {
      if (!uniqueIds.has(user.uid)) {
        uniqueIds.add(user.uid);
        uniqueParticipants.push(user);
      }
    });

    // Filter duplicates in this.pending based on player property
    this.pending.forEach((pendingItem) => {
      if (!uniqueIds.has(pendingItem.player)) {
        uniqueIds.add(pendingItem.player);
        uniqueParticipants.push(pendingItem);
      }
    });

    uniqueParticipants.forEach((p) => {
      const joiner = this.eventJoiners.find((j) => j.player === p.id);
      if (joiner) p.rsvpStatus = joiner.rsvpStatus;
    });

    // If the user is not the host, hide all non-yes participants
    if (!this.isHost) {
      // eslint-disable-next-line max-len
      return uniqueParticipants.filter((participant) => participant.rsvpStatus === EventRsvpStatus.PLAYING || this.user.id === participant.id);
    }

    return uniqueParticipants;
  }

  invalidUserPhotos: string[] = [];
  totalCount = new BehaviorSubject(0);

  ngAfterViewInit(): void {
    this.totalCount.next(this.getTotalCount());
  }


  /**
   * Event fired when there's an error loading the user's photo
   * @param {GthUserModel} user User that has error in their photo
   */
  onImageError(user: GthUserModel) {
    this.invalidUserPhotos.push(user.uid);
  }

  getGuestCount(user: GthUserModel) {
    const ejs = this.eventJoiners.filter((ej) => ej.player === user.id);
    const count = ejs[0].guests.reduce((sum, obj) => sum + obj.number, 0);
    return count;
  }

  getEventJoinerGuestCount(user: EventJoiner) {
    const count = user[0].guests.reduce((sum, obj) => sum + obj.number, 0);
    return count;
  }

  getTotalCount(): number {
    let total = 0;
    this.displayedParticipants.map((user) => {
      total += user instanceof GthUserModel ?
        this.getGuestCount(user) :
        this.getEventJoinerGuestCount(user as EventJoiner);
    });

    return total + this.displayedParticipants.length;
  }
}
