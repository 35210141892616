<div>
  <h1>{{formDetails.header}}</h1>
</div>
<div class="form-wrapper">
  <form [formGroup]="profileForm">
    <div class="account-status-wrapper">
      <p i18n="text to choose an avatar">Choose an Avatar (Optional)</p>
      <app-select-avatar-button [avatarFormControl]="photoURL" [photoUrlFromSocialMedia]="photoUrlFromSocialMedia">
      </app-select-avatar-button>
    </div>
    <br />
    <div class="controls-container">
      <div [ngClass]="{'flex-col': formDetails.layout === 'vertical'}">
        <!-- Display Name-->
        <mat-form-field class="form-field" appearance="outline">
          <mat-label>Display Name</mat-label>
          <input matInput required i18n="Input for display name" type="text" class="displayName"
            formControlName="displayName" [minlength]="constraints.displayName.minLength"
            [maxlength]="constraints.displayName.maxLength" />
          <mat-hint align="end">{{profileForm.controls.displayName.value?.length ?? 0}} /
            {{constraints.displayName.maxLength}}</mat-hint>
        </mat-form-field>

        <!-- Full Name -->
        <mat-form-field class="form-field" appearance="outline">
          <mat-label>Full Name</mat-label>
          <input matInput required i18n="Input for full name" type="text" class="fullName" formControlName="fullName"
            [minLength]="constraints.fullName.minLength" [maxLength]="constraints.fullName.maxLength" />
          <mat-hint align="end">{{profileForm.controls.fullName.value?.length ?? 0}} /
            {{constraints.fullName.maxLength}}</mat-hint>
        </mat-form-field>
      </div>
      <div [ngClass]="{'flex-col': formDetails.layout === 'vertical'}">
        <mat-form-field class="form-field" appearance="outline">
          <mat-label>Email</mat-label>
          <input matInput required maxlength="100" type="email" class="email" i18n="Input for email"
            formControlName="email" email />
        </mat-form-field>

        <mat-form-field class="form-field" appearance="outline">
          <mat-label>Default Location</mat-label>
          <input required class="city-input" matInput #cityInput ngx-gp-autocomplete
            formControlName="cityName" (change)="clearCityInput()" #placesRef="ngx-places" [options]="options"
            (onAddressChange)="onAutocompleteSelected($event)" />

        </mat-form-field>
      </div>

      <div [ngClass]="{'flex-col': formDetails.layout === 'vertical'}">
        <mat-form-field class="form-field" appearance="outline" *ngIf="genders">
          <mat-label>What gender do you identify with?</mat-label>
          <mat-select class="gender" formControlName="gender" #smallGendersSelect [value]="gender">
            <mat-option *ngFor="let gender of genders" value="{{ gender.label }}">{{ gender.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="!initialSignUp" class="form-field" appearance="outline">
          <mat-label>Which pronouns do you prefer?</mat-label>
          <mat-select formControlName="pronouns">
            <mat-option>None</mat-option>
            <mat-option value="He/His">He/His</mat-option>
            <mat-option value="She/Her">She/Her</mat-option>
            <mat-option value="They/Them">They/Them</mat-option>
            <mat-option value="Unspecified">Unspecified</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <button class="get-started-button" type="button" (click)="setAdditionalInfo()" color="primary"
      [disabled]="isFormValid()" i18n="Button to sign up" mat-flat-button>
      {{ isNewUser ? 'Setup Account' : 'Save Changes' }}
    </button>
  </form>
</div>
