import { Injectable } from '@angular/core';

export interface GthTournamentBracketTeam {
  name: string;
  score: number;
}

export interface GthTournamentBracketGame {
  teams: GthTournamentBracketTeam[];
}

export interface GthTournamentBracketRound {
  matches: GthTournamentBracketGame[];

  _offset?: string;
}

@Injectable({
  providedIn: 'root',
})
export class GthBracketService {
  getMockBracket(numberOfTeams: number) {
    const tournament: GthTournamentBracketRound[] = [];
    let teams: string[] = [];
    for (let i = 0; i < numberOfTeams; i++) {
      teams.push(this.getTeamName(i + 1));
    }

    while (teams.length > 1) {
      const round = this.getRound(teams);
      const matches = round.matches;
      const offset = round._offset;

      tournament.push({
        type: 'Winnerbracket',
        matches,
      } as GthTournamentBracketRound);

      teams = matches.map((match) => {
        const winner = match.teams
          .sort((teamA, teamB) => teamA.score > teamB.score ? -1 : 1)[0];
        return winner.name;
      });
      if (offset) {
        teams.unshift(offset);
      }
    }

    if (teams.length === 1) {
      const finalRound = this.getFinalRound(teams[0]);
      tournament.push(finalRound);
    }

    return tournament;
  }

  private getRound(teamCollection: string[]): GthTournamentBracketRound {
    const round: GthTournamentBracketRound = {
      matches: [],
    };
    let offset: string | undefined;

    if (teamCollection.length % 2 === 1) {
      offset = teamCollection.pop();
    }

    while (teamCollection.length > 1) {
      const firstTeam = teamCollection.shift();
      const secondTeam = teamCollection.shift();
      const teams: GthTournamentBracketTeam[] = [];
      teams.push({
        name: firstTeam as string,
        score: 1,
      });
      if (secondTeam) {
        teams.push({
          name: secondTeam,
          score: 2,
        });
      }
      const match = {
        teams,
      };
      round.matches.push(match);
    }

    return {
      ...round,
      _offset: offset,
    };
  }

  private getFinalRound(teamName: string): GthTournamentBracketRound {
    return {
      type: 'Final',
      matches: [
        {
          teams: [{ name: teamName, score: -1 }],
        },
      ],
    } as GthTournamentBracketRound;
  }

  private getTeamName(index: number) {
    const baseChar = ('A').charCodeAt(0);
    let letters = '';
    do {
      index -= 1;
      letters = String.fromCharCode(baseChar + (index % 26)) + letters;
      index = (index / 26) >> 0;
    } while (index > 0);
    return `Team ${letters}`;
  }
}
