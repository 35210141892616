import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService } from '@angular/fire/compat/analytics';
import { isDevMode } from '@angular/core';
import { USE_EMULATOR as USE_AUTH_EMULATOR } from '@angular/fire/compat/auth';
import { environment } from '@environments/environment';
import { USE_EMULATOR as USE_DATABASE_EMULATOR } from '@angular/fire/compat/database';
import { USE_EMULATOR as USE_STORAGE_EMULATOR } from '@angular/fire/compat/storage';
import { USE_EMULATOR as USE_FUNCTIONS_EMULATOR } from '@angular/fire/compat/functions';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireRemoteConfigModule, DEFAULTS, SETTINGS as RemoteConfigSettings } from '@angular/fire/compat/remote-config';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { AngularFirestoreModule, SETTINGS, USE_EMULATOR as USE_FIRESTORE_EMULATOR } from '@angular/fire/compat/firestore';

export const FirebaseModules = [
  AngularFireModule.initializeApp(environment.firebase),
  AngularFireRemoteConfigModule,
  AngularFireMessagingModule,
  AngularFirestoreModule,
  AngularFireAnalyticsModule,
];
export const FirebaseProviders = [
  { provide: DEFAULTS, useValue: { enableSubscriptions: true } },
  {
    provide: SETTINGS, useValue: { debug: true },
  },
  ScreenTrackingService,
  UserTrackingService,
  {
    provide: RemoteConfigSettings,
    useFactory: () => isDevMode() ? { minimumFetchIntervalMillis: 10_000 } : {},
  },
  {
    provide: USE_AUTH_EMULATOR,
    useValue: environment.useEmulators ? ['http://localhost:9099'] : undefined,
  },
  {
    provide: USE_DATABASE_EMULATOR,
    useValue: environment.useEmulators ? ['localhost', 9000] : undefined,
  },
  {
    provide: USE_FIRESTORE_EMULATOR,
    useValue: environment.useEmulators ? ['localhost', 8080] : undefined,
  },
  {
    provide: USE_STORAGE_EMULATOR,
    useValue: environment.useEmulators ? ['localhost', 9199] : undefined,
  },
  {
    provide: USE_FUNCTIONS_EMULATOR,
    useValue: environment.useEmulators ? ['localhost', 5001] : undefined,
  },
];
