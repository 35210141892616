import {
  OnInit,
  Output,
  Component,
  EventEmitter,
  ViewChild,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, Subscription, of } from 'rxjs';
import { NgxGpAutocompleteDirective } from '@angular-magic/ngx-gp-autocomplete';
import { ActivatedRoute } from '@angular/router';

import { DefaultCity } from '@index/interfaces/user';
import { GthAuthService } from '../../services/auth.service';
import { EventItemTypes } from '@index/enums/event-item-type';
import { GameType } from '@index/interfaces/stored-enums';
import { GthGenderModel } from '@sentinels/models/gender';
import { GthEventRatingModel } from '@sentinels/models/event-rating';
import { SrvApiService } from '@sentinels/services/api.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map, shareReplay } from 'rxjs/operators';
import { ScrollService } from '@gth-legacy/services/scroll.service';

export interface GthFilterSettings {
  online: boolean;
  context?: string;
  gender?: string;
  city?: {
    lat: number;
    lng: number;
    name: string;
  };
  gameType?: string[];
  ratings?: number[];
  eventType?: string;
  startDate?: Date;
  endDate?: Date;
  showMap?: boolean;
  showFavorites?: boolean;
  days?: string[];
  exactDate?: Date;
}

export enum SearchTypes {
  TEAMS = 'Teams',
  PICKUP_GAMES = 'Pick Up Games',
  PLAYERS = 'Players',
}

export interface SearchBarResponse {
  searchType: SearchTypes;
  source: 'auto' | 'manual';
  queryParams: any;
}

@Component({
  selector: 'gth-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class GthSearchBarComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  context = '';

  @Input()
  searchButtonText = 'Search';

  @Input()
  defaultCity?: { city: DefaultCity | undefined };

  @Input()
  createButtonText = 'Create Event';

  @Output()
  createButtonClick = new EventEmitter();

  @Output()
  filter = new EventEmitter<SearchBarResponse>();

  @ViewChild('placesRef')
  placesRef: NgxGpAutocompleteDirective;

  options = {
    componentRestrictions: {
      country: ['US'],
    },
    types: ['cities'],
  };

  searchTypes = Object.values(SearchTypes);
  filterOpened = true;

  types = 'cities';
  genders: GthGenderModel[] = [];
  eventTypes$: Observable<any>;
  sports$: Observable<GameType[]>;
  ratings: GthEventRatingModel[] = [];
  gameTypes: GameType[] = [];
  days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  user$ = this.auth.userModel$;
  filterDisplayed = false;
  placeInputOptions: any = {
    types: ['cities'],
    componentRestrictions: { country: 'US' },
  };
  displayOptions = false;
  displayedSearchType = 'Sport';

  get sportsDisplayed() {
    return this.displayedSearchType === 'Sport';
  }

  get eventTypesDisplayed() {
    return this.displayedSearchType === 'Event Type';
  }

  get genderDisplayed() {
    return this.displayedSearchType === 'Gender';
  }

  get ratingsDisplayed() {
    return this.displayedSearchType === 'Ratings';
  }

  get daysDisplayed() {
    return this.displayedSearchType === 'Days';
  }

  get exactDateDisplayed() {
    return this.displayedSearchType === 'ExactDate';
  }

  get isTeams() {
    return this.context === 'teams';
  }

  get isAvailablePlayers() {
    return this.context === 'players';
  }

  get isPickupGames() {
    return this.context === 'pickup-games';
  }

  get showMap() {
    const ctrl = this.searchForm.get('showMap');
    return ctrl ? ctrl.value : false;
  }
  set showMap(val: boolean) {
    this.searchForm.get('showMap')?.setValue(val);
  }

  searchForm = new UntypedFormGroup({
    searchType: new UntypedFormControl(SearchTypes.PICKUP_GAMES, Validators.required),

    city: new UntypedFormGroup({
      name: new UntypedFormControl(''),
      lat: new UntypedFormControl(''),
      lng: new UntypedFormControl(''),
    }),
    cityName: new UntypedFormControl(''),
    gameType: new UntypedFormControl([]),
    eventType: new UntypedFormControl(EventItemTypes.Pickup),
    gender: new UntypedFormControl(''),
    ratings: new UntypedFormControl([]),
    startDate: new UntypedFormControl(undefined),
    endDate: new UntypedFormControl(undefined),
    showMap: new UntypedFormControl(false),
    showFavorites: new UntypedFormControl(false),
    online: new UntypedFormControl(false),
    days: new UntypedFormControl([]),
    exactDate: new UntypedFormControl(undefined),
  });

  private subscription = new Subscription();
  isSmallScreen$: Observable<boolean>;

  constructor(
    private auth: GthAuthService,
    private api: SrvApiService,
    private activatedRoute: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
    private scrollService: ScrollService,
  ) {


  }

  async ngOnInit() {
    this.scrollService.scrollSubject.subscribe(()=>{
      this.displayOptions = false;
    });

    this.isSmallScreen$ =
     this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small])
      .pipe(
        map((result) => result.matches),
        shareReplay(),
      );
    this.ratings = await this.api.eventRatings.listAsync();
    this.genders = (await this.api.genders.listAsync())
      .filter((i) => i.label !== 'Other');
    this.gameTypes = await this.api.gameTypes.listAsync();
    this.eventTypes$ = of(EventItemTypes);

    this.searchForm.valueChanges.subscribe(() => {
      this.onSearchButtonClick('auto');
    });

    switch (this.context) {
      case 'pickup-games':
        this.searchForm.get('searchType')?.setValue(SearchTypes.PICKUP_GAMES);
        break;
      case 'players':
        this.searchForm.get('searchType')?.setValue(SearchTypes.PLAYERS);
        break;
      case 'teams':
        this.searchForm.get('searchType')?.setValue(SearchTypes.TEAMS);
        this.clearCityInput();
        break;
      case 'landing-page':
      default:
        break;
    }

    this.activatedRoute.queryParamMap
      .subscribe((map) => {
        const params = map['params'];
        let keyCount = 0;
        // eslint-disable-next-line guard-for-in
        for (const _key in params) {
          keyCount++;
        }
        if (keyCount === 0) {
          return;
        }
        const city = {
          name: params['name'],
          lat: parseInt(params['lat']),
          lng: parseInt(params['lng']),
        };
        const online = params['online'] === 'true';
        const context = params['context'] ?? '';

        if (!city.name || !city.lat || !city.lng) {
          return;
        }
        let gameType = params['gameTypes'] ?? params['gameType'];
        /** Split game types from route */
        if (gameType && !Array.isArray(gameType)) {
          gameType = gameType.split(',');
        }
        this.searchForm.patchValue({
          context,
          city: city,
          cityName: city?.name ?? '',
          gameType,
          online,
        },
          { emitEvent: true },
        );
      });
  }

  onScroll() {
    this.displayOptions = false;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.defaultCity) {
      this.populateDefaultCity();
    }
  }

  setCityInput(location: { name: string, lat: number, lng: number }) {
    this.searchForm.get('city').setValue(location);
    this.searchForm.get('cityName').setValue(location.name);
  }

  onGameTypeChanged(gameType: string[]) {
    this.searchForm.get('gameType').setValue(gameType);
  }

  getBtnColor(exists: boolean) {
    return exists ? 'primary' : 'accent';
  }

  clearCityInput() {
    this.searchForm.get('city')?.reset();
  }

  onAutocompleteSelected(place: any) {
    if (!place || !place.geometry || !place.geometry.location) {
      return;
    }
    const city = {
      name: place.formatted_address,
      lat: place.geometry!.location.lat(),
      lng: place.geometry!.location.lng(),
    };
    this.searchForm.get('city')!.setValue({
      name: city.name,
      lat: city.lat,
      lng: city.lng,
    });
    this.searchForm.get('cityName')!.setValue(place.formatted_address);
  }

  onSearchButtonClick(source: 'auto' | 'manual' = 'manual') {
    const value = this.searchForm.getRawValue();

    const { name, lat, lng } = value.city;
    const { searchType, online } = value;

    if (!name || !lat || !lng) return;
    const queryParams = {
      city: online ? undefined : {
        name,
        lat,
        lng,
      },
      gameType: value.gameType,
      context: value.context,
      eventType: value.eventType,
      gender: value.gender,
      ratings: value.ratings,
      showFavorites: value.showFavorites,
      showMap: value.showMap,
      online: value.online,
      days: value.days,
      exactDate: value.exactDate,
    };

    this.filter.emit({
      searchType,
      source,
      queryParams,
    });
  }

  onRefreshButtonClick() {
    this.onSearchButtonClick('manual');
  }

  openCreateGameDialog() {
    this.createButtonClick.emit();
  }

  onOnlineChange(online: boolean) {
    this.searchForm.get('online').setValue(online);
    if (online) {
      this.clearCityInput();
      this.searchForm.get('cityName').disable();
    } else {
      this.searchForm.get('cityName').enable();
    }
  }

  onClearFilterButtonClick() {
    this.searchForm.setValue({
      searchType: this.getSearchType(),
      city: {
        name: '',
        lat: '',
        lng: '',
      },
      cityName: '',
      gameType: [],
      eventType: EventItemTypes.Pickup,
      gender: '',
      ratings: [],
      startDate: '',
      endDate: '',
      showMap: false,
      showFavorites: false,
      online: false,
      days: [],
      exactDate: '',
    });
    this.populateDefaultCity();
    this.onSearchButtonClick('manual');
  }

  private getSearchType() {
    switch (this.context) {
      case 'pickup-games':
        return SearchTypes.PICKUP_GAMES;
      case 'players':
        return SearchTypes.PLAYERS;
      case 'teams':
        return SearchTypes.TEAMS;
      case 'landing-page':
      default:
        return SearchTypes.PICKUP_GAMES;
    }
  }

  private populateDefaultCity() {
    const cityCtrl = this.searchForm.get('city');
    const cityNameCtrl = this.searchForm.get('cityName');

    const cityValue = cityCtrl.value;
    const cityName = cityNameCtrl.value;
    if (cityName && cityValue.name) {
      return;
    }

    if (this.defaultCity && this.defaultCity.city) {
      const { name, lat, lng } = this.defaultCity.city;
      cityCtrl.setValue({
        name,
        lat,
        lng,
      });
      cityNameCtrl.setValue(name);
      this.displayOptions = true;
    }
  }
}
