<h3 class="title">{{title}}</h3>

<ul class="skill-levels">
  <ng-container *ngFor="let skillLevel of SkillLevel | keyvalue: originalOrder;
  trackBy: identifySkillLevel; let i=index">
    <li *ngIf="skillLevel.value !== SkillLevel.Any" class="skill-level" (click)="onSkillLevelClick(skillLevel.value)"
      [ngClass]="{'selected': skillLevelFormControl.value?.includes(skillLevel.value)}">
      {{skillLevel.value}}
    </li>
  </ng-container>
  <li class="skill-level" (click)="onSkillLevelClick(SkillLevel.Any)"
    [ngClass]="{'selected': skillLevelFormControl.value?.length === 4}">All Skill Levels</li>
</ul>