<div class="avatar-dialog">
  <h1 mat-dialog-title>{{ title }}</h1>
  <mat-dialog-content class="avatar-body">
    <ng-container *ngIf="loading else showAvatarsTmpl">
      <div class="fx-auto fx-fill fx-centered">
        <mat-spinner [diameter]="36"></mat-spinner>
      </div>
    </ng-container>
    <ng-template #showAvatarsTmpl>
      <div class="avatar-images-container">
        <div class="avatar-image" *ngFor="let in of imageNumbers; let i = index">
          <img
            #avatarImage
            tabindex="0"
            (click)="selectedAvatarIndex = i"
            class="avatar-image"
            [class.avatar-image--selected]="i === selectedAvatarIndex"
            loading="lazy"
            role="button"
            src="assets/avatars/avatar-{{ i }}.jpg"
          />
        </div>
      </div>
    </ng-template>
  </mat-dialog-content>
  <mat-dialog-actions class="avatar-actions">
    <button
      class="dialog-button"
      mat-stroked-button
      [mat-dialog-close]="closeDialog(closeMethod.CANCEL)">
      Add Later
    </button>

    <button mat-stroked-button class="dialog-button" (click)="fileInput.click()">
      Upload Image
    </button>
    <input type="file" class="display-none" #fileInput (change)="onFileSelect($event)" accept="image/*" />

    <button
      *ngIf="data.showSocialMediaButton"
      class="dialog-button"
      mat-stroked-button
      color="accent"
      [mat-dialog-close]="closeDialog(closeMethod.SAVE, true)">
      Use Photo from Social Media Account
    </button>

    <button
      class="dialog-button"
      mat-stroked-button
      color="primary"
      [disabled]="selectedAvatarIndex === -1"
      [mat-dialog-close]="closeDialog(closeMethod.SAVE)">
      Save
    </button>
  </mat-dialog-actions>
</div>
