import {
  AfterViewInit,
  Component,
  ChangeDetectionStrategy,
  EventEmitter,
  ViewChild,
  TemplateRef,
  Input,
  Output,
} from '@angular/core';
import {
  startOfDay,
  endOfDay,
  isSameDay,
  isSameMonth,
  addHours,
  addMinutes,
} from 'date-fns';
import { Subject, BehaviorSubject } from 'rxjs';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarView,
} from 'angular-calendar';
import { EventColor } from 'calendar-utils';

import { GthEventItemModel } from '@sentinels/models/event-item';

const colors: Record<string, EventColor> = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};

@Component({
  selector: 'gth-calendar-component',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: 'calendar.component.html',
  styleUrls: ['calendar.component.scss'],
})
export class CalendarComponent implements AfterViewInit {
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
  @Input() eventItems: GthEventItemModel[];

  @Output() handleCalendarClickedEvent = new EventEmitter<GthEventItemModel>();

  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  modalData: {
    action: string;
    event: CalendarEvent;
  };

  actions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-fw fa-pencil-alt"></i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      },
    },
    {
      label: '<i class="fas fa-fw fa-trash-alt"></i>',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events$.next(this.events.filter((iEvent) => iEvent !== event));
        this.handleEvent('Deleted', event);
      },
    },
  ];

  refresh = new Subject<void>();
  events$= new BehaviorSubject<CalendarEvent[]>([]);
  activeDayIsOpen = false;

  get events() {
    return this.events$.getValue();
  }

  makeCalendarEvent(eventItem: GthEventItemModel): CalendarEvent<GthEventItemModel> {
    const end = addHours(
      addMinutes(eventItem.dateStart, eventItem.duration.minutes),
      eventItem.duration.hours);
    return {
      start: eventItem.dateStart,
      end,
      title: eventItem.title,
      color: { ...colors.green },
      actions: this.actions,
      allDay: false,
      resizable: {
        beforeStart: false,
        afterEnd: false,
      },
      draggable: false,
      meta: eventItem,
    };
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  ngAfterViewInit() {
    this.events$.next(this.eventItems.map((item)=>{
      return {
        ...this.makeCalendarEvent(item),
      } as CalendarEvent;
    }));
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
    this.handleEventEmit(event.meta);
  }

  handleEventEmit(value: GthEventItemModel) {
    this.handleCalendarClickedEvent.emit(value);
  }

  addEvent(): void {
    this.events$.next([
      ...this.events,
      {
        title: 'New event',
        start: startOfDay(new Date()),
        end: endOfDay(new Date()),
        color: colors.red,
        draggable: true,
        resizable: {
          beforeStart: true,
          afterEnd: true,
        },
      },
    ]);
  }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events$.next(this.events.filter((event) => event !== eventToDelete));
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }
}
