import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppConfirmDialogComponent } from './confirm-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';

const ANGULAR_MATERIAL_MODULES = [
  MatDialogModule, MatFormFieldModule, MatInputModule,
  MatButtonModule,
];

const CORE_MODULES = [CommonModule, FormsModule];

const COMPONENTS = [AppConfirmDialogComponent];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [...CORE_MODULES, ...ANGULAR_MATERIAL_MODULES],
  exports: [...COMPONENTS],
})
export class AppConfirmDialogModule { }
