import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { from, Observable, of } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';

import { MessageCreateRequest, MessageUpdateRequest, Payload, Results } from '@index/interfaces';
import { GthConversationModel } from '../../../../../sentinels/src/lib/models/conversation';
import { CallableOptions, CallableRoutes, GthCallableService } from './callable.service';
import { GthErrorLoggerService } from './error-logger.service';

const CONTEXT = 'GthConversationFunctionService';

@Injectable({
  providedIn: 'root',
})
export class GthConversationFunctionService {
  constructor(
    public functions: AngularFireFunctions,
    public callableService: GthCallableService,
    private logger: GthErrorLoggerService,
  ) { }

  /**
 * Updates a Conversation (sends message)
 * @param {MessageUpdateRequest} message
 * @return {null}
 */
  update$(message: MessageUpdateRequest) {
    return from(this.update(message));
  }

  /**
 * Creates a new Conversation/Message
 * @param {MessageCreateRequest} message
 * @return {null}
 */
  create$(message: MessageCreateRequest) {
    return from(this.create(message));
  }

  /**
  * Get a Conversation by ID
  * @param {string} conversationId
  * @param {string} userId
  * @return {GthConversationModel}
  * */
  get$(conversationId: string, userId: string): Observable<GthConversationModel> {
    return from(this.get(conversationId, userId))
      .pipe(
        switchMap((conversation) => conversation),
        first(),
      );
  }

  /**
   * Creates a new Conversation/Message
   * @param {MessageCreateRequest} message
   * @return {null}
   */
  private async create(message: MessageCreateRequest) {
    if (!this.functions) return Promise.resolve(false);

    const options: CallableOptions = {
      route: CallableRoutes.CONVERSATIONS_CREATE,
      data: message,
    };

    const response = (await this.callableService.call(options)) as Results<null>;
    this.log(`Conversation created`);
    return response;
  }

  /**
   * Updates a Conversation (sends message)
   * @param {MessageUpdateRequest} message
   * @return {null}
   */
  private async update(message: MessageUpdateRequest) {
    if (!this.functions) return Promise.resolve(false);

    const options: CallableOptions = {
      route: CallableRoutes.CONVERSATIONS_UPDATE,
      data: message,
    };

    const response = (await this.callableService.call(options)) as Results<null>;
    this.log(`Conversation updated`);
    return response;
  }

  /**
   * Get a Conversation by id
   * @param {string} conversationId
   * @param {string} userId
   * @return {GthConversationModel}
   * */
  private async get(conversationId: string, userId: string) {
    const options: CallableOptions = {
      route: CallableRoutes.CONVERSATIONS_GET,
      data: {
        conversationId,
        userId,
      },
    };
    const response = (await this.callableService.call(options)) as
      Results<Observable<GthConversationModel>>;
    if (response && response.success) {
      const payload = response.payload as Payload<Observable<GthConversationModel>>;
      this.log(`Conversation fetched by id: ${conversationId}`);
      return payload.data;
    }

    return of(undefined);
  }

  private log(text: string) {
    this.logger.debug(`${CONTEXT}: ${text}`);
  }
}
