import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { catchError, map, take } from 'rxjs/operators';
import firebase from 'firebase/compat/app';
import { EMPTY } from 'rxjs';

import { GthErrorLoggerService } from './cloud/error-logger.service';
import { DBUtil } from '@index/utils/db-utils';
import { Connection } from '@index/interfaces';

const CONTEXT = 'ConnectionsService';

@Injectable({ providedIn: 'root' })
export class ConnectionsService {
  constructor(
    private logger: GthErrorLoggerService,
    private firestore: AngularFirestore,
  ) {}
  /** -----------------------------------------
   * Temporary functions below while developing
   */
  /**
   * Retrieves a list of follower IDs for a specified user.
   *
   * @param {string} userId - The ID of the user whose followers are being retrieved.
   * @return {Promise<string[]> | null} - A Promise resolving to an array of follower IDs,
   * or `null` if an error occurs during retrieval.
   */
  async getFollowers(userId: string) {
    try {
      const docRef = this.firestore.collection(DBUtil.User).doc(userId)
        .collection(DBUtil.Followers);
      const querySnap = await docRef.get().pipe(take(1)).toPromise();
      if (querySnap.empty) return null;
      return querySnap.docs.map((d) => d.id);
    } catch (error: unknown) {
      console.error(`Something went wrong getting followers for ${userId}`, error);
      return null;
    }
  }
  /**
   * Determines whether a specific user (followerId) is following another user (followedId).
   *
   * @param {string} followerId - The ID of the user who is potentially following.
   * @param {string} followedId - The ID of the user who is potentially being followed.
   * @return {Promise<boolean | null>} - A Promise resolving to `true` if the user is
   * following, `false` if they are not following, or `null` if an error occurs.
   */
  async getFollowing(followerId: string, followedId: string) {
    try {
      const docRef = this.firestore.collection(DBUtil.User).doc(followedId)
        .collection(DBUtil.Followers).doc(followerId);
      const docSnap = await docRef.get().pipe(take(1)).toPromise();
      return docSnap.exists;
    } catch (error: unknown) {
      console.error(`Something went wrong getting following`, error);
      return null;
    }
  }
  /**
   * Creates a connection between two users, indicating that the followerId is following
   * the followedId.
   *
   * @param {string} followerId - The ID of the user who is initiating the follow action.
   * @param {string} followedId - The ID of the user who is being followed.
   * @return {Promise<boolean | null>} - A Promise resolving to `true` if the follow action
   * was successful, `false` if it failed, or `null` if an error occurs.
   */
  async follow(followerId: string, followedId: string) {
    try {
      const batch = this.firestore.firestore.batch();

      /** Update followers */
      const followerRef = this.firestore.collection(DBUtil.User).doc(followedId)
        .collection(DBUtil.Followers).doc(followerId);
      batch.set(followerRef.ref, { createdAt: firebase.firestore.Timestamp.now() });

      /** Update following */
      const followedRef = this.firestore.collection(DBUtil.User).doc(followerId)
        .collection(DBUtil.Following).doc(followedId);
      batch.set(followerRef.ref, { createdAt: firebase.firestore.Timestamp.now() });

      return batch.commit()
        .then(() => true)
        .catch(() => false);
    } catch (error: unknown) {
      console.error(`Something went wrong following user`, error);
      return null;
    }
  }
  /**
   * Removes a connection between two users, indicating that the followerId is no longer
   * following the followedId.
   *
   * @param {string} followerId - The ID of the user who is initiating the unfollow action.
   * @param {string} followedId - The ID of the user who is being unfollowed.
   * @return {Promise<boolean | null>} - A Promise resolving to `true` if the unfollow
   * action was successful, `false` if it failed, or `null` if an error occurs.
   */
  async unfollow(followerId: string, followedId: string) {
    try {
      const batch = this.firestore.firestore.batch();

      /** Update followers */
      const followerRef = this.firestore.collection(DBUtil.User).doc(followedId)
        .collection(DBUtil.Followers).doc(followerId).ref;
      batch.delete(followerRef);

      /** Update following */
      const followedRef = this.firestore.collection(DBUtil.User).doc(followerId)
        .collection(DBUtil.Following).doc(followedId).ref;
      batch.delete(followerRef);

      return batch.commit()
        .then(() => true)
        .catch(() => false);
    } catch (error: unknown) {
      console.error(`Something went wrong unfollowing user`, error);
      return null;
    }
  }
  /**
   * Temporary functions above while developing
   * ------------------------------------------
   */

  /**
   * Retrieves an observable stream of connection IDs for a specified user.
   *
   * @param {string} userId - The ID of the user whose connection IDs are being observed.
   * @return {Observable<string[]>} - An Observable stream that emits an array of connection
   * IDs whenever the connections change,
   * or completes if an error occurs.
   */
  getConnections$(userId: string) {
    return this.firestore
      .collection(DBUtil.User)
      .doc(userId)
      .collection<Connection>(DBUtil.Connections)
      .valueChanges({ idField: 'id' })
      .pipe(
        map((docs) => docs.map((d) => d.id)),
        catchError((error: unknown) => {
          console.error(`Something went wrong getting connecting for ${userId}`, error);
          return EMPTY;
        }),
      );
  }
  /**
   * Retrieves a list of connection IDs for a specified user.
   *
   * @param {string} userId - The ID of the user whose connections are being retrieved.
   * @return {Promise<string[]> | null} - A Promise resolving to an array of connection IDs,
   * or `null` if an error occurs during retrieval.
   */
  async getConnections(userId: string) {
    try {
      const querySnap = await this.firestore
        .collection(DBUtil.User).doc(userId)
        .collection<Connection>(DBUtil.Connections).get()
        .pipe(take(1)).toPromise();
      if (querySnap.empty) return null;
      return querySnap.docs.map((doc) => doc.id);
    } catch (error: unknown) {
      console.error(`Something went wrong getting connections for ${userId}`, error);
      return null;
    }
  }
  /**
   * Adds a connection between two users.
   *
   * @param {string} connecteeId - The ID of the user to whom the connection is being added.
   * @param {string} connectorId - The ID of the user who is adding the connection.
   * @return {Promise<boolean>} - A Promise resolving to `true` if the connection was
   * successfully added, `false` if it couldn't be added, or `null` on error.
   */
  async addConnection(connecteeId: string, connectorId: string) {
    try {
      const batch = this.firestore.firestore.batch();
      /** Add connectee connection */
      const connecteeRef = this.firestore
        .collection(DBUtil.User).doc(connecteeId)
        .collection<Connection>(DBUtil.Connections).doc(connectorId).ref;
      batch.set(connecteeRef, { createdAt: firebase.firestore.Timestamp.now() });

      /** Add connector connection */
      const connectorRef = this.firestore
        .collection(DBUtil.User).doc(connectorId)
        .collection<Connection>(DBUtil.Connections).doc(connecteeId).ref;
      batch.set(connectorRef, { createdAt: firebase.firestore.Timestamp.now() });

      return batch.commit()
        .then(() => true)
        .catch(() => false);
    } catch (error: unknown) {
      console.error('Something went wrong adding connection', error);
      return null;
    }
  }
  /**
   * Removes a connection between two users.
   *
   * @param {string} connecteeId - The ID of the user from whom the connection
   * is being removed.
   * @param {string} connectorId - The ID of the user who is removing the connection.
   * @return {Promise<boolean>} - A Promise resolving to `true` if the connection was
   * successfully removed, `false` if it couldn't be removed, or `null` on error.
   */
  async removeConnection(connecteeId: string, connectorId: string) {
    try {
      const batch = this.firestore.firestore.batch();
      /** Remove connectee connection */
      const connecteeRef = this.firestore
        .collection(DBUtil.User).doc(connecteeId)
        .collection<Connection>(DBUtil.Connections).doc(connectorId).ref;
      batch.delete(connecteeRef);

      /** Remove connector connection */
      const connectorRef = this.firestore
        .collection(DBUtil.User).doc(connectorId)
        .collection<Connection>(DBUtil.Connections).doc(connecteeId).ref;
      batch.delete(connectorRef);

      return batch.commit()
        .then(() => true)
        .catch(() => false);
    } catch (error: unknown) {
      console.error('Something went wrong removing connection', error);
      return null;
    }
  }
  /**
   * Checks whether a connection exists between two users.
   * @param {string} connecteeId - The ID of the user initiating the follow action.
   * @param {string} connectorId - The ID of the user being followed.
   * @return {Promise<boolean>} - A Promise resolving to `true` if the connection exists
   * `false` if it doesn't, or `null` on error.
   */
  async connectionExists(connecteeId: string, connectorId: string) {
    try {
      const docRef = this.firestore
        .collection(DBUtil.User).doc(connecteeId)
        .collection<Connection>(DBUtil.Connections).doc(connectorId);
      const docSnap = await docRef.get().pipe(take(1)).toPromise();
      return docSnap.exists;
    } catch (error: unknown) {
      console.error('Something went wrong checking if connection exists', error);
      return null;
    }
  }

  private log(text: string) {
    this.logger.debug(`${CONTEXT}: ${text}`);
  }
}
