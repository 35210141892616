import { Component, Inject, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { NgxGpAutocompleteModule } from '@angular-magic/ngx-gp-autocomplete';
import { NgxGpAutocompleteDirective } from '@angular-magic/ngx-gp-autocomplete';
import { geohashForLocation } from 'geofire-common';
import { NgIf } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';

import { AppReverseGeocodeService } from '../../services/reverse-geocode.service';
import { Location } from '@index/interfaces/location';
import { GthGoogleMapModule } from '../google-map/google-map.module';

export interface AddressSelectionContract {
  address: Location;
}

@Component({
  templateUrl: './address-selection.component.html',
  styleUrls: ['./address-selection.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    GthGoogleMapModule,
    NgxGpAutocompleteModule,
  ],
})
export class AddressSelectionComponent {
  @ViewChild('placesRef')
  placesRef: NgxGpAutocompleteDirective;

  options = {
    componentRestrictions: {
      country: ['US'],
    },
  };

  /**
   * Returns true if the address form is valid
   */
  get isFormValid() {
    return this.form.valid;
  }

  get lat() {
    return this.form.get('lat') ? this.form.get('lat')!.value : '';
  }

  get lng() {
    return this.form.get('lng') ? this.form.get('lng')!.value : '';
  }

  get placeResult() {
    const ctrl = this.form.get('placeResult');
    if (!ctrl || !ctrl.value) {
      return { name: '' };
    }
    return ctrl.value;
  }

  zoom = 16;
  form = new UntypedFormGroup({
    placeResult: new UntypedFormControl({ name: '' }, Validators.required),
    lat: new UntypedFormControl('', Validators.required),
    lng: new UntypedFormControl('', Validators.required),
  });
  placeInputOptions: any = {
    types: ['address'],
    componentRestrictions: { country: 'US' },
  };
  private originalValue;
  private dirty = false;

  constructor(
    private dialogRef: MatDialogRef<AddressSelectionComponent>,
    @Inject(MAT_DIALOG_DATA) private data: AddressSelectionContract,
    private geocode: AppReverseGeocodeService,
  ) {
    if (this.data && this.data.address) {
      this.originalValue = { ...this.data.address };
      this.form.get('placeResult')!.setValue({ name: this.data.address.formattedAddress });
      this.form.get('lat')!.setValue(this.data.address.lat);
      this.form.get('lng')!.setValue(this.data.address.lng);
    }
  }

  onSaveButtonClick() {
    if (!this.form.dirty && !this.dirty) {
      this.dialogRef.close(this.originalValue);
      return;
    }

    const form = this.form.getRawValue();

    this.dialogRef.close({
      lat: form.lat,
      lng: form.lng,
      formattedAddress: form.placeResult.formatted_address,
      hash: geohashForLocation([form.lat, form.lng]),
    });
  }

  onCancelButtonClick() {
    if (!this.originalValue) {
      this.dialogRef.close(undefined);
      return;
    }

    this.dialogRef.close({
      lat: this.originalValue.lat,
      lng: this.originalValue.lng,
      formattedAddress: this.originalValue.formattedAddress,
      hash: null,
    });
  }

  clearCityInput() {
    this.form.reset();
  }

  onAutocompleteSelected(place: any) {
    if (!place.geometry || !place.geometry.location) {
      return;
    }
    const location = place.geometry.location;
    this.form.get('placeResult')!.setValue(place);
    this.form.get('lat')!.setValue(location.lat());
    this.form.get('lng')!.setValue(location.lng());
    this.dirty = true;
  }

  async markerDragEnd(event: google.maps.MapMouseEvent) {
    const lat = event.latLng!.lat();
    const lng = event.latLng!.lng();
    const address = await this.geocode.getReverseGeocode$(lat, lng).toPromise();
    if (!address) {
      return;
    }

    this.form.get('placeResult')!.setValue(
      {
        name: address.formattedAddress,
        formatted_address: address.formattedAddress,
      });
    this.form.get('lat')!.setValue(address.lat);
    this.form.get('lng')!.setValue(address.lng);
    this.dirty = true;
  }
}
