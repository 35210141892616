<div class="fx-fill file-upload fx-column">
  <label for="fileInput">{{ label }}</label>
  <div class="fx-row button-row">
    <button type="button" class="action-button" mat-flat-button color="primary" (click)="fileInput.click()"
      [disabled]="disabled" [matBadge]="this.files.length" [matBadgeHidden]="this.files.length === 0">
      Upload a File
    </button>
    <p class="file-size">Maximum file size is {{maxUploadSize}}MB</p>
  </div>
</div>

<input type="file" #fileInput class="file-input__input" [accept]="accepts" [multiple]="multiple"
  (change)="onFileInput($event)" />
