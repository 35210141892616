import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { first } from 'rxjs/operators';

import { Topic } from '@index/interfaces/topic';
import { DBUtil } from '../../../../../index/src/lib/utils/db-utils';
import { GthCacheUserService } from '../cache/user.service';

export type TopicRole = 'member' | 'admin' | 'blocked' | 'creator' | 'none';
export interface DBTopicRole {
  user: string;
  role: TopicRole;
}

@Injectable({
  providedIn: 'root',
})
export class GthTopicUserFunctionService {
  constructor(
    private firestore: AngularFirestore,
    private users: GthCacheUserService,
  ) { }


  /**
   * Adds a user to the team
   * @param {string} teamId Id of the team
   * @param {string} topicId Id of the topic
   * @param {string} userId Id of the user being added
   * @param {TopicRole} role Role of the new user
   * @return {Promise<boolean>} true if success
   */
  async addUser(teamId: string, topicId: string, userId: string, role: TopicRole) {
    const topicsRef = await this.firestore
      .collection(`${DBUtil.TeamTopics}/${teamId}/topics/${topicId}/roles`);
    const newRole: DBTopicRole = {
      user: userId,
      role,
    };
    try {
      await topicsRef.add(newRole);
      return true;
    } catch {
      return false;
    }
  }

  /**
   * Adds a user to the team
   * @param {string} teamId Id of the team
   * @param {string} topicId Id of the topic
   * @param {string[]} userIds Id of the user being added
   * @param {TopicRole} role Role of the new user
   * @return {Promise<boolean[]>} true if success
   */
  async addUsers(teamId: string, topicId: string, userIds: string[], role: TopicRole) {
    const requests = userIds.map((u) => this.addUser(teamId, topicId, u, role));
    return Promise.all(requests);
  }

  /**
   * Removes a user by id
   * @param {string} teamId Id of the team
   * @param {string} topicId Id of the topic
   * @param {string} userId Id of the user being removed
   * @return {Promise<boolean>} true if success
   */
  async removeUser(teamId: string, topicId: string, userId: string) {
    const userRef = await this.firestore
      .collection(`${DBUtil.TeamTopics}/${teamId}/topics/${topicId}/roles`,
        (ref) => ref
          .where('user', '==', userId))
      .get()
      .pipe(
        first(),
      )
      .toPromise();

    if (!userRef || userRef.empty || userRef.docs.length === 0) {
      return false;
    }

    const user = userRef.docs[0];
    try {
      await user.ref.delete();
      return true;
    } catch {
      return false;
    }
  }

  async getTopicUsers(topic: Topic) {
    let userIds = [topic.creator];
    userIds = userIds.concat(topic.admins ?? []);
    userIds = userIds.concat(topic.users ?? []);
    return await this.users.getUsersById(userIds);
  }
}
