<h3>TERMS AND CONDITIONS</h3>
<br>
<h4>Welcome to Gametime Hero!</h4>  
<p>Gametime Hero is owned and operated by Gametime Hero, LLC.</p>
<p>These are the terms and conditions for: </p>
<ul>
    <li><strong>Gametime Hero App (Available on Google Play and App Store).</strong></li>  
    <li><a target="_blank" href="https://gametimehero.com">https://gametimehero.com</a></li> 
</ul>
<p>By using the platform, you agree to be bound by these terms and conditions and our privacy policy. In these terms and conditions, the words "platform" refers to the Gametime Hero App and website together, "we", "us", "our" and “Gametime Hero” refers to Gametime Hero and "you" and “user" refers to you, the Gametime Hero user.
</p>
<p>The following terms and conditions apply to your use of the platform. This includes mobile and tablet versions, as well as any other version of Gametime Hero accessible via desktop, mobile, tablet, social media or other devices.
</p>
<p>PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING, POSTING INFORMATION ON, OR OBTAINING ANY INFORMATION FROM GAMETIME HERO.
</p>

<h4>1. ACCEPTANCE OF TERMS </h4>
<p>This agreement sets forth legally binding terms for your use of Gametime Hero. By registering and using the platform, you agree to be bound by this agreement. If you do not accept the terms of this agreement, you should not use the platform and discontinue use of the service immediately. We may modify this agreement from time to time, and such modification shall be effective upon its posting on the platform. You agree to be bound by any modification to these terms and conditions when you use Gametime Hero after any such modification is posted; it is therefore important that you review this agreement regularly.
</p>
<p>Platform access and registration is available to all ages. It is the responsibility of parents and legal guardians to determine whether any of the content is appropriate for their children or minors under guardianship.  
</p>
<p>You represent and warrant that all registration information you submit is accurate and truthful; and that your use of the platform does not violate any applicable law or regulation. Gametime Hero may, in its sole discretion, refuse to offer the platform to any user and change its eligibility criteria at any time. This provision is void where prohibited by law and the right to access the service and the platform is revoked in such jurisdictions.
</p>
<p>By registering and using the platform, you represent and warrant that you have the full right, power and authority to enter into this agreement and to fully perform all of your obligations hereunder. You further represent and warrant that you are under no legal disability or contractual restriction that prevents you from entering into this agreement.
</p>

<h4>2. ACCOUNT </h4>
<p>Users will be able to register on the platform by providing a valid email address and choosing a password. Users may also register and open an account through the user's Google, Facebook or Apple account. You are responsible for maintaining the confidentiality of your password and account information, and are fully responsible for all activities that occur under your password or account. You agree to (a) immediately notify Gametime Hero of any unauthorised use of your password or account or any other breach of security, and (b) ensure that you log out of your account at the end of each session. You may never use another user's account without Gametime Hero’ prior authorisation. Gametime Hero will not be liable for any loss or damage arising from your breach of this agreement.
</p>
<p>Users can cancel their accounts at any time and for any reason through the user's account settings or by sending us their request through our contact information. Such cancellation will only result in the deletion of the account and the deletion of all personal data provided to Gametime Hero.
</p>
<p>Gametime Hero reserves the right to terminate your account or your access immediately, with or without notice, and without liability to you, if Gametime Hero believes that you have violated any of these terms, provided Gametime Hero with false or misleading information, or interfered with any other party's use of the platform or service.
</p>
<p>Users may share information through their profile with other users. Any information that users provide and share through the platform is the sole responsibility of the users themselves. Users are free to provide and share information, but are responsible for the use of such information, its publication and disclosure. Gametime Hero is not responsible for the information provided and shared by users through the platform.
</p>

<h4>3. NOTIFICATIONS AND NEWSLETTER </h4>
<p>By providing Gametime Hero with your email address, you agree that we may use your email address and phone number to send you notifications and other messages such as changes to platform features, news and special content. If you do not wish to receive these emails, you may opt-out of receiving them by submitting your removal request through the contact information or by using the "unsubscribe" option in the emails. Opting out may prevent you from receiving notifications and mailings about news or special content.
</p>

<h4>4. GROUPS AND EVENTS </h4>
<p>Users will be able to create and publish groups and events within the platform related to the world of sports. Users will be able to select the location, duration and specific theme of the group. Users creating the group will be able to select whether the event is public or private. Users can join any public group without user authorization. Private events require authorization from the creating user to join. User creators will be free to accept or reject any user in their private group and will be able to expel any user from the group.
</p>
<p>Users may also create payment groups. The user creator shall be free to determine the entry fee of the payment group. To join a paid group, the user must pay the appropriate entry fee imposed by the group creator. Once the entry fee is paid, the user will have access to the group or event on the terms contained in the event or group. Payments for paid group entries will be processed through our payment processors available on the platform. Payment will be charged to the user's credit/debit card immediately upon completion of the group entry payment process. Once the transaction is processed, we will send an electronic receipt of the transaction to the user's email address.
</p>
<p>Your payment details will be treated and safeguarded securely and for the sole purpose of processing transactions within the platform. Gametime Hero reserves the right to contract any payment processor available on the market, which treats your data for the sole purpose of processing transactions within the platform.
</p>

<h4>5. USER CONTENT </h4>
<p>Some features of the platform may allow users to provide content and information through the creation and publication of groups and events within the platform. The user retains copyright or any rights they may have in the content they provide through the platform. Gametime Hero is not responsible for the accuracy, safety or legality of the content posted on the platform by users. The user is solely and exclusively responsible for its content and the consequences of its publication. By providing content and information through the platform, the user grants Gametime Hero a worldwide, non-exclusive, royalty-free, fully paid right and license to host, store, transfer, display, perform, reproduce and modify the user's content for the sole purpose of displaying it through the platform and to the general public.
</p>

<h4>6. COMMUNITY GUIDELINES </h4>
<p>Gametime Hero is not responsible for the content of the user. The user is solely responsible for the content and the consequences of providing content through the platform. By providing content through the platform, you affirm, represent and warrant that:
</p>
<ul> 
    <li>You are the creator and owner of the content you provide through the platform, or have the necessary licenses, rights, consents and permissions to authorize Gametime Hero to publish and display your content through the platform.
    </li> 
    <li>Your Content does not infringe, violate or misappropriate any third party right, including any copyright, trademark, patent, trade secret, moral right, right of privacy, right of publicity or any other intellectual property or proprietary right.
    </li> 
    <li>Your content does not defame, libel or invade the privacy, publicity or other proprietary rights of any other person.
    </li> 
    <li>Your content does not cause Gametime Hero to violate any law or regulation.
    </li> 
    <li>Your user content could not be considered by a reasonable person to be objectionable, profane, indecent, pornographic, harassing, threatening, embarrassing, hateful or otherwise inappropriate.
</ul>
<p>In addition to the above representations and warranties, the creator is prohibited from providing and posting the following types of content:
</p>
<ul> 
    <li>Content depicting, promoting, facilitating or soliciting (real, simulated or implied) sexual activities with minors, incest, zoophilia, violence, kidnapping, rape, lack of consent, hypnosis, intoxication, sexual assault, torture, sadomasochistic or hard bondage abuse, weapons (unless obviously fake), asphyxiation, extreme fisting, genital mutilation, necrophilia.
    </li>
    <li>Content related to blood, scatology, vomit or excrement.
    </li>
    <li>Any content that includes any individual who has not consented to such content (a) being taken, captured or memorialized in any way, or (b) being posted, uploaded or shared on Gametime Hero. 
    </li>
    <li>Content that depicts or promotes illegal or illicit drugs.
    </li>
    <li>Content that depicts or promotes suicide or self-harm.
    </li>
    <li>Any content that depicts illegal behavior or that may be considered obscene under applicable law.
    </li>
    <li>Any content that depicts any person under the age of eighteen (18).
    </li>
    <li>Any content posted with the intent to extort money or other benefit from a third party in exchange for removal of the content.
    </li>
    <li>Any content that uses or promotes sweepstakes, lotteries or games of chance and gambling.
    </li>
    <li>Any content that violates anti-discrimination laws.
    </li>
    <li>Content posted with the intent to collect phone numbers, addresses, last names, email addresses, URLs, geographic location or any other personal information of users or third parties without their consent.
    </li>
</ul>
<p>Any violation of these terms and conditions or the publication of prohibited content on the platform may result in the removal of the content on the platform and the suspension of the user's account.
</p>

<h4>7. USER CONTENT DISCLAIMER </h4>
<p>Gametime Hero may, at any time and without notice, filter, remove, edit or block any user content that, in our sole judgment, violates these terms and conditions or is otherwise objectionable, including the removal of any group or event that violates our community standards and these terms and conditions. If a user or content owner notifies us that user content allegedly does not conform to these terms, we may investigate the allegation and determine in our sole discretion whether to remove the user content, which we reserve the right to do at any time and without notice. You acknowledge and agree that Gametime Hero reserves the right to, and may from time to time, monitor any and all information transmitted or received through the service for operational and other purposes. If at any time Gametime Hero decides to monitor content, Gametime Hero continues to assume no responsibility or liability for the content or any loss or damage incurred as a result of the use of the content. During monitoring, information may be examined, recorded, copied and used in accordance with our privacy policy.
</p>

<h4>8. DISCLAIMER</h4>
<p>Content posted by users through the creation of groups and events within the platform are not offered or provided by Gametime Hero. Gametime Hero services are limited solely to providing the platform to facilitate the creation and publication of groups or events by users. Gametime Hero is at no time responsible for the content included and available in the groups and events created and published by users. 
</p>
<p> Gametime Hero is not responsible for the accuracy, security or legality of the content posted by users through the creation of groups and events by users. Gametime Hero makes no representations whatsoever about the content posted through the creation of groups and events on the platform by users.
</p>
<p>Disputes arising between users will be resolved by the users themselves. Gametime Hero will not accept any claims in relation to the content posted on the platform by users through groups and events.
</p>
<p>Gametime Hero shall not be liable for any damages caused to the physical or moral integrity of persons, such as injury, death or any other moral damage such as threats, insults and slander that may fall on a natural person, as a result of the communications established on the platform. Communications and relationships established between users as a result of any connection within the platform are the sole and exclusive responsibility of the users.
</p>
<p>In the event that one or more users or any third party initiates any claim or legal action against Gametime Hero, other users or any third party, each and every user involved in such claims or actions exempt Gametime Hero from any liability.
</p>

<h4>9. THIRD-PARTY MATERIALS. </h4>
<p>“Third-Party Materials” means any content, images, videos, texts or other material that is owned by a third party, such as stock images, videos and texts. Such Third-Party Materials are subject to the applicable third-party terms and licenses, and may only be used as permitted by such terms and licenses.
</p>

<h4>10. LICENSE TO USE THE PLATFORM </h4>
<p>Gametime Hero grants you a personal, worldwide, royalty-free, non-assignable and non-exclusive license to use the platform. This license is for the sole purpose of allowing you to use and enjoy the functionality available on the platform, including the functionality to create and publish groups or events in the manner permitted by these terms. You may not copy, modify, distribute, sell or lease any part of the platform or the software contained therein, nor may you reverse engineer or attempt to extract the source code of such software, unless such restrictions are prohibited by law, or you have our written permission.
</p>
<p>The user agrees not to use the platform negligently, for fraudulent purposes or in an unlawful manner. Likewise, the user agrees not to partake in any conduct or action that could damage the image, interests, or rights of the Gametime Hero platform or third parties.
</p>
<p>This service prohibits sending of messages, that: (1) Any kind of messages that are catalogued as SPAM. (2) Are harassing, abusive, defamatory, obscene, in bad faith, unethical or otherwise illegal content (3) distribute trojans, viruses or other malicious computer software (4) Are intending to commit fraud, impersonating other persons, phishing, scams, or related crime (5) distribute intellectual property without ownership or a license to distribute such property (6) Breach, in any way, the terms of service, privacy policy or rules of this web site or the recipients.
</p>
<p>Gametime Hero reserves the right to terminate your access immediately, with or without notice, and without liability to you, if Gametime Hero believes that you have violated any of these terms or interfered with the use of the platform or service by others.
</p>

<h4>11. COPYRIGHT </h4>
<p>All materials on Gametime Hero, including, without limitation, names, logos, trademarks, images, text, columns, graphics, videos, photographs, illustrations, software and other elements are protected by copyrights, trademarks and/or other intellectual property rights owned and controlled by Gametime Hero or by third parties that have licensed or otherwise provided their material to the platform. You acknowledge and agree that all materials on Gametime Hero are made available for limited, non-commercial, personal use only. Except as specifically provided herein. No material may be copied, reproduced, republished, sold, downloaded, posted, transmitted, or distributed in any way, or otherwise used for any purpose, by any person or entity, without Gametime Hero prior express written permission. You may not add, delete, distort, or otherwise modify the material. Any unauthorized attempt to modify any material, to defeat or circumvent any security features, or to utilize Gametime Hero or any part of the material for any purpose other than its intended purposes is strictly prohibited.
</p>
<p>Users may share content through the platform. By providing and sharing your content through Gametime Hero, you agree and consent that your content may be publicly displayed on the platform and may be shared by other users of the platform. By storing and sharing your content and that of other users, you also agree not to modify or remove, directly or indirectly, any copyright, trade name, service mark, trademark or any other property appearing in the content available on Gametime Hero. Any alteration or use of content outside the guidelines of these terms violates intellectual property rights and may be subject to claims or lawsuits. By accessing our platform, you do not have any right or title to the content available or other intellectual property.
</p>

<h4>12. COPYRIGHT INFRINGEMENT (DMCA) </h4>
<p> Gametime Hero will respond to all inquiries, complaints and claims regarding alleged infringement for failure to comply with or violation of the provisions contained in the Digital Millennium Copyright Act (DMCA). Gametime Hero respects the intellectual property of others, and expects users to do the same.  If you believe, in good faith, that any material provided on or in connection with the website infringes your copyright or other intellectual property right, please send us your copyright infringement request pursuant to Section 512 of the Digital Millennium Copyright Act (DMCA), via our contact information, with the following information:  
<ul>
    <li>Identification of the intellectual property right that is allegedly infringed. All relevant registration numbers or a statement regarding ownership of the work should be included.
    </li>
    <li> A statement that specifically identifies the location of the infringing material, in sufficient detail so that Gametime Hero can find it on the platform. 
    </li>
    <li> Your name, address, telephone number and email address.
    </li>
    <li> A statement by you that you have a good faith belief that the use of the allegedly infringing material is not authorized by the copyright owner, or its agents, or by law.
    </li>
    <li> A statement by you, made under penalty of perjury, that the information in your notification is accurate, and that you are the copyright owner or authorized to act on its behalf.
    </li>
    <li> An electronic or physical signature of the copyright owner or of the person authorized to act on the copyright owner's behalf.
    </li>
</ul>
<p>Upon receipt of a request for copyright infringement under the Copyright Act, Gametime Hero will contact the allegedly infringing user so that the user may respond to the request in accordance with the terms contained in the Digital Millennium Copyright Act.
</p>
<p>Responses to copyright infringement requests must contain the following:
</p>
<ul>
    <li> The physical or electronic signature of the user;
    </li>
    <li> The identification of the content that has been removed or the place where the content was posted;
    </li>
    <li> A statement, under oath, indicating a good faith belief that the content or material was removed due to an error.
    </li>
    <li> The name, address and telephone number of the user; and 
    </li>
    <li> A statement that the user consents to the jurisdiction of the court in which the user is located.
    </li>
</ul>
<p> All copyright infringement requests and responses may be submitted through our contact information.
</p>

<h4>13. PERSONAL DATA </h4>
<p>Any personal information you submit in connection with the use of the platform will be used in accordance with our privacy policy. Please see our privacy policy.
</p>

<h4>14. PROHIBITED ACTIVITIES </h4>
<p>The content and information available on the platform (including, but not limited to, data, information, text, music, sound, photos, graphics, video, maps, icons or other material), as well as the infrastructure used to provide such content and information, is proprietary to Gametime Hero or licensed to the Gametime Hero by third parties. For all content other than your content, you agree not to otherwise modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell or re-sell any information, software or content obtained from or through the platform. In addition, the following activities are prohibited:
</p>
<ul>
    <li>Use the platform or content for any commercial purpose, outside the scope of those commercial purposes explicitly permitted under this agreement and related guidelines as made available by Gametime Hero.
    </li>
    <li>Access, monitor, reproduce, distribute, transmit, broadcast, display, sell, license, copy or otherwise exploit any content of the platform, including but not limited to, using any robot, spider, scraper or other automated means or any manual process for any purpose not in accordance with this agreement or without our express written permission.
    </li>
    <li>Take any action that imposes, or may impose, in our discretion, an unreasonable or disproportionately large load on our infrastructure.
    </li>
    <li>Deep-link to any portion of the platform for any purpose without our express written permission.
    </li>
    <li>"Frame", "mirror" or otherwise incorporate any part of the platform into any other platforms or service without our prior written authorization.
    </li>
    <li>Attempt to modify, translate, adapt, edit, decompile, disassemble, or reverse engineer any software programs used by Gametime Hero.
    </li>
    <li>Circumvent, disable or otherwise interfere with security-related features of the platform or features that prevent or restrict use or copying of any content.
    </li>
</ul>

<h4>15. DISCLAIMER OF WARRANTIES </h4>
<p>Because of the nature of the Internet Gametime Hero provides and maintains the platform on an "as is", "as available" basis and makes no promise that use of the platform will be uninterrupted or entirely error free. 
</p>
<p>Our platform may from time to time contain links to other platforms which are not under the control of and are not maintained by us. These links are provided for your convenience only and we are not responsible for the content of those platforms.
</p>
<p>Except as provided above we can give no other warranties, conditions or other terms, express or implied, statutory or otherwise and all such terms are hereby excluded to the maximum extent permitted by law.
</p>
<p>You will be responsible for any breach of these terms by you and if you use the platform in breach of these terms you will be liable to and will reimburse Gametime Hero for any loss or damage caused as a result.
</p>
<p>Gametime Hero will not be liable in any amount for failure to perform any obligation under this agreement if such failure is caused by the occurrence of any unforeseen event beyond its reasonable control including without limitation Internet outages, communications outages, fire, flood, war or act of God.
</p>
<p>These terms do not affect your statutory rights as a consumer which are available to you.
</p>
<p>Subject as aforesaid, to the maximum extent permitted by law, Gametime Hero excludes liability for any loss or damage of any kind howsoever arising, including without limitation any direct, indirect or consequential loss whether or not such arises out of any problem you notify to Gametime Hero and Gametime Hero shall have no liability to pay any money by way of compensation, including without limitation all liability in relation to:
<ul>
    <li>Any incorrect or inaccurate information on the platform.
    </li>
    <li>The infringement by any person of any Intellectual Property Rights of any third party caused by their use of the platform.
    </li>
    <li>Any loss or damage resulting from your use or the inability to use the platform or resulting from unauthorized access to, or alteration of your transmissions or data in circumstances which are beyond our control.
    </li>
    <li>Any loss of profit, wasted expenditure, corruption or destruction of data or any other loss which does not directly result from something we have done wrong.
    </li>
    <li>Any amount or kind of loss or damage due to viruses or other malicious software that may infect a user's computer equipment, software, data or other property caused by persons accessing or using content from the platform or from transmissions via emails or attachments received from Gametime Hero.
    </li>
    <li>All representations, warranties, conditions and other terms which but for this notice would have effect.
    </li>
</ul>   

<h4>16. ELECTRONIC COMMUNICATIONS </h4>
<p>No responsibility will be accepted by Gametime Hero for failed, partial or garbled computer transmissions, for any computer, telephone, cable, network, electronic or internet hardware or software malfunctions, failures, connections, availability, for the acts or omissions of any user, internet accessibility or availability or for traffic congestion or unauthorized human act, including any errors or mistakes. 
</p>

<h4>17. THIRD PARTIES </h4>
<p>Through your use of the platform, you may encounter links to third party sites or be able to interact with third party sites. Such third parties may charge a fee for use of certain content or services provided on or by way of their platforms. Therefore, you should make whatever investigation you feel is necessary or appropriate before proceeding with any transaction with any third party to determine whether a charge will be incurred. Where Gametime Hero provide details of fees or charges for such third-party content or services, such information is provided for convenience and information purposes only. Any interactions with third party sites and apps are at your own risk. You expressly acknowledge and agree that Gametime Hero are in no way responsible or liable for any such third-party sites.
</p>

<h4>18. INDEMNIFICATION </h4>
<p>You agree to defend and indemnify Gametime Hero from and against any claims, causes of action, demands, recoveries, losses, damages, fines, penalties or other costs or expenses of any kind or nature including but not limited to reasonable legal and accounting fees, brought by third parties as a result of:
</p>
<ul>
    <li>Your breach of this agreement or the documents referenced herein.
    </li>
    <li>Your violation of any law or the rights of a third party.
    </li>
    <li>Your use of the Gametime Hero platform.
    </li>
</ul>

<h4>19. CHANGES AND TERMINATION </h4>
<p>We may change the platform and these terms at any time, in our sole discretion and without notice to you. You are responsible for remaining knowledgeable about these terms. Your continued use of the platform constitutes your acceptance of any changes to these terms and any changes will supersede all previous versions of the terms. Unless otherwise specified herein, all changes to these terms apply to all users take effect. Furthermore, we may terminate this agreement with you under these terms at any time by notifying you in writing (including by email) or without any warning.
</p>

<h4>20. ASSIGNMENT </h4>
<p>This agreement and any rights and licenses granted hereunder, may not be transferred or assigned by you, but may be assigned by Gametime Hero without restriction.
</p>

<h4>21. INTEGRATION CLAUSE </h4>
<p>This agreement together with the privacy policy and any other legal notices published by Gametime Hero, shall constitute the entire agreement between you and Gametime Hero concerning and governs your use of the platform.
</p>

<h4>22. DISPUTES </h4>
<p>The user agrees that any dispute, claim or controversy arising out of or relating to these terms and conditions, or the breach, termination, enforcement, interpretation or validity thereof or the use of the platform, shall be resolved by binding arbitration between the user and Gametime Hero, provided that each party retains the right to bring an individual action in a court of competent jurisdiction.
</p>
<p>In the event that a dispute arises in connection with the use of the platform or breach of these terms and conditions, the parties agree to submit their dispute to arbitration resolution before a reputable arbitration organization as mutually agreed by the parties and in accordance with applicable commercial arbitration rules.
</p>
<p>You agree to initiate a formal dispute proceeding by sending us a communication through our contact information. Gametime Hero may choose to send you a written offer after receiving your initial communication. If we offer and send you a settlement offer and you do not accept the offer, or we are unable to resolve your dispute satisfactorily and you wish to continue with the dispute process, you must initiate the dispute resolution process before an accredited arbitration organization and file a separate Demand for Arbitration. Any award rendered by the arbitration tribunal shall be final and conclusive on the parties. 
</p>
<p>To the fullest extent permitted by law, you agree that you will not file, join or participate in any class action lawsuit in connection with any claim, dispute or controversy that may arise in connection with your use of the platform.
</p>
<p>The courts of the United States, specifically the courts located in your State, shall have jurisdiction over any dispute, controversy or claim relating to Gametime Hero and its business operations. Any such dispute or controversy shall be brought and resolved in the courts of the United States, specifically the courts located in your State.
</p>

<h4>23. FINAL PROVISIONS </h4>
<p>These terms and conditions are governed by international law. Use of the Gametime Hero platform is unauthorized in any jurisdiction that does not give effect to all provisions of these terms and conditions.
</p>
<p>Our performance of these terms is subject to existing laws and legal process, and nothing contained in these terms limits our right to comply with law enforcement or other governmental or legal requests or requirements relating to your use of our platform or information provided to or gathered by us with respect to such use.
</p>
<p>If any part of these terms is found to be invalid, illegal or unenforceable, the validity, legality and enforceability of the remaining provisions will not in any way be affected or impaired. Our failure or delay in enforcing any provision of these terms at any time does not waive our right to enforce the same or any other provision(s) hereof in the future.
</p>
<p>Any rights not expressly granted herein are reserved.
</p>

<h4>24. CONTACT INFORMATION </h4>
<p>If you have questions or concerns about these terms, please contact us through our contact page or via the contact information below:
</p>
<p><strong>Gametime Hero, LLC.</strong></p>
<br>