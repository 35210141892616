import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { EventItemGuest } from '@index/interfaces';
import { GthGenderModel, GthEventItemForm } from '@sentinels/models';
import { SrvApiService } from '@sentinels/services/api.service';

@Component({
  selector: 'gth-rsvp-with-guest-dialog',
  templateUrl: './rsvp-with-guest-dialog.component.html',
  styleUrls: ['./rsvp-with-guest-dialog.component.scss'],
})
export class GthRsvpWithGuestDialogComponent implements OnInit {
  genders: GthGenderModel[] = [];

  private eventItemForm = new GthEventItemForm();
  @Input() buttonText = 'ASK TO JOIN GAME';

  constructor(
    private api: SrvApiService,
    private dialogRef: MatDialogRef<GthRsvpWithGuestDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      buttonText?: string,
      guests: EventItemGuest[],
    },
  ) { }

  async ngOnInit() {
    this.genders = await this.api.genders.listAsync();

    if (this.data?.buttonText) this.buttonText = this.data?.buttonText;
    const guests = this.data?.guests;
    if (guests) {
      for (const guest of guests) {
        this.eventItemForm.specificGenders.push({ type: guest.type, number: guest.number });
      }
    }
  }

  get specificGendersArray(): EventItemGuest[] {
    if (!this.eventItemForm.specificGenders) return [];

    return this.eventItemForm.specificGenders;
  }

  get isGenderSpecific() {
    return this.eventItemForm.isGenderSpecific;
  }

  specificTypeChange(index: number, el: MatSelectChange) {
    const gendersArray = this.specificGendersArray;
    gendersArray[index].type = el.value;
    this.onGenderSpecificChange();
  }

  onGenderSpecificChange() {
    this.eventItemForm.isGenderSpecificChanged();
    if (this.isGenderSpecific) {
      this.eventItemForm.disableNumberOfPlayers();
    } else {
      this.eventItemForm.enableNumberOfPlayers();
    }
    this.eventItemForm.numberOfPlayers = this.specificNumberTotal();
  }

  specificNumberTotal() {
    let total = 0;
    this.specificGendersArray.forEach(({ number }) => {
      total = total + number;
    });
    return total;
  }

  specificNumberChange(index: number, src: EventTarget) {
    const gendersArray = this.specificGendersArray;
    gendersArray[index].number = parseInt((src as HTMLInputElement).value);
    if (gendersArray[index].number < 0) {
      gendersArray[index].number = 0;
    }
    this.onGenderSpecificChange();
  }

  removePlayerType(index: number) {
    this.specificGendersArray.splice(index, 1);
    this.onGenderSpecificChange();
  }

  addSpecificGender() {
    this.eventItemForm.addSpecificGender();
  }

  onAskToJoinBtnClick() {
    const guests: EventItemGuest[] = [];
    for (const guest of this.specificGendersArray) {
      if (guest.number) guests.push(guest);
    }

    this.dialogRef.close(guests);
  }
}
