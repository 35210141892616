
<div *ngIf="!joiners || joiners.length === 0">
    No one has joined this event Yet!
</div>
<ng-container *ngIf="joiners">
    <mat-list class="list">
        <ng-container *ngFor="let joiner of joiners; let i = index">
            <mat-list-item *ngIf="joiner.user as player" class="list-item">
                <div class="fx-row">
                    <div class="fx-auto fx-fill
                    content">
                        <h3>{{ player.displayNameFallback }} </h3> 
                    </div>
                    <div class="fx-auto fx-fill content">
                        <ng-container *ngIf="editingIndex !== i">
                            <p> {{ joiner.rsvpStatus }}</p> 
                            <button mat-icon-button class="icon" (click)="onEdit(i)">
                                <mat-icon >edit</mat-icon>
                            </button>
                        </ng-container>
                        <ng-container *ngIf="editingIndex === i">
                            <div class="fx-centered">
                                <mat-form-field>
                                    <mat-label>Select Rsvp level</mat-label>
                                    <mat-select class="select" [(ngModel)]="selectedOption">
                                        <mat-option 
                                            *ngFor="let status of statuses " 
                                            [value]="status.value">{{status.viewValue}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <button class="center-btn"
                                mat-raised-button 
                                [disabled]="!selectedOption"
                                (click)="onSaveJoiner(joiner)"
                                color="primary">Save</button>
                        </ng-container>
                    </div>

                    <div class="fx-row action-container">
                        <button mat-icon-button class="nav-btn" (click)="onMessageButtonClick(joiner)"
                            matTooltip="Message Participant">
                            <mat-icon>message</mat-icon>
                        </button>
                    </div>
                </div>
            </mat-list-item>
        </ng-container>
    </mat-list>
</ng-container>
<!-- 

<div class="add-user-container">
    <mat-form-field class="email-field">
        <input matInput placeholder="Enter Email to Add" [(ngModel)]="newUserEmail">
    </mat-form-field>
    <mat-form-field class="status-field">
        <mat-label>Select Rsvp level</mat-label>
        <mat-select [(ngModel)]="newUserSelectedOption">
            <mat-option *ngFor="let status of statuses" [value]="status.value">{{status.viewValue}}</mat-option>
        </mat-select>
    </mat-form-field>
    <button mat-raised-button color="primary" (click)="addUser()">Add</button>
</div>

  -->