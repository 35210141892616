import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { GthFileUploadService } from '../../services/file-upload.service';

@Component({
  selector: 'gth-stored-image',
  templateUrl: './stored-image.component.html',
  styleUrls: ['./stored-image.component.scss'],
})
export class GthStoredImageComponent implements OnInit {
  @Input()
  id = '';

  @Output()
  imageError = new EventEmitter<void>();

  imagePath$?: Observable<string>;

  constructor(private store: GthFileUploadService) {

  }

  ngOnInit(): void {
    this.imagePath$ = this.store.getById$(this.id).pipe(map((i) => i.path));
  }

  onImageError() {
    this.imageError.emit();
  }
}
