import {
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { GthFileUploadService } from '../../services/file-upload.service';

@Component({
  selector: 'gth-stored-video',
  templateUrl: './stored-video.component.html',
  styleUrls: ['./stored-video.component.scss'],
})
export class GthStoredVideoComponent implements OnInit {
  @ViewChild('videoPlayer', { static: false })
  private videoPlayer: ElementRef<HTMLVideoElement>;

  @Input()
  id = '';

  videoPath$?: Observable<string>;
  playing = false;

  constructor(private store: GthFileUploadService) {

  }

  ngOnInit(): void {
    this.videoPath$ = this.store.getById$(this.id).pipe(map((i) => i.path));
  }

  onVideoClick(evt: MouseEvent) {
    evt.stopPropagation();
    if (!this.videoPlayer || !this.videoPlayer.nativeElement) {
      return;
    }
    const videoPlayer = this.videoPlayer.nativeElement;
    if (this.playing) {
      videoPlayer.pause();
    } else {
      videoPlayer.load();
      videoPlayer.play();
    }
    this.playing = !this.playing;
  }

  onPlayButtonClick(evt: MouseEvent) {
    this.onVideoClick(evt);
  }
}
