import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

import {
  Payload,
  Results,
} from '@index/interfaces';
import { CallableOptions, CallableRoutes, GthCallableService } from './callable.service';
import { GthErrorLoggerService } from './error-logger.service';
import { GthBadgeModel } from '../../../../../sentinels/src/lib/models/badge';

const CONTEXT = 'GthBadgeFunctionService';

@Injectable({ providedIn: 'root' })
export class GthBadgeFunctionService {
  constructor(
    public functions: AngularFireFunctions,
    public callableService: GthCallableService,
    private logger: GthErrorLoggerService,
  ) { }

  /**
   * Fetch a badge by id
   * @param {string} id - The id of the badge
   * @return {GthBadgeModel} - Badge if it exists
   */
  public getBadgeById$(id: string): Observable<GthBadgeModel | null> {
    return from(this.getBadgeById(id));
  }

  /**
   * Fetches a list of badges
   * @return {GthBadgeModel[]} - List of badges
   */
  public getBadges$(): Observable<GthBadgeModel[] | null> {
    return from(this.getBadges());
  }

  private async getBadgeById(id: string): Promise<GthBadgeModel | null> {
    if (!this.functions) return Promise.resolve(null);

    const options: CallableOptions = {
      route: CallableRoutes.BADGES_READ,
      data: { id },
    };

    const response = (await this.callableService.call(options)) as Results<GthBadgeModel>;

    if (response.success && response.payload) {
      const result = response.payload as Payload<GthBadgeModel>;
      return result.data;
    }

    return null;
  }

  private async getBadges(): Promise<GthBadgeModel[] | null> {
    if (!this.functions) return Promise.resolve(null);

    const options: CallableOptions = {
      route: CallableRoutes.BADGES_LIST,
      data: {},
    };

    const response = (await this.callableService.call(options)) as Results<GthBadgeModel[]>;

    if (response.success && response.payload) {
      const badges = (response.payload as unknown as Payload<GthBadgeModel>[])
        .map((p) => p.data);
      this.log(`Badges fetched`);
      return badges ?? [];
    }

    return null;
  }

  private log(text: string) {
    this.logger.debug(`${CONTEXT}: ${text}`);
  }
}
