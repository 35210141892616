// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { DB_API_KEY, STRIPE_API_KEY } from '../keys';

const firebaseConfig = {
  apiKey: DB_API_KEY,
  authDomain: 'gametimehero-dev.firebaseapp.com',
  databaseURL: 'https://gametimehero-dev-default-rtdb.firebaseio.com',
  projectId: 'gametimehero-dev',
  storageBucket: 'gametimehero-dev.appspot.com',
  messagingSenderId: '714560815466',
  appId: '1:714560815466:web:f57f5acc4764077cc7e513',
  measurementId: 'G-CLN2Q34155',
  host: 'localhost:8080',
  ssl: false,
};


export const environment = {
  envName: 'dev',
  production: false,
  useEmulators: false,
  googleMaps: DB_API_KEY,
  firebase: firebaseConfig,
  stripe: STRIPE_API_KEY,
  root: 'https://gametimehero-dev.web.app',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such
 * as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production
 * mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
