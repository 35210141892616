import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
  ValidatorFn,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';

import { EventRepeatType } from '@index/interfaces';
import { GthTimeService } from '../../services/time.service';

export interface TimeSelectionContract {
  startDate: Date;
  durationHours: number;
  durationMinutes: number;
  recurrence: EventRepeatType;
}

interface TimeSelectionRepeatType {
  value: EventRepeatType;
  name: string;
}

const REPEAT_TYPES: TimeSelectionRepeatType[] = [
  {
    value: EventRepeatType.None,
    name: 'Does not repeat',
  },
  {
    value: EventRepeatType.Daily,
    name: 'Daily',
  },
  {
    value: EventRepeatType.Weekly,
    name: 'Weekly',
  },
  {
    value: EventRepeatType.Monthly,
    name: 'Monthly',
  },
  {
    value: EventRepeatType.Annually,
    name: 'Annually',
  },
  {
    value: EventRepeatType.EveryWeekday,
    name: 'Every weekday (Monday to Friday)',
  },
];

@Component({
  templateUrl: './time-selection.component.html',
  styleUrls: ['./time-selection.component.scss'],
  standalone: true,
   imports: [
     CommonModule,
     FormsModule,
     ReactiveFormsModule,
     MatInputModule,
     MatFormFieldModule,
     MatDatepickerModule,
     MatNativeDateModule,
     MatButtonModule,
     MatSelectModule,
     MatDialogModule,
   ],
})
export class TimeSelectionComponent implements OnInit {
  /**
   * Returns true if the address form is valid
   */
  get isFormValid() {
    return this._timeForm.valid;
  }

  _repeatTypes = REPEAT_TYPES;
  _timeOptions: any[] = [];

  _timeForm = new UntypedFormGroup({
    startDate: new UntypedFormControl('', [Validators.required, this.dateRangeValidator()]),
    startTime: new UntypedFormControl(''),
    durationHours: new UntypedFormControl('', Validators.required),
    durationMinutes: new UntypedFormControl('', [
      Validators.min(0),
      Validators.max(59),
    ]),
    recurrence: new UntypedFormControl(EventRepeatType.None),
  });

  constructor(
    private dialogRef: MatDialogRef<TimeSelectionComponent>,
    private time: GthTimeService,
  ) { }

  _onSaveButtonClick() {
    const value = this._timeForm.getRawValue();
    const startDate = value.startDate as Date;
    const durationHours = value.durationHours as number;
    const durationMinutes = value.durationMinutes as number;
    const contract = {
      // eslint-disable-next-line max-len
      startDate: new Date(
        // eslint-disable-next-line max-len
        `${startDate.getMonth() + 1}/${startDate.getDate()}/${startDate.getFullYear()} ${value.startTime
        }`,
      ),
      // eslint-disable-next-line max-len
      durationHours: durationHours,
      durationMinutes: durationMinutes ? durationMinutes : 0,
      recurrence: value.recurrence,
    };
    this.dialogRef.close(contract);
  }

  onCancelButtonClick() {
    this.dialogRef.close('cancel');
  }

  ngOnInit() {
    this._timeOptions = this.time.getTimeOptions();
  }

  dateRangeValidator(): ValidatorFn {
    return (control) => {
      if (!control.value) return null;

      const today = new Date();
      const dateValue = new Date(control.value);

      if (dateValue.getTime() < today.getTime()) {
        return { message: 'Date cant be in the past' };
      }

      return null;
    };
  }
}
