import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NgIf } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { APP_ROUTES } from '@shared/helpers';
import { EventInfoComponent, JoinEventOptions } from '../../components/event-info/event-info.component';
import { GthEventItemModel, GthTeamModel, GthUserModel } from '@sentinels/models';
import { EventInfoDialogCloseMethod } from '@sentinels/enums';

export interface EventInfoDialogOpenContract {
  event: GthEventItemModel;
  user: GthUserModel;
  team?: GthTeamModel;
  platform: 'gth' | 'meh',
}

export interface EventInfoDialogCloseContract {
  useSocialMedia: boolean;
  selectedImage: ElementRef | null;
  closeMethod: EventInfoDialogCloseMethod;
}

@Component({
  selector: 'gth-event-info-dialog',
  templateUrl: './event-info-dialog.component.html',
  styleUrls: ['./event-info-dialog.component.scss'],
  standalone: true,
  imports: [
    EventInfoComponent,
    MatButtonModule,
    MatIconModule,
    NgIf,
    MatProgressSpinnerModule,
  ],
})
export class EventInfoDialogComponent implements OnInit {
  loading = true;
  get event() {
    return this.data ? this.data.event : null;
  }

  get user() {
    return this.data ? this.data.user : null;
  }

  get team() {
    return this.data ? this.data.team : null;
  }

  get platform() {
    return this.data ? this.data.platform : 'gth';
  }

  get urlPath() {
    return this.data ? `${APP_ROUTES.DiscoverGames}/${this.event.id}` : '';
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: EventInfoDialogOpenContract,
    private dialogRef: MatDialogRef<EventInfoDialogComponent>,
  ) {}

  ngOnInit() {
    this.loading = false;
  }

  onJoinEvent(joinOptions: JoinEventOptions) {
    console.debug('EventInfoDialogComponent: join event');
    this.dialogRef.close(this.dialogCloseContract(EventInfoDialogCloseMethod.SAVE));
  }

  onEditEvent(event: GthEventItemModel) {
    console.debug('EventInfoDialogComponent: edit event', event);
    this.dialogRef.close(this.dialogCloseContract(EventInfoDialogCloseMethod.UPDATE));
  }

  onDeclineInvite(event: GthEventItemModel) {
    console.debug('EventInfoDialogComponent: decline invite to event', event);
    this.dialogRef.close(this.dialogCloseContract(EventInfoDialogCloseMethod.LEAVE));
  }

  onCancelEvent(event: GthEventItemModel) {
    console.debug('EventInfoDialogComponent: cancel event', event);
    this.dialogRef.close(this.dialogCloseContract(EventInfoDialogCloseMethod.CANCEL));
  }

  onLeaveEvent(event: GthEventItemModel) {
    console.debug('EventInfoDialogComponent: leave event', event);
    this.dialogRef.close(this.dialogCloseContract(EventInfoDialogCloseMethod.LEAVE));
  }

  dialogCloseContract(
    method: EventInfoDialogCloseMethod = EventInfoDialogCloseMethod.OTHER,
    useSocialMedia = false,
  ): EventInfoDialogCloseContract {
    return {
      useSocialMedia,
      selectedImage: null,
      closeMethod: method,
    };
  }

  onClose() {
    this.dialogRef.close();
  }
}
