import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

/**
 * TODO rkara-ssr-defect
 * This file does not work appropriately while built in SSR.
 */

@Injectable({
  providedIn: 'root',
})
export class SrvSafeStorageService {
  private store?: Storage;
  constructor(
    @Inject(DOCUMENT)
    document: Document,
  ) {
    this.store = document.defaultView?.localStorage;
  }

  setItem(key: string, value: string): void {
    if (this.store) {
      this.store.setItem(key, value);
    }
  }

  removeItem(key: string): void {
    if (this.store) {
      this.store.removeItem(key);
    }
  }

  getItem(key: string): string | null {
    if (this.store) {
      return this.store.getItem(key);
    } else {
      return null;
    }
  }

  clear(): void {
    if (this.store) {
      this.store.clear();
    }
  }
}
