export enum NotificationType {
  /** Event Notifications */
  EVENT_JOINER_APPROVED = 'eventJoinerApproved',
  EVENT_JOINER_DENIED = 'eventJoinerDenied',
  EVENT_JOINER_DROPPED = 'eventJoinerDropped',
  EVENT_JOINER_PENDING_JOINER = 'eventJoinerPendingJoiner',
  EVENT_JOINER_PENDING_CREATOR = 'eventJoinerPendingCreator',
  EVENT_FULL = 'eventFull',
  EVENT_JOINER_WAITLIST = 'eventJoinerWaitList',
  EVENT_CANCELLED = 'eventCancelled',
  EVENT_UPCOMING = 'eventUpcoming',

  /** Badge Notifications */
  NEW_BADGE= 'newBadge',

  /** Message Notifications */
  NEW_MESSAGE = 'newMessage',

  /** Team Notifications */
  TEAM_REMINDER = 'teamReminder',
  TEAM_JOINER_PENDING_JOINER = 'teamJoinerPendingJoiner',
  TEAM_JOINER_APPROVED = `teamJoinerApproved`,
  TEAM_JOINER_DENIED = `teamJoinerDenied`,
  TEAM_JOINER_DROPPED = 'teamJoinerDropped',
  TEAM_JOINER_PENDING_CREATOR = 'teamJoinerPendingCreator',

  /** Rating Notifications */
  RATE_PLAYER = 'ratePlayer',
  RATE_HOST = 'rateHost',
}
