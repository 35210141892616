<mat-progress-bar *ngIf="createLoading || linkLoading" mode="indeterminate" color="primary" />

<div class="dialog fx-fill fx-column" *ngIf="(user | async) as user ">
  <h1 mat-dialog-title class="title">Connect to Stripe</h1>

  <mat-dialog-content class="content">
    <p>Connect Your Stripe Account to start receiving payments from events</p>
    <p class="mat-caption">This usually takes no more than 5 minutes.</p>
    <mat-form-field appearance="outline">
      <mat-label>Home Country</mat-label>
      <mat-select id="country-dropdown" [(value)]="selectedCountry">
        <mat-option *ngFor="let country of getCountries()" [value]="country.code">
          {{ country.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

  </mat-dialog-content>

  <mat-dialog-actions class="fx-row actions">
    <button mat-flat-button color="primary" class="btn-brand-green fx-auto" [disabled]="createLoading || linkLoading"
      (click)="linkOrCreateStripeAccount(createType.NEW)">
      <ng-container *ngIf="createLoading else showCreateText">
        <mat-progress-spinner mode="indeterminate" color="accent" [diameter]="25" />
      </ng-container>
      <ng-template #showCreateText>Create Stripe Account</ng-template>
    </button>
    <button mat-flat-button color="primary" class="btn-brand-green fx-auto" [disabled]="linkLoading || createLoading"
      (click)="linkOrCreateStripeAccount(createType.LINK)">
      <ng-container *ngIf="linkLoading else showLinkText">
        <mat-progress-spinner mode="indeterminate" color="accent" [diameter]="25" />
      </ng-container>
      <ng-template #showLinkText>Link Existing Stripe Account</ng-template>
    </button>
  </mat-dialog-actions>
</div>