export const APP_ROUTES = {
  Root: '/',
  Home: '/home',
  Admin: '/admin',
  Login: '/login',
  SignUp: '/sign-up',
  ForgotPassword: '/forgot-password',
  DiscoverGames: '/discover/games',
  CreateGame: '/create',
  Profile: '/profile',
  MoreInfo: '/more-info',
  Messages: '/messages',
  Teams: '/teams',
  Team: '/team',
  DiscoverPlayers: '/discover/players',
  Settings: '/settings',
  DiscoverTeams: '/discover/teams',
  CustomWebsite: '/custom-website',
  CreateTeam: '/teams/create',
  NewFeature: 'https://forms.gle/CAhCjH169VW6jy3q8',
  NewBug: 'https://forms.gle/DrSfkn7MrBrKX5Ft6',
  PaymentSuccess: '/payment-success',
  Transactions: '/transactions',
  ExternalTeams: '/external-teams',
  Auth: '/auth',
};

export const TEAMS_ROUTES = {
  DASHBOARD: 'dashboard',
  ROSTER: 'roster',
  SCHEDULE: 'schedule',
  RSVPS: 'rsvps',
  UPLOADS: 'uploads',
  ANNOUNCEMENTS: 'announcements',
  ADMIN: 'admin',
  WEBSITE: 'website',
  SURVEY: 'survey',
  CREATE_SURVEY: 'survey/create',
  SURVEY_FORM: (formId: string) => `${formId}/form`,
  TOPICS: 'topics',
  INVOICES: 'invoices',
  REVIEWS: 'reviews',
};
