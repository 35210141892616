import { Component, ElementRef, Input, Renderer2, ViewChild } from '@angular/core';
import { AvatarComponent, CloseMethod, DialogClosedProps } from '@shared/dialogs/avatar/avatar.component';
import { MatDialog } from '@angular/material/dialog';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-select-avatar-button',
  templateUrl: './select-avatar-button.component.html',
  styleUrls: ['./select-avatar-button.component.scss'],
})
export class SelectAvatarButtonComponent {
  @Input() photoUrlFromSocialMedia: string | null = null;
  @Input() avatarFormControl: AbstractControl;
  @ViewChild('avatarIconButton', { read: ElementRef, static: false })
  avatarIconButton!: ElementRef;
  constructor(
    private dialog: MatDialog,
    private render: Renderer2,
    ) {}
  openDialog() {
    const dialogRef = this.dialog.open(AvatarComponent, {
      width: '600px',
      height: '675px',
      data: {
        showSocialMediaButton: !!this.photoUrlFromSocialMedia,
      },
      backdropClass: 'gth-overlay-backdrop',
    });

    dialogRef.afterClosed().subscribe((result: DialogClosedProps | null) => {
      // Don't change image if close method wasn't CloseMethod.SAVE
      if (!result || result.closeMethod !== CloseMethod.SAVE) return;

      // Change image to one from social media account ;)
      if (result.useSocialMedia && this.photoUrlFromSocialMedia) {
        this.avatarFormControl?.setValue(this.photoUrlFromSocialMedia);
        return;
      }

      // Don't change image if no image ;)
      if (!result.selectedImage) return;
      this.avatarFormControl?.setValue(result.selectedImage);
    });
  }

  showIcon() {
    this.render.removeClass(this.avatarIconButton.nativeElement, 'hidden');
  }

  hideIcon() {
    if (!this.avatarFormControl?.value) return;
    this.render.addClass(this.avatarIconButton.nativeElement, 'hidden');
  }
  getUrl() {
    const url = this.avatarFormControl?.value;
    if (!url) return 'none';
    return `url(${this.avatarFormControl?.value})`;
  }
}
