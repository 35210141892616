import { Component } from '@angular/core';


@Component({
  selector: 'gth-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.scss'],
})
export class GthTermsConditionsComponent {


}
