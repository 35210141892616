import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { GthStoredVideoComponent } from './stored-video.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    AngularFireStorageModule,
    MatButtonModule,
    MatIconModule,
  ],
  exports: [GthStoredVideoComponent],
  declarations: [GthStoredVideoComponent],
})
export class GthStoredVideoModule { }
