import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { from, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

import {
  CreateTeamRequest,
  ExternalTeamRatingItem,
  TeamRating,
  Payload,
  Results,
  Team,
  TeamListRequest,
  UpdateTeamRequest,
} from '@index/interfaces';
import { CallableOptions, CallableRoutes, GthCallableService } from './callable.service';
import { GthErrorLoggerService } from './error-logger.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';

const CONTEXT = 'GthTeamFunctionService';
const COLLECTION_NAME = 'teams';

@Injectable({
  providedIn: 'root',
})
export class GthTeamFunctionService {
  constructor(
    private firestore: AngularFirestore,
    private functions: AngularFireFunctions,
    private callableService: GthCallableService,
    private logger: GthErrorLoggerService,
  ) { }

  /**
   * Creates a team
   * @param {CreateTeamRequest} team Team Details
   * @return {string} Id of the created team
   */
  create$(team: CreateTeamRequest): Observable<string | null> {
    return from(this.create(team));
  }

  /**
   * Fetches a list of teams by query
   * @param {TeamListRequest} contract Team Filter Contract
   * @return {Team[]} List of teams
   */
  getTeams$(contract: TeamListRequest): Observable<Team[]> {
    return from(this.getTeams(contract)).pipe(
      map((teams) => teams.filter((t) => t.name)),
    );
  }

  /**
   * Fetch a team by id
   * @param {string} id Id of the team
   * @return {Team} Team if it exists
   */
  getTeamById$(id: string): Observable<Team | null> {
    return from(this.getTeamById(id));
  }

  /**
   * Deletes a team by id
   * @param {string} id Id of the team
   * @return {boolean} true if success
   */
  deleteTeamById$(id: string): Observable<boolean> {
    return from(this.deleteTeamById(id));
  }

  /**
   * Updates a team
   * @param {UpdateTeamRequest} team
   * @return {boolean} true if success
   */
  updateTeam$(team: UpdateTeamRequest): Observable<boolean> {
    return from(this.updateTeam(team));
  }

  getRatings$(id: string): Observable<ExternalTeamRatingItem[]> {
    return from(this.getRatings(id));
  }

  async getRatings(id: string): Promise<ExternalTeamRatingItem[]> {
    const ref = await this.firestore.collection(
      `${COLLECTION_NAME}/${id}/ratings`,
    ).get()
      .pipe(
        first(),
      )
      .toPromise();

    if (ref.empty) {
      return [];
    }

    return ref.docs.map((d) => d.data()) as ExternalTeamRatingItem[];
  }

  async rate(userId: string, id: string, ratings: TeamRating[]) {
    if (!id || !userId) return Promise.resolve(false);
    const ref = await this.firestore.collection(COLLECTION_NAME)
      .doc(id)
      .get()
      .pipe(
        first(),
      )
      .toPromise();

    if (!ref.exists) {
      return false;
    }

    await this.deleteRatingsByUserId(id, userId);

    const ratingsRef = this.firestore.collection(
      `${COLLECTION_NAME}/${id}/ratings`,
    );

    const requests = [];
    for (let i = 0; i < ratings.length; i++) {
      const model = ratings[i];
      requests.push(ratingsRef.add({
        userId,
        rating: model.id,
      }));
    }

    return Promise.allSettled(requests);
  }

  async deleteRatingsByUserId(id: string, userId: string) {
    const db = this.firestore.firestore;
    const batch = db.batch();
    const ref = db.collection(`${COLLECTION_NAME}/${id}/ratings`);
    const qs = await ref.where('userId', '==', userId).get();
    qs.docs.forEach((doc) => batch.delete(doc.ref));
    try {
      await batch.commit();
      return true;
    } catch {
      return false;
    }
  }

  private async create(team: CreateTeamRequest): Promise<string | null> {
    if (!this.functions) return Promise.resolve(null);

    const options: CallableOptions = {
      route: CallableRoutes.TEAMS_CREATE,
      data: team,
    };
    const results = await this.callableService.call(options) as Results<string>;
    const payload = results.payload as Payload<string>;
    const teamID = payload.data;
    this.log(`Team Created`);
    if (results.success) {
      return teamID;
    }
    return null;
  }

  private async getTeams(contract: TeamListRequest): Promise<Team[]> {
    if (!this.functions) return Promise.resolve([]);

    const options: CallableOptions = {
      route: CallableRoutes.TEAMS_LIST,
      data: contract,
    };

    const response = await this.callableService.call(options);
    if (!response || !response.payload) {
      return [];
    }

    const teams = (response.payload as any[]).map((d: any) => d.data as Team);
    this.log(`Teams fetched`);
    return teams ? teams : [];
  }

  private async getTeamById(id: string): Promise<Team | null> {
    if (!this.functions) return Promise.resolve(null);

    const options: CallableOptions = {
      route: CallableRoutes.TEAMS_READ,
      data: { id },
    };

    const response = (await this.callableService.call(options)) as Results<Team>;

    if (!response) return null;

    if (response.success && response.payload) {
      const result = response.payload as Payload<Team>;
      this.log(`Team fetched by id: ${id}`);
      return result.data;
    }

    return null;
  }

  private async deleteTeamById(id: string): Promise<boolean> {
    if (!this.functions) return Promise.resolve(false);

    const options: CallableOptions = {
      route: CallableRoutes.TEAMS_DELETE,
      data: { id },
    };

    (await this.callableService.call(options)) as Results<Team>;
    this.log(`Team deleted by id: ${id}`);
    return true;
  }

  private async updateTeam(team: UpdateTeamRequest): Promise<boolean> {
    if (!this.functions) return Promise.resolve(false);

    const options: CallableOptions = {
      route: CallableRoutes.TEAMS_UPDATE,
      data: team,
    };

    const response = (await this.callableService.call(options)) as Results<Team>;
    this.log(`Team updated by id: ${team.id}`);
    return response.success;
  }

  private log(text: string) {
    this.logger.debug(`${CONTEXT}: ${text}`);
  }
}
