import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectAvatarButtonComponent } from '@shared/components/select-avatar-button/select-avatar-button.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

const ANGULAR_MATERIAL_MODULES = [
  MatIconModule, MatButtonModule,
  MatProgressSpinnerModule,
];

const CORE_MODULES = [CommonModule];

const COMPONENTS = [SelectAvatarButtonComponent];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [...CORE_MODULES, ...ANGULAR_MATERIAL_MODULES],
  exports: [...COMPONENTS],
})
export class SelectAvatarButtonModule { }
